/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import AlertDialog from "app/components/confirmBox";
import DataTable2 from "app/components/shopperTable";
import AlertDialog2 from "app/components/confirmBoxDeliveryPickup";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import Tooltip from "@mui/material/Tooltip";

import IP from "app/IP";
import imageIP from "app/imageIP";

const axios = require("axios");

function SalesList() {
  const [menu, setMenu] = useState(null);
  const [data, setData] = useState([])
  const [salesData, setSalesData] = useState({ columns: [], rows: [] });
  const [index, setIndex] = useState(0);
  const [showConfirm, setShowConfirm] = useState(false);
  const [language, setLanguage] = useState(localStorage.getItem("Language"));
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [active, setActive] = useState(false);
  const [transport, setTransport] = useState("D");
  const [changeStatusAlert, setChangeStatusAlert] = useState(false);
  const [stores, setStores] = useState([])
  const [selectedStore, setSelectedStore] = useState();
  const [filterStatus, setFilterStatus] = useState(0) // 6 completed, 3 approved, 10 confirmed, 11 en revision, 0 all
  const [deliveryFee, setDeliveryFee] = useState();
  const [total, setTotal] = useState();
  const [status, setStatus] = useState([])
  const [selectedStatus, setSelectedStatus] = useState()
  const [saleId, setSaleId] = useState(0);
  const [role, setRole] = useState(0)
  const toggleSnackbar = () => setShow(!show);
  const toggleSnackbar2 = () => setShow2(!show2);

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);
  const navigate = useNavigate();

  async function DeleteSales() {
    await axios
      .post(
        IP + "/cancel/sales",
        { sales_order_id: localStorage.getItem("salesDeleteId") },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then(function (response) {
        localStorage.removeItem("salesDeleteId");
        getSalesData(role);
      })
      .catch(function (error) {
        // if (!error.response.data.authenticated) {
        //   navigate("/signIn");
        // }
        console.log(error);
      });
  }

  async function getStores() {
    await axios
    .get(IP + "/stores/getActive", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        Accept: "application/json",
      },
    })
    .then(function (response) {
      setStores(
        response.data.data.map((item) => {
          return { label: item.name, value: item.id };
        })
      );
    })
    .catch(function (error) {
      console.log(error.response.data.authenticated);
      if (!error.response.data.authenticated) {
        navigate("/signIn");
      }
    });
  }

  async function getUserDetails() {
    await axios
    .get(IP + "/user/profile", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(function (response) {
      let user = response.data.data
      setRole(user.role.id);
      getSalesData(user.role.id);
      if (user.role.id == 2 || user.role.id == 8)
        setFilterStatus(3)
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  async function changeStatus(id) {
    await axios
      .post(
        IP + "/sales/setActive/" + id,
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        toggleSnackbar();
        getSalesData(role);
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function confirmSalesOrder() {
    await axios
      .post(
        IP + "/submit/sales",
        { sales_order_id: saleId, transport: transport },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        toggleSnackbar2();
        getSalesData(role, filterStatus);
        setSaleId(0);
        setTimeout(() => {
          setShow2(false);
        }, 3000);
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function getSalesData(roleId,statusId, storeId) {
    let filter = ''
    if (statusId)
      filter = "?status_id=" + statusId
    await axios
      .get(IP + "/sales_orders" + filter, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        setData(response.data.data.filter((item) => {
          return item.status.status_id != 1;
        }))
        let salesColumns = roleId == 2 ? [
          {
            Header: language == 1 ? "Número de Orden" : "Sales Order Number",
            accessor: "num",
            width: "15%",
          },
          { Header: language == 1 ? "Tienda" : "Store", accessor: "store_id", width: "15%" },
          { Header: language == 1 ? "Fecha" : "Date", accessor: "date", width: "10%" },
          {
            Header: language == 1 ? "Total de Bultos" : "Total Quantity",
            accessor: "bultos",
            width: "5%",
          },
          //{ Header: language == 1 ? "Impuestos" : "Tax", accessor: "tax", width: "5%" },
          { Header: language == 1 ? "Estatus" : "Status", accessor: "status_id" },
          // { Header: language == 1 ? "Activo" : "Active", accessor: "active" },
          { Header: "Manual/Ecommerce", accessor: "manual" },
          { Header: language == 1 ? "Acción" : "Action", accessor: "action", width: "15%" },
        ] : [
          {
            Header: language == 1 ? "Número de Orden" : "Sales Order Number",
            accessor: "num",
            width: "15%",
          },
          { Header: language == 1 ? "Tienda" : "Store", accessor: "store_id", width: "15%" },
          { Header: language == 1 ? "Fecha" : "Date", accessor: "date", width: "10%" },
          {
            Header: language == 1 ? "Total de Bultos" : "Total Quantity",
            accessor: "bultos",
            width: "5%",
          },
          { Header: "Subtotal", accessor: "subtotal", width: "10%" },
          { Header: language == 1 ? "Cargo por Entrega" : "Delivery Fee", accessor: "delivery" },
          //{ Header: language == 1 ? "Impuestos" : "Tax", accessor: "tax", width: "5%" },
          { Header: language == 1 ? "Estatus" : "Status", accessor: "status_id" },
          // { Header: language == 1 ? "Activo" : "Active", accessor: "active" },
          { Header: "Manual/Ecommerce", accessor: "manual" },
          { Header: language == 1 ? "Acción" : "Action", accessor: "action", width: "15%" },
        ];

        let salesRows = response.data.data
          .filter((item) => {
            return (item.status.status_id != 1 || item.is_manual == 1) && (storeId ? storeId == item.store.store_id : true)
          })
          .map((item) => {
            //console.log(item);
            return {
              num: item.so_number,
              date: item.date,
              bultos: item.total_bultos_pedidos,
              delivery: item.delivery_fee,
              subtotal: item.subtotal,
              tax: item.tax,
              store_id: item.store.credit_check ? <MDTypography variant="button" color="error">{item.store.name}</MDTypography> : item.store.name,
              status_id: item.status.name,
              manual: item.is_manual == 1 ? "Manual" : "Ecommerce",
              active: item.active == "Active" ? 
                language==1 ? "Activo" : "Active" :
                language==1 ? "Inactivo" : "Inactive",
              action:
                item.status.status_id == 1 ? ( //pendiente
                  <>
                    <Grid>
                      {item.active == "Inactive" ? (
                        <Tooltip title={language == 1 ? "Activar" : "Activate"}>
                          <MDButton
                            variant="gradient"
                            color="success"
                            onClick={() => {
                              changeStatus(item.id);
                              setActive(false);
                            }}
                            iconOnly
                          >
                            <Icon>check</Icon>
                          </MDButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title={language == 1 ? "Desactivar" : "Deactivate"}>
                          <MDButton
                            variant="gradient"
                            color="warning"
                            onClick={() => {
                              changeStatus(item.id);
                              setActive(true);
                            }}
                            iconOnly
                          >
                            <Icon>do_not_disturb</Icon>
                          </MDButton>
                        </Tooltip>
                      )}{" "}
                      {/* <>
                        <Tooltip title={language == 1 ? "Ver Orden" : "View Order"}>
                          <MDButton
                            variant="gradient"
                            color="info"
                            onClick={() => {
                              localStorage.setItem("viewOrderId", item.id);
                              navigate("/view-sales");
                            }}
                            iconOnly
                          >
                            <Icon>visibility</Icon>
                          </MDButton>
                        </Tooltip>
                        &thinsp;
                      </> */}
                      <Tooltip title={language == 1 ? "Editar" : "Edit"}>
                        <MDButton
                          variant="gradient"
                          color="info"
                          iconOnly
                          onClick={() => {
                            navigate("/create-sale", {state: {id: item.id, store: {label: item.store.name, value: item.store.store_id}}});
                          }}
                        >
                          <Icon>edit</Icon>
                        </MDButton>
                      </Tooltip>{" "}
                      <>
                        <Tooltip title={language == 1 ? "Borrar" : "Delete"}>
                          <MDButton
                            variant="gradient"
                            color="error"
                            iconOnly
                            onClick={() => {
                              localStorage.setItem("salesDeleteId", item.id);
                              setShowConfirm(true);
                            }}
                          >
                            <Icon>delete</Icon>
                          </MDButton>
                        </Tooltip>
                        &thinsp;
                      </>
                    </Grid>
                  </>
                ) : item.status.status_id == 3 ? ( //aprobada
                  <Grid>
                    {item.active == "Inactive" ? (
                      <Tooltip title={language == 1 ? "Activar" : "Activate"}>
                        <MDButton
                          variant="gradient"
                          color="success"
                          onClick={() => {
                            changeStatus(item.id);
                            setActive(false);
                          }}
                          iconOnly
                        >
                          <Icon>checkmark</Icon>
                        </MDButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title={language == 1 ? "Desactivar" : "Deactivate"}>
                        <MDButton
                          variant="gradient"
                          color="warning"
                          onClick={() => {
                            changeStatus(item.id);
                            setActive(true);
                          }}
                          iconOnly
                        >
                          <Icon>do_not_disturb</Icon>
                        </MDButton>
                      </Tooltip>
                    )}{" "}
                    <Tooltip title={language == 1 ? "Ver Orden" : "View Order"}>
                      <MDButton
                        variant="gradient"
                        color="info"
                        onClick={() => {
                          localStorage.setItem("viewOrderId", item.id);
                          navigate("/view-sales");
                        }}
                        iconOnly
                      >
                        <Icon>visibility</Icon>
                      </MDButton>
                    </Tooltip>
                    &thinsp;
                    {(roleId == 2 || roleId == 8 || roleId == 1) && <Tooltip title={language == 1 ? "Empezar Envío" : "Start Shipping"}>
                      <MDButton
                        variant="gradient"
                        color="dark"
                        onClick={() => {
                          localStorage.setItem("SaleId", item.id);
                          navigate("/emos/shippingList");
                        }}
                        iconOnly
                      >
                        <Icon>local_shipping</Icon>
                      </MDButton>
                    </Tooltip>}
                    &thinsp;
                  </Grid>
                ) : item.status.status_id == 5 || item.status.status_id == 6 ? ( //parcialmente completada(5), completada(6)
                  <Grid>
                    <>
                      <Tooltip title={language == 1 ? "Ver Orden" : "View Order"}>
                        <MDButton
                          variant="gradient"
                          color="info"
                          onClick={() => {
                            localStorage.setItem("viewOrderId", item.id);
                            navigate("/view-sales");
                          }}
                          iconOnly
                        >
                          <Icon>visibility</Icon>
                        </MDButton>
                      </Tooltip>
                      &thinsp;
                    </>
                  </Grid>
                ) : item.status.status_id == 10 ? ( //confirmado
                  item.active == "Inactive" ? (
                    <>
                      <Tooltip title={language == 1 ? "Activar" : "Activate"}>
                        <MDButton
                          variant="gradient"
                          color="success"
                          onClick={() => {
                            changeStatus(item.id);
                            setActive(false);
                          }}
                          iconOnly
                        >
                          <Icon>checkmark</Icon>
                        </MDButton>
                      </Tooltip>
                    </>
                  ) : (
                    <>
                      <Tooltip title={language == 1 ? "Desactivar" : "Deactivate"}>
                        <MDButton
                          variant="gradient"
                          color="warning"
                          onClick={() => {
                            changeStatus(item.id);
                            setActive(false);
                          }}
                          iconOnly
                        >
                          <Icon>do_not_disturb</Icon>
                        </MDButton>
                      </Tooltip>{" "}
                      <Tooltip title={language == 1 ? "Ver Orden" : "View Order"}>
                        <MDButton
                          variant="gradient"
                          color="info"
                          onClick={() => {
                            localStorage.setItem("viewOrderId", item.id);
                            navigate("/view-sales");
                          }}
                          iconOnly
                        >
                          <Icon>visibility</Icon>
                        </MDButton>
                      </Tooltip>{" "}
                      {(roleId == 6 || roleId == 1) && <Tooltip title={language == 1 ? "Aprobar" : "Approve"}>
                        <MDButton
                          variant="gradient"
                          color="success"
                          onClick={() => {
                            setChangeStatusAlert(true);
                            setSaleId(item.id);
                            setActive(true);
                          }}
                          iconOnly
                        >
                          <Icon>thumb_up</Icon>
                        </MDButton>
                      </Tooltip>}
                      {" "}
                      {(roleId == 6 || roleId == 1) && <Tooltip title={language == 1 ? "Rechazar" : "Deny"}>
                        <MDButton
                          variant="gradient"
                          color="error"
                          iconOnly
                          onClick={() => {
                            localStorage.setItem("salesDeleteId", item.id);
                            setShowConfirm(true);
                          }}
                        >
                          <Icon>block</Icon>
                        </MDButton>
                      </Tooltip>}
                    </>
                  )
                ) : item.active == "Inactive" ? (
                  <>
                    <Tooltip title={language == 1 ? "Activar" : "Activate"}>
                      <MDButton
                        variant="gradient"
                        color="success"
                        onClick={() => {
                          changeStatus(item.id);
                          setActive(false);
                        }}
                      >
                        <Icon>checkmark</Icon>
                      </MDButton>
                    </Tooltip>
                    <Tooltip title={language == 1 ? "Ver Orden" : "View Order"}>
                      <MDButton
                        variant="gradient"
                        color="info"
                        onClick={() => {
                          localStorage.setItem("viewOrderId", item.id);
                          navigate("/view-sales");
                        }}
                        iconOnly
                      >
                        <Icon>visibility</Icon>
                      </MDButton>
                    </Tooltip>
                    &thinsp;
                  </>
                ) : (
                  <>
                    <Tooltip title={language == 1 ? "Desactivar" : "Deactivate"}>
                      <MDButton
                        variant="gradient"
                        color="warning"
                        onClick={() => {
                          changeStatus(item.id);
                        }}
                        iconOnly
                      >
                        <Icon>do_not_disturb</Icon>
                      </MDButton>
                    </Tooltip>
                    {"  "}
                    <Tooltip title={language == 1 ? "Ver Orden" : "View Order"}>
                      <MDButton
                        variant="gradient"
                        color="info"
                        onClick={() => {
                          localStorage.setItem("viewOrderId", item.id);
                          navigate("/view-sales");
                        }}
                        iconOnly
                      >
                        <Icon>visibility</Icon>
                      </MDButton>
                    </Tooltip>
                    
                    {"  "}
                    {(item.status.status_id == 11 && (roleId == 8 || roleId == 2 || roleId == 1)) && <Tooltip title={language == 1 ? "Descargar Reporte de Shipping" : "Download Shipping Report"}>
                      <MDButton
                        variant="gradient"
                        color="error"
                        href={imageIP + "salesDownload/" + item.id}
                        iconOnly
                      >
                        <Icon>download</Icon>
                      </MDButton>
                    </Tooltip>}
                    {" "}
                    {(item.status.status_id == 11 && (roleId == 8 || roleId == 1)) && <Tooltip title={language == 1 ? "Empezar Envío" : "Start Shipping"}>
                      <MDButton
                        variant="gradient"
                        color="dark"
                        onClick={() => {
                          localStorage.setItem("SaleId", item.id);
                          navigate("/emos/shippingList");
                        }}
                        iconOnly
                      >
                        <Icon>local_shipping</Icon>
                      </MDButton>
                    </Tooltip>}
                    &thinsp;
                  </>
                ),
            };
          });

        let tableData = { columns: salesColumns, rows: salesRows };
        //console.log(tableData)
        setSalesData(tableData);
      })
      .catch((error) => {
        // if (!error.response.data.authenticated) {
        //   navigate("/signIn");
        // }
        console.log(error);
      });
  }

  function filterSales(store, roleId) {
    let salesColumns = [
      {
        Header: language == 1 ? "Número de Orden" : "Sales Order Number",
        accessor: "num",
        width: "15%",
      },
      { Header: language == 1 ? "Tienda" : "Store", accessor: "store_id", width: "15%" },
      { Header: language == 1 ? "Fecha" : "Date", accessor: "date", width: "10%" },
      {
        Header: language == 1 ? "Total de Bultos" : "Total Quantity",
        accessor: "bultos",
        width: "5%",
      },
      { Header: "Subtotal", accessor: "subtotal", width: "10%" },
      { Header: language == 1 ? "Cargo por Entrega" : "Delivery Fee", accessor: "delivery" },
      //{ Header: language == 1 ? "Impuestos" : "Tax", accessor: "tax", width: "5%" },
      { Header: language == 1 ? "Estatus" : "Status", accessor: "status_id" },
      // { Header: language == 1 ? "Activo" : "Active", accessor: "active" },
      { Header: "Manual/Ecommerce", accessor: "manual" },
      { Header: language == 1 ? "Acción" : "Action", accessor: "action", width: "15%" },
    ];

    let rowsData = data.filter((item) => {
        return item.store.store_id == store.value;
      })

    if (store.value == 0) {
      rowsData = data
    }

    let salesRows = rowsData
    .map((item) => {
      //console.log(item);
      return {
        num: item.so_number,
        date: item.date,
        bultos: item.total_bultos_pedidos,
        delivery: item.delivery_fee,
        subtotal: item.subtotal,
        tax: item.tax,
        store_id: item.store.credit_check ? <MDTypography variant="button" color="error">{item.store.name}</MDTypography> : item.store.name,
        status_id: item.status.name,
        manual: item.is_manual == 1 ? "Manual" : "Ecommerce",
        active: item.active == "Active" ? 
          language==1 ? "Activo" : "Active" :
          language==1 ? "Inactivo" : "Inactive",
        action:
          item.status.status_id == 1 ? ( //pendiente
            <>
              <Grid>
                {item.active == "Inactive" ? (
                  <Tooltip title={language == 1 ? "Activar" : "Activate"}>
                    <MDButton
                      variant="gradient"
                      color="success"
                      onClick={() => {
                        changeStatus(item.id);
                        setActive(false);
                      }}
                      iconOnly
                    >
                      <Icon>check</Icon>
                    </MDButton>
                  </Tooltip>
                ) : (
                  <Tooltip title={language == 1 ? "Desactivar" : "Deactivate"}>
                    <MDButton
                      variant="gradient"
                      color="warning"
                      onClick={() => {
                        changeStatus(item.id);
                        setActive(true);
                      }}
                      iconOnly
                    >
                      <Icon>do_not_disturb</Icon>
                    </MDButton>
                  </Tooltip>
                )}{" "}
                {/* <>
                  <Tooltip title={language == 1 ? "Ver Orden" : "View Order"}>
                    <MDButton
                      variant="gradient"
                      color="info"
                      onClick={() => {
                        localStorage.setItem("viewOrderId", item.id);
                        navigate("/view-sales");
                      }}
                      iconOnly
                    >
                      <Icon>visibility</Icon>
                    </MDButton>
                  </Tooltip>
                  &thinsp;
                </> */}
                <Tooltip title={language == 1 ? "Editar" : "Edit"}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    iconOnly
                    onClick={() => {
                      navigate("/create-sale", {state: {id: item.id, store: {label: item.store.name, value: item.store.store_id}}});
                    }}
                  >
                    <Icon>edit</Icon>
                  </MDButton>
                </Tooltip>{" "}
                <>
                  <Tooltip title={language == 1 ? "Borrar" : "Delete"}>
                    <MDButton
                      variant="gradient"
                      color="error"
                      iconOnly
                      onClick={() => {
                        localStorage.setItem("salesDeleteId", item.id);
                        setShowConfirm(true);
                      }}
                    >
                      <Icon>delete</Icon>
                    </MDButton>
                  </Tooltip>
                  &thinsp;
                </>
              </Grid>
            </>
          ) : item.status.status_id == 3 || item.status.status_id == 6 ? ( //aprobada
            <Grid>
              {item.active == "Inactive" ? (
                <Tooltip title={language == 1 ? "Activar" : "Activate"}>
                  <MDButton
                    variant="gradient"
                    color="success"
                    onClick={() => {
                      changeStatus(item.id);
                      setActive(false);
                    }}
                    iconOnly
                  >
                    <Icon>checkmark</Icon>
                  </MDButton>
                </Tooltip>
              ) : (
                <Tooltip title={language == 1 ? "Desactivar" : "Deactivate"}>
                  <MDButton
                    variant="gradient"
                    color="warning"
                    onClick={() => {
                      changeStatus(item.id);
                      setActive(true);
                    }}
                    iconOnly
                  >
                    <Icon>do_not_disturb</Icon>
                  </MDButton>
                </Tooltip>
              )}{" "}
              <Tooltip title={language == 1 ? "Ver Orden" : "View Order"}>
                <MDButton
                  variant="gradient"
                  color="info"
                  onClick={() => {
                    localStorage.setItem("viewOrderId", item.id);
                    navigate("/view-sales");
                  }}
                  iconOnly
                >
                  <Icon>visibility</Icon>
                </MDButton>
              </Tooltip>
              &thinsp;
              {(roleId == 2 || roleId == 8 || roleId == 1) && <Tooltip title={language == 1 ? "Empezar Envío" : "Start Shipping"}>
                <MDButton
                  variant="gradient"
                  color="dark"
                  onClick={() => {
                    localStorage.setItem("SaleId", item.id);
                    navigate("/emos/shippingList");
                  }}
                  iconOnly
                >
                  <Icon>local_shipping</Icon>
                </MDButton>
              </Tooltip>}
              &thinsp;
            </Grid>
          ) : item.status.status_id == 5 || item.status.status_id == 6 ? ( //parcialmente completada(5), completada(6)
            <Grid>
              {item.active == "Inactive" ? (
                <Tooltip title={language == 1 ? "Activar" : "Activate"}>
                  <MDButton
                    variant="gradient"
                    color="success"
                    onClick={() => {
                      changeStatus(item.id);
                      setActive(false);
                    }}
                    iconOnly
                  >
                    <Icon>checkmark</Icon>
                  </MDButton>
                </Tooltip>
              ) : (
                <Tooltip title={language == 1 ? "Desactivar" : "Deactivate"}>
                  <MDButton
                    variant="gradient"
                    color="warning"
                    onClick={() => {
                      changeStatus(item.id);
                      setActive(true);
                    }}
                    iconOnly
                  >
                    <Icon>do_not_disturb</Icon>
                  </MDButton>
                </Tooltip>
              )}
              {"  "}
              <>
                <Tooltip title={language == 1 ? "Ver Orden" : "View Order"}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={() => {
                      localStorage.setItem("viewOrderId", item.id);
                      navigate("/view-sales");
                    }}
                    iconOnly
                  >
                    <Icon>visibility</Icon>
                  </MDButton>
                </Tooltip>
                &thinsp;
              </>
            </Grid>
          ) : item.status.status_id == 10 ? ( //confirmado
            item.active == "Inactive" ? (
              <>
                <Tooltip title={language == 1 ? "Activar" : "Activate"}>
                  <MDButton
                    variant="gradient"
                    color="success"
                    onClick={() => {
                      changeStatus(item.id);
                      setActive(false);
                    }}
                    iconOnly
                  >
                    <Icon>checkmark</Icon>
                  </MDButton>
                </Tooltip>
              </>
            ) : (
              <>
                <Tooltip title={language == 1 ? "Desactivar" : "Deactivate"}>
                  <MDButton
                    variant="gradient"
                    color="warning"
                    onClick={() => {
                      changeStatus(item.id);
                      setActive(false);
                    }}
                    iconOnly
                  >
                    <Icon>do_not_disturb</Icon>
                  </MDButton>
                </Tooltip>{" "}
                <Tooltip title={language == 1 ? "Ver Orden" : "View Order"}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={() => {
                      localStorage.setItem("viewOrderId", item.id);
                      navigate("/view-sales");
                    }}
                    iconOnly
                  >
                    <Icon>visibility</Icon>
                  </MDButton>
                </Tooltip>{" "}
                {(roleId == 6 || roleId == 1) && <Tooltip title={language == 1 ? "Aprobar" : "Approve"}>
                  <MDButton
                    variant="gradient"
                    color="success"
                    onClick={() => {
                      setChangeStatusAlert(true);
                      setSaleId(item.id);
                      setActive(true);
                    }}
                    iconOnly
                  >
                    <Icon>thumb_up</Icon>
                  </MDButton>
                </Tooltip>}
                {" "}
                {(roleId == 6 || roleId == 1) && <Tooltip title={language == 1 ? "Rechazar" : "Deny"}>
                  <MDButton
                    variant="gradient"
                    color="error"
                    iconOnly
                    onClick={() => {
                      localStorage.setItem("salesDeleteId", item.id);
                      setShowConfirm(true);
                    }}
                  >
                    <Icon>block</Icon>
                  </MDButton>
                </Tooltip>}
              </>
            )
          ) : item.active == "Inactive" ? (
            <>
              <Tooltip title={language == 1 ? "Activar" : "Activate"}>
                <MDButton
                  variant="gradient"
                  color="success"
                  onClick={() => {
                    changeStatus(item.id);
                    setActive(false);
                  }}
                >
                  <Icon>checkmark</Icon>
                </MDButton>
              </Tooltip>
              <Tooltip title={language == 1 ? "Ver Orden" : "View Order"}>
                <MDButton
                  variant="gradient"
                  color="info"
                  onClick={() => {
                    localStorage.setItem("viewOrderId", item.id);
                    navigate("/view-sales");
                  }}
                  iconOnly
                >
                  <Icon>visibility</Icon>
                </MDButton>
              </Tooltip>
              &thinsp;
            </>
          ) : (
            <>
              <Tooltip title={language == 1 ? "Desactivar" : "Deactivate"}>
                <MDButton
                  variant="gradient"
                  color="warning"
                  onClick={() => {
                    changeStatus(item.id);
                  }}
                  iconOnly
                >
                  <Icon>do_not_disturb</Icon>
                </MDButton>
              </Tooltip>
              {"  "}
              <Tooltip title={language == 1 ? "Ver Orden" : "View Order"}>
                <MDButton
                  variant="gradient"
                  color="info"
                  onClick={() => {
                    localStorage.setItem("viewOrderId", item.id);
                    navigate("/view-sales");
                  }}
                  iconOnly
                >
                  <Icon>visibility</Icon>
                </MDButton>
              </Tooltip>
              
              {"  "}
              {(item.status.status_id == 11 && (roleId == 8 || roleId == 2 || roleId == 1)) && <Tooltip title={language == 1 ? "Descargar Reporte de Shipping" : "Download Shipping Report"}>
                <MDButton
                  variant="gradient"
                  color="error"
                  href={imageIP + "salesDownload/" + item.id}
                  iconOnly
                >
                  <Icon>download</Icon>
                </MDButton>
              </Tooltip>}
              {" "}
              {(item.status.status_id == 11 && (roleId == 8 || roleId == 1)) && <Tooltip title={language == 1 ? "Empezar Envío" : "Start Shipping"}>
                <MDButton
                  variant="gradient"
                  color="dark"
                  onClick={() => {
                    localStorage.setItem("SaleId", item.id);
                    navigate("/emos/shippingList");
                  }}
                  iconOnly
                >
                  <Icon>local_shipping</Icon>
                </MDButton>
              </Tooltip>}
              &thinsp;
            </>
          ),
      };
    });

    let tableData = { columns: salesColumns, rows: salesRows };
    //console.log(tableData)
    setSalesData(tableData);
  }

  async function GetStatus() {
    // console.log("aqui");
    //setRedirect(true);
    await axios
      .get(IP + "/statuses?sales=1", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(function (response) {
        //console.log(response.data.data);
        setStatus(
          response.data.data.map(item=>{
            return { label: item.name, id: item.id }
          })
        );
      })
      .catch(function (error) {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  useEffect(() => {
    getUserDetails()
    getStores();
    GetStatus()
  }, []);

  return (
    <DashboardLayout>
      <AlertDialog
        Show={showConfirm}
        setShowConfirm={setShowConfirm}
        Message={language == 1 ? "¿Estás seguro que quieres denegar esta orden?" : "Are you sure you want to deny this sale?"}
        OkayFunction={DeleteSales}
      />
      <AlertDialog
        Show={changeStatusAlert}
        setShowConfirm={setChangeStatusAlert}
        Message={
          language == 1
            ? "¿Estás seguro que quieres que se apruebe esta Orden de Venta?"
            : "Are you sure you want to approve this Sales Order?"
        }
        OkayFunction={confirmSalesOrder}
      />
      <MDSnackbar
        color="info"
        icon="notifications"
        title=""
        content={
          language == 1
            ? `Venta ${active ? "Desactivada" : "Activada"} Exitosamente!`
            : `Sale ${active ? "Deactivated" : "Activated"} Succesfully!`
        }
        dateTime={language==1 ? "Ahora" : "Now"}
        open={show}
        close={toggleSnackbar}
      />
      <MDSnackbar
        color="info"
        icon="notifications"
        title=""
        content={language == 1 ? "La venta ha sido aprobada!" : "Sale Aproved!"}
        dateTime={language==1 ? "Ahora" : "Now"}
        open={show2}
        close={() => setShow2(false)}
      />
      <DashboardNavbar />

      <MDBox my={3}>
        {/* <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          <MDBox display="flex">
            <MDBox ml={1}>
              <MDButton variant="outlined" color="dark">
                <Icon>description</Icon>
                &nbsp;{language == 1 ? "Exportar CSV" : "export csv"}
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox> */}
        <Card>
          <Grid container>
            <Grid item lg={10.5}>
              <Grid container>
                <Autocomplete
                  disablePortal
                  sx={{ width: 275, mt: 2, ml: 2 }}
                  value={selectedStore}
                  onChange={(event, newValue) => {
                    setSelectedStore(newValue);
                    if (newValue == null) {
                      getSalesData(role,selectedStatus?.value, newValue)
                    } else {
                      filterSales(newValue,role)
                    }
                  }}
                  options={stores}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={language == 1 ? "Tiendas" : "Stores"}
                      variant="standard"
                    />
                  )}
                />
                {status.length > 0 && <Autocomplete
                  disablePortal
                  sx={{ width: 275, mt: 2, ml: 2 }}
                  value={selectedStatus}
                  onChange={(event, newValue) => {
                    setSelectedStatus(newValue);
                    getSalesData(role,newValue?.id ?? 0, selectedStore?.value)
                  }}
                  options={status}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={language == 1 ? "Estatus" : "Status"}
                      variant="standard"
                    />
                  )}
                />}
              </Grid>
            </Grid>
            <MDBox
              display="flex"
              justifyContent="right"
              alignItems="flex-start"
            >
              {role == 1 && <MDButton
                variant="gradient"
                color="info"
                sx={{ mt: 3, ml: 2, float: 'right' }}
                onClick={()=>navigate('/create-sale')}
              >
                {language == 1 ? "Entrada Manual" : "Manual Entry"}
              </MDButton>}
            </MDBox>
          </Grid>
          {/* <MDBox
            display="flex"
            justifyContent="left"
            alignItems="flex-start"
            ml={2}
            mt={2}
          >
            {role != 2 && <MDButton
              color="dark"
              size="medium"
              onClick={() => {
                setFilterStatus(0)
                setSelectedStore()
                getSalesData(role)
              }}
              variant={filterStatus === 0 ? "gradient" : "outlined"}
            >
              <Icon>density_small</Icon>
              &nbsp;{language == 1 ? "Todas" : "All"}
            </MDButton>}
            {role != 2 && <MDButton
              sx={{ ml: 1 }}
              color="success"
              size="medium"
              onClick={() => {
                setFilterStatus(6)
                setSelectedStore()
                getSalesData(role,6)
              }}
              variant={filterStatus === 6 ? "gradient" : "outlined"}
            >
              <Icon>check_circle</Icon>
              &nbsp;{language == 1 ? "Completadas" : "Completed"}
            </MDButton>}
            {role != 2 && <MDButton
              sx={{ ml: 1 }}
              color="error"
              size="medium"
              onClick={() => {
                setFilterStatus(11)
                setSelectedStore()
                getSalesData(role,11)
              }}
              variant={filterStatus === 11 ? "gradient" : "outlined"}
            >
              <Icon>grading</Icon>
              &nbsp;{language == 1 ? "En Revisión" : "In Review"}
            </MDButton>}
            {role != 2 && <MDButton
              sx={{ ml: 1 }}
              color="warning"
              size="medium"
              onClick={() => {
                setFilterStatus(3)
                setSelectedStore()
                getSalesData(role,3)
              }}
              variant={filterStatus === 3 ? "gradient" : "outlined"}
            >
              <Icon>thumb_up</Icon>
              &nbsp;{language == 1 ? "Aprobadas" : "Approved"}
            </MDButton>}
            {role != 2 && <MDButton
              sx={{ ml: 1 }}
              color="info"
              size="medium"
              onClick={() => {
                setFilterStatus(10)
                setSelectedStore()
                getSalesData(role,10)
              }}
              variant={filterStatus === 10 ? "gradient" : "outlined"}
            >
              <Icon>task</Icon>
              &nbsp;{language == 1 ? "Confirmadas" : "Confirmed"}
            </MDButton>}
          </MDBox> */}
          <DataTable2
            table={salesData}
            index={index}
            setIndex={setIndex}
            pageEntries={10}
            entriesPerPage={true}
            canSearch
          />
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default SalesList;
