/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav.
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
  11. The `module` is a value that is stored in the Modules table within the database.
*/

// Material Dashboard 2 PRO React layouts
import Analytics from "layouts/dashboards/analytics";
import Sales from "layouts/dashboards/sales";
import ProfileOverview from "layouts/pages/profile/profile-overview";
import AllProjects from "layouts/pages/profile/all-projects";
import NewUser from "layouts/pages/users/new-user";
import Settings from "layouts/pages/account/settings";
import Billing from "layouts/pages/account/billing";
import Invoice from "layouts/pages/account/invoice";
import Timeline from "layouts/pages/projects/timeline";
import PricingPage from "layouts/pages/pricing-page";
import Widgets from "layouts/pages/widgets";
import RTL from "layouts/pages/rtl";
import Charts from "layouts/pages/charts";
import Notifications from "layouts/pages/notifications";
import Kanban from "layouts/applications/kanban";
import Wizard from "layouts/applications/wizard";
import DataTables from "layouts/applications/data-tables";
import Calendar from "layouts/applications/calendar";
import NewProduct from "layouts/ecommerce/products/new-product";
import EditProducts from "layouts/ecommerce/products/edit-product";
import ProductPage from "layouts/ecommerce/products/product-page";
import OrderList from "layouts/ecommerce/orders/order-list";
import OrderDetails from "layouts/ecommerce/orders/order-details";
import SignInBasic from "layouts/authentication/sign-in/basic";
import SignInCover from "layouts/authentication/sign-in/cover";
import SignInIllustration from "layouts/authentication/sign-in/illustration";
import SignUpCover from "layouts/authentication/sign-up/cover";
import ResetCover from "layouts/authentication/reset-password/cover";

import TaxList from "app/taxes/taxlist";
import AddTax from "app/taxes/addtax";
import EditTax from "app/taxes/editTax";
import WarehouseList from "app/warehouses/warehouses";
import AddWarehouse from "app/warehouses/addWarehouse";
import EditWarehouse from "app/warehouses/editWarehouse";
import MeasurementList from "app/measurements/measurements";
import AddMeasurement from "app/measurements/addMeasurements";
import EditMeasurement from "app/measurements/editMeasurement";
import Cities from "app/cities/cities";
import AddCity from "app/cities/addcity";
import EditCity from "app/cities/editcity";
import Categories from "app/categories/categories";
import Addcategory from "app/categories/addCategory";
import EditCategory from "app/categories/editCategory";
import SubCategories from "app/subcategories/subcategories";
import AddSubCategory from "app/subcategories/addSubCategory";
import EditSubCategory from "app/subcategories/editSubCategory";
import ProductList from "app/products/products";
import AddProduct2 from "app/products/addProduct";
import EditProduct2 from "app/products/editProduct";
import PurchaseOrders from "app/purchase orders/purchaseOrder";
import AddPurchaseOrder from "app/purchase orders/addPurchaseOrder";
import EditPurchaseOrder from "app/purchase orders/editPurchaseOrder";
import ViewPurchaseOrder from "app/purchase orders/viewPurchaseOrder";
import SalesList from "app/sales/sales";
import SalesEcommerce from "app/ecommerce/salesEcommerce";
import EditSale from "app/sales/editSales";
import ViewSale from "app/sales/viewSales";
import SalesInvoice from "app/sales/salesInvoice";
import RegisterPayments from "app/registerPayments/registerPayments";
import OnSaleList from "app/onSale/onSale";
import AddOnSale from "app/onSale/createOnSale";
import ViewOrders from "app/ecommerce/viewOrders";
import CreateFamily from "app/families/createFamily";
import EditFamily from "app/families/editFamily";
import Family from "app/families/families";
import AddReceiving from "app/receivings/createReceivings";
import EditReceiving from "app/receivings/editReceivings";
import Ecommerce from "app/ecommerce/ecommerce";

// Material Dashboard 2 PRO React components
import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";

import routesR from "routes2";

// Images
import profilePicture from "assets/images/team-3.jpg";
import ShopperList from "app/shopper/shopper";
import AddShopper from "app/shopper/createShopper";
import EditOnSale from "app/onSale/editOnSale";
import ReportMinimumList from "app/reports/reportMinimum";
import ReportInvetoryList from "app/reports/reportInventory";
import ReportProfitsList from "app/reports/reportProfit";
import ReportSalesList from "app/reports/reportSales";
import ReportExpirationList from "app/reports/reportExpiration";
import ReportSolicitedList from "app/reports/reportSolicited";
import ReportPaymentsList from "app/reports/reportPayments";
import ReportBestSeller from "app/reports/reportBestSeller";
import EditShopper from "app/shopper/editShopper";
import ReportSalesByStore from "app/reports/reportSalesByStore";
import RoleAccess from "app/roleAccess/roleAccess";
import ViewInvoice from "app/invoices/viewInvoice";
import PaymentDetails from "app/payments/paymentDetails";
import EditPayment from "app/payments/editPayment";
import ManageCarousel from "app/carousel/manageCarousel";
import ViewOrder from "app/ecommerce/viewSingleOrder";
import CarouselView from "app/carousel/viewCarousel";
import ViewDelivery from "app/deliveries/deliveries";
import CreateDelivery from "app/deliveries/createDelivery";
import EditDelivery from "app/deliveries/editDelivery";
import ViewPaymentsAdmin from "app/payments/viewPaymentsAdmin";
import BestSellersOrders from "app/reports/viewBestSellerSales";
import CreateCarousel from "app/carousel/createCarousel";
import ViewZones from "app/zones/zones";
import CreateZone from "app/zones/createZone";
import EditZone from "app/zones/editZone";
import StorePayments from "app/stores/storePayments";
import StorePaymentsCreate from "app/stores/storePaymentsCreate";
import StorePaymentsEdit from "app/stores/storePaymentsEdit";
import StoreInvoiceList from "app/invoices/storeInvoiceIndex";
import ViewPayments from "app/payments/viewPayments";
import InvoiceListAdmin from "app/invoices/invoiceIndexAdmin";
import imageIP from "app/imageIP";
import CreateSalesOrder from "app/sales/createSales";
import ReportNoImage from "app/reports/reportNoImage";
import ReportTaxes from "app/reports/reportTaxes";
import ReportExcemption from "app/reports/reportExcemption";
import ImageCopy from "app/products/imageCopy";
import ReportNewProducts from "app/reports/reportNewProducts";
import SyncMultiBusiness from "app/sync_multibusiness/syncMultibusiness";
import ReportPurchases from "app/reports/reportPurchases";
import PurchaseOrderValidation from "app/purchase orders/purchaseOrderValidation";
import ReportInvoicePayments from "app/reports/reportInvoicePayments";
import ReportAging from "app/reports/reportAging";
import AdjustInvoice from "app/invoices/adjustInvoice";

const routes = routesR.concat([
  { hide: true, type: "divider", key: "divider-0" },
  {
    type: "collapse",
    name: "Inventory",
    nombre: "Inventario",
    key: "inventory",
    module: "Inventory",
    roles: [1],
    icon: <Icon fontSize="medium">inventory</Icon>,
    collapse: [
      {
        name: "Products",
        nombre: "Productos",
        key: "products",
        route: "/products",
        component: <ProductList />,
      },
    ],
  },
  {
    hide: true,
    type: "collapse",
    name: "Create Family",
    key: "createfamily",
    route: "/createfamily",
    module: "Inventory",
    roles: [1, 3, 7],
    icon: <Icon>shopping_bag</Icon>,
    component: <CreateFamily />,
    noCollapse: true,
  },
  {
    hide: true,
    type: "collapse",
    name: "Edit Family",
    key: "editfamily",
    route: "/editfamily",
    module: "Inventory",
    roles: [1, 3, 7],
    icon: <Icon>shopping_bag</Icon>,
    component: <EditFamily />,
    noCollapse: true,
  },
  {
    hide: true,
    type: "collapse",
    name: "Family",
    key: "families",
    route: "/families",
    module: "Inventory",
    roles: [1, 3, 7],
    icon: <Icon>shopping_bag</Icon>,
    component: <Family />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Ecommerce",
    key: "ecommerce",
    route: "/ecommerce",
    module: "Ecommerce",
    roles: [1, 3, 7],
    icon: <Icon>shopping_bag</Icon>,
    component: <Ecommerce />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Payments",
    nombre: "Pagos",
    module: "Payments",
    key: "pagos",
    roles: [1],
    icon: <Icon fontSize="medium">attach_money</Icon>,
    collapse: [
      {
        name: "Register Payments",
        nombre: "Registrar Pagos",
        key: "registerpagos",
        route: "/emos/registerPayments",
        component: <RegisterPayments />,
      },
      {
        name: "View Payments",
        nombre: "Ver Pagos",
        key: "viewpayments",
        route: "/emos/viewPayments",
        component: <ViewPayments />,
      },
      // {
      //   name: "View Invoices",
      //   nombre: "Facturas",
      //   key: "invoices",
      //   module: "Payments",
      //   route: "/invoices",
      //   component: <InvoiceList />,
      // },
      // {
      //   name: "View Invoices",
      //   nombre: "Ver Facturas",
      //   module: "Invoice Admin",
      //   key: "invoicesadmin",
      //   route: "/invoices",
      //   component: <InvoiceListAdmin />,
      // },
    ],
  },
  {
    type: "collapse",
    name: "Invoices",
    nombre: "Facturas",
    key: "invoice",
    route: "/invoices",
    module: "Invoices",
    roles: [1, 3, 7],
    icon: <Icon>receipt</Icon>,
    component: <InvoiceListAdmin />,
    noCollapse: true,
  },
  {
    name: "Invoice Adjustments",
    key: "invoiceadjustments",
    module: "Invoices",
    route: "/invoice-adjustments",
    component: <AdjustInvoice />,
  },
  // {
  //   type: "collapse",
  //   name: "Payments",
  //   nombre: "Pagos",
  //   key: "storepayments",
  //   route: "/store-payments",
  //   module: "Ecommerce",
  //   roles: [1, 3, 7],
  //   icon: <Icon>attach_money</Icon>,
  //   component: <ViewPayments />,
  //   noCollapse: true,
  // },
  {
    type: "collapse",
    name: "View Orders",
    nombre: "Ver Ordenes",
    key: "vieworders",
    route: "/vieworders",
    module: "View Orders",
    roles: [1],
    icon: <Icon>checklist</Icon>,
    component: <ViewOrders />,
    noCollapse: true,
  },

  // {
  //   name: "Ecommerce 2",
  //   key: "ecommerce2",
  //   module: "Stores",
  //   route: "/ecommerce-purchase",
  //   component: <Ecommerce />,
  // },
  {
    name: "Sales Invoice",
    key: "salesinvoice",
    module: "Stores",
    route: "/sales-invoice",
    component: <SalesInvoice />,
  },
  {
    name: "Invoice View",
    key: "invoiceview",
    module: "Payments",
    route: "/view-invoice",
    component: <ViewInvoice />,
  },
  {
    name: "View Order",
    nombre: "Ver Orden",
    key: "vieworder",
    module: "Stores",
    route: "/view-order",
    component: <ViewSale />,
  },
  {
    name: "Create Product",
    key: "createproduct",
    module: "Stores",
    route: "/create-product",
    component: <AddProduct2 />,
  },
  {
    name: "Edit Product",
    key: "editproduct",
    module: "Stores",
    route: "/edit-product",
    component: <EditProduct2 />,
  },
  {
    name: "Store Payments",
    key: "storepayments",
    module: "Stores",
    route: "/store-payments",
    component: <StorePayments />,
  },
  {
    name: "Create Store Payments",
    key: "createstorepayments",
    module: "Stores",
    route: "/create-store-payments",
    component: <StorePaymentsCreate />,
  },
  {
    name: "Edit Store Payments",
    key: "editstorepayments",
    module: "Stores",
    route: "/edit-store-payments",
    component: <StorePaymentsEdit />,
  },
  {
    name: "Edit Product",
    key: "editproduct",
    module: "Stores",
    route: "/edit-product",
    component: <EditProduct2 />,
  },
  {
    name: "Payment Details",
    key: "paymentdetails",
    module: "Payments",
    route: "/payment-details",
    component: <PaymentDetails />,
  },
  {
    type: "collapse",
    name: "Sales",
    nombre: "Ventas",
    module: "Sales",
    key: "sales",
    roles: [1, 3],
    icon: <Icon fontSize="medium">discount</Icon>,
    collapse: [
      {
        name: "View Sales",
        nombre: "Ver Ventas",
        key: "viewsales",
        route: "/sales",
        component: <SalesList />,
      },
    ],
  },
  {
    name: "Create Sale",
    key: "createsale",
    route: "/create-sale",
    module: "Stores",
    component: <CreateSalesOrder />,
  },
  {
    name: "Edit Sales",
    key: "editsales",
    module: "Stores",
    route: "/edit-sales",
    component: <EditSale />,
  },
  {
    name: "View Sale",
    key: "viewsale",
    module: "Stores",
    route: "/view-sales",
    component: <ViewSale />,
  },
  {
    name: "Validate Orders",
    key: "validateorders",
    module: "Purchase Orders",
    route: "/validate-purchase-orders",
    component: <PurchaseOrderValidation />,
  },
  {
    type: "collapse",
    name: "Purchase",
    module: "Purchase Orders",
    nombre: "Compras",
    roles: [1],
    key: "purchase",
    icon: <Icon fontSize="medium">shop</Icon>,
    collapse: [
      {
        name: "Purchase Orders",
        nombre: "Ordenes de Compra",
        key: "purchaseorders",
        route: "/purchase_orders",
        component: <PurchaseOrders />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Reports",
    nombre: "Reportes",
    module: "Reports",
    key: "reports",
    roles: [1, 3],
    icon: <Icon fontSize="medium">analytics</Icon>,
    collapse: [
      {
        name: "Aging",
        nombre: "Aging",
        key: "agingreport",
        route: "/aging-report",
        component: <ReportAging />,
      },
      {
        name: "Low Stock",
        nombre: "Poca Cantidad",
        key: "minimumstockproducts",
        route: "/minimum-stock-report",
        component: <ReportMinimumList />,
      },
      {
        nombre: "Productos sin Imagen",
        name: "Products without Image",
        key: "productsnoimage",
        route: "/no-image-report",
        component: <ReportNoImage />,
      },
      {
        nombre: "Productos Nuevos",
        name: "New Products",
        key: "newproducts",
        route: "/new-products-report",
        component: <ReportNewProducts />,
      },
      {
        name: "Inventory",
        nombre: "Inventario",
        key: "inventoryreport",
        route: "/inventory-report",
        component: <ReportInvetoryList />,
      },
      {
        name: "Profit",
        nombre: "Ganancia",
        key: "profitreport",
        route: "/profit-report",
        component: <ReportProfitsList />,
      },
      {
        name: "Sales",
        nombre: "Ventas",
        key: "salesreport",
        route: "/store-sales-report",
        component: <ReportSalesList />,
      },
      {
        name: "Sales By Store",
        nombre: "Ventas Por Tiendas",
        key: "salesbystore",
        route: "/sales-by-store-report",
        component: <ReportSalesByStore />,
      },
      {
        name: "Purchases",
        nombre: "Compras",
        key: "purchasesreports",
        route: "/purchases-report",
        component: <ReportPurchases />,
      },
      {
        name: "Expirations",
        nombre: "Expiraciones",
        key: "expirationsreport",
        route: "/expirations-report",
        component: <ReportExpirationList />,
      },
      {
        name: "Payments",
        nombre: "Pagos",
        key: "paymentsreport",
        route: "/payments-report",
        component: <ReportPaymentsList />,
      },
      {
        name: "Invoice Payments",
        nombre: "Cobros",
        key: "invoicepaymentsreport",
        route: "/invoice-payments-report",
        component: <ReportInvoicePayments />,
      },
      {
        name: "Best Seller",
        nombre: "Más Vendido",
        key: "bestsellerreport",
        route: "/best-seller-reports",
        component: <ReportBestSeller />,
      },
      {
        name: "Taxes",
        nombre: "Impuestos",
        key: "taxesreport",
        route: "/taxes-report",
        component: <ReportTaxes />,
      },
      {
        name: "Tax Excemption",
        nombre: "Excepción",
        key: "taxexcemptionreport",
        route: "/excemption-report",
        component: <ReportExcemption />,
      },
    ],
  },
  {
    name: "Create Purchase Order",
    key: "createpurchaseorder",
    module: "Purchase Orders",
    route: "/create-purchase_order",
    component: <AddPurchaseOrder />,
  },
  {
    name: "View Purchase Order",
    key: "viewpurchaseorder",
    module: "Purchase Orders",
    route: "/view-purchase_order",
    component: <ViewPurchaseOrder />,
  },
  {
    name: "Edit Purchase Order",
    key: "editpurchaseorder",
    module: "Purchase Orders",
    route: "/edit-purchase_order",
    component: <EditPurchaseOrder />,
  },
  // {
  //   name: "Best Sellers Orders",
  //   key: "bestsellersorders",
  //   module: "reports",
  //   route: "/best-sellers-orders",
  //   component: <BestSellersOrders />
  // },
  {
    name: "Create Receivings",
    key: "createreceivings",
    module: "Purchase Orders",
    route: "/create-receiving",
    component: <AddReceiving />,
  },
  {
    name: "Edit Receivings",
    key: "editreceivings",
    module: "Purchase Orders",
    route: "/edit-receivings",
    component: <EditReceiving />,
  },
  {
    name: "Adjust Payments",
    key: "adjustpayments",
    module: "Payments",
    route: "/adjust-payment",
    component: <EditPayment />,
  },
  {
    name: "Edit Carousel",
    nombre: "Editar Carrusel",
    key: "editcarousel",
    module: "Configuration",
    route: "/edit-carousel",
    component: <ManageCarousel />,
  },
  {
    name: "Create Carousel",
    nombre: "Crear Carrusel",
    key: "createcarousel",
    module: "Configuration",
    route: "/create-carousel",
    component: <CreateCarousel />,
  },
  {
    type: "collapse",
    name: "Configuration",
    module: "Configuration",
    nombre: "Configuración",
    roles: [1],
    key: "configuration",
    icon: <Icon fontSize="medium">settings</Icon>,
    collapse: [
      // {
      //   name: "Warehouses",
      //   nombre: "Almacenes",
      //   key: "warehouses",
      //   route: "/warehouses",
      //   component: <WarehouseList />,
      // },
      {
        name: "Categories",
        nombre: "Categorías",
        key: "categories",
        route: "/categories",
        component: <Categories />,
      },
      {
        name: "Sub-Category",
        nombre: "Sub-Categorías",
        key: "subcategory",
        route: "/subcategories",
        component: <SubCategories />,
      },
      {
        name: "Taxes",
        nombre: "Impuestos",
        key: "taxes",
        route: "/taxes",
        component: <TaxList />,
      },
      {
        name: "Delivery",
        nombre: "Envío",
        key: "delivery",
        route: "/delivery",
        component: <ViewDelivery />,
      },
      // {
      //   name: "Cities",
      //   nombre: "Ciudades",
      //   key: "cities",
      //   route: "/cities",
      //   component: <Cities />,
      // },
      {
        name: "Zones",
        nombre: "Zonas",
        key: "zones",
        route: "/zones",
        component: <ViewZones />,
      },
      {
        name: "Image Copy",
        nombre: "Copiar Imagenes",
        key: "imagecopy",
        route: "/imagecopy",
        component: <ImageCopy />,
      },
      {
        name: "On Sale",
        nombre: "Especiales",
        key: "onsale",
        route: "/on-sale",
        component: <OnSaleList />,
      },
      {
        name: "Shopper",
        nombre: "Shopper",
        key: "shopper",
        route: "/shopper",
        component: <ShopperList />,
      },
      {
        name: "Carousel",
        nombre: "Carrusel",
        key: "carousel",
        route: "/carousel",
        component: <CarouselView />,
      },
      {
        name: "Role Access",
        nombre: "Acceso de Roles",
        key: "roleaccess",
        route: "/role-access",
        component: <RoleAccess />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Sync Multi-business",
    nombre: "Sync Multi-business",
    key: "syncmultibusiness",
    route: "/syncmultibusiness",
    module: "All",
    roles: [1],
    icon: <Icon>shopping_bag</Icon>,
    component: <SyncMultiBusiness />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "View Shopper",
    nombre: "Ver Shopper",
    key: "viewshopper",
    href: imageIP + "shopperPDF",
    module: "All",
    roles: [1],
    icon: <Icon>qr_code</Icon>,
    noCollapse: true,
  },
  {
    name: "Create Shopper",
    key: "createshopper",
    route: "/create-shopper",
    component: <AddShopper />,
  },
  {
    name: "Edit Shopper",
    key: "editshopper",
    route: "/edit-shopper",
    component: <EditShopper />,
  },
  {
    name: "Edit On Sale",
    key: "editonsale",
    route: "/edit-on-sale",
    component: <EditOnSale />,
  },
  {
    name: "Create On Sale",
    key: "createonsale",
    route: "/create-on-sale",
    component: <AddOnSale />,
  },
  {
    name: "Create Zone",
    nombre: "Crear Zona",
    key: "createzone",
    route: "/create-zone",
    component: <CreateZone />,
  },
  {
    name: "Edit Zone",
    nombre: "Editar Zona",
    key: "editzone",
    route: "/edit-zone",
    component: <EditZone />,
  },
  {
    name: "Create Delivery",
    key: "createdelivery",
    route: "/create-delivery",
    component: <CreateDelivery />,
  },
  {
    name: "Edit Delivery",
    key: "editdelivery",
    route: "/edit-delivery",
    component: <EditDelivery />,
  },
  // {
  //   name: "Create Warehouse",
  //   key: "createwarehouse",
  //   route: "/create-warehouse",
  //   component: <AddWarehouse />,
  // },
  // {
  //   name: "Edit Warehouse",
  //   key: "editwarehouse",
  //   route: "/edit-warehouse",
  //   component: <EditWarehouse />,
  // },
  {
    name: "Edit Category",
    key: "editcategory",
    route: "/edit-category",
    component: <EditCategory />,
  },
  {
    name: "Create Category",
    key: "createcategory",
    route: "/create-category",
    component: <Addcategory />,
  },
  {
    name: "Create Sub-Category",
    key: "createsubcategory",
    route: "/create-subcategory",
    component: <AddSubCategory />,
  },
  {
    name: "Edit Sub-Category",
    key: "editsubcategory",
    route: "/edit-subcategory",
    component: <EditSubCategory />,
  },
  {
    name: "Create Tax",
    key: "createtax",
    route: "/create-tax",
    component: <AddTax />,
  },
  // {
  //   name: "Create City",
  //   key: "createcity",
  //   route: "/create-city",
  //   component: <AddCity />,
  // },
  // {
  //   name: "Edit City",
  //   key: "editcity",
  //   route: "/edit-city",
  //   component: <EditCity />,
  // },
  {
    hide: true,
    type: "collapse",
    name: "Measurements",
    module: "Configuration",
    nombre: "Medidas",
    roles: [1],
    key: "measurements",
    icon: <Icon fontSize="medium">square_foot</Icon>,
    collapse: [
      {
        name: "View Measurements",
        nombre: "Ver Medidas",
        key: "viewmeasurements",
        route: "/measurements",
        component: <MeasurementList />,
      },
    ],
  },
  {
    name: "Edit Measurement",
    key: "editmeasurement",
    module: "Configuration",
    route: "/edit-measurement",
    component: <EditMeasurement />,
  },
  {
    name: "Edit Tax",
    key: "edittax",
    module: "Configuration",
    route: "/edit-tax",
    component: <EditTax />,
  },

  {
    hide: true,
    type: "collapse",
    name: "Brooklyn Alice",
    key: "brooklyn-alice",
    icon: <MDAvatar src={profilePicture} alt="Brooklyn Alice" size="sm" />,
    collapse: [
      {
        name: "My Profile",
        key: "my-profile",
        route: "/pages/profile/profile-overview",
        component: <ProfileOverview />,
      },
      {
        name: "Settings",
        key: "profile-settings",
        route: "/pages/account/settings",
        component: <Settings />,
      },
      {
        name: "Logout",
        key: "logout",
        route: "/authentication/sign-in/basic",
        component: <SignInBasic />,
      },
    ],
  },
  { hide: true, type: "divider", key: "divider-0" },
  {
    hide: true,
    type: "collapse",
    name: "Dashboards",
    key: "dashboards",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        name: "Analytics",
        key: "analytics",
        route: "/dashboards/analytics",
        component: <Analytics />,
      },
      {
        name: "Sales",
        key: "sales",
        route: "/dashboards/sales",
        component: <Sales />,
      },
    ],
  },
  // { hide: true, type: "title", title: "Pages", key: "title-pages" },
  {
    hide: false,
    type: "collapse",
    name: "Pages",
    key: "pages",
    icon: <Icon fontSize="medium">image</Icon>,
    collapse: [
      {
        name: "Profile",
        key: "profile",
        collapse: [
          {
            name: "Profile Overview",
            key: "profile-overview",
            route: "/pages/profile/profile-overview",
            component: <ProfileOverview />,
          },
          {
            name: "All Projects",
            key: "all-projects",
            route: "/pages/profile/all-projects",
            component: <AllProjects />,
          },
        ],
      },
      {
        name: "Users",
        key: "users",
        collapse: [
          {
            name: "New User",
            key: "new-user",
            route: "/pages/users/new-user",
            component: <NewUser />,
          },
        ],
      },
      {
        name: "Payments Admin View",
        key: "paymentsadminview",
        module: "Users",
        route: "/paymentsAdminView",
        component: <ViewPaymentsAdmin />,
      },
      {
        hide: true,
        name: "Account",
        key: "account",
        collapse: [
          {
            name: "Settings",
            key: "settings",
            route: "/pages/account/settings",
            component: <Settings />,
          },
          {
            name: "Billing",
            key: "billing",
            route: "/pages/account/billing",
            component: <Billing />,
          },
          {
            name: "Invoice",
            key: "invoice",
            route: "/pages/account/invoice",
            component: <Invoice />,
          },
        ],
      },
      {
        hide: true,
        name: "Projects",
        key: "projects",
        collapse: [
          {
            name: "Timeline",
            key: "timeline",
            route: "/pages/projects/timeline",
            component: <Timeline />,
          },
        ],
      },
      {
        name: "Pricing Page",
        key: "pricing-page",
        route: "/pages/pricing-page",
        component: <PricingPage />,
      },
      { name: "RTL", key: "rtl", route: "/pages/rtl", component: <RTL /> },
      { name: "Widgets", key: "widgets", route: "/pages/widgets", component: <Widgets /> },
      { name: "Charts", key: "charts", route: "/pages/charts", component: <Charts /> },
      {
        name: "Notfications",
        key: "notifications",
        route: "/pages/notifications",
        component: <Notifications />,
      },
    ],
  },
  {
    hide: true,
    type: "collapse",
    name: "Applications",
    key: "applications",
    icon: <Icon fontSize="medium">apps</Icon>,
    collapse: [
      {
        name: "Kanban",
        key: "kanban",
        route: "/applications/kanban",
        component: <Kanban />,
      },
      {
        name: "Wizard",
        key: "wizard",
        route: "/applications/wizard",
        component: <Wizard />,
      },
      {
        name: "Data Tables",
        key: "data-tables",
        route: "/applications/data-tables",
        component: <DataTables />,
      },
      {
        name: "Calendar",
        key: "calendar",
        route: "/applications/calendar",
        component: <Calendar />,
      },
    ],
  },
  {
    hide: true,
    type: "collapse",
    name: "Ecommerce",
    key: "ecommerce",
    icon: <Icon fontSize="medium">shopping_basket</Icon>,
    collapse: [
      {
        name: "Products",
        key: "products",
        collapse: [
          {
            name: "New Product",
            key: "new-product",
            route: "/ecommerce/products/new-product",
            component: <NewProduct />,
          },
          {
            name: "Edit Product",
            key: "edit-product",
            route: "/ecommerce/products/edit-product",
            component: <EditProducts />,
          },
          {
            name: "Product Page",
            key: "product-page",
            route: "/ecommerce/products/product-page",
            component: <ProductPage />,
          },
        ],
      },
      {
        hide: true,
        name: "Orders",
        key: "orders",
        collapse: [
          {
            name: "Order List",
            key: "order-list",
            route: "/ecommerce/orders/order-list",
            component: <OrderList />,
          },
          {
            name: "Order Details",
            key: "order-details",
            route: "/ecommerce/orders/order-details",
            component: <OrderDetails />,
          },
        ],
      },
    ],
  },
  {
    hide: true,
    type: "collapse",
    name: "Authentication",
    key: "authentication",
    icon: <Icon fontSize="medium">content_paste</Icon>,
    collapse: [
      {
        name: "Sign In",
        key: "sign-in",
        collapse: [
          {
            name: "Basic",
            key: "basic",
            route: "/authentication/sign-in/basic",
            component: <SignInBasic />,
          },
          {
            name: "Cover",
            key: "cover",
            route: "/authentication/sign-in/cover",
            component: <SignInCover />,
          },
          {
            name: "Illustration",
            key: "illustration",
            route: "/authentication/sign-in/illustration",
            component: <SignInIllustration />,
          },
        ],
      },
      {
        name: "Sign Up",
        key: "sign-up",
        collapse: [
          {
            name: "Cover",
            key: "cover",
            route: "/authentication/sign-up/cover",
            component: <SignUpCover />,
          },
        ],
      },
      {
        name: "Reset Password",
        key: "reset-password",
        collapse: [
          {
            name: "Cover",
            key: "cover",
            route: "/authentication/reset-password/cover",
            component: <ResetCover />,
          },
        ],
      },
    ],
  },
  { hide: true, type: "divider", key: "divider-1" },
  { hide: true, type: "title", title: "Docs", key: "title-docs" },
  {
    hide: true,
    type: "collapse",
    name: "Basic",
    key: "basic",
    icon: <Icon fontSize="medium">upcoming</Icon>,
    collapse: [
      {
        name: "Getting Started",
        key: "getting-started",
        collapse: [
          {
            name: "Overview",
            key: "overview",
            href: "https://www.creative-tim.com/learning-lab/react/overview/material-dashboard/",
          },
          {
            name: "License",
            key: "license",
            href: "https://www.creative-tim.com/learning-lab/react/license/material-dashboard/",
          },
          {
            name: "Quick Start",
            key: "quick-start",
            href: "https://www.creative-tim.com/learning-lab/react/quick-start/material-dashboard/",
          },
          {
            name: "Build Tools",
            key: "build-tools",
            href: "https://www.creative-tim.com/learning-lab/react/build-tools/material-dashboard/",
          },
        ],
      },
      {
        name: "Foundation",
        key: "foundation",
        collapse: [
          {
            name: "Colors",
            key: "colors",
            href: "https://www.creative-tim.com/learning-lab/react/colors/material-dashboard/",
          },
          {
            name: "Grid",
            key: "grid",
            href: "https://www.creative-tim.com/learning-lab/react/grid/material-dashboard/",
          },
          {
            name: "Typography",
            key: "base-typography",
            href: "https://www.creative-tim.com/learning-lab/react/base-typography/material-dashboard/",
          },
          {
            name: "Borders",
            key: "borders",
            href: "https://www.creative-tim.com/learning-lab/react/borders/material-dashboard/",
          },
          {
            name: "Box Shadows",
            key: "box-shadows",
            href: "https://www.creative-tim.com/learning-lab/react/box-shadows/material-dashboard/",
          },
          {
            name: "Functions",
            key: "functions",
            href: "https://www.creative-tim.com/learning-lab/react/functions/material-dashboard/",
          },
          {
            name: "Routing System",
            key: "routing-system",
            href: "https://www.creative-tim.com/learning-lab/react/routing-system/material-dashboard/",
          },
        ],
      },
    ],
  },
  {
    hide: true,
    type: "collapse",
    name: "Components",
    key: "components",
    icon: <Icon fontSize="medium">view_in_ar</Icon>,
    collapse: [
      {
        name: "Alerts",
        key: "alerts",
        href: "https://www.creative-tim.com/learning-lab/react/alerts/material-dashboard/",
      },
      {
        name: "Avatar",
        key: "avatar",
        href: "https://www.creative-tim.com/learning-lab/react/avatar/material-dashboard/",
      },
      {
        name: "Badge",
        key: "badge",
        href: "https://www.creative-tim.com/learning-lab/react/badge/material-dashboard/",
      },
      {
        name: "Badge Dot",
        key: "badge-dot",
        href: "https://www.creative-tim.com/learning-lab/react/badge-dot/material-dashboard/",
      },
      {
        name: "Box",
        key: "box",
        href: "https://www.creative-tim.com/learning-lab/react/box/material-dashboard/",
      },
      {
        name: "Buttons",
        key: "buttons",
        href: "https://www.creative-tim.com/learning-lab/react/buttons/material-dashboard/",
      },
      {
        name: "Date Picker",
        key: "date-picker",
        href: "https://www.creative-tim.com/learning-lab/react/datepicker/material-dashboard/",
      },
      {
        name: "Dropzone",
        key: "dropzone",
        href: "https://www.creative-tim.com/learning-lab/react/dropzone/material-dashboard/",
      },
      {
        name: "Editor",
        key: "editor",
        href: "https://www.creative-tim.com/learning-lab/react/quill/material-dashboard/",
      },
      {
        name: "Input",
        key: "input",
        href: "https://www.creative-tim.com/learning-lab/react/input/material-dashboard/",
      },
      {
        name: "Pagination",
        key: "pagination",
        href: "https://www.creative-tim.com/learning-lab/react/pagination/material-dashboard/",
      },
      {
        name: "Progress",
        key: "progress",
        href: "https://www.creative-tim.com/learning-lab/react/progress/material-dashboard/",
      },
      {
        name: "Snackbar",
        key: "snackbar",
        href: "https://www.creative-tim.com/learning-lab/react/snackbar/material-dashboard/",
      },
      {
        name: "Social Button",
        key: "social-button",
        href: "https://www.creative-tim.com/learning-lab/react/social-buttons/material-dashboard/",
      },
      {
        name: "Typography",
        key: "typography",
        href: "https://www.creative-tim.com/learning-lab/react/typography/material-dashboard/",
      },
    ],
  },
  {
    hide: true,
    type: "collapse",
    name: "Change Log",
    key: "changelog",
    href: "https://github.com/creativetimofficial/ct-material-dashboard-pro-react/blob/main/CHANGELOG.md",
    icon: <Icon fontSize="medium">receipt_long</Icon>,
    noCollapse: true,
  },
]);

export default routes;
