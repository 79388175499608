import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Icon } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import ProductCell from "layouts/ecommerce/products/product-page/components/ProductCell";
import MDAvatar from "components/MDAvatar";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import MDDatePicker from "components/MDDatePicker";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// NewUser page components
import UserInfo from "layouts/pages/users/new-user/components/UserInfo";
import Address from "layouts/pages/users/new-user/components/Address";
import Socials from "layouts/pages/users/new-user/components/Socials";
import Profile from "layouts/pages/users/new-user/components/Profile";
import FormField from "layouts/pages/users/new-user/components/FormField";

// NewUser layout schemas for form and form feilds
import validations from "layouts/pages/users/new-user/schemas/validations";
import form from "layouts/pages/users/new-user/schemas/form";
import initialValues from "layouts/pages/users/new-user/schemas/initialValues";
import MDInput from "components/MDInput";

import PurchaseOrderModal from "app/components/purchaseOrderModal";
import AlertDialog from "app/components/confirmBox";
import AlertDialog2 from "app/components/confirmBoxDeliveryPickup";
import AlertDialogPurchase from "app/components/confirmBoxPurchaseOrder";

import IP from "app/IP";
import imageIP from "app/imageIP";

const axios = require("axios");

function CreateSalesOrder() {
  const [currentSale, setCurrentSale] = useState([])
  const [currentShopper, setCurrentShopper] = useState([])
  const [stores, setStores] = useState([])
  const [selectedStore, setSelectedStore] = useState()
  const [salesOrderId, setSalesOrderId] = useState(null)
  const [special, setSpecial] = useState(false);
  const [discount, setDiscount] = useState();
  const [subtotal, setSubtotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [taxTotal, setTaxTotal] = useState(0);
  const [qtyTotal, setQtyTotal] = useState(0);
  const [deliveryFeeAmount, setDeliveryFeeAmount] = useState(0)
  const [previousBalance, setPreviousBalance] = useState(0)
  const [selectedProduct, setSelectedProduct] = useState()
  const [quantity, setQuantity] = useState(0)
  const [transport, setTransport] = useState('C')
  const [productCheck, setProductCheck] = useState([]);
  const [added, setAdded] = useState(false);
  const [vendorProducts, setVendorProducts] = useState({ columns: [], rows: [] });
  const [show, setShow] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [errors, setErrors] = useState([]);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false)
  const [open3, setOpen3] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [prod, setProd] = useState("");
  const [changeTable, setChangeTable] = useState(true);
  const [purchaseOProducts, setPurchaseOProducts] = useState([]);
  const [isQtyEdit, setIsQtyEdit] = useState(false);
  const [prodToDel, setProdToDel] = useState(null);
  const [language, setLanguage] = useState(localStorage.getItem("Language"));
  const [changeStatus, setChangeStatus] = useState(false);
  const [currentTotal, setCurrentTotal] = useState(0.00)
  const [deliveries, setDeliveries] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false)
  const [show2, setShow2] = useState(false)
  const [showmsg2, setShowMsg2] = useState("");
  const [deliveryOptions, setDeliveryOptions] = useState([]);
  const toggleSnackbar = () => setShow(!show);
  const toggleSnackbar2 = () => setShowMsg2(!showmsg2);
  const toggleSnackbar3 = () => setShow2(!show2);
  const [additionalFees, setAdditionalFees] = useState(0.0);
  const {state} = useLocation()
  const navigate = useNavigate();

  async function getOnSale() {
    // console.log("aqui");
    //setRedirect(true);
    await axios
      .get(IP + "/onsale/products", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(function (response) {
        response.data.data.map((item) => {
          currentSale.push(item);
        });
      })
      .catch(function (error) {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function getShopper() {
    // console.log("aqui");
    //setRedirect(true);
    await axios
      .get(IP + "/shopper/products", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(function (response) {
        response.data.data.map((item) => {
          currentShopper.push(item);
        });
      })
      .catch(function (error) {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function getProducts(salesId) {
    await axios
      .get(
        IP +
          (salesId ?? salesOrderId
            ? "/ecommerce/" + salesId ?? salesOrderId
            : "/products"),
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        if (salesId ?? salesOrderId) {
          setProductCheck(response.data.data?.selected);
          if (response.data.data.selected.length == 0)
            setChangeTable(true)
        }

        let saleIds = currentSale.map((item) => {
          return item.product_id;
        });

        let shopperIds = currentShopper.filter((item)=>{
          return item.isShopper == true
        }).map((item) => {
          return item.product_id;
        });

        let expressIds = currentShopper.filter((item)=>{
          return item.isExpress == true
        }).map((item) => {
          return item.product_id;
        });

        let vendorProductColumns = [
          { Header: language == 1 ? "Imagen" : "Image", accessor: "image"},
          { Header: "UPC", accessor: "upc"},
          { Header: language == 1 ? "UPC Unidad" : "UPC Unit", accessor: "upc_unit"},
          { Header: language == 1 ? "Código" : "Code", accessor: "code", width: "5%" },
          { Header: language == 1 ? "Descripción" : "Description", accessor: "name", width: '20%'},
          {
            Header: language == 1 ? "Disponible" : "Available",
            accessor: "availability",
          },
          { Header: language == 1 ? "Empaque" : "Package", accessor: "package" },
          { Header: language == 1 ? "Precio" : "Price", accessor: "price" },
          { Header: language == 1 ? "Oferta" : "Sale", accessor: "sale"},
          { Header: language == 1 ? "Acción" : "Action", accessor: "action", width: '13%' },
        ];
        //test
        let vendorProductRows = (
          (salesOrderId ?? salesId) ? response?.data?.data?.all : response.data.data
        ).filter(item=>{
          return item.active == "Active"
        }).map((item) => {
          return {
            image: (
              <>
                <ProductCell name="" image={imageIP + item.image} />
                <MDButton
                  variant="gradient"
                  color="info"
                  iconOnly
                  style={{ height: "1", width: "1" }}
                  onClick={() => {
                    setOpen2(true)
                    setSelectedProduct(item)
                  }}
                >
                  <Icon>visibility</Icon>
                </MDButton>
              </>
            ),
            subdept: item.subcategory.name,
            upc: item.upc,
            code: item.code,
            upc_unit: item.upcUnit,
            sale: (
              <>
                {saleIds.includes(item.id) ? (
                  <MDTypography variant="button" color="info">
                    {language == 1 ? "En Oferta! " : "On Sale! "}
                  </MDTypography>
                ) : null}
                {shopperIds.includes(item.id) ? (
                  <MDTypography variant="button" sx={{ color: "red" }}>
                    Shopper{" "}
                  </MDTypography>
                ) : null}
                {expressIds.includes(item.id) ? (
                  <MDTypography variant="button" sx={{ color: "orange" }}>
                    Express{" "}
                  </MDTypography>
                ) : null}
              </>
            ),
            price: saleIds.includes(item.id) ? (
              <>
                <MDTypography
                  sx={{ textDecoration: "line-through" }}
                  variant="button"
                  color="error"
                >
                  {item.sales_price}
                </MDTypography>
                <MDTypography sx={{ ml: 1 }} variant="button" color="success">
                  {
                    currentSale.filter((sale) => {
                      return sale.product_id == item.id;
                    })[0].specialPrice
                  }
                </MDTypography>
              </>
            ) : (
              item.sales_price
            ),
            name: item.name,
            availability: item.available,
            package: item.packaging,
            action: (
              <MDButton
                color="info"
                onClick={() => {
                  setOpen(true);
                  setSelectedProduct(item)
                }}
                type="button"
                variant="gradient"
              >
                <Icon>shopping_cart</Icon>
                &nbsp;{language == 1 ? "Añadir al carrito" : "Add to cart"}
              </MDButton>
            ),
          };
        });

        let tableData = { columns: vendorProductColumns ?? [], rows: vendorProductRows ?? [] };
        setVendorProducts(tableData);

        setPreviousBalance(response.data.data?.store?.store_balance ?? 0)
        setDeliveryFeeAmount(response.data.data?.store?.location?.fee)

        let cartColumns = [
          { Header: language == 1 ? "Imagen" : "Image", accessor: "image", width: "2%" },
          { Header: "UPC", accessor: "upc", width: "5%" },
          { Header: language == 1 ? "UPC Unidad" : "UPC Unit", accessor: "upc_unit", width: "5%" },
          { Header: language == 1 ? "Código" : "Code", accessor: "code", width: "5%" },
          { Header: language == 1 ? "Oferta" : "Sale", accessor: "sale", width: "3%" },
          { Header: language == 1 ? "Descripción" : "Description", accessor: "name", width: "15%" },
          {
            Header: language == 1 ? "Disponible" : "Available",
            accessor: "availability",
            width: "7%",
          },
          { Header: language == 1 ? "Empaque" : "Package", accessor: "package", width: "6%" },
          { Header: language == 1 ? "Precio" : "Price", accessor: "price", width: "8%" },
          { Header: language == 1 ? "Cantidad" : "Quantity", accessor: "qty", width: "5%" },
          { Header: language == 1 ? "Impuesto" : "Tax", accessor: "tax", width: "5%" },
          { Header: "Total", accessor: "total", width: "5%" },
          { Header: language == 1 ? "Acción" : "Action", accessor: "action", width: "10%" },
        ];

        let cartRows = []

        response?.data?.data?.selected?.map((item) => {
          if (saleIds.includes(item.product.id)) {
            currentSale
              .filter((sale) => {
                return sale.product_id == item.product.id;
              })
              .map((sale) => {
                cartRows.push({
                  image: (
                    <MDAvatar
                      src={imageIP + item.product.image}
                      style={{ cursor: "pointer" }}
                      size="xl"
                      variant="rounded"
                      onClick={() => {setOpen2(true), setSelectedProduct(item.product)}}
                    />
                  ),
                  sale: (
                    <>
                      <MDTypography variant="button" color="info">
                        {language == 1 ? "En Oferta! " : "On Sale! "}
                      </MDTypography>
                      {shopperIds.includes(item.product.id) ? (
                        <MDTypography variant="button" sx={{ color: "red" }}>
                          Shopper{" "}
                        </MDTypography>
                      ) : null}
                      {expressIds.includes(item.product.id) ? (
                        <MDTypography variant="button" sx={{ color: "orange" }}>
                          Express{" "}
                        </MDTypography>
                      ) : null}
                    </>
                  ),
                  upc: item.product.upc,
                  code: item.product.code,
                  upc_unit: item.product.upcUnit,
                  name: item.product.name,
                  package: item.product.packaging,
                  price: sale.specialPrice,
                  availability: item.product.available,
                  tax: item.product.tax.percent + '%',
                  qty: item.quantity,
                  total: parseFloat(
                    sale.specialPrice * item.quantity +
                      sale.specialPrice * item.quantity * (item.product.tax.percent / 100)
                  ).toFixed(2),
                  action: (
                    <Grid>
                      <Tooltip title={language == 1 ? "Editar" : "Edit"}>
                        <MDButton
                          variant="gradient"
                          iconOnly
                          color="info"
                          onClick={() => {
                            setSelectedProduct(item);
                            setQuantity(item.quantity);
                            setSpecial(true);
                            setDiscount(sale.specialPrice);
                            setOpen3(true);
                          }}
                          autoFocus
                        >
                          <Icon>edit</Icon>
                        </MDButton>
                      </Tooltip>
                      {"   "}
                      <Tooltip title={language == 1 ? "Borrar" : "Delete"}>
                        <MDButton
                          variant="gradient"
                          iconOnly
                          color="error"
                          onClick={() => {
                            setSelectedProduct(item);
                            setShowDelete(true);
                          }}
                          autoFocus
                        >
                          <Icon>delete</Icon>
                        </MDButton>
                      </Tooltip>
                    </Grid>
                  ),
                });
              });
          } else {
            cartRows.push({
              image: (
                <MDAvatar
                  src={imageIP + item.product.image}
                  style={{ cursor: "pointer" }}
                  size="xl"
                  variant="rounded"
                  onClick={() => {
                    setSelectedProduct(item.product), setOpen2(true);
                  }}
                />
              ),
              sale: (
                <>
                  {shopperIds.includes(item.product.id) ? (
                    <MDTypography variant="button" sx={{ color: "red" }}>
                      Shopper{" "}
                    </MDTypography>
                  ) : null}
                  {expressIds.includes(item.product.id) ? (
                    <MDTypography variant="button" sx={{ color: "orange" }}>
                      Express{" "}
                    </MDTypography>
                  ) : null}
                </>
              ),
              upc: item.product.upc,
              upc_unit: item.product.upcUnit,
              name: item.product.name,
              package: item.product.packaging,
              price: parseFloat(item.product.sales_price).toFixed(2),
              availability: item.product.available,
              tax: item.product.tax.percent + '%',
              qty: item.quantity,
              total: parseFloat(item.total).toFixed(2),
              action: (
                <Grid>
                  <Tooltip title={language == 1 ? "Editar" : "Edit"}>
                    <MDButton
                      variant="gradient"
                      iconOnly
                      color="info"
                      onClick={() => {
                        setSelectedProduct(item);
                        setQuantity(item.quantity);
                        setSpecial(false);
                        setOpen3(true);
                      }}
                      autoFocus
                    >
                      <Icon>edit</Icon>
                    </MDButton>
                  </Tooltip>
                  {"   "}
                  <Tooltip title={language == 1 ? "Borrar" : "Delete"}>
                    <MDButton
                      variant="gradient"
                      iconOnly
                      color="error"
                      onClick={() => {
                        setSelectedProduct(item);
                        setShowDelete(true);
                      }}
                      autoFocus
                    >
                      <Icon>delete</Icon>
                    </MDButton>
                  </Tooltip>
                </Grid>
              ),
            });
          }
        });

        let subPrices = []

        response.data.data.selected?.map((item) => {
          subPrices.push(
          (saleIds.includes(item.product.id) ? 
            currentSale
              .filter((sale) => {
                return sale.product_id == item.product.id;
              })[0].specialPrice
          : parseFloat(item.price)) * item.quantity)
        });

        let totalSub = subPrices.reduce((prev, current) => prev + current, 0)
        setSubtotal(totalSub)

        let prices = [];

        response.data.data.selected?.map((item) => {
          let sale = currentSale
          .filter((sale) => {
            return sale.product_id == item.product.id;
          })[0]
          prices.push(saleIds.includes(item.product.id) ? 
            sale.specialPrice * item.quantity +
              sale.specialPrice * item.quantity * (item.product.tax.percent / 100)
           : parseFloat(item.total))
        });
        console.log(prices)

        let totalPrice = prices.reduce((prev, current) => prev + current, 0);
        setTotal(totalPrice);

        let taxPrices = []

        response.data.data.selected.map(item=>{
          let sale = currentSale
          .filter((sale) => {
            return sale.product_id == item.product.id;
          })[0]
          taxPrices.push(saleIds.includes(item.product.id) 
            ?  sale.specialPrice * item.quantity * (item.product.tax.percent / 100) 
            :  parseFloat(item.tax)
          )
        })
        
        let totalTax = taxPrices.reduce((prev, current) => prev + current, 0.00)

        setTaxTotal(totalTax);

        let qty = response.data.data.selected
          ?.map((item) => {
            return item.quantity;
          })
          .reduce((prev, current) => prev + current, 0);
        setQtyTotal(qty);

        let tableData2 = { columns: cartColumns ?? [], rows: cartRows ?? [] };
        setPurchaseOProducts(tableData2);
      })
      .catch((error) => {
        // if (!error.response.data.authenticated) {
        //   navigate("/signIn");
        // }
        console.log(error);
      });
  }

  async function DeleteOrderDetailProduct() {
    await axios
      .delete(IP + "/so_detail/" + selectedProduct.id, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(function (response) {
        getProducts(salesOrderId);
        toggleSnackbar2()
        setTimeout(() => {
          setShowMsg2(false);
        }, 5000);
      })
      .catch(function (error) {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function getSalesId(body) {
    console.log('getting id')
    await axios
      .post(IP + "/sales_orders/manual", body, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        setSalesOrderId(response.data.data.id)
        AddProduct(body, response.data.data.id);
      })
      .catch((error) => {
        // setErrors(error.response.data.data);
        console.log(error);
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        if (error.response?.data?.data?.store) {
          // setShowM4(true);
          // setError(error.response?.data?.data?.store)
        }
      });
  }

  async function AddProduct(body, salesId) {
    console.log('adding product')
    console.log(body.product.quantity, body.product.product_id);
    //setCartItems(true)
    if (body.product.product_id && body.product.quantity && (salesId ?? salesOrderId)) {
      await axios
        .post(
          IP + "/sales_orders/" + (salesId ?? salesOrderId) + "/so_detail",
          {
            quantity: body.product.quantity,
            product_id: body.product.product_id,
            sales_order_id: salesId ?? salesOrderId,
            tax: 0,
            quantity_delivered: 0,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          console.log(response);
          getProducts(salesId);
          toggleSnackbar();
          setTimeout(() => {
            setShow(false)
          }, 3000);
        })
        .catch((error) => {
          if (!error.response.data.authenticated) {
            navigate("/signIn");
          }
          console.log(error);
        });
    }
  }

  async function updateCart() {
    await axios
      .put(
        IP + "/so_detail/" + selectedProduct.id,
        {
          sales_order_id: salesOrderId,
          product_id: selectedProduct.product.id,
          quantity: quantity,
          quantity_delivered: selectedProduct.quantity_delivered,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then(function (response) {
        setQuantity(0);
        getProducts(salesOrderId);
        toggleSnackbar3();
        setTimeout(() => {
          setShow2(false);
        }, 5000);
      })
      .catch(function (error) {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  function TestValidation(message) {
    if (message)
      return message.map((item) => {
        return (
          <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
            <label>{item}</label>
          </MDTypography>
        );
      });
    else return null;
  }

  function ShowError(param) {
    // console.log(errors["name"]);
    if (param.id) return TestValidation(errors[param.id]);
    else return null;
  }

  async function CambiarEstatus() {
    await axios
      .post(
        IP + "/confirmed/sales",
        {
          sales_order_id: salesOrderId,
          transport: transport,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        setShowConfirm(!showConfirm)
        setTimeout(() => {
          // window.location.reload(false);
          navigate("/sales");
        }, 3000);
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        if (error.response.data.message == 300) {
          setShowError(true)
          setErrorProd(error.response.data.data)
        }
        console.log(error);
      });
  }

  async function getStores() {
    await axios
    .get(IP + "/stores/getActive", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        Accept: "application/json",
      },
    })
    .then(function (response) {
      setStores(
        response.data.data.map((item) => {
          return { label: item.name, value: item.id };
        })
      );
    })
    .catch(function (error) {
      console.log(error.response.data.authenticated);
      if (!error.response.data.authenticated) {
        navigate("/signIn");
      }
    });
  }

  const getTotal = () => {
    return currentTotal
  }

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  useEffect(() => {
    //getPurchaseOrder();
    //getDeliveryOptions();
    //getProducts()
    getShopper()
    getOnSale()
    getStores()
    if (state?.id) {
      setSalesOrderId(state?.id)
      setSelectedStore(state?.store)
      getProducts(state?.id)
    }
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <PurchaseOrderModal
        open={false}
        setAdded={setAdded}
        currentTotal={getTotal()}
        setCurrentTotal={setCurrentTotal}
        Product={prod}
        setOpen={setOpen}
        setVendorProducts={setVendorProducts}
        setPurchaseOProducts={setPurchaseOProducts}
        isEdit={isQtyEdit}
        setIsQtyEdit={setIsQtyEdit}
        setProd={setProd}
        deleteProd={""}
        setProdToDel={setProdToDel}
      />
      <AlertDialog
        Show={showDelete}
        setShowConfirm={setShowDelete}
        Message={
          language == 1
            ? "¿Estás seguro que quieres que se borre este producto de la orden?"
            : "Are you sure you want remove this product from the order?"
        }
        OkayFunction={DeleteOrderDetailProduct}
      />
      <MDSnackbar
        color="info"
        icon="notifications"
        title={language == 1 ? "EMOS Notificación" : "EMOS Notification"}
        content={language == 1 ? "El Carrito fue actualizado." : "The Cart has been updated."}
        dateTime={language==1 ? "Ahora" : "Now"}
        open={show2}
        close={toggleSnackbar3}
      />
      <MDSnackbar
        color="info"
        icon="notifications"
        title={language == 1 ? "EMOS Notificación" : "EMOS Notification"}
        content={
          language == 1
            ? "La orden fue confirmada exitosamente!"
            : "The order was confirmed successfully!"
        }
        dateTime={language==1 ? "Ahora" : "Now"}
        open={showConfirm}
        close={()=>setShowConfirm(!showConfirm)}
      />
      <ThemeProvider theme={darkTheme}>
        <Dialog open={open}>
          <DialogTitle>{language == 1 ? "Producto" : "Product"}: {selectedProduct?.name}</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              value={quantity}
              label={language == 1 ? "Cantidad" : "Order Qty"}
              type="number"
              onChange={(e) => {
                if ((e.target.value >= 0 || e.target.value == "") && e.target.value <= selectedProduct?.available) {
                  setQuantity(e.target.value);
                }
              }}
              fullWidth
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={()=>{setOpen(!open), setQuantity(0)}}>{language == 1 ? "Cancelar" : "Cancel"}</Button>
            <Button
            disabled={quantity == 0}
              onClick={() => {
                salesOrderId
                  ? AddProduct({
                      product: {
                        product_id: selectedProduct?.id,
                        quantity: quantity,
                      },
                    }, salesOrderId)
                  : getSalesId({
                      date: new Date().toISOString(),
                      subtotal: 0.0,
                      tax: 0.0,
                      store_id: 1,
                      status_id: 1,
                      active: 1,
                      store_id: selectedStore?.value,
                      product: {
                        product_id: selectedProduct?.id,
                        quantity: quantity,
                      },
                    });
                setOpen(false);
                setQuantity();
              }}
            >
              {language == 1 ? "Añadir" : "Add"}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={open2}>
          <DialogTitle>{selectedProduct?.product?.name}</DialogTitle>
          <DialogContent>
            <MDBox
              component="img"
              height="50%"
              width="50%"
              src={imageIP + selectedProduct?.image}
              alt=""
              ml={17}
            />
            <TextField
              margin="dense"
              label="Sub. Dept."
              type="text"
              fullWidth
              value={selectedProduct?.subcategory?.name}
              variant="standard"
            />
            <TextField
              margin="dense"
              label="UPC"
              type="text"
              fullWidth
              value={selectedProduct?.upc}
              variant="standard"
            />
            <TextField
              margin="dense"
              label={language == 1 ? "UPC Unidad" : "UPC Unit"}
              type="text"
              fullWidth
              value={selectedProduct?.upcUnit}
              variant="standard"
            />
            <TextField
              margin="dense"
              label={language == 1 ? "Disponible" : "Available"}
              type="text"
              fullWidth
              value={selectedProduct?.available}
              variant="standard"
            />
            <TextField
              margin="dense"
              label={language == 1 ? "Empaque" : "Package"}
              type="text"
              fullWidth
              value={selectedProduct?.packaging}
              variant="standard"
            />
            <TextField
              margin="dense"
              label={language == 1 ? "Precio" : "Price"}
              type="text"
              fullWidth
              value={"$" + selectedProduct?.sales_price}
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen2(!open2)}>
              {language == 1 ? "Cancelar" : "Cancel"}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={open3}>
          <DialogTitle>{language == 1 ? "Producto" : "Product"}:</DialogTitle>
          <DialogTitle>{selectedProduct?.product?.name}</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              value={quantity}
              label={language == 1 ? "Cantidad" : "Product Qty"}
              type="number"
              onChange={(e) => {
                if (
                  e.target.value >= 0 &&
                  e.target.value <= selectedProduct?.product?.available
                ) {
                  setQuantity(e.target.value);
                }
              }}
              fullWidth
              variant="standard"
            />
            <TextField
              autoFocus
              margin="dense"
              id="name"
              value={special ? discount : selectedProduct?.product?.sales_price}
              label={language == 1 ? "Precio" : "Price"}
              type="text"
              fullWidth
              variant="standard"
            />
            <MDTypography variant="h6">
              Total: $
              {special
                ? parseFloat(quantity * discount).toFixed(2)
                : parseFloat(quantity * selectedProduct?.product?.sales_price).toFixed(2)}
            </MDTypography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpen3(false);
              }}
            >
              {language == 1 ? "Cancelar" : "Cancel"}
            </Button>
            <Button
              onClick={() => {
                updateCart();
                setOpen3(false);
              }}
            >
              {language == 1 ? "Actualizar" : "Update"}
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
      <AlertDialog2
        Show={changeStatus}
        setShowConfirm={setChangeStatus}
        Message={
          language == 1
            ? "¿Estás seguro que quieres que se confirme esta orden?"
            : "Are you sure you want to confirm this order?"
        }
        selected={transport}
        setSelected={setTransport}
        OkayFunction={CambiarEstatus}
        fee={deliveryFeeAmount}
        total={total}
      />
      <MDSnackbar
        color="info"
        icon="notifications"
        title={language == 1 ? "EMOS Notificación" : "EMOS Notification"}
        content={language == 1 ? "Producto añadido al carrito!" : "Product added to cart!"}
        dateTime={language==1 ? "Ahora" : "Now"}
        open={show}
        close={toggleSnackbar}
      />
      <MDSnackbar
        color="info"
        icon="notifications"
        title={language == 1 ? "EMOS Notificación" : "EMOS Notification"}
        content={language == 1 ? "El Carrito fue actualizado." : "The Cart has been updated."}
        dateTime={language==1 ? "Ahora" : "Now"}
        open={showmsg2}
        close={toggleSnackbar2}
      />
      <MDBox py={3} mb={20} height="65vh">
        <Card sx={{ overflow: "visible" }}>
          <MDBox p={3}>
            <MDBox>
              <MDBox>
                <MDBox lineHeight={0}>
                  <MDTypography variant="h5">
                    {language == 1 ? "Crear Orden Manual" : "Create Manual Order"}
                  </MDTypography>
                  <MDTypography variant="button" color="text">
                    {language == 1
                      ? "Favor de entrar la información de la orden."
                      : "Please enter order information."}
                  </MDTypography>
                </MDBox>
                <MDBox id="box1" mt={1.625}>
                  {salesOrderId ? 
                  <MDInput label={language == 1 ? "Tienda" : "Store"} sx={{width: 500}} variant="standard" value={selectedStore?.label}/> :
                  <Autocomplete
                    disablePortal
                    sx={{ width: 500 }}
                    value={selectedStore}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        setSelectedStore(newValue);
                        getProducts()
                      } else {
                        setVendorProducts({ columns: [], rows: [] })
                      }
                    }}
                    options={stores}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={language == 1 ? "Tiendas" : "Stores"}
                        variant="standard"
                      />
                    )}
                  />}
                  <ShowError id={"store_id"} />
                </MDBox>
              </MDBox>
              {/* <MDBox mt={1.625} display="flex" justifyContent="right">
                <MDButton
                  variant="gradient"
                  color="light"
                  onClick={() => {
                    localStorage.removeItem("purchaseOrderId");
                    localStorage.removeItem("vendorId");
                    navigate("/purchase_orders");
                  }}
                >
                  {language == 1 ? "Cancelar" : "Cancel"}
                </MDButton>
                <MDButton
                  type="button"
                  variant="gradient"
                  className="float-sm-right"
                  color="info"
                  onClick={sendPurchaseOrder}
                  disabled={disableButton}
                >
                  {language == 1 ? "Guardar" : "Save"}
                </MDButton>
              </MDBox> */}
            </MDBox>
          </MDBox>
            <MDBox id="box1" mb={-8}>
              {productCheck[0] || added ? (
                !changeTable ? (
                  <MDButton
                    sx={{ ml: 2, mb: 9 }}
                    type="button"
                    variant="gradient"
                    onClick={() => {
                      setChangeTable(true);
                    }}
                    className="float-sm-right"
                    color="info"
                  >
                    {language == 1 ? "Añadir Productos" : "Add Products"}
                  </MDButton>
                ) : (
                  <MDButton
                    sx={{ ml: 2 }}
                    type="button"
                    variant="gradient"
                    className="float-sm-right"
                    color="info"
                    onClick={() => {
                      setChangeTable(false);
                    }}
                  >
                    <Icon>shopping_cart</Icon>
                    &nbsp;{language==1 ? "Carrito" : "Cart"}
                  </MDButton>
                )
              ) : null}{" "}
            </MDBox>
            {changeTable ? ( 
              <DataTable table={vendorProducts} entriesPerPage={false} canSearch />
            ) : ( <>
                <DataTable
                  table={{
                    columns: [
                      {Header: language == 1 ? "Tienda:" : "Store:", accessor: "store"},
                      {Header: language == 1 ? "Total de Bultos:" : "Qty Total:", accessor: "qty"},
                      {Header: "Sub-Total", accessor: "sub"},
                      {Header: language == 1 ? "Total de Impuestos:" : "Tax Total:", accessor: "tax"},
                      {Header: "Total", accessor: "total"},
                      {Header: language==1 ? "Balance Previo" : "Previous Balance", accessor: "previous"},
                      {Header: language==1 ? "Balance Nuevo" : "New Balance", accessor: "new"},
                      {Header: "", accessor: "action"}
                    ],
                    rows: [
                      {
                        store: selectedStore?.label,
                        qty: qtyTotal, 
                        sub: '$' + parseFloat(subtotal).toFixed(2), 
                        tax: '$' + parseFloat(taxTotal).toFixed(2), 
                        total: '$' + parseFloat(total).toFixed(2),
                        previous: '$' + parseFloat(previousBalance).toFixed(2),
                        new: '$' + parseFloat(parseFloat(previousBalance) + parseFloat(total)).toFixed(2),
                        action: 
                        <MDButton
                          color="info"
                          style={{ float: "right" }}
                          size="medium"
                          onClick={() => {
                            setChangeStatus(true)
                          }}
                          hidden={!(productCheck.length > 0)}
                          variant="gradient"
                        >
                          <Icon>checkmark</Icon>
                          &nbsp;{language == 1 ? "Confirmar Orden" : "Confirm Order"}
                        </MDButton>
                      }
                    ]
                  }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                />
                <DataTable table={purchaseOProducts} entriesPerPage={false} canSearch />
              </>
            )}
        </Card> 
      </MDBox>
    </DashboardLayout>
  );
}

export default CreateSalesOrder;
