/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { flushSync } from "react-dom";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import { Button, Grid } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import AlertDialog from "app/components/confirmBox";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import Autocomplete from "@mui/material/Autocomplete";
import MDInput from "components/MDInput";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import EditableRow from "./EditableRow";
import ReadOnlyRow from "./ReadOnlyRow";
import InputAdornment from "@mui/material/InputAdornment";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
// Material Dashboard 2 PRO React examples
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";

import Pagination from "./pagination";
import swal from "sweetalert";
// Data
import dataTableData from "layouts/ecommerce/orders/order-list/data/dataTableData";

import IP from "app/IP";

const axios = require("axios");

function RegisterPayments() {
  const current = new Date();
  let month = current.getMonth() + 1;
  let day = current.getDate();
  const currentDate = `${current.getFullYear()}-${month < 10 ? `0${month}` : `${month}`}-${
    day < 10 ? `0${day}` : `${day}`
  }`;

  const [menu, setMenu] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [language, setLanguage] = useState(localStorage.getItem("Language"));
  const [paymentData, setPaymentData] = useState({ columns: [], rows: [] });
  const [date, setDate] = useState(currentDate);
  const [errors, setErrors] = useState([]);
  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState([]);
  const [monto, setMonto] = useState(0.0);
  const [totalPay, setTotalPay] = useState(0.0);
  const [received, setReceived] = useState(0.0);
  const [remainder, setRemainder] = useState(0.0);
  const [invoices, setInvoices] = useState([]);
  const [editInvoice, setEditInvoice] = useState(0);
  const [start, SetStart] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState("CHECK");
  const [transactionNumber, setTransactionNumber] = useState("");
  const [paginateData, setPaginateData] = useState({
    columns: [
      { Header: "Invoice Number.", accessor: "invoice_number" },
      { Header: "Store", accessor: "store_name" },
      { Header: "Pending", accessor: "total" },
      { Header: "Payment Amount", accessor: "amount" },
      { Header: "Actions", accessor: "actions" },
    ],
    rows: [],
  });
  const [editFormData, setEditFormData] = useState({
    fullName: "",
    address: "",
    phoneNumber: "",
    email: "",
  });

  let timer;

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);
  const navigate = useNavigate();
  const { state } = useLocation();

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <Divider sx={{ margin: "0.5rem 0" }} />
      <MenuItem onClick={closeMenu}>
        <MDTypography variant="button" color="error" fontWeight="regular">
          Remove Filter
        </MDTypography>
      </MenuItem>
    </Menu>
  );

  async function GetStores() {
    console.log("aqui");
    //setRedirect(true);
    await axios
      .get(IP + "/stores", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(function (response) {
        //console.log(response.data.data);

        let storeData = response.data.data.map((item) => {
          return { value: item.id, label: item.name };
        });

        setStores(storeData);

        if (state?.storeId) {
          let store = storeData.filter((item) => {
            return item.value == state.storeId;
          });
          console.log(store);
          if (store.length > 0) {
            GetInvoices(store);
            setSelectedStore(store);
          }
        }
      })
      .catch(function (error) {
        // console.log(error.response.data.authenticated);
        if (!error.response?.data.authenticated) {
          navigate("/signIn");
        }
      });
  }

  function DoCalc(event) {
    event.preventDefault();
    let inv = [];
    // inv.forEach((item) => {
    //   if (id == item.invoice_id) {
    //     item.amount = parseFloat(add);
    //   }
    // });
    console.log("do Calc");
    console.log(inv);
    var pay = parseFloat(0.0);
    // inv.forEach((item) => {
    //   console.log(item);
    //   if (item.amount > 0) {
    //     pay += parseFloat(item.amount);
    //   }
    // });
    //console.log("pay " + pay + "  add " + add + " id " + id);
    if (received >= 0 && pay >= 0) {
      setTotalPay(parseFloat(pay));
      setRemainder(parseFloat(received) - parseFloat(pay));
    } else {
      setTotalPay(0.0);
    }
    //setInvoices(inv);
  }

  async function GetInvoices(value) {
    await axios
      .post(
        IP + "/invoices/getByStore",
        {
          stores: value.map((item) => {
            return item.value;
          }),
          filter: "paid",
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then(function (response) {
        const invoiceCol = [
          {
            Header: language == 1 ? "Número de Factura" : "Invoice Number",
            accessor: "invoice_number",
          },
          { Header: language == 1 ? "Tienda" : "Store", accessor: "store_name" },
          { Header: language == 1 ? "Pendiente" : "Pending", accessor: "pending" },
          { Header: language == 1 ? "Pago" : "Payment", accessor: "payment" },
        ];

        let invoiceVar = response.data.data.map((item) => {
          return {
            ...item,
            amount: 0,
          };
        });
        setPaymentData({
          rows: invoiceVar.map((item) => {
            return {
              ...item,
              payment:
                editInvoice == item.invoice_id ? (
                  <>
                    <MDInput
                      id={"input" + item.invoice_id}
                      defaultValue={0.0}
                      name="paymentInput"
                      onChange={DoCalc}
                      variant="standard"
                    />
                    <MDButton
                      variant="gradient"
                      style={{ marginBottom: "5%" }}
                      onClick={() => {
                        setEditInvoice(0);
                      }}
                      color="info"
                    >
                      {language == 1 ? "Guardar" : "Save"}
                    </MDButton>
                  </>
                ) : (
                  <>
                    <Grid container spacing={4} style={{ display: "flex", flexDirection: "row" }}>
                      {" "}
                      <Grid item>
                        <text>{item.amount}</text>
                      </Grid>
                      <Grid item>
                        <MDButton
                          variant="gradient"
                          color="info"
                          onClick={() => {
                            console.log(item.invoice_id);
                            setEditInvoice(item.invoice_id);
                          }}
                          iconOnly
                        >
                          <Icon>edit</Icon>
                        </MDButton>
                      </Grid>
                    </Grid>
                  </>
                ),
              checkbox: send(item),
            };
          }),
          columns: invoiceCol,
        });
        let mon = 0.0;
        response.data.data.forEach((item) => {
          console.log(item)
          mon += parseFloat(item.pending);
        });
        setMonto(parseFloat(mon).toFixed(2));

        setInvoices(
          invoiceVar.map((item) => {
            return {
              invoice_id: item.invoice_id,
              invoice_number: item.invoice_number,
              total: parseFloat(item.total ?? 0).toFixed(2),
              pending: parseFloat(item.pending ?? 0).toFixed(2),
              store_name: item.store.name,
              amount: 0,
              actions: false,
            };
          })
        );
        setPaginateData({
          columns: [
            {
              Header: language == 1 ? "Número de Factura" : "Invoice Number",
              accessor: "invoice_number",
            },
            { Header: language == 1 ? "Tienda" : "Store", accessor: "store_name" },
            { Header: language == 1 ? "Pendiente" : "Pending", accessor: "pending" },
            { Header: language == 1 ? "Pago" : "Payment", accessor: "payment" },
          ],
          rows: response.data.data.map((item) => {
            return {
              invoice_id: item.invoice_id,
              invoice_number: item.invoice_number,
              total: parseFloat(item.total ?? 0).toFixed(2),
              pending: parseFloat(item.pending ?? 0).toFixed(2),
              store_name: item.store.name,
              amount: 0,
              actions: false,
            };
          }),
        });
      })
      .catch(function (error) {
        // console.log(error.response.data.authenticated);
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
      });
  }

  function send(item) {
    return (
      <input
        defaultChecked={item.checked}
        onChange={(e) => {
          let x = paymentData;
          console.log(x);
          x.rows.map((xi) => {
            if (xi.id == item.id) {
              xi.checked = e.target.checked;
            }
            return xi;
          });
        }}
        type="checkbox"
      />
    );
  }

  const handleEditFormChange = (event, pending) => {
    event.preventDefault();

    if (event.target.value >= 0 || event.target.value == "") {
      const fieldName = event.target.getAttribute("name");
      const fieldValue = event.target.value;

      const newFormData = { ...editFormData };
      newFormData[fieldName] = fieldValue;

      setEditFormData(newFormData);
    }
    if (event.target.value > pending) {
      clearTimeout(timer)

      timer = setTimeout(()=>{
        const fieldName = event.target.getAttribute("name");
        const fieldValue = pending;
  
        const newFormData = { ...editFormData };
        newFormData[fieldName] = fieldValue;
  
        setEditFormData(newFormData);
      }, 2000)
    }
  };

  const handleEditFormSubmit = (event) => {
    console.log(editFormData);
    event.preventDefault();

    const editedInvoice = {
      invoice_id: editFormData.invoice_id,
      fullName: editFormData.fullName,
      invoice_number: editFormData.address,
      pending: editFormData.phoneNumber,
      amount: editFormData.email,
      store_name: editFormData.store_name,
      actions: false,
    };

    const newInvoice = [...invoices];

    const index = invoices.findIndex((invoice) => invoice.invoice_id === editFormData.invoice_id);

    newInvoice[index] = editedInvoice;
    let sumPay = 0.0;

    newInvoice.forEach((item) => {
      if (item.amount > 0) sumPay += parseFloat(item.amount);
    });

    console.log(received)
    if (received - sumPay >= 0) {
      setInvoices(newInvoice);
      setEditInvoice(null);
      setEditFormData(null);

      setTotalPay(sumPay);
      setRemainder(received - sumPay);
    } else {
      swal(
        language == 1
          ? "Alerta! La cantidad sometida es menor que las cantidades de pago. Favor validar el valor del pago. "
          : "Alert! The quantity entered is less than the sum of all payments. Please validate the payment value.",
        "",
        "warning"
      );
    }
  };

  const handleEditFormSubmitManual = (event, values) => {
    console.log(editFormData);
    event.preventDefault();

    const editedInvoice = {
      invoice_id: values.invoice_id,
      fullName: values.fullName,
      invoice_number: values.address,
      pending: values.phoneNumber,
      amount: values.email,
      store_name: values.store_name,
      actions: false,
    };

    const newInvoice = [...invoices];

    const index = invoices.findIndex((invoice) => invoice.invoice_id === values.invoice_id);

    newInvoice[index] = editedInvoice;

    setInvoices(newInvoice);
    setEditInvoice(null);
    setEditFormData(null);
    let sumPay = 0.0;

    newInvoice.forEach((item) => {
      if (item.amount > 0) sumPay += parseFloat(item.amount);
    });
    setTotalPay(sumPay);
    setRemainder(received - sumPay);
  };

  const handleEditClick = (event, invoice) => {
    console.log(invoices);
    event.preventDefault();
    setEditInvoice(invoice.invoice_id);

    const formValues = {
      invoice_id: invoice.invoice_id,
      fullName: invoice.fullName,
      address: invoice.invoice_number,
      phoneNumber: invoice.pending,
      email: invoice.amount,
      store_name: invoice.store_name,
      actions: false,
    };

    setEditFormData(formValues);
  };
  const handleDeleteClick = (event, invoice) => {
    console.log(invoices);
    event.preventDefault();

    const formValues = {
      invoice_id: invoice.invoice_id,
      fullName: invoice.fullName,
      address: invoice.invoice_number,
      phoneNumber: invoice.pending, //total
      store_name: invoice.store_name,
      email: 0.0,
      actions: false,
    };

    setEditFormData(formValues);
    handleEditFormSubmitManual(event, formValues);
    setEditInvoice(0);
  };

  const handleCancelClick = () => {
    setEditInvoice(null);
  };

  function handlePaginate(startPag, endPag) {
    if (start != startPag) {
      SetStart(startPag);
      setInvoices(paginateData.rows.slice(startPag, endPag));
    }

    console.log("paginate " + startPag + " " + start);
  }

  async function submitPayment() {
    let paymentinvoices = invoices.filter((item) => item.amount > 0);

    let payment_id = 0;
    await axios
      .post(
        IP + "/payments",
        {
          transaction_number: transactionNumber,
          active: 1,
          payment_method: paymentMethod,
          date: date,
          total: totalPay,
          stores: selectedStore.map((item) => {
            return item.value;
          }),
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then(async function (response) {
        //console.log(response.data.data);
        payment_id = response.data.data.id;
        if (payment_id > 0) {
          await axios
            .post(
              IP + "/payments/" + payment_id + "/payment_details",
              {
                payments: paymentinvoices.map((item) => {
                  return {
                    invoice_id: item.invoice_id,
                    payment_id: payment_id,
                    amount: item.amount,
                  };
                }),
              },
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("token"),
                  Accept: "application/json",
                },
              }
            )
            .then(function (response) {
              //console.log(response.data.data);
              payment_id: response.data.data.id;
            })
            .catch(function (error) {
              // console.log(error.response.data.authenticated);
              if (!error.response.data.authenticated) {
                navigate("/signIn");
              }
            });
        }
        navigate("/emos/viewPayments");
      })
      .catch(function (error) {
        // console.log(error.response.data.authenticated);
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        setErrors(error.response.data.data);
      });
  }

  function TestValidation(message) {
    if (message)
      return message.map((item) => {
        return (
          <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
            <label>{item}</label>
          </MDTypography>
        );
      });
    else return null;
  }

  function ShowError(param) {
    if (param.id) return TestValidation(errors[param.id]);
    else return null;
  }

  useEffect(() => {
    //getCityData();

    GetStores();
  }, []);

  return (
    <DashboardLayout>
      <AlertDialog
        Show={showConfirm}
        setShowConfirm={setShowConfirm}
        Message={
          language == 1
            ? "¿Estás seguro de que quieres guardar estos pagos?"
            : "Are you sure you want to submit these payments?"
        }
        OkayFunction={submitPayment}
      />
      <DashboardNavbar />
      <Card>
        <MDBox p={2}>
          <MDTypography variant="h5">
            {language == 1 ? "Registrar Pagos" : "Register Payments"}
          </MDTypography>
          <Grid container spacing={3} display="flex" justifyContent={"flex-end"}>
            <Grid item lg={2}>
              <MDInput
                variant="standard"
                type="date"
                label={language == 1 ? "Fecha" : "Date"}
                sx={{ width: "100%" }}
                value={date}
                onChange={(e) => {
                  setDate(e.target.value);
                }}
              />
              <ShowError id={"date"} />
            </Grid>
            <Grid style={{ position: "relative", float: "right", marginBottom: "2%" }} item lg={4}>
              <Autocomplete
                multiple={true}
                disablePortal
                onChange={(event, newValue) => {
                  setSelectedStore(newValue);
                  GetInvoices(newValue);
                  console.log(newValue);
                }}
                value={selectedStore}
                options={stores}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    label={language == 1 ? "Tiendas" : "Store"}
                    fullWidth
                    variant="standard"
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item lg={4}>
              <MDInput
                label={language == 1 ? "Total Balance Pendiente:" : "Total Pending Amount:"}
                placeholder="$0.00"
                value={selectedStore.length > 0 ? monto : 0.0}
                variant="filled"
                size="small"
              />
            </Grid>{" "}
            <Grid item lg={4}>
              <MDInput
                label={language == 1 ? "Pago Total:" : "Total Payment:"}
                placeholder="$0.00"
                value={parseFloat(totalPay).toFixed(2)}
                variant="filled"
                size="small"
              />
            </Grid>{" "}
            <Grid item lg={4}>
              <MDInput
                placeholder="$0.00"
                label={language == 1 ? "Restante:" : "Remainder:"}
                value={parseFloat(remainder).toFixed(2)}
                variant="filled"
                size="small"
              />
            </Grid>
          </Grid>
          <Grid style={{ marginTop: "2%" }} container>
            <Grid item>
              &nbsp;&nbsp;
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  onChange={(e) => {
                    setPaymentMethod(e.target.value);
                  }}
                  value={paymentMethod}
                >
                  <FormControlLabel value={"CASH"} control={<Radio />} label={language==1 ? "Efectivo" : "Cash"} />
                  <FormControlLabel
                    value={"CHECK"}
                    control={<Radio />}
                    label={language == 1 ? "Cheque" : "Check"}
                  />
                  <FormControlLabel value={"ACH"} control={<Radio />} label="ACH" />
                  <FormControlLabel value={"OTROS"} control={<Radio />} label="OTROS" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item lg={2.5}>
              <MDInput
                InputProps={{
                  startAdornment: <InputAdornment position="start">No.</InputAdornment>,
                }}
                value={transactionNumber}
                onChange={(e) => {
                  if (!isNaN(e.target.value)) {
                    setTransactionNumber(e.target.value);
                  } else {
                  }
                }}
                variant="outlined"
                size="small"
              />
              <ShowError id={"transaction_number"} />
            </Grid>
            <Grid style={{ marginLeft: "1%" }} item xs={2}>
              <MDInput
                label={language == 1 ? "Cantidad Recibida:" : "Quantity Received:"}
                placeholder="$0.00"
                value={received}
                onChange={(e) => {
                  console.log(monto)
                  if ((e.target.value >= 0 || e.target.value == "") && e.target.value <= parseFloat(monto)) {
                    setReceived(e.target.value);
                    clearTimeout(timer);
                    timer = setTimeout(() => {
                      if (received >= 0 && totalPay >= 0 && e.target.value != "") {
                        setRemainder(
                          parseFloat(parseFloat(e.target.value) - parseFloat(totalPay)).toFixed(2)
                        );
                        setReceived(parseFloat(e.target.value ?? 0).toFixed(2));
                      } else setRemainder(0.0);
                    }, 4000);
                  }

                  if (e.target.value > parseFloat(monto)) {
                    setReceived(parseFloat(monto).toFixed(2))
                    setRemainder(
                      parseFloat(parseFloat(monto) - parseFloat(totalPay)).toFixed(2)
                    );
                  }
                }}
                variant="outlined"
                size="small"
              />
            </Grid>
          </Grid>
          <Grid style={{ maxHeight: "400px", overflow: "auto" }}>
            <form onSubmit={handleEditFormSubmit}>
              <Table>
                <MDBox component="thead">
                  <TableRow
                    style={{ position: "sticky", top: 0, backgroundColor: "white", zIndex: 2 }}
                  >
                    <DataTableHeadCell>
                      {language == 1 ? "Número de Factura" : "Invoice Number"}
                    </DataTableHeadCell>
                    <DataTableHeadCell>
                      {language == 1 ? "Nombre de Tienda" : "Store Name"}
                    </DataTableHeadCell>
                    <DataTableHeadCell>{language == 1 ? "Pendiente" : "Pending"}</DataTableHeadCell>
                    <DataTableHeadCell>
                      {language == 1 ? "Cantidad de Pago" : "Payment Amount"}
                    </DataTableHeadCell>
                    <DataTableHeadCell>{language == 1 ? "Acción" : "Actions"}</DataTableHeadCell>
                  </TableRow>
                </MDBox>

                <TableBody>
                  {invoices.map((invoice) => (
                    <Fragment>
                      {editInvoice === invoice.invoice_id ? (
                        <EditableRow
                          editFormData={editFormData}
                          handleEditFormChange={handleEditFormChange}
                          handleCancelClick={handleCancelClick}
                        />
                      ) : (
                        <ReadOnlyRow
                          invoice={invoice}
                          handleEditClick={handleEditClick}
                          handleDeleteClick={handleDeleteClick}
                        />
                      )}
                    </Fragment>
                  ))}
                </TableBody>
              </Table>
            </form>
          </Grid>
          <Grid
            container
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              marginTop: "3%",
            }}
          >
            <Grid style={{ marginRight: "3%" }}>
              <MDButton
                variant="gradient"
                style={{ marginBottom: "5%" }}
                disabled={remainder != 0}
                onClick={() => {
                  var lengthCheck = invoices.filter((item) => item.amount > 0).length;

                  if (lengthCheck > 0) {
                    setShowConfirm(true);
                  }
                }}
                color="success"
              >
                {language == 1 ? "Guardar Pago" : "Save Payment"}
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </Card>
    </DashboardLayout>
  );
}

export default RegisterPayments;
