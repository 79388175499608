/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router components
import { useLocation, Link, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDBadge from "components/MDBadge";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarDesktopMenu,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 PRO React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";
import MDTypographyRoot from "components/MDTypography/MDTypographyRoot";
import Logout from "app/components/logoutFunc";
import LanguageModal from "app/components/languageModal";
import IP from "app/IP";
import { Card } from "@mui/material";

const axios = require("axios");

function ShippingNavbar({ absolute, light, isMini, userData, shipping, vendor, date, soNum, store, totalQty, qtyScan, setQtyScan, setChangeTable, hideConfirm, setConfirm }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const [role, setRole] = useState("");
  const [name, setName] = useState("");
  const [table, setTable] = useState(true)
  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState({ label: "", value: "" });
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
  const [storeBalance, setStoreBalance] = useState(0.00)
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  const [language, setLanguage] = useState(localStorage.getItem("Language"));
  const [showLang, setShowLang] = useState(false);
  const [isMobile, setIsMobile] = useState(false)
  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }
    console.log('navbar')
    console.log(fixedNavbar)

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  const handleResize = () => {
    if (window.innerWidth < 720) {
        setIsMobile(true)
    } else {
        setIsMobile(false)
    }
  }

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 3 }}
    >
      <NotificationItem
        icon={<Icon>language</Icon>}
        title={language == 1 ? "Lenguaje" : "Language"}
        onClick={() => {
          setShowLang(true);
        }}
      />
      {/* <NotificationItem
        icon={<Icon>podcasts</Icon>}
        title={language == 1 ? "Configuración App" : "Settings"}
        onClick={handleConfiguratorOpen}
      /> */}
      <NotificationItem
        icon={<Icon>account_circle</Icon>}
        title={language == 1 ? "Perfil" : "Profile"}
        onClick={() => {
          navigate("/userProfile");
        }}
      />
      <NotificationItem
        icon={<Icon>logout</Icon>}
        onClick={() => {
          Logout();
          navigate("/signIn");
        }}
        title="Logout"
      />
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  async function getUserDetails() {
    await axios
      .get(IP + "/user/profile", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        //console.log(response.data.data);

        var user = response.data.data;
        GetStores(user.role.id);
        console.log("user");
        //setUserName(user.username);
        setName(user.name);
        //setEmail(user.email);
        //setAddress(user.address);
        //setAddress2(user.address2);
        setRole(user.role);
        //setSelectedRole({ id: user.role.id, label: user.role.name });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function GetStores(roleId) {
    if (roleId == 7) {
      await axios
        .get(IP + "/auth/stores/current", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        })
        .then(function (response) {
          console.log({ label: response.data.data.name, id: response.data.data.id });
          setSelectedStore({ label: response.data.data.name, id: response.data.data.id });
          setStoreBalance(response.data.data.balance)
        })
        .catch(function (error) {
          console.log(error.response.data.authenticated);
          if (!error.response.data.authenticated) {
            //      navigate("/signIn");
          }
        });

      await axios
        .get(IP + "/stores/getActive", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        })
        .then(function (response) {
          setStores(
            response.data.data.map((item) => {
              return { label: item.name, value: item.id };
            })
          );
        })
        .catch(function (error) {
          console.log(error.response.data.authenticated);
          if (!error.response.data.authenticated) {
            navigate("/signIn");
          }
        });
    }
  }

  async function changeStore(id) {
    await axios
      .post(
        IP + "/auth/stores/current",
        { store_id: id },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then(function (response) {
        console.log("store changed");
        window.location.reload(true);
      })
      .catch(function (error) {
        console.log(error);
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
      });
  }

  useEffect(() => {
    if (userData) {
      setRole(userData.role)
      setName(userData.name)
      GetStores(userData.role.id)
    } else {
      getUserDetails()
    }
    console.log(state);
  }, []);

  useEffect(()=>{
    if (role.id == 7) {
      handleResize()
    }
  })

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <LanguageModal Show={showLang} setShow={setShowLang} />
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          {state?.hideBackButton ? null : (
            <IconButton
              sx={navbarDesktopMenu}
              onClick={() => {
                navigate(-1);
              }}
              size="small"
              disableRipple
            >
              <Icon fontSize="medium" sx={iconsStyle}>
                arrow_back
              </Icon>
            </IconButton>
          )}
          <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
          <IconButton
            sx={{...navbarDesktopMenu, ml: {lg: 8}}}
            style={{ zIndex: 9999 }}
            onClick={handleMiniSidenav}
            size="small"
            disableRipple
          >
            <MDTypography variant="button" fontWeight="bold">Menu</MDTypography>
          </IconButton>
        </MDBox>
        {isMini ? null : (
          <>
            <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
              <MDBox pr={3}>
                {role.id == 7 && stores.length > 1 ? (
                  <Autocomplete
                    disablePortal
                    value={selectedStore}
                    onChange={(event, newValue) => {
                      console.log(newValue);
                      setSelectedStore(newValue);
                      changeStore(newValue.value);
                    }}
                    options={stores}
                    renderInput={(params) => (
                      <MDInput
                        {...params}
                        sx={{ width: isMobile ? "5em" : "12em" }}
                        label={language == 1 ? "Tiendas" : "Stores"}
                        onClick={() => {
                          console.log("");
                        }}
                      />
                    )}
                  />
                ) : role.id == 7 && stores.length == 1 && !isMobile && <MDInput label={language==1 ? "Tienda" : "Store"} value={stores[0].label ?? ""} />}
              </MDBox>
              {role.id == 7 && <MDBox pr={1} mr={1}>
                <MDTypography variant='button' fontWeight="medium">Balance: ${parseFloat(storeBalance).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</MDTypography>
              </MDBox>}
              <MDBox pr={1}>         
                <MDTypography variant="button" fontWeight="medium">
                  {role.name}: {name}
                </MDTypography>
              </MDBox>
              <MDBox color={light ? "white" : "inherit"}>
                {/* <Link to="/authentication/sign-in/basic">
                <IconButton sx={navbarIconButton} size="small" disableRipple>
                  <Icon sx={iconsStyle}>account_circle</Icon>
                </IconButton>
              </Link>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                onClick={handleConfiguratorOpen}
              >
                <Icon sx={iconsStyle}>notifications</Icon>
              </IconButton> */}
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarIconButton}
                  aria-controls="notification-menu"
                  aria-haspopup="true"
                  variant="contained"
                  onClick={handleOpenMenu}
                >
                  <MDBadge badgeContent={null} color="error" size="xs" circular>
                    <Icon sx={iconsStyle}>settings</Icon>
                  </MDBadge>
                </IconButton>
                {renderMenu()}
              </MDBox>
            </MDBox>
          </>
        )}
      </Toolbar>
      <MDBox p={3}>
              <Grid container spacing={2} >
                <Grid item lg={4} sm={0}>
                  {shipping ? <> <MDTypography variant="h6">
                    {language == 1 ? "Fecha" : "Date"}: {date} 
                  </MDTypography>
                  <MDTypography variant="h6">
                    {language == 1 ? "Número de Orden" : "Sales Order Number"}: {soNum}
                  </MDTypography>
                  <MDTypography variant="h6">
                    {language == 1 ? "Tienda" : "Store"}: {store}
                  </MDTypography>
                  <MDTypography variant="h6">
                    {language == 1 ? "Total de Bultos" : "Total Quantity"}: {totalQty}
                  </MDTypography> </> : <> <MDTypography variant="h6">
                    {language == 1 ? "Vendedor: " : "Vendor: "}
                    {vendor?.name}
                  </MDTypography>
                  <MDTypography variant="h6">
                    {"Vendor PO #: "}
                    {vendor?.number}
                  </MDTypography> </>}
                </Grid>
                <Grid item lg={3.5}>
                  <FormControl style={{ float: "right"  }}>
                    <h5 style={{ color: "gray" }}>
                      {language == 1 ? "Número de Cantidad" : "Quantity Scan Number:"}
                    </h5>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={qtyScan}
                      onChange={(e) => {
                        setQtyScan(e.target.value);
                      }}
                    >
                      <FormControlLabel value={1} control={<Radio />} label="1" />
                      <FormControlLabel value={5} control={<Radio />} label="5" />
                      <FormControlLabel value={10} control={<Radio />} label="10" />
                      <FormControlLabel value={25} control={<Radio />} label="25" />
                      <FormControlLabel value={50} control={<Radio />} label="50" />
                      <FormControlLabel value={100} control={<Radio />} label="100" />
                      <FormControlLabel
                        value="all"
                        control={<Radio />}
                        label={language == 1 ? "Todo" : "All"}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item lg={4.5}>
                  { !hideConfirm ?
                    <MDButton
                      variant="gradient"
                      style={{ float: "right" }}
                      onClick={() => {
                        setConfirm(true);
                        // scrollToElement(652)
                      }}
                      color="warning"
                    >
                      {language == 1 ? (shipping ? "Confirmar Shipping" : "Confirmar Recibido") : (shipping ? "Confirm Shipping" : "Confirm Receiving")}
                    </MDButton> : null
                  }{" "}
                  {table ? <MDButton
                    variant="gradient"
                    style={{ float: "right" }}
                    onClick={() => {
                      setChangeTable(false);
                      setTable(false)
                    //   setScanReady(false);
                    }}
                    color="info"
                  >
                    {language == 1 ? "Ver Productos Escaneados" : "View Scanned Products"}
                  </MDButton> :          
                  <MDButton                   
                    variant="gradient"
                    style={{ float: "right" }}
                    onClick={() => {
                      setChangeTable(true);
                      setTable(true)
                    }}
                    color="success"
                  >
                    {language == 1 ? "Añadir Más Productos" : "Add More Products"}
                  </MDButton>}
                </Grid>
                
              </Grid>
              </MDBox>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
ShippingNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
ShippingNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default ShippingNavbar;
