/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import { Button, Grid } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import AlertDialog from "app/components/confirmBox";
import DataTable2 from "app/components/shopperTable";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import QrCodeRoundedIcon from "@mui/icons-material/QrCodeRounded";
import Tooltip from "@mui/material/Tooltip";
import swal from "sweetalert";

// Data
import dataTableData from "layouts/ecommerce/orders/order-list/data/dataTableData";

import IP from "app/IP";

const axios = require("axios");

function PurchaseOrders() {
  const [menu, setMenu] = useState(null);
  const [purchaseOrder, setPurchaseOrder] = useState({ columns: [], rows: [] });
  const [showConfirm, setShowConfirm] = useState(false);
  const [active, setActive] = useState(false);
  const [index, setIndex] = useState(0);
  const [language, setLanguage] = useState(localStorage.getItem("Language"));
  const [show, setShow] = useState(false);
  const toggleSnackbar = () => setShow(!show);

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);
  const navigate = useNavigate();

  async function changeStatus(id) {
    await axios
      .post(
        IP + "/purchase/setActive/" + id,
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        toggleSnackbar();
        getPurchaseOrder();
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function DeletePurchaseOrder() {
    await axios
      .delete(IP + "/purchase_orders/" + localStorage.getItem("purchaseOrderDeleteId"), {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(function (response) {
        localStorage.removeItem("purchaseOrderDeleteId");
        getPurchaseOrder();

        if (response.data.message == "Cannot delete orders that started their process.") {
          swal(
            language == 1 ? "Alerta!" : "Alert!",
            language == 1
              ? "No se puede borrar una orden que ya fue confirmada!"
              : "Cannot delete a confirmed Purchase Order!",
            "warning"
          );
        }
      })
      .catch(function (error) {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function getPurchaseOrder() {
    await axios
      .get(IP + "/purchase_orders", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        let purchaseOrderColumns = [
          { Header: language == 1 ? "Número de PO" : "PO Number", accessor: "po_num" },
          { Header: language == 1 ? "Fecha" : "Date", accessor: "date" },
          { Header: "EDA", accessor: "eda" },
          { Header: language == 1 ? "Estatus" : "Status", accessor: "status" },
          { Header: language == 1 ? "Vendedor" : "Vendor", accessor: "vendor" },
          {
            Header: language == 1 ? "Total de Bultos" : "Quantity Total",
            accessor: "total_bultos",
          },
          { Header: language == 1 ? "Total de Order" : "Order Total", accessor: "total" },
          { Header: language == 1 ? "Activo" : "Active", accessor: "active" },
          { Header: language == 1 ? "Acción" : "Action", accessor: "action", width: "20%" },
        ];

        let purchaseOrderRows = response.data.data.map((item) => {
          // console.log(item);
          return {
            po_num: item.po_number,
            date: item.date,
            eda: item.eda,
            status: item.status.name,
            vendor: item.vendor.name,
            total: "$" + item.total_amount,
            total_bultos: parseInt(item.total_bultos),
            active: item.active == "Active" ? 
              language==1 ? "Activo" : "Active" :
              language==1 ? "Inactivo" : "Inactive",
            action: (
              <Grid>
                {" "}
                {item.status.id == 3 ? (
                  <>
                    <Tooltip title="Receiving">
                      <MDButton
                        variant="gradient"
                        color="dark"
                        onClick={() => {
                          localStorage.setItem(
                            "RecInfo",
                            JSON.stringify({ venid: item.vendor.id, poid: item.id })
                          );
                          localStorage.setItem("purchaseOrderId", item.id);
                          navigate("/emos/receivingList");
                        }}
                        iconOnly
                      >
                        <QrCodeRoundedIcon />
                      </MDButton>
                    </Tooltip>{" "}
                    <Tooltip title={language == 1 ? "Ver Order" : "View Order"}>
                      <MDButton
                        variant="gradient"
                        color="info"
                        onClick={() => {
                          localStorage.setItem(
                            "RecInfo",
                            JSON.stringify({ venid: item.vendor.id, poid: item.id })
                          );
                          localStorage.setItem("purchaseOrderId", item.id);
                          navigate("/view-purchase_order");
                        }}
                        iconOnly
                      >
                        <Icon>visibility</Icon>
                      </MDButton>
                    </Tooltip>
                  </>
                ) : item.active == "Inactive" ? null : (
                  item.status.id == 1 && <Tooltip title={language == 1 ? "Editar" : "Edit"}>
                    <MDButton
                      variant="gradient"
                      color="info"
                      onClick={() => {
                        localStorage.setItem("purchaseOrderId", item.id);
                        navigate("/edit-purchase_order");
                      }}
                      iconOnly
                    >
                      <Icon>edit</Icon>
                    </MDButton>
                  </Tooltip>
                )}{" "}
                {(item.status.id != 1 && item.status.id != 3) && <Tooltip title={language == 1 ? "Ver Order" : "View Order"}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={() => {
                      localStorage.setItem(
                        "RecInfo",
                        JSON.stringify({ venid: item.vendor.id, poid: item.id })
                      );
                      localStorage.setItem("purchaseOrderId", item.id);
                      navigate("/view-purchase_order");
                    }}
                    iconOnly
                  >
                    <Icon>visibility</Icon>
                  </MDButton>
                </Tooltip>}
                {"  "}
                {item.active == "Inactive" ? (
                  <Tooltip title={language == 1 ? "Activar" : "Activate"}>
                    <MDButton
                      variant="gradient"
                      color="success"
                      onClick={() => {
                        changeStatus(item.id);
                        setActive(false);
                      }}
                      iconOnly
                    >
                      <Icon>check</Icon>
                    </MDButton>
                  </Tooltip>
                ) : (
                  <Tooltip title={language == 1 ? "Desactivar" : "Deactivate"}>
                    <MDButton
                      variant="gradient"
                      color="warning"
                      onClick={() => {
                        changeStatus(item.id);
                        setActive(true);
                      }}
                      iconOnly
                    >
                      <Icon>do_not_disturb</Icon>
                    </MDButton>
                  </Tooltip>
                )}{" "}
                {item.status.id == 1 && <Tooltip title={language == 1 ? "Borrar" : "Delete"}>
                  <MDButton
                    variant="gradient"
                    color="error"
                    onClick={() => {
                      localStorage.setItem("purchaseOrderDeleteId", item.id);
                      setShowConfirm(true);
                    }}
                    iconOnly
                  >
                    <Icon>delete</Icon>
                  </MDButton>
                </Tooltip>}
                {" "}
                <Tooltip title={language == 1 ? "Validar" : "Validate"}>
                  <MDButton
                    variant="gradient"
                    color="light"
                    onClick={() => {
                      navigate('/validate-purchase-orders', {state: {id: item.id}})
                    }}
                    iconOnly
                  >
                    <Icon>assignment_turned_in</Icon>
                  </MDButton>
                </Tooltip>
              </Grid>
            ),
          };
        });

        let tableData = { columns: purchaseOrderColumns, rows: purchaseOrderRows };
        //console.log(tableData)
        setPurchaseOrder(tableData);
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  useEffect(() => {
    getPurchaseOrder();
  }, []);

  return (
    <DashboardLayout>
      <AlertDialog
        Show={showConfirm}
        setShowConfirm={setShowConfirm}
        Message={
          language == 1
            ? "¿Estás seguro que quieres borrar esta orden de compra"
            : "Are you sure you want to delete the purchase order?"
        }
        OkayFunction={DeletePurchaseOrder}
      />
      <MDSnackbar
        color="info"
        icon="notifications"
        title=""
        content={
          language == 1
            ? `Orden de Compra ${active ? "Desactivada" : "Activada"} Exitosamente!`
            : `Purchase Order ${active ? "Deactivated" : "Activated"} Succesfully!`
        }
        dateTime={language==1 ? "Ahora" : "Now"}
        open={show}
        close={toggleSnackbar}
      />
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          <MDButton
            variant="gradient"
            color="info"
            onClick={() => navigate("/create-purchase_order")}
          >
            {language == 1 ? "Crear Orden De Compra" : "create purchase order"}
          </MDButton>
        </MDBox>
        <Card>
          <DataTable2
            table={purchaseOrder}
            index={index}
            setIndex={setIndex}
            pageEntries={10}
            entriesPerPage={true}
            canSearch
          />
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default PurchaseOrders;
