import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// NewUser page components
import UserInfo from "layouts/pages/users/new-user/components/UserInfo";
import Address from "layouts/pages/users/new-user/components/Address";
import Socials from "layouts/pages/users/new-user/components/Socials";
import Profile from "layouts/pages/users/new-user/components/Profile";

// NewUser layout schemas for form and form feilds
import validations from "layouts/pages/users/new-user/schemas/validations";
import form from "layouts/pages/users/new-user/schemas/form";
import initialValues from "layouts/pages/users/new-user/schemas/initialValues";
import MDInput from "components/MDInput";

import IP from "app/IP";

const axios = require("axios");

function AddWarehouse() {
  const [name, setName] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [phone, setPhone] = useState("");
  const [contactName, setContactName] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [cities, setCities] = useState([{}]);
  const [selectedCity, setSelectedCity] = useState("");
  const [show, setShow] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [errors, setErrors] = useState([]);
  const [language, setLanguage] = useState(localStorage.getItem("Language"));
  const toggleSnackbar = () => setShow(!show);

  const navigate = useNavigate();

  async function GetCities() {
    // console.log("aqui");
    //setRedirect(true);
    await axios
      .get(IP + "/cities", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(function (response) {
        //console.log(response.data.data);
        setCities(
          response.data.data.map((item) => {
            return { label: item.name, id: item.id };
          })
        );
      })
      .catch(function (error) {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function sendWarehouse() {
    setDisableButton(true);
    await axios
      .post(
        IP + "/warehouses",
        {
          name: name,
          address1: address1,
          address2: address2,
          zipcode: zipcode,
          phone: phone,
          contact_name: contactName,
          contact_phone: contactPhone,
          contact_email: contactEmail,
          city_id: selectedCity.id,
          active: true
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        setTimeout(() => {
          navigate("/warehouses");
        }, 3000);
        console.log(response);
        toggleSnackbar();
      })
      .catch((error) => {
        setErrors(error.response.data.data);
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });

    setTimeout(() => {
      setDisableButton(false);
    }, 3000);
  }

  function TestValidation(message) {
    if (message)
      return message.map((item) => {
        return (
          <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
            <label>{item}</label>
          </MDTypography>
        );
      });
    else return null;
  }

  function ShowError(param) {
    // console.log(errors["name"]);
    if (param.id) return TestValidation(errors[param.id]);
    else return null;
  }

  function formatPhoneNumber(value) {
    // if input value is falsy eg if the user deletes the input, then just return
    if (!value) return value;

    // clean the input for any non-digit values.
    const phoneNumber = value.replace(/[^\d]/g, "");

    // phoneNumberLength is used to know when to apply our formatting for the phone number
    const phoneNumberLength = phoneNumber.length;

    // we need to return the value with no formatting if its less then four digits
    // this is to avoid weird behavior that occurs if you  format the area code to early

    if (phoneNumberLength < 4) return phoneNumber;

    // if phoneNumberLength is greater than 4 and less the 7 we start to return
    // the formatted number
    if (phoneNumberLength < 7) {
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
    }

    // finally, if the phoneNumberLength is greater then seven, we add the last
    // bit of formatting and return it.
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  }


  useEffect(() => {
    GetCities();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDSnackbar
        color="info"
        icon="notifications"
        title={language == 1 ? "EMOS Notificación" : "EMOS Notification"}
        content={language == 1 ? "Almacén Creado Exitosamente!" : "Warehouse Created Successfully!"}
        dateTime={language==1 ? "Ahora" : "Now"}
        open={show}
        close={toggleSnackbar}
      />
      <MDBox py={3} mb={20} height="65vh">
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 8 }}>
          <Grid item xs={12} lg={8}>
            <Card sx={{ height: "100%" }}>
              <MDBox p={3}>
                <MDBox>
                  <MDBox>
                    <MDBox lineHeight={0}>
                      <MDTypography variant="h5">
                        {language == 1 ? "Añadir Almacén" : "Add Warehouse"}
                      </MDTypography>
                      <MDTypography variant="button" color="text">
                        {language == 1
                          ? "Favor de entrar la información del almacén."
                          : "Please enter warehouse information."}
                      </MDTypography>
                    </MDBox>
                    <MDBox id="box1" mt={1.625}>
                      <Grid id="grid1" container spacing={3}>
                        <Grid id="grid1-1" item xs={12} sm={6}>
                          <MDInput
                            variant="standard"
                            label={language == 1 ? "Nombre de Almacén" : "Warehouse Name"}
                            fullWidth
                            value={name}
                            onChange={(e) => {
                              setName(e.target.value);
                            }}
                          />
                          <ShowError id={"name"} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <MDInput
                            variant="standard"
                            label={language == 1 ? "Teléfono" : "Phone Number"}
                            fullWidth
                            value={phone}
                            onChange={(e) => {
                              setPhone(formatPhoneNumber(e.target.value));
                            }}
                          />
                          <ShowError id={"phone"} />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                          <MDInput
                            variant="standard"
                            label={language == 1 ? "Dirección Linea 1" : "Address Line 1"}
                            fullWidth
                            value={address1}
                            onChange={(e) => {
                              setAddress1(e.target.value);
                            }}
                          />
                          <ShowError id={"address1"} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <MDInput
                            variant="standard"
                            label={language == 1 ? "Dirección Linea 2" : "Address Line 2"}
                            fullWidth
                            value={address2}
                            onChange={(e) => {
                              setAddress2(e.target.value);
                            }}
                          />
                          <ShowError id={"address2"} />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                          <Autocomplete
                            disablePortal
                            value={selectedCity}
                            onChange={(event, newValue) => {
                              console.log(newValue);
                              setSelectedCity(newValue);
                            }}
                            options={cities}
                            renderInput={(params) => (
                              <MDInput
                                {...params}
                                label={language == 1 ? "Ciudad" : "City"}
                                variant="standard"
                              />
                            )}
                          />
                          <ShowError id={"city_id"} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <MDInput
                            variant="standard"
                            label="ZipCode"
                            fullWidth
                            maxLength={5}
                            value={zipcode}
                            onChange={(e) => {
                              if (e.target.value.length <= 5 && e.target.value >= 0) {
                                setZipcode(e.target.value)
                              }
                            }}
                          />
                          <ShowError id={"zipcode"} />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                          <MDInput
                            variant="standard"
                            label="Contact Name"
                            fullWidth
                            value={contactName}
                            onChange={(e) => {
                              setContactName(e.target.value);
                            }}
                          />
                          <ShowError id={"contact_name"} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <MDInput
                            variant="standard"
                            label="Contact Email"
                            fullWidth
                            value={contactEmail}
                            onChange={(e) => {
                              setContactEmail(e.target.value);
                            }}
                          />
                          <ShowError id={"contact_email"} />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                          <MDInput
                            variant="standard"
                            label="Contact Phone Number"
                            fullWidth
                            value={contactPhone}
                            onChange={(e) => {
                              setContactPhone(formatPhoneNumber(e.target.value));
                            }}
                          />
                          <ShowError id={"contact_phone"} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <MDBox mt={2} width="100%" display="flex" justifyContent="right">
                            <MDButton
                              variant="gradient"
                              color="light"
                              onClick={() => navigate("/warehouses")}
                            >
                              Cancel
                            </MDButton>
                            <MDButton
                              type="button"
                              variant="gradient"
                              className="float-sm-right"
                              color="info"
                              onClick={sendWarehouse}
                              disabled={disableButton}
                            >
                              Save
                            </MDButton>
                          </MDBox>
                        </Grid>
                      </Grid>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default AddWarehouse;
