import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import MDDatePicker from "components/MDDatePicker";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// NewUser page components
import UserInfo from "layouts/pages/users/new-user/components/UserInfo";
import Address from "layouts/pages/users/new-user/components/Address";
import Socials from "layouts/pages/users/new-user/components/Socials";
import Profile from "layouts/pages/users/new-user/components/Profile";

// NewUser layout schemas for form and form feilds
import validations from "layouts/pages/users/new-user/schemas/validations";
import form from "layouts/pages/users/new-user/schemas/form";
import initialValues from "layouts/pages/users/new-user/schemas/initialValues";
import MDInput from "components/MDInput";
import PurchaseOrderModal from "app/components/purchaseOrderModal";
import CreateReceiving from "./createReceiving";

import IP from "app/IP";

const axios = require("axios");

function ReceivingList() {
  const current = new Date();
  let month = current.getMonth() + 1;
  const currentDate = `${current.getFullYear()}-${
    month < 10 ? `0${month}` : `${month}`
  }-${current.getDate()}`;
  const edaDate = `${current.getFullYear()}-${month < 10 ? `0${month}` : `${month}`}-${
    current.getDate() + 1
  }`;

  const [date, setDate] = useState(currentDate);
  const [eda, setEda] = useState(edaDate);
  const [pending, setPending] = useState(0)
  const [tableData, setTableData] = useState({ rows: [], columns: [] });
  const [vendors, setVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState();
  const [vendorProducts, setVendorProducts] = useState({ columns: [], rows: [] });
  const [show, setShow] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [errors, setErrors] = useState([]);
  const [open, setOpen] = useState(false);
  const [prod, setProd] = useState("");
  const [dummy, setDummy] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [changeTable, setChangeTable] = useState(false);
  const [purchaseOProducts, setPurchaseOProducts] = useState([]);
  const [isQtyEdit, setIsQtyEdit] = useState(false);
  const [prodToDel, setProdToDel] = useState(null);
  const [confirm, setConfirm] = useState(false);
  const [scannedProds, setScannedProds] = useState("");
  const [language, setLanguage] = useState(localStorage.getItem("Language"));

  const toggleSnackbar = () => setShow(!show);

  const navigate = useNavigate();
  const inputRef = useRef();

  async function GetVendors() {
    // console.log("aqui");
    //setRedirect(true);
    await axios
      .get(IP + "/vendors", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(function (response) {
        //console.log(response.data.data);
        setVendors(
          response.data.data.map((item) => {
            return { label: item.name, id: item.id };
          })
        );
      })
      .catch(function (error) {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function getVendorProducts() {
    var ven = JSON.parse(localStorage.getItem("RecInfo"));

    await axios
      .get(IP + "/purchase_orders/" + ven.poid + "/po_details", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log(response.data);
        let vendorProductColumns = [
          { Header: "Name", accessor: "name" },
          { Header: "UPC", accessor: "upc" },
          { Header: "UPC Unit", accessor: "upcunit" },
          { Header: "Qty. Max", accessor: "maximum" },
          { Header: "Qty. Min", accessor: "minimum" },
          { Header: "Available", accessor: "available" },
          { Header: "Diff.", accessor: "diff" },
          { Header: "Qty", accessor: "qty" },
          { Header: "Action", accessor: "action" },
        ];

        let vendorProductRows = response.data.data.map((item) => {
          return {
            name: item.name,
            upc: item.upc,
            upcunit: item.upcUnit,
            maximum: item.maximum,
            minimum: item.minimum,
            available: item.available,
            diff: item.diff,
          };
        });

        let tableDatax = { columns: vendorProductColumns, rows: vendorProductRows };
        setVendorProducts(vendorProductRows);
        setTableData(tableDatax);
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function sendPurchaseOrder() {
    // getVendorProducts();
    setDisableButton(true);
    await axios
      .post(
        IP + "/purchase_orders",
        {
          date: date,
          eda: eda,
          vendor_id: selectedVendor.id,
          status_id: 1,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        console.log(response);
        localStorage.setItem("purchaseOrderId", response.data.data.id);
        toggleSnackbar();
        setTimeout(() => {
          navigate("/edit-purchase_order");
        }, 5000);
      })
      .catch((error) => {
        setErrors(error.response.data.data);
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });

    setTimeout(() => {
      setDisableButton(false);
    }, 3000);
  }

  function TestValidation(message) {
    if (message)
      return message.map((item) => {
        return (
          <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
            <label>{item}</label>
          </MDTypography>
        );
      });
    else return null;
  }

  function ShowError(param) {
    // console.log(errors["name"]);
    if (param.id) return TestValidation(errors[param.id]);
    else return null;
  }
  async function getPurchaseOrder() {
    await axios
      .get(IP + "/purchase_orders/" + localStorage.getItem("purchaseOrderId"), {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        let orders = response.data.data;
        //  setDate(orders.date);
        //  setEda(orders.eda);
        ///  setSelectedVendor({ id: orders.vendor.id, label: orders.vendor.name });
        //   getVendorProducts(orders.vendor.id);
      });
  }

  function addToTable(input) {
    let columns = [{ Header: "UPC", accessor: "upc" }];
    let rows = [{ upc: input }].concat(tableData.rows);
    setTableData({ rows: rows, columns: columns });
  }

  async function getReceivings() {
    await axios
      .get(IP + "/receivings?purchase_order_id=" + localStorage.getItem("purchaseOrderId"), {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log(response.data);
        setPending(response.data.data[1])
        let vendorProductColumns = [
          {
            Header: language == 1 ? "Número de Orden de Vendedor" : "Vendor Order Number",
            accessor: "vendoro",
          },
          { Header: language == 1 ? "Vendedor" : "Vendor", accessor: "vendor" },
          { Header: language == 1 ? "Total de la Orden" : "Order Total", accessor: "total" },
          { Header: language == 1 ? "Fecha" : "Date", accessor: "date" },
          { Header: language==1 ? "Cantidad de Bultos" : "Total Quantity", accessor: 'qty' },
          { Header: language == 1 ? "Estatus" : "Status", accessor: "status" },
          { Header: language == 1 ? "Acción" : "Action", accessor: "action" },
        ];

        let vendorProductRows = response.data.data[0].map((item) => {
          return {
            vendoro: item.vendor_order_number,
            vendor: item.vendor.name,
            total: item.purchase.total,
            date: item.date,
            qty: item.total_quantity,
            vendor: item.vendor.name,
            status: item.status.name,
            action: (
              <>
                {item.status.id != 8 && item.status.id != 6 ? (
                  <MDButton
                    variant="gradient"
                    onClick={() => {
                      localStorage.setItem("RecId", item.id);
                      navigate("/emos/receiving");
                    }}
                    color="success"
                  >
                    {language == 1 ? "Escanear Productos" : "Scan Products"}
                  </MDButton>
                ) : item.status.id == 8 ? (
                  <MDButton
                    variant="gradient"
                    onClick={() => {
                      localStorage.setItem("RecId", item.id);
                      navigate("/expiration-dates");
                    }}
                    color="dark"
                  >
                    {language == 1 ? "Establecer Fecha de Expiración" : "Set Expiration Date"}
                  </MDButton>
                ) : null}
              </>
            ),
          };
        });

        let tableDatax = { columns: vendorProductColumns, rows: vendorProductRows };
        // setVendorProducts(vendorProductRows);
        setTableData(tableDatax);
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  const timer = () =>
    setTimeout(() => {
      setInputValue("");
    }, 200);

  useEffect(() => {
    inputRef.current?.focus();
  });

  useEffect(() => {
    //getVendorProducts();

    getReceivings();
  }, []);

  return (
    <DashboardLayout>
      <PurchaseOrderModal open={open} Product={prod} setOpen={setOpen} />
      <DashboardNavbar />
      <MDSnackbar
        color="info"
        icon="notifications"
        title=""
        content=""
        dateTime={language==1 ? "Ahora" : "Now"}
        open={show}
        close={toggleSnackbar}
      />
      {changeTable ? (
        <CreateReceiving RefreshTable={getReceivings} setChangeTable={setChangeTable} />
      ) : null}
      <MDBox py={3} mb={20} height="65vh">
        {pending != 0 && <Grid>
          <MDButton
            variant="gradient"
            onClick={() => {
              setChangeTable(true);
            }}
            color="info"
          >
            {language == 1 ? "Crear Nuevo Recibido" : "Create New Receiving"}
          </MDButton>
        </Grid>}
        <Card style={{ marginTop: "2%" }}>
          <MDBox p={3}>
            <Grid>
              <DataTable height="50%" table={tableData} entriesPerPage={false} />
            </Grid>
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default ReceivingList;
