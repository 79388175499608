/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Settings page components

import BaseLayout from "layouts/pages/account/components/BaseLayout";
import Sidenav from "./components/SideNav";
import Header from "./components/Header";
import BasicInfo from "layouts/pages/account/settings/components/BasicInfo";
import ChangePassword from "./components/ChangePassword";
import UserSidenav from "./userProfileSidenav";
import UserHeader from "./userProfileHeader";
import UserChangePassword from "./userProfilePassword";
import Authentication from "layouts/pages/account/settings/components/Authentication";
import Accounts from "layouts/pages/account/settings/components/Accounts";
import Notifications from "layouts/pages/account/settings/components/Notifications";
import Sessions from "layouts/pages/account/settings/components/Sessions";
import DeleteAccount from "layouts/pages/account/settings/components/DeleteAccount";
import UserProfile from "./userProfile";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

function UserProfileLayout() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={3}>
            <UserSidenav />
          </Grid>
          <Grid item xs={12} lg={9}>
            <MDBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <UserHeader />
                </Grid>
                </Grid>
                <Grid container spacing={3}>
                <Grid item xs={12}>
                  <UserProfile />
                </Grid>
                <Grid item xs={12}>
                  <UserChangePassword />
                </Grid>
{/* 
                <Grid item xs={12}>
                  <Authentication /> 
                </Grid>
                <Grid item xs={12}>
                  <Notifications /> 
                </Grid> */}
              </Grid>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default UserProfileLayout;
