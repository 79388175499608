/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import AlertDialog from "app/components/confirmBox";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable2 from "app/components/shopperTable";
import MDSnackbar from "components/MDSnackbar";

import IP from "app/IP";
import { Autocomplete, responsiveFontSizes, Switch, TextField } from "@mui/material";

const axios = require("axios");

function CreateZone() {
  const [name, setName] = useState("");
  const [monday, setMonday] = useState(false)
  const [tuesday, setTuesday] = useState(false)
  const [wednesday, setWednesday] = useState(false)
  const [thursday, setThursday] = useState(false)
  const [friday, setFriday] = useState(false)
  const [saturday, setSaturday] = useState(false)
  const [sunday, setSunday] = useState(false)
  const [selectedDay, setSelectedDay] = useState()
  const [errors, setErrors] = useState([]);
  const [disableButton, setDisableButton] = useState(false);
  const [language, setLanguage] = useState(localStorage.getItem("Language"));
  const [show, setShow] = useState(false);
  const toggleSnackbar = () => setShow(!show);

  const navigate = useNavigate();

  async function createZone() {
    setDisableButton(true);
    await axios
      .post(
        IP + "/zones",
        {
            name: name,
            monday: monday,
            tuesday: tuesday,
            wednesday: wednesday,
            thursday: thursday,
            friday: friday,
            saturday: saturday,
            sunday: sunday,
            delivery_day: selectedDay?.label
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        setTimeout(() => {
          navigate('/zones')
        }, 3000)
        toggleSnackbar();
      })
      .catch((error) => {
        if (!error.response.data?.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
        setErrors(error.response.data.data);
      });

    setTimeout(() => {
      setDisableButton(false);
    }, 3000);
  }

  function TestValidation(message) {
    if (message)
      return message.map((item) => {
        return (
          <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
            <label>{item}</label>
          </MDTypography>
        );
      });
    else return null;
  }

  function ShowError(param) {
    if (param.id) return TestValidation(errors[param.id]);
    else return null;
  }

  return (
    <DashboardLayout>
      <MDSnackbar
        color="info"
        icon="notifications"
        title={language == 1 ? "EMOS Notificación" : "EMOS Notification"}
        content={
          language == 1 ? "Zona creada exitosamente!" : "Zone Created Succesfully"
        }
        dateTime={language==1 ? "Ahora" : "Now"}
        open={show}
        close={toggleSnackbar}
      />
      <DashboardNavbar />
      <MDBox py={3} mb={20} height="65vh">
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 8 }}>
          <Grid item xs={12} lg={8}>
            <Card sx={{ height: "100%" }}>
              <MDBox p={3}>
                <MDBox lineHeight={0}>
                  <MDTypography variant="h4">
                    {language == 1 ? "Crear Zona" : "Create Zone"}
                  </MDTypography>
                  <MDTypography variant="button" color="text">
                    {language == 1
                      ? "Favor de entrar la información de la zona."
                      : "Please enter zone information."}
                  </MDTypography>
                </MDBox>
                <MDBox mt={1.625}>
                  <Grid item xs={12} sm={"auto"}>
                    <MDInput
                      type="text"
                      label={language == 1 ? "Nombre" : "Name"}
                      variant="standard"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      fullWidth
                    />
                    <ShowError id="name" />
                    <Autocomplete
                      disablePortal
                      value={selectedDay}
                      onChange={(event, newValue) => {
                        setSelectedDay(newValue)
                      }}
                      sx={{marginBottom: 5, mt: 1}}
                      options={[
                        {label: 'Monday'},
                        {label: 'Tuesday'},
                        {label: 'Wednesday'},
                        {label: 'Thursday'},
                        {label: 'Friday'},
                        {label: 'Saturday'},
                        {label: 'Sunday'}
                      ]}
                      fullWidth
                      renderInput={(params) => ( <>
                        <TextField
                          {...params}
                          label={language == 1 ? "Día de Entrega" : "Delivery Day"}
                          variant="standard"
                        />
                         <ShowError id="delivery_day" />
                        </>
                      )}
                    />
                    <Grid container>
                      <Grid item xs={12} lg={1.7}>
                        <Grid container>
                          <Grid item lg={"auto"}>
                            <MDTypography variant="h6">{language==1 ? "Lunes" : 'Monday'}:</MDTypography>
                            <Switch
                              checked={monday}
                              onChange={(e)=>setMonday(e.target.checked)}
                              sx={{marginTop: -1}}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} lg={1.7}>
                        <Grid container>
                          <Grid item lg={"auto"}>
                            <MDTypography variant="h6">{language==1 ? "Martes" : 'Tuesday'}:</MDTypography>
                            <Switch
                              checked={tuesday}
                              onChange={(e)=>setTuesday(e.target.checked)}
                              sx={{marginTop: -1}}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} lg={1.74}>
                        <Grid container>
                          <Grid item lg={"auto"}>
                            <MDTypography variant="h6">{language==1 ? "Miércoles" : 'Wednesday'}:</MDTypography>
                            <Switch
                              checked={wednesday}
                              onChange={(e)=>setWednesday(e.target.checked)}
                              sx={{marginTop: -1}}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} lg={1.7}>
                        <Grid container>
                          <Grid item lg={"auto"}>
                            <MDTypography variant="h6">{language==1 ? "Jueves" : 'Thursday'}:</MDTypography>
                            <Switch
                              checked={thursday}
                              onChange={(e)=>setThursday(e.target.checked)}
                              sx={{marginTop: -1}}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} lg={1.7}>
                        <Grid container>
                          <Grid item lg={"auto"}>
                            <MDTypography variant="h6">{language==1 ? "Viernes" : 'Friday'}:</MDTypography>
                            <Switch
                              checked={friday}
                              onChange={(e)=>setFriday(e.target.checked)}
                              sx={{marginTop: -1}}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} lg={1.7}>
                        <Grid container>
                          <Grid item lg={"auto"}>
                            <MDTypography variant="h6">{language==1 ? "Sábado" : 'Saturday'}:</MDTypography>
                            <Switch
                              checked={saturday}
                              onChange={(e)=>setSaturday(e.target.checked)}
                              sx={{marginTop: -1}}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} lg={1.7}>
                        <Grid container>
                          <Grid item lg={"auto"}>
                            <MDTypography variant="h6">{language==1 ? "Domingo" : 'Sunday'}:</MDTypography>
                            <Switch
                              checked={sunday}
                              onChange={(e)=>setSunday(e.target.checked)}
                              sx={{marginTop: -1}}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <ShowError id={"days"} />
                    <MDBox mt={2} width="100%" display="flex" justifyContent="space-between">
                      <MDButton
                        variant="gradient"
                        color="light"
                        onClick={()=>navigate('/zones')}
                      >
                        {language == 1 ? "Cancelar" : "Cancel"}
                      </MDButton>
                      <MDButton
                        variant="gradient"
                        color="info"
                        disabled={disableButton}
                        onClick={() => {
                          if (!monday && !tuesday && !wednesday && !thursday && !friday && !saturday && !sunday)
                            setErrors({
                              ...errors,
                              days: [
                                language == 1 
                                  ? "La zona debe tener por lo menos un día activo" 
                                  : "The zone must have at least one active day"
                              ]
                            })
                          else {
                            createZone()
                          }
                        }}
                      >
                        {language == 1 ? "Guardar" : "Save"}
                      </MDButton>
                    </MDBox>
                  </Grid>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default CreateZone;
