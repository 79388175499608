/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

// Images
import burceMars from "assets/images/bruce-mars.jpg";

import IP from "app/IP";

const axios = require("axios");

function UserHeader() {

const [name, setName] = useState("")
const [role, setRole] = useState("")

    async function getUserDetails() {
        await axios
          .get(IP + "/user/profile", {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then(function (response) {
            //console.log(response.data.data);
    
            var user = response.data.data;
            console.log("user");
            //setUserName(user.username);
            setName(user.name);
            //setEmail(user.email);
            //setAddress(user.address);
            //setAddress2(user.address2);
            setRole(user.role.name);
            //setSelectedRole({ id: user.role.id, label: user.role.name });
          })
          .catch(function (error) {
            console.log(error);
          });
      }

      useEffect(()=>{
        getUserDetails();
      }, [])

  return (
    <Card id="profile">
      <MDBox p={2}>
        <Grid container spacing={3} alignItems="center">
          {/* <Grid item>
            <MDAvatar src={burceMars} alt="profile-image" size="xl" shadow="sm" />
          </Grid> */}
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                {name}
              </MDTypography>
              <MDTypography variant="button" color="text" fontWeight="medium">
                {role}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3} sx={{ ml: "auto" }}>
            <MDBox
              display="flex"
              justifyContent={{ md: "flex-end" }}
              alignItems="center"
              lineHeight={1}
            >
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default UserHeader;
