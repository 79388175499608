/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import AlertDialog from "app/components/confirmBox";
import ProductCell from "layouts/ecommerce/products/product-page/components/ProductCell";
import { ExportToExcel } from "app/components/exportToExcel";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import jsPDF from "jspdf";

import IP from "app/IP";
import imageIP from "app/imageIP";
import DataTable2 from "app/components/shopperTable";

const axios = require("axios");

function ReportNoImage() {
  const current = new Date();
  let day = current.getDate();
  let month = current.getMonth() + 1;
  const currentDate = `${current.getFullYear()}-${month < 10 ? `0${month}` : `${month}`}-${
    day < 10 ? `0${day}` : `${day}`
  }`;

  const [menu, setMenu] = useState(null);
  const [report, setReport] = useState({ columns: [], rows: [] });
  const [index, setIndex] = useState(0);
  const [data, setData] = useState();
  const [date, setDate] = useState(true);
  const [startDate, setStartDate] = useState(currentDate);
  const [endDate, setEndDate] = useState(currentDate);
  const [options, setOptions] = useState("");
  const [vendors, setVendors] = useState([])
  const [selectedVendor, setSelectedVendor] = useState("")
  const [open, setOpen] = useState(false);
  const [allData, setAllData] = useState([])
  const [responseData, setResponseData] = useState([]);
  const [download, setDownload] = useState(true)
  const [language, setLanguage] = useState(localStorage.getItem("Language"));
  const [sortBy, setSortBy] = useState(
    language == 1 ? { label: "Cantidad Por Enviar", id: 1 } : { label: "Quantity Out", id: 1 }
  );
  const [sortSelect, setSortSelect] = useState();

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);
  const navigate = useNavigate();

  const createPDF = () => {
    setDownload(false)
    const pdf = new jsPDF("portrait", "px", "a0"); //px
    const data = document.querySelector("#pdf");
    pdf.html(data).then(() => {
      pdf.save("inventory.pdf");
      setDownload(true)
    });
  };

  const handleClose = () => {
    setOpen(false);
    setSortSelect("");
  };

  const sorting = [
    {
      id: 1,
      option: "Quantity Out",
    },
    {
      id: 2,
      option: "Quantity In",
    },
  ];

  const sortingEsp = [
    {
      id: 1,
      option: "Cantidad Por Enviar",
    },
    {
      id: 2,
      option: "Cantidad Por Recibir",
    },
  ];

  function sortOptions() {
    language == 1
      ? setOptions(
          sortingEsp.map((item) => {
            return {
              label: item.option,
              id: item.id,
            };
          })
        )
      : setOptions(
          sorting.map((item) => {
            return {
              label: item.option,
              id: item.id,
            };
          })
        );
  }

  async function GetVendors() {
    // console.log("aqui");
    //setRedirect(true);
    await axios
      .get(IP + "/vendors", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(function (response) {
        //console.log(response.data.data);
        let vendor = [];

        response.data.data.map((item) => {
          if (item.active == "Active") {
            vendor.push({ label: item.name, id: item.id });
          }

          setVendors(vendor);
        });
      })
      .catch(function (error) {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function getInventoryData() {
    await axios
      .get(IP + "/reports/noImage", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        setAllData(response.data.data)
        setResponseData(response.data.data);

        let columns = [
          { Header: "UPC", accessor: "upc" },
          { Header: language == 1 ? "Nombre de Producto" : "Product Name", accessor: "name" },
          { Header: language == 1 ? "Vendedor" : "Vendor", accessor: "vendor" },
          { Header: language == 1 ? "En Inventario" : "In Stock", accessor: "stock" },
          { Header: "", accessor: "action", width: "0%" }
        ];

        let rows = response.data.data
        .map((item) => {
          return {
            upc: item.upc,
            vendor: item.vendor.name,
            name: item.name,
          //   <MDTypography 
          //   fontWeight='light'
          //   variant='inherit' 
          //   sx={{cursor: 'pointer', textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}
          //   onClick={()=>{
          //     localStorage.setItem("productId",item.id)
          //     navigate('/edit-product')
          //   }}
          // >
          //   {item.name}
          // </MDTypography>,
            stock: item.stock,
            test: item.name,
            action: <MDButton
              variant="gradient"
              color="info"
              onClick={() => {
                localStorage.setItem("productId", item.id);
                navigate("/edit-product");
              }}
              iconOnly
            >
              <Icon>visibility</Icon>
            </MDButton>
          };
        });

        let excelRows = response.data.data.map((item) => {
          if (language == 1) {
            return {
              ID: item.id,
              UPC: item.upc,
              "Nombre del Producto": item.name,
              Vendedor: item.vendor.name,
              "En Almacen": item.stock,
            };
          } else {
            return {
              ID: item.id,
              UPC: item.upc,
              "Product Name": item.name,
              Vendor: item.vendor.name,
              "In Stock": item.stock,
            };
          }
        });

        setData(excelRows);

        let tableData = { columns: columns, rows: rows };
        //console.log(tableData)
        setReport(tableData);
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  function filterVendor(vendor) {
    if (vendor) {
      setResponseData(allData.filter((item) => {
        return item.vendor.id == vendor.id
      }))
    }
    if (!vendor) {
      setResponseData(allData)
    }
    let columns = [
      { Header: language == 1 ? "Imagen" : "Image", accessor: "image", width: "10%" },
      { Header: "UPC", accessor: "upc" },
      { Header: language == 1 ? "Nombre de Producto" : "Product Name", accessor: "name" },
      { Header: language == 1 ? "Vendedor" : "Vendor", accessor: "vendor" },
      { Header: language == 1 ? "En Inventario" : "In Stock", accessor: "stock" },
      { Header: language == 1 ? "Por Enviar" : "Quantity Out", accessor: "sold" },
      { Header: language == 1 ? "Por Recibir" : "Quantity In", accessor: "bought" },
    ];

    if (vendor) {
      let rows = allData.filter((item) => {
        return item.vendor.id == vendor.id
      }).map(item=>{
        return {
          image: <ProductCell name="" image={imageIP + item.image} />,
          upc: item.upc,
          sold: item.out_qty,
          vendor: item.vendor.name,
          bought: item.in_qty,
          name: item.name,
          stock: item.stock,
        };
      });

      let excelRows = allData.filter((item) => {
        return item.vendor.id == vendor.id
      }).map(item=>{
        if (language == 1) {
          return {
            UPC: item.upc,
            "Nombre de Producto": item.name,
            "En Almacen": item.stock,
            Vendido: item.out_qty,
            Comprado: item.in_qty,
          };
        } else {
          return {
            UPC: item.upc,
            "Product Name": item.name,
            "In Stock": item.stock,
            Sold: item.out_qty,
            Bought: item.in_qty,
          };
        }
      });

      setData(excelRows);

      let tableData = { columns: columns, rows: rows };

      setReport(tableData);
    }

    if (!vendor) {
      let rows = allData.map((item) => {
        return {
          image: <ProductCell name="" image={imageIP + item.image} />,
          upc: item.upc,
          sold: item.out_qty,
          vendor: item.vendor.name,
          bought: item.in_qty,
          name: item.name,
          stock: item.stock,
        };
      });

      let excelRows = allData.map((item) => {
        if (language == 1) {
          return {
            UPC: item.upc,
            "Nombre de Producto": item.name,
            "En Almacen": item.stock,
            Vendido: item.out_qty,
            Comprado: item.in_qty,
          };
        } else {
          return {
            UPC: item.upc,
            "Product Name": item.name,
            "In Stock": item.stock,
            Sold: item.out_qty,
            Bought: item.in_qty,
          };
        }
      });

      setData(excelRows);

      let tableData = { columns: columns, rows: rows };
      //console.log(tableData)
      setReport(tableData);
    }
  }

  useEffect(() => {
    getInventoryData();
    GetVendors();
    sortOptions();
  }, []);

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start">
          <MDBox display="flex">
          <MDBox ml={1}>
            <ExportToExcel apiData={data} fileName="Productos sin imagen" />
          </MDBox>
          {/* <MDBox ml={1}>
            <MDButton variant="outlined" color="dark" onClick={createPDF}>
            {language == 1 ? "Descargar PDF" : "Download PDF"}
            </MDButton>
          </MDBox> */}
          </MDBox>
          </MDBox>
          <MDBox my={3}>
            <Card>
              {/* <Autocomplete
                sx={{ width: "15%", mt: 2, ml: 2 }}
                disablePortal
                value={selectedVendor}
                onChange={(event, newValue) => {
                  setSelectedVendor(newValue);
                  filterVendor(newValue)
                }}
                options={vendors}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={language == 1 ? "Vendedor" : "Vendor"}
                    variant="standard"
                  />
                )}
              /> */}
              <DataTable2 table={report} entriesPerPage={true} canSearch pageEntries={10} index={index} setIndex={setIndex} />
            </Card>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default ReportNoImage;
