/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import AlertDialog from "app/components/confirmBox";
import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import IP from "app/IP";
import { Autocomplete, TextField } from "@mui/material";
import MDInput from "components/MDInput";

const axios = require("axios");

function ViewOrders() {
  const [menu, setMenu] = useState(null);
  const [roleId, setRoleId] = useState(0)
  const [data, setData] = useState([]);
  const [salesData, setSalesData] = useState({ columns: [], rows: [] });
  const [showConfirm, setShowConfirm] = useState(false);
  const [status, setStatus] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [language, setLanguage] = useState(localStorage.getItem("Language"));

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);
  const navigate = useNavigate();

  async function getUserDetails() {
    await axios
      .get(IP + "/user/profile", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        getSalesData(response.data.data.role.id)
        setRoleId(response.data.data.role.id)
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function DeleteSales() {
    await axios
      .delete(IP + "/sales_orders/" + localStorage.getItem("salesDeleteId"), {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(function (response) {
        localStorage.removeItem("salesDeleteId");
        getSalesData();
      })
      .catch(function (error) {
        // if (!error.response.data.authenticated) {
        //   navigate("/signIn");
        // }
        console.log(error);
      });
  }

  async function GetStatus() {
    // console.log("aqui");
    //setRedirect(true);
    await axios
      .get(IP + "/statuses", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(function (response) {
        //console.log(response.data.data);
        setStatus(
          response.data.data.filter((item) => {
            return item.sales == 1
          }).map(item=>{
            return { label: item.name, id: item.id }
          })
        );
      })
      .catch(function (error) {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function getSalesData(role) {
    await axios
      .get(IP + (role == 7 ? "/ecommerce/store/orders" : "/pending_sales"), {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        setData(response.data.data);

        let salesColumns = [
          { Header: language == 1 ? "Número de Orden" : "Sales Order Number", accessor: "num" },
          { Header: language == 1 ? "Fecha" : "Date", accessor: "date" },
          { Header: language == 1 ? "Total de Bultos" : "Total Quantity", accessor: "qty" },
          { Header: "Sub-Total", accessor: "sub" },
          { Header: language==1 ? "Total de Impuesto" : "Tax Total", accessor: 'tax' },
          { Header: language == 1 ? "Cargo por Entrega" : "Delivery Fee", accessor: "delivery" },
          { Header: "Total", accessor: "total" },
          { Header: language==1 ? "Entrega/Recogido" : "Delivery/Pickup", accessor: 'pickup' },
          { Header: language == 1 ? "Tienda" : "Store", accessor: "store_id" },
          { Header: language == 1 ? "Estatus" : "Status", accessor: "status_id" },
          { Header: language == 1 ? "Acción" : "Action", accessor: "action" },
        ];

        let salesRows = response.data.data.map((item) => {
          //console.log(item);
          return {
            num: item.so_number,
            date: item.date,
            sub: item.subtotal,
            tax: item.tax,
            pickup: item.has_delivery ? language==1 ? "Entrega" : "Delivery" : language==1 ? "Recogido" : "Pickup",
            qty: item.total_bultos_pedidos,
            total: item.total_amount,
            delivery: item.delivery_fee,
            store_id: item.store.name,
            status_id: item.status.name,
            action:
              item.status.status_id == 1 && roleId == 7 ? (
                <Grid>
                  <Tooltip title={language == 1 ? "Editar" : "Edit"}>
                    <MDButton
                      variant="gradient"
                      color="info"
                      iconOnly
                      onClick={() => {
                        localStorage.setItem("SalesOrderId", item.id);
                        navigate("/ecommerce");
                      }}
                    >
                      <Icon>edit</Icon>
                    </MDButton>
                  </Tooltip>
                  {"   "}
                  {/* <Tooltip title={language == 1 ? "Borrar" : "Delete"}>
                    <MDButton
                      variant="gradient"
                      color="error"
                      iconOnly
                      onClick={() => {
                        localStorage.setItem("salesDeleteId", item.id);
                        setShowConfirm(true);
                      }}
                    >
                      <Icon>delete</Icon>
                    </MDButton>
                  </Tooltip> */}
                </Grid>
              ) : (
                <Tooltip title={language == 1 ? "Ver" : "View"}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={() => {
                      localStorage.setItem("viewOrderId", item.id);
                      navigate("/view-order");
                    }}
                    iconOnly
                  >
                    <Icon>visibility</Icon>
                  </MDButton>
                </Tooltip>
              ),
          };
        });

        let tableData = { columns: salesColumns, rows: salesRows };
        //console.log(tableData)
        setSalesData(tableData);
      })
      .catch((error) => {
        // if (!error.response.data.authenticated) {
        //   navigate("/signIn");
        // }
        console.log(error);
      });
  }

  function filterOrders(status) {
    let salesColumns = [
      { Header: language == 1 ? "Número de Orden" : "Sales Order Number", accessor: "num" },
      { Header: language == 1 ? "Fecha" : "Date", accessor: "date" },
      { Header: language == 1 ? "Total de Bultos" : "Total Quantity", accessor: "qty" },
      { Header: 'Sub-Total', accessor: "sub" },
      { Header: language==1 ? "Total de Impuesto" : "Tax Total", accessor: 'tax' },
      { Header: "Total", accessor: "total" },
      { Header: language == 1 ? "Cargo por Delivery" : "Delivery Fee", accessor: "delivery" },
      { Header: language==1 ? "Entrega/Recogido" : "Delivery/Pickup", accessor: 'pickup' },
      { Header: language == 1 ? "Tienda" : "Store", accessor: "store_id" },
      { Header: language == 1 ? "Estatus" : "Status", accessor: "status_id" },
      { Header: language == 1 ? "Acción" : "Action", accessor: "action" },
    ];

    if (status) {
      let salesRows = data
        .filter((item) => {
          return item.status.status_id == status.id;
        })
        .map((item) => {
          return {
            num: item.so_number,
            date: item.date,
            sub: item.subtotal,
            tax: item.tax,
            total: item.total_amount,
            qty: item.total_bultos_pedidos,
            delivery: item.delivery_fee,
            store_id: item.store.name,
            pickup: item.has_delivery ? language==1 ? "Entrega" : "Delivery" : language==1 ? "Recogido" : "Pickup",
            status_id: item.status.name,
            action:
              item.status.status_id == 1 ? (
                <Grid>
                  <Tooltip title={language == 1 ? "Editar" : "Edit"}>
                    <MDButton
                      variant="gradient"
                      color="info"
                      iconOnly
                      onClick={() => {
                        localStorage.setItem("SalesOrderId", item.id);
                        navigate("/ecommerce");
                      }}
                    >
                      <Icon>edit</Icon>
                    </MDButton>
                  </Tooltip>
                  {"   "}
                  <Tooltip title={language == 1 ? "Borrar" : "Delete"}>
                    <MDButton
                      variant="gradient"
                      color="error"
                      iconOnly
                      onClick={() => {
                        localStorage.setItem("salesDeleteId", item.id);
                        setShowConfirm(true);
                      }}
                    >
                      <Icon>delete</Icon>
                    </MDButton>
                  </Tooltip>
                </Grid>
              ) : (
                <Tooltip title={language == 1 ? "Ver" : "View"}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={() => {
                      localStorage.setItem("viewOrderId", item.id);
                      navigate("/view-order");
                    }}
                    iconOnly
                  >
                    <Icon>visibility</Icon>
                  </MDButton>
                </Tooltip>
              ),
          };
        });

      let tableData = { columns: salesColumns, rows: salesRows };
      setSalesData(tableData);
    } else {
      let salesRows = data.map((item) => {
        //console.log(item);
        return {
          num: item.so_number,
          date: item.date,
          sub: item.subtotal,
          tax: item.tax,
          delivery: item.delivery_fee,
          qty: item.total_bultos_pedidos,
          pickup: item.has_delivery ? "Delivery" : "Pickup",
          store_id: item.store.name,
          status_id: item.status.name,
          action:
            item.status.status_id == 1 ? (
              <Grid>
                <Tooltip title={language == 1 ? "Editar" : "Edit"}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    iconOnly
                    onClick={() => {
                      localStorage.setItem("SalesOrderId", item.id);
                      navigate("/ecommerce");
                    }}
                  >
                    <Icon>edit</Icon>
                  </MDButton>
                </Tooltip>
                {"   "}
                <Tooltip title={language == 1 ? "Borrar" : "Delete"}>
                  <MDButton
                    variant="gradient"
                    color="error"
                    iconOnly
                    onClick={() => {
                      localStorage.setItem("salesDeleteId", item.id);
                      setShowConfirm(true);
                    }}
                  >
                    <Icon>delete</Icon>
                  </MDButton>
                </Tooltip>
              </Grid>
            ) : (
              <Tooltip title={language == 1 ? "Ver" : "View"}>
                <MDButton
                  variant="gradient"
                  color="info"
                  onClick={() => {
                    localStorage.setItem("viewOrderId", item.id);
                    navigate("/view-order");
                  }}
                  iconOnly
                >
                  <Icon>visibility</Icon>
                </MDButton>
              </Tooltip>
            ),
        };
      });

      let tableData = { columns: salesColumns, rows: salesRows };
      setSalesData(tableData);
    }
  }

  async function nav() {
    await axios
      .post(
        IP + "/sales_orders",
        {
          date: new Date().toISOString(),
          subtotal: 0.0,
          tax: 0.0,
          store_id: 1,
          status_id: 1,
          active: 1,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then(function (response) {
        localStorage.setItem("SalesOrderId", response.data.data.id);
        navigate("/ecommerce");
      })
      .catch(function (error) {
        // if (!error.response.data.authenticated) {
        //   navigate("/signIn");
        // }
        console.log(error);
      });
  }

  useEffect(() => {
    GetStatus();
    getUserDetails()
  }, []);

  return (
    <DashboardLayout>
      <AlertDialog
        Show={showConfirm}
        setShowConfirm={setShowConfirm}
        Message={
          language == 1
            ? "¿Estás seguro de que quieres borrar esta venta?"
            : "Are you sure you want to delete this sale?"
        }
        OkayFunction={DeleteSales}
      />
      <DashboardNavbar />
      <MDBox my={3}>
        {/* <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          <MDButton variant="gradient" color="info" onClick={nav}>
            {language == 1 ? "Crear Orden" : "create sale"}
          </MDButton>
        </MDBox> */}
        <Card>
          {roleId == 7 && <Autocomplete
            sx={{ width: "15%", mt: 2, ml: 2 }}
            disablePortal
            value={selectedStatus}
            onChange={(event, newValue) => {
              setSelectedStatus(newValue);
              filterOrders(newValue);
              console.log(newValue);
            }}
            options={status}
            renderInput={(params) => (
              <TextField
                {...params}
                label={language == 1 ? "Estatus" : "Status"}
                variant="standard"
              />
            )}
          />}
          <DataTable table={salesData} entriesPerPage={true} canSearch />
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default ViewOrders;
