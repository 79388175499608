/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import ImgsViewer from "react-images-viewer";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Carousel from "react-bootstrap/Carousel";
import Autocomplete from "@mui/material/Autocomplete";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import AlertDialog from "app/components/confirmBox";
import ProductCell from "layouts/ecommerce/products/product-page/components/ProductCell";
import ProductCard from "app/components/productCard";
import CarouselCard from "../components/carouselCard";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import DataTable2 from "app/components/shopperTable";
import MDInput from "components/MDInput";
import MDSnackbar from "components/MDSnackbar";

import IP from "app/IP";

const axios = require("axios");

function CreateCarousel() {
  const current = new Date();
  let day = current.getDate();
  let month = current.getMonth() + 1;
  const currentDate = `${current.getFullYear()}-${month < 10 ? `0${month}` : `${month}`}-${
    day < 10 ? `0${day}` : `${day}`
  }`;

  const [duration, setDuration] = useState("");
  const [startDate, setStartDate] = useState(currentDate);
  const [endDate, setEndDate] = useState(currentDate);
  const [vendors, setVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState("");
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [file, setFile] = useState()
  const [image, setImage] = useState()
  const [errors, setErrors] = useState([])
  const [name, setName] = useState("");
  const [length, setLength] = useState()
  const [interval, setInterval] = useState(5);
  const [position, setPosition] = useState("");
  const [language, setLanguage] = useState(localStorage.getItem("Language"));

  const fileRef = useRef();

  const handleChange = (e) => {
    const [fileVar] = e.target.files;
    let img = new Image()
    img.src = URL.createObjectURL(fileVar)
    img.onload = () => {
      console.log(img.width + " " + img.height);
      if (img.width == 1920 && img.height == 400) {
        setFile(fileVar)
        setImage(URL.createObjectURL(fileVar))
        setShow(false)
      } else {
        setShow(true)
        if (image) {
          setTimeout(()=>{
            setShow(false)
          }, 10000)
        }
      }
    }
  };

  const navigate = useNavigate();

  async function getVendors(prods) {
    // console.log("aqui");
    //setRedirect(true);
    await axios
      .get(IP + "/vendors", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(function (response) {
        let ids = prods.map((prod) => {
          return prod.vendor.id;
        });

        let vendor = [];

        response.data.data.map((item) => {
          if (ids.includes(item.id) && item.active == "Active") {
            vendor.push({ label: item.name, id: item.id });
          }
        });

        setVendors(vendor);
      })
      .catch(function (error) {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        //  console.log(error);
      });
  }

  async function getProductData() {
    await axios
      .get(IP + "/products", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        getVendors(response.data.data.filter(item=>{
          return item.active == "Active"
        }));
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        //   console.log(error);
      });
  }

  async function getCarousel() {
    await axios
      .get(IP + "/carousels", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        setLength(response.data.data.length)
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        //   console.log(error);
      });
  }

  async function saveCarousel() {
    const formData = new FormData();
    formData.append("name", name)
    formData.append("image", file)
    if (duration == "recurring") {
      formData.append("is_recurrent", 1)
    } else {
      formData.append("is_recurrent", 0)
      formData.append("from", startDate)
      formData.append("to", endDate)
    }
    formData.append("interval", interval * 1000)
    formData.append("position", position)
    formData.append("vendor_id", selectedVendor?.id)
    await axios
      .post(IP + "/carousels", formData, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "multipart/form-data",
        },
      })
      .then(response=>{
        setShow2(true)
        setTimeout(()=>{navigate('/edit-carousel', {state: {select: length}})}, 4000)
      })
      .catch(function (error) {
        setErrors(error?.response?.data?.data)
        console.log(error);
        // setErrors(error.response.data.data);
      })
  }

  function removeImage() {
    setImage(null)
    setFile(null)
  }

  // const convertToBase64 = (file) => {
  //   return new Promise((resolve, reject) => {
  //     const fileReader = new FileReader();
  //     fileReader.readAsDataURL(file);
  //     fileReader.onload = () => {
  //       resolve(fileReader.result);
  //     };
  //     fileReader.onerror = (error) => {
  //       reject(error);
  //     };
  //   });
  // };

  function TestValidation(message) {
    if (message) {
      setTimeout(()=>{
        setErrors([])
      }, 8000)
      return message.map((item) => {
        return (
          <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
            <label>{item}</label>
          </MDTypography>
        );
      });
    }
    else return null;
  }

  function ShowError(param) {
    // console.log(errors["name"]);
    if (param.id) return TestValidation(errors[param.id]);
    else return null;
  }

  useEffect(() => {
    getProductData()
    getCarousel()
  }, [])

  return (
    <DashboardLayout>
      <MDSnackbar
        color="info"
        icon="notifications"
        title={language == 1 ? "EMOS Notificación" : "EMOS Notification"}
        content={
          language == 1
            ? "Carrusel Creado Exitosamente!"
            : "Carousel Created Successfully!"
        }
        dateTime={language==1 ? "Ahora" : "Now"}
        open={show2}
        close={()=>{setShow2(!show2)}}
      />
      <DashboardNavbar />
      <Card>
        <MDBox p={2.5}>
          {/* <MDTypography variant='h4' sx={{mb: 1}}>{language==1 ? "Carrusel" : "Carousel"}</MDTypography> */}
          <input
            ref={fileRef}
            onChange={(e) => {
              handleChange(e);
            }}
            multiple={false}
            type="file"
            hidden
          />
          <Carousel
            variant="dark"
            interval={null}
            keyboard={true}
          >
            <Carousel.Item>
              <img width={'100%'} height={'100%'} src={image} />
            </Carousel.Item>
          </Carousel>
          <Grid container spacing={1} sx={{ mt: 1.5 }}>
            <Grid item lg={12} md={4}>
              <MDButton
                variant="gradient"
                color="info"
                size="medium"
                sx={{ mt: 1.5 }}
                onClick={() => fileRef.current.click()}
              >
                {language == 1 ? "Adjuntar" : "Attach"}
              </MDButton>
              {"  "}
              <MDButton
                variant="gradient"
                color="light"
                size="medium"
                sx={{ mt: 1.5 }}
                hidden={!image}
                onClick={removeImage}
              >
                {language == 1 ? "remover" : "remove"}
              </MDButton>
              {show ?
                <MDTypography sx={{mt: .5}} variant='body2' fontWeight='regular' color='error'>
                  {language == 1
                    ? "La imagen debe tener un largo de 1920 y una altura de 400"
                    : "The image must have a width of 1920 and a height of 400"}
                </MDTypography> 
              : null}
            </Grid>
          </Grid>
          <MDTypography sx={{ mt: 1 }} variant="body1">
            {language == 1 ? "Duración" : "Duration"}
          </MDTypography>
          <Grid container spacing={0}>
            <Grid item lg={2.5} md={6}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={duration}
                  onChange={(e) => {
                    setDuration(e.target.value);
                  }}
                >
                  <FormControlLabel
                    value="recurring"
                    control={<Radio />}
                    label={language == 1 ? "Recurrente" : "Recurring"}
                  />
                  <FormControlLabel
                    value="date"
                    control={<Radio />}
                    label={language == 1 ? "Rango de Fechas" : "Date Range"}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item lg={9}>
              <Grid container spacing={1}>
                {duration == "date" ? (
                  <>
                    <Grid item lg={2}>
                      <MDInput
                        type="date"
                        label={language == 1 ? "Desde" : "From"}
                        variant="standard"
                        value={startDate}
                        onChange={(e) => {
                          setStartDate(e.target.value);
                        }}
                        fullWidth
                      />
                      <ShowError id={'from'} />
                    </Grid>
                    <Grid item lg={2}>
                      <MDInput
                        type="date"
                        label={language == 1 ? "Hasta" : "To"}
                        variant="standard"
                        value={endDate}
                        onChange={(e) => {
                          setEndDate(e.target.value);
                        }}
                        fullWidth
                      />
                      <ShowError id={'to'} />
                    </Grid>
                  </>
                ) : null}
                <Grid item lg={1.5} md={5}>
                  <MDInput
                    type="number"
                    label={language == 1 ? "Intervalo (Segundos)" : "Interval (Seconds)"}
                    variant="standard"
                    value={interval}
                    onChange={(e) => {
                      if ((e.target.value >= 5 && e.target.value <= 10) || e.target.value == "") {
                        setInterval(e.target.value);
                      }
                    }}
                  />
                  <ShowError id={'interval'} />
                </Grid>
                <Grid item lg={1}>
                  <MDInput
                    type="number"
                    label={language == 1 ? "Posición" : "Position"}
                    variant="standard"
                    value={position}
                    onChange={(e) => {
                      if (e.target.value >= 1 && e.target.value <= 10 || e.target.value == "") {
                        setPosition(e.target.value);
                      }
                    }}
                  />
                  <ShowError id={'position'} />
                </Grid>
                <Grid item lg={2}>
                  <MDInput
                    type="text"
                    label={language == 1 ? "Nombre" : "Name"}
                    variant="standard"
                    value={name}
                    fullWidth
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                  <ShowError id={"name"} />
                </Grid>
                <Grid item lg={2}>
                  <Autocomplete
                    // sx={{ width: "25%", ml: 1, mt: 1 }}
                    disablePortal
                    value={selectedVendor}
                    onChange={(event, newValue) => {
                      setSelectedVendor(newValue);
                    }}
                    options={vendors}
                    renderInput={(params) => (
                      <TextField {...params} label="Vendors" variant="standard" />
                    )}
                  />
                  <ShowError id={'vendor_id'} /> 
                </Grid>
                <Grid item lg={1.5}>
                  <MDButton
                    variant='gradient'
                    color='info'
                    size="medium"
                    hidden={!image}
                    onClick={saveCarousel}
                    // sx={{ mt: 1.5, float: 'right' }}
                  >
                    {language == 1 ? "Guardar" : "Save"}
                  </MDButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
      </Card>
    </DashboardLayout>
  );
}

export default CreateCarousel;
