/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import IP from "../../IP";
import { Icon } from "@mui/material";
import routes from "routes";

const axios = require("axios");

function CheckAccess() {
  const navigate = useNavigate();
  const location = useLocation();
  const [role, setRole] = useState(0);
  const [modules, setModules] = useState([]);

  //Put this into a function because previously it was inside an async useEffect which causes an "destroy is not a function" error.
  const checkAccessFunction = async () => {
    let rolex = role;
    let modulesx = modules;

    if (rolex == 0) {
      await axios
        .get(IP + "/user/profile", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then(function (response) {
          //   console.log(response.data.data);

          let user = response.data.data;

          setRole(user.role.id);
          rolex = user.role.id;
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    if (modules.length == 0) {
      await axios
        .get(IP + "/config/access", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then(function (response) {
          //   console.log(response.data.data);

          let modules = response.data.data;
          setModules(response.data.data);
          modulesx = response.data.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    console.log(modulesx);

    //console.log(location.pathname);
    var routeToCheck = routes.filter((route) => {
      return (
        route.route == location.pathname ||
        route?.collapse?.some((x) => x.route == location.pathname)
      );
    });

    if (routeToCheck.length > 0 && rolex != 0) {
      if (
        !routeToCheck[0]?.roles?.includes(rolex) &&
        location.pathname != "/signIn" &&
        location.pathname != "/access-denied"
      ) {
        //  navigate("/access-denied");
        console.log("access denied");
        console.log(routeToCheck[0]);
        console.log(!routeToCheck[0]?.roles?.includes(rolex));
      }

      modulesx.forEach((item) => {
        if (item[0][1] == routeToCheck[0]?.module) {
          console.log(item);
          if (item.length > 1) {
            if (
              item.includes(rolex) &&
              location.pathname != "/signIn" &&
              location.pathname != "/access-denied"
            ) {
            } else {
              //navigate("/access-denied");
              console.log("access denied 3");
              console.log(routeToCheck[0]);
              console.log(item);
              console.log(rolex);
            }
          } else {
            if (location.pathname != "/signIn" && location.pathname != "/access-denied") {
              // navigate("/access-denied");
              console.log("access denied 2");
              console.log(routeToCheck[0]);
              console.log(item);
            }
          }
        }
      });
    } else {
      console.log("no role");
    }
  };

  //Don't give useEffect the "async" keyword or else you will get a "destroy is not a funciton" error.
  useEffect(() => {
    checkAccessFunction();
  }, [location, role]);

  return { roles: role, modules: modules };
}

export default CheckAccess;
