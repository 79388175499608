import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import MDDatePicker from "components/MDDatePicker";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// NewUser page components
import UserInfo from "layouts/pages/users/new-user/components/UserInfo";
import Address from "layouts/pages/users/new-user/components/Address";
import Socials from "layouts/pages/users/new-user/components/Socials";
import Profile from "layouts/pages/users/new-user/components/Profile";

// NewUser layout schemas for form and form feilds
import validations from "layouts/pages/users/new-user/schemas/validations";
import form from "layouts/pages/users/new-user/schemas/form";
import initialValues from "layouts/pages/users/new-user/schemas/initialValues";
import MDInput from "components/MDInput";

import IP from "app/IP";

const axios = require("axios");

function AddPurchaseOrder() {
  const current = new Date();
  let day = current.getDate()
  let month = current.getMonth() + 1;
  const currentDate = `${current.getFullYear()}-${
  month < 10 ? `0${month}` : `${month}`
  }-${day < 10 ? `0${day}` : `${day}`}`;

  const [date, setDate] = useState(currentDate);
  const [eda, setEda] = useState(currentDate);  
  const [vendors, setVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState("");
  const [vendorProducts, setVendorProducts] = useState({ columns: [], rows: [] });
  const [show, setShow] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [errors, setErrors] = useState([]);
  const [language, setLanguage] = useState(localStorage.getItem("Language"));
  const toggleSnackbar = () => setShow(!show);

  const navigate = useNavigate();

  async function GetProducts() {
    await axios
    .get(IP + "/products", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        Accept: "application/json",
      },
    })
    .then(function (response) {
      //console.log(response.data.data);
      let products = response.data.data
      GetVendors(products)
    })
    .catch(function (error) {
      if (!error.response.data.authenticated) {
        navigate("/signIn");
      }
      console.log(error);
    });
  }

  async function GetVendors(products) {
    // console.log("aqui");
    //setRedirect(true);
    await axios
      .get(IP + "/vendors", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(function (response) {
        //console.log(response.data.data);
        // setVendors(
        //   response.data.data.map((item) => {
        //     console.log(item.active == "Active")
        //     return { label: item.name, id: item.id };
        //   })
        // );
        response.data.data.map(item=>{
          if (item.active == "Active") {
            vendors.push({ label: item.name, id: item.id })
          }
        })

        // response.data.data.map(item=>{
        //   if (products.some(prod=>prod.vendor.id==item.id)) {
        //     vendors.push({ label: item.name, id: item.id })
        //     console.log(vendors)
        //   }
        // })

      })
      .catch(function (error) {
        // if (!error.response.data.authenticated) {
        //   navigate("/signIn");
        // }
        console.log(error);
      });
  }

  async function sendPurchaseOrder() {
    // getVendorProducts();
    setDisableButton(true);
    await axios
      .post(
        IP + "/purchase_orders",
        {
          date: date,
          eda: eda,
          vendor_id: selectedVendor.id,
          status_id: 1,
          active: true
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        console.log(response);
        localStorage.setItem("purchaseOrderId", response.data.data.id);
        toggleSnackbar();
        setTimeout(() => {
          navigate("/edit-purchase_order");
        }, 2500);
      })
      .catch((error) => {
        setErrors(error.response.data.data);
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });

    setTimeout(() => {
      setDisableButton(false);
    }, 3000);
  }

  function TestValidation(message) {
    if (message)
      return message.map((item) => {
        return (
          <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
            <label>{item}</label>
          </MDTypography>
        );
      });
    else return null;
  }

  function ShowError(param) {
    // console.log(errors["name"]);
    if (param.id) return TestValidation(errors[param.id]);
    else return null;
  }

  useEffect(() => {
    GetVendors()
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDSnackbar
        color="info"
        icon="notifications"
        title={language == 1 ? "EMOS Notificación" : "EMOS Notification"}
        content={
          language == 1
            ? "Orden de Compra Creada Exitosamente!"
            : "Purchase Order Created Successfully!"
        }
        dateTime={language==1 ? "Ahora" : "Now"}
        open={show}
        close={toggleSnackbar}
      />
      <MDBox py={3} mb={20} height="65vh">
        <Card sx={{ overflow: "visible" }}>
          <MDBox p={3}>
            <MDBox>
              <MDBox>
                <MDBox lineHeight={0}>
                  <MDTypography variant="h5">
                    {language == 1 ? "Crear Orden de Compra" : "Create Purchase Order"}
                  </MDTypography>
                  <MDTypography variant="button" color="text">
                    {language == 1
                      ? "Favor de entrar la información de la orden de compra."
                      : "Please enter order information."}
                  </MDTypography>
                </MDBox>
                <MDBox id="box1" mt={1.625}>
                  <Grid id="grid1" container spacing={3}>
                    <Grid id="grid1-1" item xs={12} sm={3}>
                      <MDInput
                        type="date"
                        variant="standard"
                        label={language == 1 ? "Fecha" : "Date"}
                        fullWidth
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                      />
                      <ShowError id={"date"} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <MDInput
                        type="date"
                        variant="standard"
                        label="EDA"
                        fullWidth
                        value={eda}
                        onChange={(e) => {
                          setEda(e.target.value);
                        }}
                      />
                      <ShowError id={"eda"} />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <Autocomplete
                        disablePortal
                        value={selectedVendor}
                        onChange={(event, newValue) => {
                          console.log(newValue);
                          setSelectedVendor(newValue);
                        }}
                        options={vendors}
                        renderInput={(params) => (
                          <MDInput
                            {...params}
                            label={language == 1 ? "Vendedor" : "Vendor"}
                            variant="standard"
                          />
                        )}
                      />
                      <ShowError id={"vendor_id"} />
                    </Grid>
                  </Grid>
                </MDBox>
              </MDBox>
              <MDBox mt={1.625} display="flex" justifyContent="right">
                <MDButton
                  variant="gradient"
                  color="light"
                  onClick={() => {
                    localStorage.removeItem("vendorId");
                    navigate("/purchase_orders");
                  }}
                >
                  {language == 1 ? "Cancelar" : "Cancel"}
                </MDButton>
                <MDButton
                  type="button"
                  variant="gradient"
                  className="float-sm-right"
                  color="info"
                  //onClick={getVendorProducts}
                  onClick={sendPurchaseOrder}
                  disabled={disableButton}
                >
                  {language == 1 ? "Guardar" : "Save"}
                </MDButton>
                {/* <MDButton variant="gradient" color='light' onClick={()=> console.log(selectedVendor.id)}>Check</MDButton> */}
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
        <Grid>
          <MDBox id="box1" mt={2}>
            <Card>
              {/*  <DataTable table={vendorProducts} entriesPerPage={false} canSearch /> */}
            </Card>
          </MDBox>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default AddPurchaseOrder;
