import React from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
// Material Dashboard 2 PRO React examples
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

const ReadOnlyRow = ({ invoice, handleEditClick, handleDeleteClick }) => {
  const [language, setLanguage] = React.useState(localStorage.getItem("Language"));
  return (
    <TableRow>
      <DataTableBodyCell>{invoice.invoice_number}</DataTableBodyCell>
      <DataTableBodyCell>{invoice.store_name}</DataTableBodyCell>
      <DataTableBodyCell>{invoice.pending}</DataTableBodyCell>
      <DataTableBodyCell>{invoice.amount}</DataTableBodyCell>
      <DataTableBodyCell>
        <MDButton
          variant="gradient"
          color="info"
          type="button"
          onClick={(event) => handleEditClick(event, invoice)}
        >
          {language==1 ? "Editar" : "Edit"}
        </MDButton>
        {invoice.amount > 0 ? (
          <MDButton
            variant="gradient"
            color="error"
            type="button"
            onClick={(event) => handleDeleteClick(event, invoice)}
          >
            {language==1 ? "Reiniciar" : "Reset"}
          </MDButton>
        ) : null}
      </DataTableBodyCell>
    </TableRow>
  );
};

export default ReadOnlyRow;
