/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import AlertDialog from "app/components/confirmBox";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable2 from "app/components/shopperTable";
import MDSnackbar from "components/MDSnackbar";

import IP from "app/IP";
import { responsiveFontSizes } from "@mui/material";

const axios = require("axios");

function RoleAccess() {
  const [access, setAccess] = useState([]);
  const [roleAccess, setRoleAccess] = useState({ columns: [], rows: [] });
  const [length, setLength] = useState(10);
  const [index, setIndex] = useState(0);
  const [language, setLanguage] = useState(localStorage.getItem("Language"));
  const [show, setShow] = useState(false);
  const toggleSnackbar = () => setShow(!show);

  const navigate = useNavigate();

  async function getRoles() {
    await axios
      .get(IP + "/roles", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        getRoleAccess(response.data.data.filter(item=>{
          return item.id != 9
        }));
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  function generateTable(modules, roles, columns) {
    var role_qty = roles.length;
    var modules_qty = modules.length;
    let rows = [];
    let accessArray = [];

    for (var modules_index = 0; modules_index < modules_qty; modules_index++) {
      var module = modules[modules_index];
      var module_name = module[0][1];

      accessArray.push([modules_index + 1]);

      for (let id = 1; id < modules[modules_index].length; id++) {
        accessArray[modules_index].unshift(modules[modules_index][id]);
      }

      console.log(accessArray[modules_index]);

      rows.push({
        module: module_name,
      });

      for (var roles_index = 0; roles_index < role_qty; roles_index++) {
        let role = roles[roles_index];
        let roleId = roles[roles_index].id;
        let moduleId = modules_index;

        let filteredModule = modules[modules_index].filter((item) => {
          return item == roleId;
        });

        Object.assign(rows[modules_index], {
          [role.name]:
            filteredModule.map((item) => {
              return item;
            }) == roleId ? (
              <>
                <Checkbox
                  defaultChecked
                  onClick={(event) => {
                    if (event.target.checked) {
                      accessArray[moduleId].unshift(roleId);
                      console.log(accessArray[moduleId]);
                    } else {
                      accessArray[moduleId] = accessArray[moduleId].filter((item) => {
                        return item != roleId;
                      });
                      if (roleId == moduleId + 1) {
                        accessArray[moduleId].push(moduleId + 1);
                      }
                      console.log(accessArray[moduleId]);
                    }
                  }}
                />
              </>
            ) : (
              <>
                <Checkbox
                  onClick={(event) => {
                    if (event.target.checked) {
                      accessArray[moduleId].unshift(roleId);
                      console.log(accessArray[moduleId]);
                    } else {
                      accessArray[moduleId] = accessArray[moduleId].filter((item) => {
                        return item != roleId;
                      });
                      if (roleId == moduleId + 1) {
                        accessArray[moduleId].push(moduleId + 1);
                      }
                      console.log(accessArray[moduleId]);
                    }
                  }}
                />
              </>
            ),
        });
      }
    }

    let tableData = { columns: columns, rows: rows };

    setAccess(accessArray);

    setRoleAccess(tableData);
  }

  async function getRoleAccess(roles) {
    let tableColumns = roles.map((role) => {
      return { Header: role.name, accessor: role.name, width: "10%" };
    });
    tableColumns.unshift({
      Header: language == 1 ? "Módulo" : "Module",
      accessor: "module",
      width: "20%",
    });
    await axios
      .get(IP + "/config/access", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        setLength(response.data.data.length);
        generateTable(response.data.data, roles, tableColumns);
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function sendAccess() {
    console.log(access);
    await axios
      .post(
        IP + "/config/access",
        {
          access: access,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        getRoles();
        toggleSnackbar();
        setTimeout(() => {
          setShow(false);
        }, 3000);
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  useEffect(() => {
    getRoles();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDSnackbar
        color="info"
        icon="notifications"
        title={language == 1 ? "EMOS Notificación" : "EMOS Notification"}
        content={
          language == 1
            ? "Los Roles fueron actualizados exitosamente."
            : "The Roles were updated successfully."
        }
        dateTime={language==1 ? "Ahora" : "Now"}
        open={show}
        close={toggleSnackbar}
      />
      <MDBox my={3}>
        <Card sx={{ p: 1.5 }}>
          <DataTable2
            table={roleAccess}
            index={index}
            setIndex={setIndex}
            pageEntries={length}
            entriesPerPage={false}
          />
          <MDBox display="flex" justifyContent="right" alignItems="flex-start" mb={2}>
            <MDButton variant="gradient" color="info" onClick={() => sendAccess()}>
              {language == 1 ? "Guardar" : "Save"}
            </MDButton>
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default RoleAccess;
