/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { jsPDF } from "jspdf";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import AlertDialog from "app/components/confirmBox";
import ViewInvoice from "./viewInvoice";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import IP from "app/IP";
import imageIP from "app/imageIP";

const axios = require("axios");

function InvoiceListAdmin() {
  const [menu, setMenu] = useState(null);
  const [data, setData] = useState([]);
  const [invoices, setInvoices] = useState({ columns: [], rows: [] });
  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState("");
  const [download, setDownload] = useState(true);
  const [user, setUser] = useState()
  const [language, setLanguage] = useState(localStorage.getItem("Language"));

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);
  const navigate = useNavigate();

  async function GetStores(storeIds) {
    await axios
      .get(IP + "/stores/getActive", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        console.log(response.data.data);
        let store = [];

        response.data.data.map((item) => {
          if (item.active == "Active" && storeIds.includes(item.id)) {
            store.push({ label: item.name, id: item.id });
          }
        });
        store.unshift({ label: language == 1 ? "Todas" : "All", id: 0 });

        setStores(store);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function getUserDetails() {
    await axios
      .get(IP + "/user/profile", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        setUser(response.data.data)
        getInvoiceData(response.data.data.role.id)
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function getInvoiceData(role) {
    await axios
      .get(IP + "/invoices", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        // console.log(response.data.data);
        setData(response.data.data);

        let storeIds = []

        response.data.data.map(item=>{
          if (!storeIds.includes(item.store.store_id)) {
            storeIds.push(item.store.store_id)
          }
        })

        if (role != 7)
          GetStores(storeIds)

        let columns = [
          {
            Header: language == 1 ? "Número de Factura" : "Invoice Number",
            accessor: "invoice_number",
          },
          { Header: language == 1 ? "Número de Orden" : "Order Number", accessor: "order" },
          {
            Header: language == 1 ? "Tienda" : "Store",
            accessor: "store_name",
          },
          { Header: language == 1 ? "Completamente Pagado" : "Fully Paid", accessor: "paid" },
          { Header: language == 1 ? "Cantidad Total" : "Total Amount", accessor: "total" },
          { Header: language == 1 ? "Total de Pagos" : "Total Paid", accessor: 'pay_total' },
          { Header: language == 1 ? "Total Pendiente" : "Total Pending", accessor: 'pending' },
          { Header: language == 1 ? "Acción" : "action", accessor: "action", width: '25%' },
        ];

        let rows = response.data.data.map((item) => {
          //console.log(item);
          return {
            invoice_number: item.invoice_number,
            order: item.sales.so_number,
            paid:
              item.fully_paid == 1
                ? language == 1
                  ? "Pagado"
                  : "Paid"
                : language == 1
                ? "Pendiente"
                : "Pending",
            total: parseFloat(item.total_amount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            pay_total: parseFloat(item.payment_total).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            pending: parseFloat(item.pending).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            store_name: item.store.name,
            action: (
              <Grid>
                <Tooltip title={language == 1 ? "Ver" : "View"}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={() => {
                      navigate("/view-invoice");
                      localStorage.setItem("invoiceId", item.id);
                    }}
                    iconOnly
                  >
                    <Icon>visibility</Icon>
                  </MDButton>
                </Tooltip>{" "}
                {/* <Tooltip title={language == 1 ? "Imprimir" : "Print"}>
                  <MDButton
                    variant="gradient"
                    color="warning"
                    onClick={() => {
                      navigate("/view-invoice", { state: { print: true } });
                      localStorage.setItem("invoiceId", item.id);
                    }}
                    iconOnly
                  >
                    <Icon>print</Icon>
                  </MDButton>
                </Tooltip>
                {"  "} */}
                <Tooltip title={language == 1 ? "Descargar" : "Download"}>
                  <MDButton
                    variant="gradient"
                    color="error"
                    href={imageIP + "invoiceDownload/" + item.id}
                    iconOnly
                  >
                    <Icon>download</Icon>
                  </MDButton>
                </Tooltip>
                {"  "}
                <Tooltip title={language == 1 ? "Ajustes" : "Adjustments"}>
                  <MDButton
                    variant="gradient"
                    color="dark"
                    onClick={() => {
                      navigate('/invoice-adjustments', {
                        state: {InvoiceId: item.id}
                      })
                    }}
                    iconOnly
                  >
                    <Icon>file_copy</Icon>
                  </MDButton>
                </Tooltip>
                {"  "}
                <Tooltip title={language == 1 ? "Descargar Ajustes" : "Download Adjustments"}>
                  <MDButton
                    variant="gradient"
                    color="light"
                    href={imageIP + "adjustmentDownload/" + item.id}
                    iconOnly
                  >
                    <Icon>download</Icon>
                  </MDButton>
                </Tooltip>
                {"  "}
                {role != 7 && <Tooltip title={language == 1 ? "Pagos" : "Payments"}>
                  <MDButton
                    variant="gradient"
                    color="success"
                    onClick={() => {
                      navigate("/invoice-payments-admin", {
                        state: { InvoiceId: item.id },
                      });
                    }}
                    iconOnly
                  >
                    <Icon>attach_money</Icon>
                  </MDButton>
                </Tooltip>}
              </Grid>
            ),
          };
        });

        let tableData = { columns: columns, rows: rows };
        //console.log(tableData)
        setInvoices(tableData);
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  function filterPayments(store) {
    console.log(store);
    let columns = [
      {
        Header: language == 1 ? "Número de Factura" : "Invoice Number",
        accessor: "invoice_number",
      },
      { Header: language == 1 ? "Número de Orden" : "Order Number", accessor: "order" },
      {
        Header: language == 1 ? "Tienda" : "Store",
        accessor: "store_name",
      },
      { Header: language == 1 ? "Completamente Pagado" : "Fully Paid", accessor: "paid" },
      { Header: language == 1 ? "Cantidad Total" : "Total Amount", accessor: "total" },
      { Header: language == 1 ? "Total de Pagos" : "Total Paid", accessor: 'pay_total' },
      { Header: language == 1 ? "Total Pendiente" : "Total Pending", accessor: 'pending' },
      { Header: language == 1 ? "Acción" : "action", accessor: "action", width: '25%' },
    ];

    if (store?.id != 0 && store) {
      let rows = data
        .filter((item) => {
          return item.sales.store_id == store.id;
        })
        .map((item) => {
          //console.log(item);
          return {
            invoice_number: item.invoice_number,
            order: item.sales.so_number,
            store_name: item.store.name,
            paid:
              item.fully_paid == 1
                ? language == 1
                  ? "Pagado"
                  : "Paid"
                : language == 1
                ? "Pendiente"
                : "Pending",
            total: item.total_amount,
            pay_total: parseFloat(item.payment_total).toFixed(2),
            pending: item.pending,
            action: (
              <Grid>
                <Tooltip title={language == 1 ? "Ver" : "View"}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={() => {
                      navigate("/view-invoice");
                      localStorage.setItem("invoiceId", item.id);
                    }}
                    iconOnly
                  >
                    <Icon>visibility</Icon>
                  </MDButton>
                </Tooltip>{" "}
                {/* <Tooltip title={language == 1 ? "Imprimir" : "Print"}>
                  <MDButton
                    variant="gradient"
                    color="warning"
                    onClick={() => {
                      navigate("/view-invoice", { state: { print: true } });
                      localStorage.setItem("invoiceId", item.id);
                    }}
                    iconOnly
                  >
                    <Icon>print</Icon>
                  </MDButton>
                </Tooltip>
                {"  "} */}
                <Tooltip title={language == 1 ? "Descargar" : "Download"}>
                  <MDButton
                    variant="gradient"
                    color="error"
                    href={imageIP + "invoiceDownload/" + item.id}
                    iconOnly
                  >
                    <Icon>download</Icon>
                  </MDButton>
                </Tooltip>
                {"  "}
                <Tooltip title={language == 1 ? "Ajustes" : "Adjustments"}>
                  <MDButton
                    variant="gradient"
                    color="dark"
                    onClick={() => {
                      navigate('/invoice-adjustments', {
                        state: {InvoiceId: item.id}
                      })
                    }}
                    iconOnly
                  >
                    <Icon>file_copy</Icon>
                  </MDButton>
                </Tooltip>
                {"  "}
                <Tooltip title={language == 1 ? "Descargar Ajustes" : "Download Adjustments"}>
                  <MDButton
                    variant="gradient"
                    color="light"
                    href={imageIP + "adjustmentDownload/" + item.id}
                    iconOnly
                  >
                    <Icon>download</Icon>
                  </MDButton>
                </Tooltip>
                {"  "}
                {user?.role?.id != 7 && <Tooltip title={language == 1 ? "Pagos" : "Payments"}>
                  <MDButton
                    variant="gradient"
                    color="success"
                    onClick={() => {
                      navigate("/invoice-payments-admin", {
                        state: { InvoiceId: item.id },
                      });
                    }}
                    iconOnly
                  >
                    <Icon>attach_money</Icon>
                  </MDButton>
                </Tooltip>}
              </Grid>
            ),
          };
        });

      let tableData = { columns: columns, rows: rows };
      //console.log(tableData)
      setInvoices(tableData);
    }

    if (store?.id == 0 || !store) {
      let rows = data.map((item) => {
        //console.log(item);
        return {
          invoice_number: item.invoice_number,
          paid:
            item.fully_paid == 1
              ? language == 1
                ? "Pagado"
                : "Paid"
              : language == 1
              ? "Pendiente"
              : "Pending",
          total: item.total_amount,
          pending: item.pending,
          store_name: item.store.name,
          pay_total: parseFloat(item.payment_total).toFixed(2),
          action: (
              <Grid>
                <Tooltip title={language == 1 ? "Ver" : "View"}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={() => {
                      navigate("/view-invoice");
                      localStorage.setItem("invoiceId", item.id);
                    }}
                    iconOnly
                  >
                    <Icon>visibility</Icon>
                  </MDButton>
                </Tooltip>{" "}
                {/* <Tooltip title={language == 1 ? "Imprimir" : "Print"}>
                  <MDButton
                    variant="gradient"
                    color="warning"
                    onClick={() => {
                      navigate("/view-invoice", { state: { print: true } });
                      localStorage.setItem("invoiceId", item.id);
                    }}
                    iconOnly
                  >
                    <Icon>print</Icon>
                  </MDButton>
                </Tooltip>
                {"  "} */}
                <Tooltip title={language == 1 ? "Descargar" : "Download"}>
                  <MDButton
                    variant="gradient"
                    color="error"
                    href={imageIP + "invoiceDownload/" + item.id}
                    iconOnly
                  >
                    <Icon>download</Icon>
                  </MDButton>
                </Tooltip>
                {"  "}
                <Tooltip title={language == 1 ? "Ajustes" : "Adjustments"}>
                  <MDButton
                    variant="gradient"
                    color="dark"
                    onClick={() => {
                      navigate('/invoice-adjustments', {
                        state: {InvoiceId: item.id}
                      })
                    }}
                    iconOnly
                  >
                    <Icon>file_copy</Icon>
                  </MDButton>
                </Tooltip>
                {"  "}
                <Tooltip title={language == 1 ? "Descargar Ajustes" : "Download Adjustments"}>
                  <MDButton
                    variant="gradient"
                    color="light"
                    href={imageIP + "adjustmentDownload/" + item.id}
                    iconOnly
                  >
                    <Icon>download</Icon>
                  </MDButton>
                </Tooltip>
                {"  "}
                {user?.role?.id != 7 && <Tooltip title={language == 1 ? "Pagos" : "Payments"}>
                  <MDButton
                    variant="gradient"
                    color="success"
                    onClick={() => {
                      navigate("/invoice-payments-admin", {
                        state: { InvoiceId: item.id },
                      });
                    }}
                    iconOnly
                  >
                    <Icon>attach_money</Icon>
                  </MDButton>
                </Tooltip>}
              </Grid>
            ),
        };
      });

      let tableData = { columns: columns, rows: rows };
      //console.log(tableData)
      setInvoices(tableData);
    }
  }

  async function sendEmail() {
    await axios
    .get(IP + "/sendMail", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        Accept: "application/json",
      },
    })
    .then(response=>{

    })
    .catch(error=>{
      console.log(error)
    })
  }

  useEffect(() => {
    getUserDetails()
    //getInvoiceData();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar userData={user} />
      <MDBox my={3}>
        <Card>
          {stores.length > 2 && <Autocomplete
            sx={{ width: "15%", mt: 2, ml: 2 }}
            disablePortal
            value={selectedStore}
            onChange={(event, newValue) => {
              setSelectedStore(newValue);
              filterPayments(newValue);
            }}
            options={stores}
            renderInput={(params) => (
              <MDInput
                {...params}
                label={language == 1 ? "Tiendas" : "Stores"}
                variant="standard"
              />
            )}
          />}
          <DataTable table={invoices} entriesPerPage={true} canSearch />
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default InvoiceListAdmin;
