import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// NewUser page components
import UserInfo from "layouts/pages/users/new-user/components/UserInfo";
import Address from "layouts/pages/users/new-user/components/Address";
import Socials from "layouts/pages/users/new-user/components/Socials";
import Profile from "layouts/pages/users/new-user/components/Profile";

// NewUser layout schemas for form and form feilds
import validations from "layouts/pages/users/new-user/schemas/validations";
import form from "layouts/pages/users/new-user/schemas/form";
import initialValues from "layouts/pages/users/new-user/schemas/initialValues";
import MDInput from "components/MDInput";

import IP from "app/IP";

const axios = require("axios");

function EditReceiving() {
  const current = new Date();
  let month = current.getMonth() + 1;
  const currentDate = `${current.getFullYear()}-${
    month < 10 ? `0${month}` : `${month}`
  }-${current.getDate()}`;

  const [date, setDate] = useState(currentDate);
  const [orderNumber, setOrderNumber] = useState("");
  const [purchaseOrder, setPurchaseOrder] = useState([{}]);
  const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState("");
  const [vendor, setVendor] = useState([{}]);
  const [selectedVendor, setSelectedVendor] = useState("");
  const [show, setShow] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [errors, setErrors] = useState([]);
  const toggleSnackbar = () => setShow(!show);

  const navigate = useNavigate();

  async function GetPurchaseOrder() {
    // console.log("aqui");
    //setRedirect(true);
    await axios
      .get(IP + "/purchase_orders", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(function (response) {
        //console.log(response.data.data);
        setPurchaseOrder(
          response.data.data.map((item) => {
            return { label: item.id, id: item.id };
          })
        );
      })
      .catch(function (error) {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function GetVendors() {
    // console.log("aqui");
    //setRedirect(true);
    await axios
      .get(IP + "/vendors", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(function (response) {
        //console.log(response.data.data);
        setVendor(
          response.data.data.map((item) => {
            return { label: item.name, id: item.id };
          })
        );
      })
      .catch(function (error) {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function sendReceiving() {
    setDisableButton(true);
    await axios
      .put(
        IP + "/receivings/" + localStorage.getItem("receivingsId"),
        {
          date: date,
          vendor_order_number: orderNumber,
          purchase_order_id: selectedPurchaseOrder.id,
          vendor_id: selectedVendor.id,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        setTimeout(() => {
          navigate("/receiving");
        }, 3000);
        console.log(response);
        toggleSnackbar();
      })
      .catch((error) => {
        setErrors(error.response.data.data);
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });

    setTimeout(() => {
      setDisableButton(false);
    }, 3000);
  }

  async function getReceivingData() {
    await axios
      .get(IP + "/receivings/" + localStorage.getItem("receivingsId"), {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        let receivings = response.data.data;
        setDate(receivings.date);
        setOrderNumber(receivings.vendor_order_number);
        setSelectedPurchaseOrder({
          id: receivings.purchaseOrder.id,
          label: receivings.receivings.id,
        });
        setSelectedVendor({ id: receivings.vendor.id, label: receivings.vendor.name });
      });
  }

  function TestValidation(message) {
    if (message)
      return message.map((item) => {
        return (
          <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
            <label>{item}</label>
          </MDTypography>
        );
      });
    else return null;
  }

  function ShowError(param) {
    // console.log(errors["name"]);
    if (param.id) return TestValidation(errors[param.id]);
    else return null;
  }

  useEffect(() => {
    getReceivingData();
    GetPurchaseOrder();
    GetVendors();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDSnackbar
        color="info"
        icon="notifications"
        title="EMOS"
        content="Created Successfully!"
        dateTime={language==1 ? "Ahora" : "Now"}
        open={show}
        close={toggleSnackbar}
      />
      <MDBox py={3} mb={20} height="65vh">
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 8 }}>
          <Grid item xs={12} lg={8}>
            <Card sx={{ height: "100%" }}>
              <MDBox p={3}>
                <MDBox>
                  <MDBox>
                    <MDBox lineHeight={0}>
                      <MDTypography variant="h5">Edit Receiving</MDTypography>
                      <MDTypography variant="button" color="text">
                        Please enter receiving information.
                      </MDTypography>
                    </MDBox>
                    <MDBox id="box1" mt={1.625}>
                      <Grid id="grid1" container spacing={3}>
                        <Grid id="grid1-1" item xs={12} sm={6}>
                          <MDInput
                            variant="standard"
                            type="date"
                            label="Date"
                            fullWidth
                            value={date}
                            onChange={(e) => {
                              setDate(e.target.value);
                            }}
                          />
                          <ShowError id={"date"} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <MDInput
                            variant="standard"
                            label="Order Number"
                            fullWidth
                            value={orderNumber}
                            onChange={(e) => {
                              setOrderNumber(e.target.value);
                            }}
                          />
                          <ShowError id={"vendor_order_number"} />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                          <Autocomplete
                            disablePortal
                            value={selectedPurchaseOrder}
                            onChange={(event, newValue) => {
                              console.log(newValue);
                              setSelectedPurchaseOrder(newValue);
                            }}
                            options={purchaseOrder}
                            renderInput={(params) => (
                              <MDInput {...params} label="Purchase Order" variant="standard" />
                            )}
                          />
                          <ShowError id={"purchase_order_id"} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Autocomplete
                            disablePortal
                            value={selectedVendor}
                            onChange={(event, newValue) => {
                              console.log(newValue);
                              setSelectedVendor(newValue);
                            }}
                            options={vendor}
                            renderInput={(params) => (
                              <MDInput {...params} label="Vendor" variant="standard" />
                            )}
                          />
                          <ShowError id={"vendor_id"} />
                        </Grid>
                      </Grid>
                    </MDBox>
                  </MDBox>
                  <MDBox mt={2} width="100%" display="flex" justifyContent="right">
                    <MDButton
                      variant="gradient"
                      color="light"
                      onClick={() => navigate("/receiving")}
                    >
                      Cancel
                    </MDButton>
                    <MDButton
                      type="button"
                      variant="gradient"
                      className="float-sm-right"
                      color="info"
                      onClick={sendReceiving}
                      disabled={disableButton}
                    >
                      Save
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default EditReceiving;
