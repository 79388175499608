/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDPagination from "components/MDPagination";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
import AlertDialog from "app/components/confirmBox";
import ProductCell from "layouts/ecommerce/products/product-page/components/ProductCell";
import ProductCard from "app/components/productCard";
import CarouselCard from "app/components/carouselCard";
import Carousel from "react-bootstrap/Carousel";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import DataTable2 from "app/components/shopperTable";
import MDInput from "components/MDInput";
import AlertDialog2 from "app/components/confirmBoxDeliveryPickup";
import MDSnackbar from "components/MDSnackbar";

import IP from "app/IP";

import "./style.css";

import { Tooltip } from "@mui/material";
import imageIP from "app/imageIP";
import { ClipLoader } from "react-spinners";

const axios = require("axios");

function Ecommerce() {
  const [menu, setMenu] = useState(null);
  const [carouselSlides, setCarouselSlides] = useState([])
  const [products, setProducts] = useState({ columns: [], rows: [] });
  const [productCards, setProductCards] = useState();
  const [quantity, setQuantity] = useState();
  const [imgsViewer, setImgsViewer] = useState(false);
  const [viewableImage, setViewableImage] = useState();
  const [cartItems, setCartItems] = useState(localStorage.getItem("SalesOrderId") != null ? false : true)
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [isMobile, setIsMobile] = useState(false)
  const [search, setSearch] = useState("");
  const [options, setOptions] = useState("");
  const [subcategories, setSubcategories] = useState([{}]);
  const [selectedSubcategory, setSelectedSubcategory] = useState("");
  const [show, setShow] = useState(false);
  const [check, setCheck] = useState(false);
  const [data, setData] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [subtotal, setSubtotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [taxTotal, setTaxTotal] = useState(0);
  const [qtyTotal, setQtyTotal] = useState(0);
  const [filterSelect, setFilterSelect] = useState();
  const [sortSelect, setSortSelect] = useState();
  const [success, setSuccess] = useState(true);
  const [loading, setLoading] = useState(true);
  const [market, setMarket] = useState(true);
  const [showM, setShowM] = useState(false);
  const [reRun, setReRun] = useState(false);
  const toggleSnackbar = () => setShowM(!showM);
  const toggleSnackbar2 = () => setShowM2(!showM2);
  const toggleSnackbar3 = () => setShowM3(!showM3);
  const [showM2, setShowM2] = useState(false);
  const [showM3, setShowM3] = useState(false);
  const [showM4, setShowM4] = useState(false);
  const [viewTotals, setViewTotals] = useState(false)
  const [showError, setShowError] = useState(false)
  const [showZoneError, setShowZoneError] = useState(false)
  const [zoneDeliveryDay, setZoneDeliveryDay] = useState('')
  const [zoneOrderDays, setZoneOrderDays] = useState("")
  const [errorProd, setErrorProd] = useState("")
  const [error, setError] = useState("")
  const [showDelete, setShowDelete] = useState(false);
  const [transport, setTransport] = useState("C");
  const [currentSale, setCurrentSale] = useState([]);
  const [currentShopper, setCurrentShopper] = useState([]);
  const [shopperDates, setShopperDates] = useState({start: "", end: "", startDisplay: "", endDisplay: ""})
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState()
  const [changeStatus, setChangeStatus] = useState(false);
  const [index, setIndex] = useState(0);
  const [page, setPage] = useState(0);
  const [pageOptions, setPageOptions] = useState([]);
  const [finalPage, setFinalPage] = useState(2);
  const [language, setLanguage] = useState(localStorage.getItem("Language"));
  const [sortBy, setSortBy] = useState(
    language == 1 ? { label: "Precio", id: 1 } : { label: "Price", id: 1 }
  );
  const [selectedProds, setSelectedProds] = useState({ columns: [], rows: [] });
  const [cartItemToUpOrDel, setCartItemToUpOrDel] = useState({});
  const [special, setSpecial] = useState(false);
  const [discount, setDiscount] = useState();
  const [zoomImage, setZoomImage] = useState(null);
  const [showZoomImage, setShowZoomImage] = useState(false);
  const [added, setAdded] = useState(false)
  const [clickedProduct, setClickedProduct] = useState({});
  const [deliveryFeeAmount, setDeliveryFeeAmount] = useState(0);
  const [storeLabel, setStoreLabel] = useState("");
  const [previousBalance, setPreviousBalance] = useState(0)
  const [currentSlide, setCurrentSlide] = useState(0)
  const [controlSlides, setControlSlides] = useState([])
  const [pause, setPause] = useState(5)
  const [role, setRole] = useState(0)

  const closeImgsViewer = () => setImgsViewer(false);
  const handleSetCurrentImage = ({ currentTarget }) => {
    console.log(currentTarget.src);
    setViewableImage(currentTarget.src);
    setImgsViewer(true);
  };

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);
  const navigate = useNavigate();
  const handleClose = () => {
    setOpen(false);
    setQuantity();
  };
  const handleClose2 = () => {
    setOpen2(false);
  };
  const handleClose3 = () => {
    setOpen3(false);
    setCheck(false);
    setSuccess(true);
    setSelectedSubcategory(null);
  };

  const handleResize = () => {
    if (window.innerWidth < 720) {
        setIsMobile(true)
    } else {
        setIsMobile(false)
    }
  }


  const cards2 = [];

  const sorting = [
    {
      id: 1,
      option: "Price",
    },
    {
      id: 2,
      option: "Available",
    },
  ];

  const sortingEsp = [
    {
      id: 1,
      option: "Precio",
    },
    {
      id: 2,
      option: "Cantidad Disponible",
    },
  ];

  function sortOptions() {
    language == 1
      ? setOptions(
          sortingEsp.map((item) => {
            return {
              label: item.option,
              id: item.id,
            };
          })
        )
      : setOptions(
          sorting.map((item) => {
            return {
              label: item.option,
              id: item.id,
            };
          })
        );
  }

  async function getUserDetails() {
    await axios
    .get(IP + "/user/profile", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(function (response) {
      let user = response.data.data
      setRole(user.role.id);
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  async function GetSubcategories() {
    // console.log("aqui");
    //setRedirect(true);
    await axios
      .get(IP + "/subcategories", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(function (response) {
        //console.log(response.data.data);
        let sub = [];

        response.data.data.map((item) => {
          if (item.active == "Active") {
            sub.push({ label: item.name, id: item.id });
          }
        });

        setSubcategories(sub);
      })
      .catch(function (error) {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function GetCategories() {
    // console.log("aqui");
    //setRedirect(true);
    await axios
      .get(IP + "/categories", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(function (response) {
        console.log(response.data.data);
        setCategories(
          response.data.data.filter((item) => {
            return item.active == "Active";
          })
        );
      })
      .catch(function (error) {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function getProductsWithCategories(categoryid) {
    setSuccess(true);
    setCheck(false);
    // setShow(!show)
    // console.log(show);

    await axios
      .get(IP + "/products?category_id=" + categoryid, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        setPageData(
          response.data.data.filter((item) => {
            return item.active == "Active";
          })
        );
        setPage(0);

        let saleIds = currentSale.map((item) => {
          return item.product_id;
        });

        let shopperIds = currentShopper.filter((item)=>{
          return item.isShopper == true
        }).map((item) => {
          return item.product_id;
        });

        let expressIds = currentShopper.filter((item)=>{
          return item.isExpress == true
        }).map((item) => {
          return item.product_id;
        });

        let ecommerceColumns = [
          { Header: language == 1 ? "Imagen" : "Image", accessor: "image", width: "2%" }, 
          { Header: "UPC", accessor: "upc", width: "5%" },
          { Header: language == 1 ? "UPC Unidad" : "UPC Unit", accessor: "upc_unit", width: "5%" },
          { Header: language == 1 ? "Descripción" : "Description", accessor: "name", width: "15%" },
          {
            Header: language == 1 ? "Disponible" : "Available",
            accessor: "availability",
            width: "7%",
          },
          { Header: language == 1 ? "Empaque" : "Package", accessor: "package", width: "6%" },
          { Header: language == 1 ? "Precio" : "Price", accessor: "price", width: "5%" },
          { Header: language == 1 ? "Oferta" : "Sale", accessor: "sale", width: "3%" },
          { Header: language == 1 ? "Acción" : "Action", accessor: "action", width: "10%" },
        ];

        if (filterSelect) {
          if (filterSelect == "lowstock") {
            filterLowStock(categoryid, response.data.data.filter((item) => {
              return item.active == "Active";
            }));
          } else if (filterSelect == "discount") {
            filterOnSale(categoryid, response.data.data.filter((item) => {
              return item.active == "Active";
            }));
          } else if (filterSelect == "shopper") {
            console.log("FILTERING SHOPPER")
            filterShopper(categoryid, response.data.data.filter((item) => {
              return item.active == "Active";
            }));
          }
        } else {

          let ecommerceRows = response.data.data
            .filter((item) => {
              return item.active == "Active";
            })
            .map((item) => {
              //console.log(item);
              return {
                image: (
                  <>
                    <ProductCell name="" image={imageIP + item.image} />
                    <MDButton
                      variant="gradient"
                      color="info"
                      iconOnly
                      style={{ height: "1", width: "1" }}
                      onClick={() => {
                        setClickedProduct(item);
                        setViewableImage(item.image);
                        setImgsViewer(true);
                      }}
                    >
                      <Icon>visibility</Icon>
                    </MDButton>
                  </>
                ),
                subdept: item.subcategory.name,
                upc: item.upc,
                sale: (
                  <>
                    {saleIds.includes(item.id) ? (
                      <MDTypography variant="button" color="info">
                        {language == 1 ? "En Oferta! " : "On Sale! "}
                      </MDTypography>
                    ) : null}
                    {shopperIds.includes(item.id) ? (
                      <MDTypography variant="button" sx={{ color: "red" }}>
                        Shopper{" "}
                      </MDTypography>
                    ) : null}
                    {expressIds.includes(item.id) ? (
                      <MDTypography variant="button" sx={{ color: "orange" }}>
                        Express{" "}
                      </MDTypography>
                    ) : null}
                  </>
                ),
                upc_unit: item.upcUnit,
                name: item.name,
                availability: item.available,
                package: item.packaging,
                price: saleIds.includes(item.id) ? (
                  <>
                    <MDTypography
                      sx={{ textDecoration: "line-through" }}
                      variant="button"
                      color="error"
                    >
                      {item.sales_price}
                    </MDTypography>
                    <MDTypography sx={{ ml: 1 }} variant="button" color="success">
                      {
                        currentSale.filter((sale) => {
                          return sale.product_id == item.id;
                        })[0].specialPrice
                      }
                    </MDTypography>
                  </>
                ) : (
                  item.sales_price
                ),
                action: (
                  role == 7 &&
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={() => {
                      setCartItemToUpOrDel(item);
                      setOpen(true);
                    }}
                  >
                    <Icon>shopping_cart</Icon>
                    &nbsp;{language == 1 ? "Añadir al carrito" : "Add to cart"}
                  </MDButton>
                ),
              };
            });

          let tableData = { columns: ecommerceColumns, rows: ecommerceRows };
          //console.log(tableData)
          setProducts(tableData);
          let cards = [];
          response.data.data
          .forEach((element) => {
            if (element.active == "Active") {
              cards.push(
                <ProductCard
                  role={role}
                  showButton={role == 7}
                  open={localStorage.getItem("SalesOrderId") ? AddProduct : getSalesId}
                  product={element}
                  name={element.name}
                  subDept={element.subcategory.name}
                  upc={element.upc}
                  code={element.upcUnit}
                  available={element.available}
                  packaging={element.packaging}
                  price={element.sales_price}
                  minimum={element.minimum}
                  image={imageIP + element.image}
                  prodId={element.id}
                  reRun={() => {
                    let value = reRun;
                    setReRun(!reRun);
                    return value;
                  }}
                  onSale={currentSale}
                  shopper={currentShopper}
                  viewImage={handleSetCurrentImage}
                />
              );
            }
          });
          setProductCards(cards);
        }
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function getOnSale() {
    // console.log("aqui");
    //setRedirect(true);
    await axios
      .get(IP + "/onsale/products", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(function (response) {
        response.data.data.map((item) => {
          currentSale.push(item);
        });
        getProductData();
      })
      .catch(function (error) {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function getShopper() {
    // console.log("aqui");
    //setRedirect(true);
    await axios
      .get(IP + "/shopper/products", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(function (response) {
        response.data.data.map((item) => {
          currentShopper.push(item);
        });
        if (response.data.data.length > 0) {
          setShopperDates({start: response.data.data[0].shopper.startDate, end: response.data.data[0].shopper.endDate, startDisplay: response.data.data[0].shopper.displayStartDate, endDisplay: response.data.data[0].shopper.displayEndDate})
        }
        console.log(currentShopper);
      })
      .catch(function (error) {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function getSalesId(body) {
    console.log('getting id')
    await axios
      .post(IP + "/sales_orders", body, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        localStorage.setItem("SalesOrderId", response.data.data.id);
        AddProduct(body);
      })
      .catch((error) => {
        // setErrors(error.response.data.data);
        console.log(error);
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        if (error.response?.data?.data?.store) {
          setShowM4(true);
          setError(error.response?.data?.data?.store)
        }
      });
  }

  async function DeleteOrderDetailProduct() {
    await axios
      .delete(IP + "/so_detail/" + cartItemToUpOrDel.id, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(function (response) {
        getProductData();
        toggleSnackbar3();
        setTimeout(() => {
          setShowM3(false);
        }, 5000);
      })
      .catch(function (error) {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function updateCart() {
    await axios
      .put(
        IP + "/so_detail/" + cartItemToUpOrDel.id,
        {
          sales_order_id: localStorage.getItem("SalesOrderId"),
          product_id: cartItemToUpOrDel.product.id,
          quantity: quantity,
          quantity_delivered: cartItemToUpOrDel.quantity_delivered,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then(function (response) {
        setQuantity(0);
        getProductData();
        toggleSnackbar3();
        setTimeout(() => {
          setShowM3(false);
        }, 5000);
      })
      .catch(function (error) {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function getProductData(adding) {
    //adding parameter is used to know if a product was just added and if the user was searching, 
    //this sets the value (true) to the added hook which calls an useEffect
    //to check if the user was searching while they added to product and then runs the search function with the new data

    setSuccess(true);

    //setShow(!show)
    console.log(show);
    await axios
      // .get(IP + "/ecommerce/" + localStorage.getItem("SalesOrderId"), {
      .get(
        IP +
          (localStorage.getItem("SalesOrderId")
            ? "/ecommerce/" + localStorage.getItem("SalesOrderId")
            : "/products"),
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        console.log(response.data.data);
        setLoading(false)
        let cards = [];
        if (localStorage.getItem("SalesOrderId")) {
          setData(
            response.data.data.all?.filter((item) => {
              return item.active == "Active";
            })
          );
          setPageData(
            response.data.data.all?.filter((item) => {
              return item.active == "Active";
            })
          );
        } else {
          setData(
            response.data?.data?.filter((item) => {
              return item.active == "Active";
            })
          );
          setPageData(
            response.data?.data?.filter((item) => {
              return item.active == "Active";
            })
          );
        }
        if (adding)
          setAdded(true)
        //setPage(0);

        let saleIds = currentSale.map((item) => {
          return item.product_id;
        });

        let shopperIds = currentShopper.filter((item)=>{
          return item.isShopper == true
        }).map((item) => {
          return item.product_id;
        });

        let expressIds = currentShopper.filter((item)=>{
          return item.isExpress == true
        }).map((item) => {
          return item.product_id;
        });

        let ecommerceColumns = [
          { Header: language == 1 ? "Imagen" : "Image", accessor: "image", width: "2%" },
          { Header: "UPC", accessor: "upc", width: "5%" },
          { Header: language == 1 ? "UPC Unidad" : "UPC Unit", accessor: "upc_unit", width: "5%" },
          { Header: language == 1 ? "Descripción" : "Description", accessor: "name", width: "15%" },
          {
            Header: language == 1 ? "Disponible" : "Available",
            accessor: "availability",
            width: "7%",
          },
          { Header: language == 1 ? "Empaque" : "Package", accessor: "package", width: "6%" },
          { Header: language == 1 ? "Precio" : "Price", accessor: "price", width: "5%" },
          { Header: language == 1 ? "Oferta" : "Sale", accessor: "sale", width: "3%" },
          { Header: language == 1 ? "Acción" : "Action", accessor: "action", width: "10%" },
        ];

        if (filterSelect) {
          if (filterSelect == "lowstock") {
            filterLowStock(selectedCategory?.id, response.data.data.filter((item) => {
              return item.active == "Active";
            }));
          } else if (filterSelect == "discount") {
            filterOnSale(selectedCategory?.id, response.data.data.filter((item) => {
              return item.active == "Active";
            }));
          } else if (filterSelect == "shopper") {
            filterShopper(selectedCategory?.id, response.data.data.filter((item) => {
              return item.active == "Active";
            }));
          }
        } else {

          let ecommerceRows = (
            localStorage.getItem("SalesOrderId") ? response.data.data?.all : response.data.data
          )
            .filter((item) => {
              return item.active == "Active";
            })
            // .map(item=>{
            //   return {...item, image: images.filter(image=>{return image.id == item.id})[0]?.image}
            // })
            .map((item) => {
              //console.log(item);
              return {
                image: (
                  <>
                    <ProductCell name="" image={imageIP + item.image} />
                    <MDButton
                      variant="gradient"
                      color="info"
                      iconOnly
                      style={{ height: "1", width: "1" }}
                      onClick={() => {
                        setClickedProduct(item);
                        setViewableImage(item.image);
                        setImgsViewer(true);
                      }}
                    >
                      <Icon>visibility</Icon>
                    </MDButton>
                  </>
                ),
                subdept: item.subcategory.name,
                upc: item.upc,
                upc_unit: item.upcUnit,
                sale: (
                  <>
                    {saleIds.includes(item.id) ? (
                      <MDTypography variant="button" color="info">
                        {language == 1 ? "En Oferta! " : "On Sale! "}
                      </MDTypography>
                    ) : null}
                    {shopperIds.includes(item.id) ? (
                      <MDTypography variant="button" sx={{ color: "red" }}>
                        Shopper{" "}
                      </MDTypography>
                    ) : null}
                    {expressIds.includes(item.id) ? (
                      <MDTypography variant="button" sx={{ color: "orange" }}>
                        Express{" "}
                      </MDTypography>
                    ) : null}
                  </>
                ),
                name: <MDTypography variant="button">{item.name}</MDTypography>,
                availability: item.available,
                package: item.packaging,
                price: saleIds.includes(item.id) ? (
                  <>
                    <MDTypography
                      sx={{ textDecoration: "line-through" }}
                      variant="button"
                      color="error"
                    >
                      {item.sales_price}
                    </MDTypography>
                    <MDTypography sx={{ ml: 1 }} variant="button" color="success">
                      {
                        currentSale.filter((sale) => {
                          return sale.product_id == item.id;
                        })[0].specialPrice
                      }
                    </MDTypography>
                  </>
                ) : (
                  item.sales_price
                ),
                action: ( role == 7 &&
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={() => {
                      setCartItemToUpOrDel(item);
                      setOpen(true);
                    }}
                  >
                    <Icon>shopping_cart</Icon>
                    &nbsp;{language == 1 ? "Añadir al carrito" : "Add to cart"}
                  </MDButton>
                ),
              };
            });

          let tableData = { columns: ecommerceColumns, rows: ecommerceRows };
          //console.log(tableData)
          if (!(adding && search.length > 0))
            setProducts(tableData);
          (localStorage.getItem("SalesOrderId") ? response.data.data.all : response.data.data) /// replace with pagination functionality
            .filter(item=>{
              return item.active == "Active"
            })
            // .map(item=>{
            //   return {...item, image: images.filter(image=>{return image.id == item.id})[0]?.image}
            // })
            .forEach((element) => {
              cards.push(
                <ProductCard
                  role={role}
                  showButton={role == 7}
                  open={localStorage.getItem("SalesOrderId") ? AddProduct : getSalesId}
                  product={element}
                  name={element.name}
                  subDept={element.subcategory.name}
                  upc={element.upc}
                  code={element.upcUnit}
                  available={element.available}
                  packaging={element.packaging}
                  price={element.sales_price}
                  minimum={element.minimum}
                  image={imageIP + element.image}
                  // image={element.image}
                  viewImage={handleSetCurrentImage}
                  prodId={element.id}
                  reRun={() => {
                    let value = reRun;
                    setReRun(!reRun);
                    return value;
                  }}
                  onSale={saleIds.includes(element.id) ? currentSale : []}
                  shopper={(shopperIds.includes(element.id) || expressIds.includes(element.id)) ? currentShopper : []}
                  // description={element.description}
                />
              );
            });
            if (!(adding && search.length > 0))
              setProductCards(cards);
          }

        //selected
        let ecommerceSelected = [];

        let ecommerceColumnsSel = [
          { Header: language == 1 ? "Imagen" : "Image", accessor: "image", width: "2%" },
          { Header: "UPC", accessor: "upc", width: "5%" },
          { Header: language == 1 ? "UPC Unidad" : "UPC Unit", accessor: "upc_unit", width: "5%" },
          { Header: language == 1 ? "Oferta" : "Sale", accessor: "sale", width: "3%" },
          { Header: language == 1 ? "Descripción" : "Description", accessor: "name", width: "15%" },
          {
            Header: language == 1 ? "Disponible" : "Available",
            accessor: "availability",
            width: "7%",
          },
          { Header: language == 1 ? "Empaque" : "Package", accessor: "package", width: "6%" },
          { Header: language == 1 ? "Precio" : "Price", accessor: "price", width: "8%" },
          { Header: language == 1 ? "Cantidad" : "Quantity", accessor: "qty", width: "5%" },
          { Header: "Subtotal", accessor: "subtotal", width: "5%" },
          { Header: language == 1 ? "Impuesto Estatal" : "State Tax", accessor: "tax", width: "5%" },
          { Header: language == 1 ? "Impuesto Municipal" : "Municipal Tax", accessor: "municipal", width: "5%" },
          { Header: "Total", accessor: "total", width: "5%" },
          { Header: language == 1 ? "Acción" : "Action", accessor: "action", width: "10%" },
        ];

        // setStoreLabel(response.data.data?.store?.name ?? "");
        // setDeliveryFeeAmount(response.data.data?.store?.location.fee)

        if (response.data.data?.selected?.length == 0) {
          setCartItems(true)
        } else {
          setCartItems(false)
        }

        setStoreLabel(response.data.data?.store?.name)
        setPreviousBalance(response.data.data?.store?.store_balance ?? 0)
        setDeliveryFeeAmount(response.data.data?.store?.location?.fee)

        response.data.data?.selected
        .map((item) => {
          if (saleIds.includes(item.product.id)) {
            currentSale
              .filter((sale) => {
                return sale.product_id == item.product.id;
              })
              .map((sale) => {
                ecommerceSelected.push({
                  image: (
                    <MDAvatar
                      src={imageIP + item.product.image}
                      style={{ cursor: "pointer" }}
                      size="xl"
                      variant="rounded"
                      onClick={() => {setOpen5(true), setCartItemToUpOrDel(item)}}
                    />
                  ),
                  upc: item.product.upc,
                  sale: (
                    <>
                      <MDTypography variant="button" color="info">
                        {language == 1 ? "En Oferta! " : "On Sale! "}
                      </MDTypography>
                      {shopperIds.includes(item.product.id) ? (
                        <MDTypography variant="button" sx={{ color: "red" }}>
                          Shopper{" "}
                        </MDTypography>
                      ) : null}
                      {expressIds.includes(item.product.id) ? (
                      <MDTypography variant="button" sx={{ color: "orange" }}>
                        Express{" "}
                      </MDTypography>
                    ) : null}
                    </>
                  ),
                  upc_unit: item.product.upcUnit,
                  name: item.product.name,
                  package: item.product.packaging,
                  price: sale.specialPrice,
                  subtotal: sale.specialPrice * item.quantity,
                  availability: item.product.available,
                  tax: item.product.tax.percent + '%',
                  municipal: response.data.data?.store?.tax_exemption == 1 ? 0 : '1%',
                  qty: item.quantity,
                  total: parseFloat(
                    sale.specialPrice * item.quantity +
                      sale.specialPrice * item.quantity * (item.product.tax.percent / 100)
                  ).toFixed(2),
                  action: (
                    <Grid>
                      <Tooltip title={language == 1 ? "Editar" : "Edit"}>
                        <MDButton
                          variant="gradient"
                          iconOnly
                          color="info"
                          onClick={() => {
                            setCartItemToUpOrDel(item);
                            setQuantity(item.quantity);
                            setSpecial(true);
                            setDiscount(sale.specialPrice);
                            setOpen4(true);
                          }}
                          autoFocus
                        >
                          <Icon>edit</Icon>
                        </MDButton>
                      </Tooltip>
                      {"   "}
                      <Tooltip title={language == 1 ? "Borrar" : "Delete"}>
                        <MDButton
                          variant="gradient"
                          iconOnly
                          color="error"
                          onClick={() => {
                            setCartItemToUpOrDel(item);
                            setShowDelete(true);
                          }}
                          autoFocus
                        >
                          <Icon>delete</Icon>
                        </MDButton>
                      </Tooltip>
                    </Grid>
                  ),
                });
              });
          } else {
            ecommerceSelected.push({
              image: (
                <MDAvatar
                  src={imageIP + item.product.image}
                  style={{ cursor: "pointer" }}
                  size="xl"
                  variant="rounded"
                  onClick={() => {
                    setCartItemToUpOrDel(item), setOpen5(true);
                  }}
                />
              ),
              sale: (
                <>
                  {shopperIds.includes(item.product.id) ? (
                    <MDTypography variant="button" sx={{ color: "red" }}>
                      Shopper{" "}
                    </MDTypography>
                  ) : null}
                  {expressIds.includes(item.product.id) ? (
                    <MDTypography variant="button" sx={{ color: "orange" }}>
                      Express{" "}
                    </MDTypography>
                  ) : null}
                </>
              ),
              upc: item.product.upc,
              upc_unit: item.product.upcUnit,
              name: item.product.name,
              package: item.product.packaging,
              price: parseFloat(item.product.sales_price).toFixed(2),
              subtotal: parseFloat(item.product.sales_price) * item.quantity,
              availability: item.product.available,
              tax: item.product.tax.percent + '%',
              municipal: response.data.data?.store?.tax_exemption == 1 ? 0 : '1%',
              qty: item.quantity,
              total: parseFloat(item.total).toFixed(2),
              action: (
                <Grid>
                  <Tooltip title={language == 1 ? "Editar" : "Edit"}>
                    <MDButton
                      variant="gradient"
                      iconOnly
                      color="info"
                      onClick={() => {
                        setCartItemToUpOrDel(item);
                        setQuantity(item.quantity);
                        setSpecial(false);
                        setOpen4(true);
                      }}
                      autoFocus
                    >
                      <Icon>edit</Icon>
                    </MDButton>
                  </Tooltip>
                  {"   "}
                  <Tooltip title={language == 1 ? "Borrar" : "Delete"}>
                    <MDButton
                      variant="gradient"
                      iconOnly
                      color="error"
                      onClick={() => {
                        setCartItemToUpOrDel(item);
                        setShowDelete(true);
                      }}
                      autoFocus
                    >
                      <Icon>delete</Icon>
                    </MDButton>
                  </Tooltip>
                </Grid>
              ),
            });
          }
        });

        let subPrices = []

        response.data.data.selected?.map((item) => {
          subPrices.push(
          (saleIds.includes(item.product.id) ? 
            currentSale
              .filter((sale) => {
                return sale.product_id == item.product.id;
              })[0].specialPrice
          : parseFloat(item.price)) * item.quantity)
        });

        let totalSub = subPrices.reduce((prev, current) => prev + current, 0)
        setSubtotal(totalSub)

        let prices = [];

        response.data.data.selected?.map((item) => {
          let sale = currentSale
          .filter((sale) => {
            return sale.product_id == item.product.id;
          })[0]
          prices.push(saleIds.includes(item.product.id) ? parseFloat(
            sale.specialPrice * item.quantity +
              sale.specialPrice * item.quantity * (item.product.tax.percent / 100)
          ) : parseFloat(item.total))
        });

        let totalPrice = prices.reduce((prev, current) => prev + current, 0);
        setTotal(totalPrice + (response.data.data?.store?.tax_exemption == 1 ? 0 : totalSub * 0.01));

        let taxPrices = []

        response.data.data.selected.map(item=>{
          let sale = currentSale
          .filter((sale) => {
            return sale.product_id == item.product.id;
          })[0]
          taxPrices.push(saleIds.includes(item.product.id) 
            ?  sale.specialPrice * item.quantity * (item.product.tax.percent / 100) 
            :  parseFloat(item.tax)
          )
        })
        
        let totalTax = taxPrices.reduce((prev, current) => prev + current, 0.00)

        setTaxTotal(totalTax + (response.data.data?.store?.tax_exemption == 1 ? 0 : totalSub * 0.01));

        let qty = response.data.data.selected
          ?.map((item) => {
            return item.quantity;
          })
          .reduce((prev, current) => prev + current, 0);
        setQtyTotal(qty);

        let tableData2 = { columns: ecommerceColumnsSel, rows: ecommerceSelected };
        // console.log(tableData2)
        setSelectedProds(tableData2);
      })
      .catch((error) => {
        // if (!error.response?.data.authenticated) {
        //   navigate("/signIn");
        // }
        setLoading(false)
        console.log(error);
      });
  }

  async function AddProduct(body) {
    if (!localStorage.getItem("SalesOrderId")) {
      getSalesId(body)
    }
    console.log('adding product')
    console.log(body.product.quantity, body.product.product_id);
    setCartItems(true)
    if (body.product.product_id && body.product.quantity && localStorage.getItem("SalesOrderId")) {
      await axios
        .post(
          IP + "/sales_orders/" + localStorage.getItem("SalesOrderId") + "/so_detail",
          {
            quantity: body.product.quantity,
            product_id: body.product.product_id,
            sales_order_id: localStorage.getItem("SalesOrderId"),
            tax: 0,
            quantity_delivered: 0,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          console.log(response);
          getProductData(true);
          toggleSnackbar();
          setTimeout(() => {
            setShowM(false);
          }, 5000);
        })
        .catch((error) => {
          if (!error.response.data.authenticated) {
            navigate("/signIn");
          }
          console.log(error);
        });
    }
  }

  //Original Function
  // async function AddProduct(qty, prodid) {
  //   console.log(qty, prodid);
  //   await axios
  //     .post(
  //       IP + "/sales_orders/" + localStorage.getItem("SalesOrderId") + "/so_detail",
  //       {
  //         quantity: qty,
  //         product_id: prodid,
  //         sales_order_id: localStorage.getItem("SalesOrderId"),
  //         tax: 0,
  //         quantity_delivered: 0,
  //       },
  //       {
  //         headers: {
  //           Authorization: "Bearer " + localStorage.getItem("token"),
  //           Accept: "application/json",
  //         },
  //       }
  //     )
  //     .then((response) => {
  //       console.log(response);
  //       getProductData();
  //       toggleSnackbar();
  //       setTimeout(() => {
  //         setShowM(false);
  //       }, 5000);
  //     })
  //     .catch((error) => {
  //       if (!error.response.data.authenticated) {
  //         navigate("/signIn");
  //       }
  //       console.log(error);
  //     });
  // }

  function filterLowStock(categoryId, prodData) {
    let cards = [];

    if ((prodData ?? data).filter((item) => item.available <= item.minimum).length > 0) {
      setPageData(data.filter((item) => item.available <= item.minimum && ((categoryId ?? item.category.id) == item.category.id)));
      setPage(0);
    }

    (prodData ?? data)
      .filter((item) => item.available <= item.minimum && ((categoryId ?? item.category.id) == item.category.id))
      .map((filteredItem) => {
          cards.push(
            <ProductCard
              role={role}
              showButton={role == 7}
              open={localStorage.getItem("SalesOrderId") ? AddProduct : getSalesId}
              product={filteredItem}
              name={filteredItem.name}
              subDept={filteredItem.subcategory.name}
              upc={filteredItem.upc}
              code={filteredItem.upcUnit}
              available={filteredItem.available}
              packaging={filteredItem.packaging}
              price={filteredItem.sales_price}
              minimum={filteredItem.minimum}
              image={imageIP + filteredItem.image}
              prodId={filteredItem.id}
              reRun={() => {
                let value = reRun;
                setReRun(!reRun);
                return value;
              }}
              onSale={currentSale}
              shopper={currentShopper}
              viewImage={handleSetCurrentImage}
            />
          );

          cards2.push(filteredItem);
      });

      let columns = [
        { Header: language == 1 ? "Imagen" : "Image", accessor: "image", width: "2%" },
        { Header: "UPC", accessor: "upc", width: "5%" },
        { Header: language == 1 ? "UPC Unidad" : "UPC Unit", accessor: "upc_unit", width: "5%" },
        {
          Header: language == 1 ? "Descripción" : "Description",
          accessor: "name",
          width: "15%",
        },
        {
          Header: language == 1 ? "Disponible" : "Available",
          accessor: "availability",
          width: "7%",
        },
        { Header: language == 1 ? "Empaque" : "Package", accessor: "package", width: "6%" },
        { Header: language == 1 ? "Precio" : "Price", accessor: "price", width: "5%" },
        { Header: language == 1 ? "Oferta" : "Sale", accessor: "sale", width: "3%" },
        { Header: language == 1 ? "Acción" : "Action", accessor: "action", width: "10%" },
      ];
      
      let rows = cards2.map((item) => {
        let saleIds = currentSale.map((item) => {
          return item.product_id;
        });

        let shopperIds = currentShopper.map((item) => {
          return item.product_id;
        });

        return {
          image: (
            <>
              <ProductCell name="" image={imageIP + item.image} />
              <MDButton
                variant="gradient"
                color="info"
                iconOnly
                style={{ height: "1", width: "1" }}
                onClick={() => {
                  setClickedProduct(item);
                  setViewableImage(item.image);
                  setImgsViewer(true);
                }}
              >
                <Icon>visibility</Icon>
              </MDButton>
            </>
          ),
          subdept: item.subcategory.name,
          upc: item.upc,
          sale: (
            <>
              {saleIds.includes(item.id) ? (
                <MDTypography variant="button" color="info">
                  {language == 1 ? "En Oferta! " : "On Sale! "}
                </MDTypography>
              ) : null}
              {shopperIds.includes(item.id) ? (
                <MDTypography variant="button" sx={{ color: "red" }}>
                  Shopper{" "}
                </MDTypography>
              ) : null}
            </>
          ),
          upc_unit: item.upcUnit,
          name: item.name,
          availability: item.available,
          package: item.packaging,
          price: saleIds.includes(item.id) ? (
            <>
              <MDTypography
                sx={{ textDecoration: "line-through" }}
                variant="button"
                color="error"
              >
                {item.sales_price}
              </MDTypography>
              <MDTypography sx={{ ml: 1 }} variant="button" color="success">
                {
                  currentSale.filter((sale) => {
                    return sale.product_id == item.id;
                  })[0].specialPrice
                }
              </MDTypography>
            </>
          ) : (
            item.sales_price
          ),
          action: ( role == 7 &&
            <MDButton
              variant="gradient"
              color="info"
              onClick={() => {
                setCartItemToUpOrDel(item);
                setOpen(true);
              }}
            >
              <Icon>shopping_cart</Icon>
              &nbsp;{language == 1 ? "Añadir al carrito" : "Add to cart"}
            </MDButton>
          ),
        };
      });

      let tableData = { columns: columns, rows: rows };
      console.log(tableData);
      setProducts(tableData);

      setProductCards(cards);
  }

  function searchProducts(value) {
    console.log('searching')
    let cards = [];

    setPageData(
      data.filter((item) => {
        if (item.name.toLowerCase().match(value.toLowerCase())) {
          //console.log(item.name.toLowerCase().match(search))
          //console.log(item)
          return item;
        }
      })
    );
    setPage(0);

    data
      .filter((item) => {
        if (item.name.toLowerCase().match(value.toLowerCase())) {
          //console.log(item.name.toLowerCase().match(search))
          //console.log(item)
          return item;
        }
      })
      .map((searchItem) => {

        let saleIds = currentSale.map((item) => {
          return item.product_id;
        });

        let shopperIds = currentShopper.map((item) => {
          return item.product_id;
        });

        if (show == false) {
          cards.push(
            <ProductCard
              role={role}
              showButton={role == 7}
              open={localStorage.getItem("SalesOrderId") ? AddProduct : getSalesId}
              product={searchItem}
              name={searchItem.name}
              subDept={searchItem.subcategory.name}
              upc={searchItem.upc}
              code={searchItem.upcUnit}
              available={searchItem.available}
              packaging={searchItem.packaging}
              price={searchItem.sales_price}
              minimum={searchItem.minimum}
              image={imageIP + searchItem.image}
              prodId={searchItem.id}
              reRun={() => {
                let value = reRun;
                setReRun(!reRun);
                return value;
              }}
              onSale={currentSale}
              shopper={currentShopper}
              viewImage={handleSetCurrentImage}
            />
          );
          setProductCards(cards);
        } else {
          let columns = [
            { Header: language == 1 ? "Imagen" : "Image", accessor: "image", width: "2%" },
            { Header: "UPC", accessor: "upc", width: "5%" },
            { Header: language == 1 ? "UPC Unidad" : "UPC Unit", accessor: "upc_unit", width: "5%" },
            {
              Header: language == 1 ? "Descripción" : "Description",
              accessor: "name",
              width: "15%",
            },
            {
              Header: language == 1 ? "Disponible" : "Available",
              accessor: "availability",
              width: "7%",
            },
            { Header: language == 1 ? "Empaque" : "Package", accessor: "package", width: "6%" },
            { Header: language == 1 ? "Precio" : "Price", accessor: "price", width: "5%" },
            { Header: language == 1 ? "Oferta" : "Sale", accessor: "sale", width: "3%" },
            { Header: language == 1 ? "Acción" : "Action", accessor: "action", width: "10%" },
          ];

          cards2.push(searchItem);

          let rows = cards2.map((item) => {
            return {
              image: (
                <>
                  <ProductCell name="" image={imageIP + item.image} />
                  <MDButton
                    variant="gradient"
                    color="info"
                    iconOnly
                    style={{ height: "1", width: "1" }}
                    onClick={() => {
                      setClickedProduct(item);
                      setViewableImage(item.image);
                      setImgsViewer(true);
                    }}
                  >
                    <Icon>visibility</Icon>
                  </MDButton>
                </>
              ),
              subdept: item.subcategory.name,
              upc: item.upc,
              sale: (
                <>
                  {saleIds.includes(item.id) ? (
                    <MDTypography variant="button" color="info">
                      {language == 1 ? "En Oferta! " : "On Sale! "}
                    </MDTypography>
                  ) : null}
                  {shopperIds.includes(item.id) ? (
                    <MDTypography variant="button" sx={{ color: "red" }}>
                      Shopper{" "}
                    </MDTypography>
                  ) : null}
                </>
              ),
              upc_unit: item.upcUnit,
              name: item.name,
              availability: item.available,
              package: item.packaging,
              price: saleIds.includes(item.id) ? (
                <>
                  <MDTypography
                    sx={{ textDecoration: "line-through" }}
                    variant="button"
                    color="error"
                  >
                    {item.sales_price}
                  </MDTypography>
                  <MDTypography sx={{ ml: 1 }} variant="button" color="success">
                    {
                      currentSale.filter((sale) => {
                        return sale.product_id == item.id;
                      })[0].specialPrice
                    }
                  </MDTypography>
                </>
              ) : (
                item.sales_price
              ),
              action: ( role == 7 &&
                <MDButton
                  variant="gradient"
                  color="info"
                  onClick={() => {
                    setCartItemToUpOrDel(item);
                    setOpen(true);
                  }}
                >
                  <Icon>shopping_cart</Icon>
                  &nbsp;{language == 1 ? "Añadir al carrito" : "Add to cart"}
                </MDButton>
              ),
            };
          });

          let tableData = { columns: columns, rows: rows };
          setProducts(tableData);
        }
      });
  }

  function filterOnSale(categoryId, prodData) {
    setSuccess(true);

    let cards = [];

    if (
      (prodData ?? data).filter((item) => {
        var list = currentSale.filter((product) => product.product_id == item.id);
        return list.length > 0 && ((categoryId ?? item.category.id) == item.category.id);
      }).length > 0
    ) {
      setPageData(
        (prodData ?? data).filter((item) => {
          var list = currentSale.filter((product) => product.product_id == item.id);
          return list.length > 0 && ((categoryId ?? item.category.id) == item.category.id);
        })
      );
      setPage(0);
    }

    (prodData ?? data)
      .filter((item) => {
        var list = currentSale.filter((product) => product.product_id == item.id);
        return list.length > 0 && ((categoryId ?? item.category.id) == item.category.id);
      })
      .map((filteredItem) => {
        //console.log(filteredItem)


          cards.push(
            <ProductCard
              role={role}
              showButton={role == 7}
              open={localStorage.getItem("SalesOrderId") ? AddProduct : getSalesId}
              product={filteredItem}
              name={filteredItem.name}
              subDept={filteredItem.subcategory.name}
              upc={filteredItem.upc}
              code={filteredItem.upcUnit}
              available={filteredItem.available}
              packaging={filteredItem.packaging}
              price={filteredItem.sales_price}
              minimum={filteredItem.minimum}
              image={imageIP + filteredItem.image}
              prodId={filteredItem.id}
              reRun={() => {
                let value = reRun;
                setReRun(!reRun);
                return value;
              }}
              onSale={currentSale}
              shopper={currentShopper}
              viewImage={handleSetCurrentImage}
            />
          );
          

          cards2.push(filteredItem);
      
      });

      setProductCards(cards);
      let columns = [
        { Header: language == 1 ? "Imagen" : "Image", accessor: "image", width: "2%" },
        { Header: "UPC", accessor: "upc", width: "5%" },
        { Header: language == 1 ? "UPC Unidad" : "UPC Unit", accessor: "upc_unit", width: "5%" },
        {
          Header: language == 1 ? "Descripción" : "Description",
          accessor: "name",
          width: "15%",
        },
        {
          Header: language == 1 ? "Disponible" : "Available",
          accessor: "availability",
          width: "7%",
        },
        { Header: language == 1 ? "Empaque" : "Package", accessor: "package", width: "6%" },
        { Header: language == 1 ? "Precio" : "Price", accessor: "price", width: "5%" },
        { Header: language == 1 ? "Oferta" : "Sale", accessor: "sale", width: "3%" },
        { Header: language == 1 ? "Acción" : "Action", accessor: "action", width: "10%" },
      ];

      let rows = cards2.map((item) => {
        let saleIds = currentSale.map((item) => {
          return item.product_id;
        });

        let shopperIds = currentShopper.map((item) => {
          return item.product_id;
        });
        return {
          image: (
            <>
              <ProductCell name="" image={imageIP + item.image} />
              <MDButton
                variant="gradient"
                color="info"
                iconOnly
                style={{ height: "1", width: "1" }}
                onClick={() => {
                  setClickedProduct(item);
                  setViewableImage(item.image);
                  setImgsViewer(true);
                }}
              >
                <Icon>visibility</Icon>
              </MDButton>
            </>
          ),
          subdept: item.subcategory.name,
          upc: item.upc,
          sale: (
            <>
              {saleIds.includes(item.id) ? (
                <MDTypography variant="button" color="info">
                  {language == 1 ? "En Oferta! " : "On Sale! "}
                </MDTypography>
              ) : null}
              {shopperIds.includes(item.id) ? (
                <MDTypography variant="button" sx={{ color: "red" }}>
                  Shopper{" "}
                </MDTypography>
              ) : null}
            </>
          ),
          upc_unit: item.upcUnit,
          name: item.name,
          availability: item.available,
          package: item.packaging,
          price: saleIds.includes(item.id) ? (
            <>
              <MDTypography
                sx={{ textDecoration: "line-through" }}
                variant="button"
                color="error"
              >
                {item.sales_price}
              </MDTypography>
              <MDTypography sx={{ ml: 1 }} variant="button" color="success">
                {
                  currentSale.filter((sale) => {
                    return sale.product_id == item.id;
                  })[0].specialPrice
                }
              </MDTypography>
            </>
          ) : (
            item.sales_price
          ),
          action: ( role == 7 &&
            <MDButton
              variant="gradient"
              color="info"
              onClick={() => {
                setCartItemToUpOrDel(item);
                setOpen(true);
              }}
            >
              <Icon>shopping_cart</Icon>
              &nbsp;{language == 1 ? "Añadir al carrito" : "Add to cart"}
            </MDButton>
          ),
        };
      });

      let tableData = { columns: columns, rows: rows };
      console.log(tableData);
      setProducts(tableData);
  }

  function filterShopper(categoryId, prodData) {
    setSuccess(true);
    let cards = [];
    console.log((prodData ?? data).filter((item) => {
      var list = currentShopper.filter((product) => product.product_id == item.id);
      return list.length > 0 && ((categoryId ?? item.category.id) == item.category.id);
    }))

    if (
      (prodData ?? data).filter((item) => {
        var list = currentShopper.filter((product) => product.product_id == item.id);
        return list.length > 0 && ((categoryId ?? item.category.id) == item.category.id);
      }).length > 0
    ) {
      setPageData(
        (prodData ?? data).filter((item) => {
          var list = currentShopper.filter((product) => product.product_id == item.id);
          return list.length > 0 && ((categoryId ?? item.category.id) == item.category.id);
        })
      );
      setPage(0);
    }

    (prodData ?? data)
      .filter((item) => {
        var list = currentShopper.filter((product) => product.product_id == item.id);
        return list.length > 0 && ((categoryId ?? item.category.id) == item.category.id);
      })
      .map((filteredItem) => {
        //console.log(filteredItem
          cards.push(
            <ProductCard
              role={role}
              showButton={role == 7}
              open={localStorage.getItem("SalesOrderId") ? AddProduct : getSalesId}
              product={filteredItem}
              name={filteredItem.name}
              subDept={filteredItem.subcategory.name}
              upc={filteredItem.upc}
              code={filteredItem.upcUnit}
              available={filteredItem.available}
              packaging={filteredItem.packaging}
              price={filteredItem.sales_price}
              minimum={filteredItem.minimum}
              image={imageIP + filteredItem.image}
              prodId={filteredItem.id}
              reRun={() => {
                let value = reRun;
                setReRun(!reRun);
                return value;
              }}
              onSale={currentSale}
              shopper={currentShopper}
              viewImage={handleSetCurrentImage}
            />
          );

          cards2.push(filteredItem);
      });

      let columns = [
        { Header: language == 1 ? "Imagen" : "Image", accessor: "image", width: "2%" },
        { Header: "UPC", accessor: "upc", width: "5%" },
        { Header: language == 1 ? "UPC Unidad" : "UPC Unit", accessor: "upc_unit", width: "5%" },
        {
          Header: language == 1 ? "Descripción" : "Description",
          accessor: "name",
          width: "15%",
        },
        {
          Header: language == 1 ? "Disponible" : "Available",
          accessor: "availability",
          width: "7%",
        },
        { Header: language == 1 ? "Empaque" : "Package", accessor: "package", width: "6%" },
        { Header: language == 1 ? "Precio" : "Price", accessor: "price", width: "5%" },
        { Header: language == 1 ? "Oferta" : "Sale", accessor: "sale", width: "3%" },
        { Header: language == 1 ? "Acción" : "Action", accessor: "action", width: "10%" },
      ];

      let rows = cards2.map((item) => {
        let saleIds = currentSale.map((item) => {
          return item.product_id;
        });

        let shopperIds = currentShopper.map((item) => {
          return item.product_id;
        });
        return {
          image: (
            <>
              <ProductCell name="" image={imageIP + item.image} />
              <MDButton
                variant="gradient"
                color="info"
                iconOnly
                style={{ height: "1", width: "1" }}
                onClick={() => {
                  setClickedProduct(item);
                  setViewableImage(item.image);
                  setImgsViewer(true);
                }}
              >
                <Icon>visibility</Icon>
              </MDButton>
            </>
          ),
          subdept: item.subcategory.name,
          upc: item.upc,
          sale: (
            <>
              {saleIds.includes(item.id) ? (
                <MDTypography variant="button" color="info">
                  {language == 1 ? "En Oferta! " : "On Sale! "}
                </MDTypography>
              ) : null}
              {shopperIds.includes(item.id) ? (
                <MDTypography variant="button" sx={{ color: "red" }}>
                  Shopper{" "}
                </MDTypography>
              ) : null}
            </>
          ),
          upc_unit: item.upcUnit,
          name: item.name,
          availability: item.available,
          package: item.packaging,
          price: saleIds.includes(item.id) ? (
            <>
              <MDTypography
                sx={{ textDecoration: "line-through" }}
                variant="button"
                color="error"
              >
                {item.sales_price}
              </MDTypography>
              <MDTypography sx={{ ml: 1 }} variant="button" color="success">
                {
                  currentSale.filter((sale) => {
                    return sale.product_id == item.id;
                  })[0].specialPrice
                }
              </MDTypography>
            </>
          ) : (
            item.sales_price
          ),
          action: ( role == 7 &&
            <MDButton
              variant="gradient"
              color="info"
              onClick={() => {
                setCartItemToUpOrDel(item);
                setOpen(true);
              }}
            >
              <Icon>shopping_cart</Icon>
              &nbsp;{language == 1 ? "Añadir al carrito" : "Add to cart"}
            </MDButton>
          ),
        };
      });

      let tableData = { columns: columns, rows: rows };
      console.log(tableData);
      setProducts(tableData);
      setProductCards(cards);
  }

  function filterSubDept() {
    let cards = [];

    if (selectedSubcategory) {
      setSuccess(true);
      setPageData(data.filter((item) => item.subcategory.name == selectedSubcategory.label && ((selectedCategory?.id ?? item.category.id) == item.category.id)));
      setPage(0);
      data
        .filter((item) => item.subcategory.name == selectedSubcategory.label && ((selectedCategory?.id ?? item.category.id) == item.category.id))
        .map((filteredItem) => {
          //console.log(filteredItem)
          let saleIds = currentSale.map((item) => {
            return item.product_id;
          });

          let shopperIds = currentShopper.map((item) => {
            return item.product_id;
          });

          if (!show) {
            cards.push(
              <ProductCard
                role={role}
                showButton={role == 7}
                open={localStorage.getItem("SalesOrderId") ? AddProduct : getSalesId}
                product={filteredItem}
                name={filteredItem.name}
                subDept={filteredItem.subcategory.name}
                upc={filteredItem.upc}
                code={filteredItem.upcUnit}
                available={filteredItem.available}
                packaging={filteredItem.packaging}
                price={filteredItem.sales_price}
                minimum={filteredItem.minimum}
                image={imageIP + filteredItem.image}
                prodId={filteredItem.id}
                reRun={() => {
                  let value = reRun;
                  setReRun(!reRun);
                  return value;
                }}
                onSale={currentSale}
                shopper={currentShopper}
                viewImage={handleSetCurrentImage}
              />
            );
            setProductCards(cards);
          } else if (show) {
            let columns = [
              { Header: language == 1 ? "Imagen" : "Image", accessor: "image", width: "2%" },
              { Header: "UPC", accessor: "upc", width: "5%" },
              { Header: language == 1 ? "UPC Unidad" : "UPC Unit", accessor: "upc_unit", width: "5%" },
              {
                Header: language == 1 ? "Descripción" : "Description",
                accessor: "name",
                width: "15%",
              },
              {
                Header: language == 1 ? "Disponible" : "Available",
                accessor: "availability",
                width: "7%",
              },
              { Header: language == 1 ? "Empaque" : "Package", accessor: "package", width: "6%" },
              { Header: language == 1 ? "Precio" : "Price", accessor: "price", width: "5%" },
              { Header: language == 1 ? "Oferta" : "Sale", accessor: "sale", width: "3%" },
              { Header: language == 1 ? "Acción" : "Action", accessor: "action", width: "10%" },
            ];

            cards2.push(filteredItem);

            let rows = cards2.map((item) => {
              return {
                image: (
                  <>
                    <ProductCell name="" image={imageIP + item.image} />
                    <MDButton
                      variant="gradient"
                      color="info"
                      iconOnly
                      style={{ height: "1", width: "1" }}
                      onClick={() => {
                        setClickedProduct(item);
                        setViewableImage(item.image);
                        setImgsViewer(true);
                      }}
                    >
                      <Icon>visibility</Icon>
                    </MDButton>
                  </>
                ),
                subdept: item.subcategory.name,
                sale: (
                  <>
                    {saleIds.includes(item.id) ? (
                      <MDTypography variant="button" color="info">
                        {language == 1 ? "En Oferta! " : "On Sale! "}
                      </MDTypography>
                    ) : null}
                    {shopperIds.includes(item.id) ? (
                      <MDTypography variant="button" sx={{ color: "red" }}>
                        Shopper{" "}
                      </MDTypography>
                    ) : null}
                  </>
                ),
                upc: item.upc,
                upc_unit: item.upcUnit,
                name: item.name,
                availability: item.available,
                package: item.packaging,
                price: saleIds.includes(item.id) ? (
                  <>
                    <MDTypography
                      sx={{ textDecoration: "line-through" }}
                      variant="button"
                      color="error"
                    >
                      {item.sales_price}
                    </MDTypography>
                    <MDTypography sx={{ ml: 1 }} variant="button" color="success">
                      {
                        currentSale.filter((sale) => {
                          return sale.product_id == item.id;
                        })[0].specialPrice
                      }
                    </MDTypography>
                  </>
                ) : (
                  item.sales_price
                ),
                action: ( role == 7 &&
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={() => {
                      setCartItemToUpOrDel(item);
                      setOpen(true);
                    }}
                  >
                    <Icon>shopping_cart</Icon>
                    &nbsp;{language == 1 ? "Añadir al carrito" : "Add to cart"}
                  </MDButton>
                ),
              };
            });

            let tableData = { columns: columns, rows: rows };
            setProducts(tableData);
          }
        });
    } else {
      setSuccess(false);
    }
  }

  function sortPriceAscend() {
    let cards = [];

    let saleIds = currentSale.map((item) => {
      return item.product_id;
    });

    let prices = [];

    data.map((item) => {
      if (saleIds.includes(item.id)) {
        prices.push({
          price: currentSale.filter((sale) => {
            return sale.product_id == item.id;
          })[0].specialPrice,
          id: item.id,
        });
      } else {
        prices.push({ price: item.sales_price, id: item.id });
      }
    });

    prices
      .sort((a, b) => (parseFloat(a.price) > parseFloat(b.price) ? 1 : -1))
      .map((item) => {
        let prod = data.filter((item2) => {
          return item.id == item2.id;
        });
        cards.push(
          <ProductCard
            role={role}
            showButton={role == 7}
            open={localStorage.getItem("SalesOrderId") ? AddProduct : getSalesId}
            product={prod[0]}
            name={prod[0].name}
            subDept={prod[0].subcategory.name}
            upc={prod[0].upc}
            code={prod[0].upcUnit}
            available={prod[0].available}
            packaging={prod[0].packaging}
            price={prod[0].sales_price}
            minimum={prod[0].minimum}
            image={imageIP + prod[0].image}
            prodId={prod[0].id}
            reRun={() => {
              let value = reRun;
              setReRun(!reRun);
              return value;
            }}
            onSale={currentSale}
            shopper={currentShopper}
            viewImage={handleSetCurrentImage}
          />
        );
        setProductCards(cards);
      });
  }

  function sortPriceDescend() {
    let cards = [];

    let saleIds = currentSale.map((item) => {
      return item.product_id;
    });

    let prices = [];

    data.map((item) => {
      if (saleIds.includes(item.id)) {
        prices.push({
          price: currentSale.filter((sale) => {
            return sale.product_id == item.id;
          })[0].specialPrice,
          id: item.id,
        });
      } else {
        prices.push({ price: item.sales_price, id: item.id });
      }
    });

    prices
      .sort((a, b) => (parseFloat(a.price) < parseFloat(b.price) ? 1 : -1))
      .map((item) => {
        let prod = data.filter((item2) => {
          return item.id == item2.id;
        });
        cards.push(
          <ProductCard
            role={role}
            showButton={role == 7}
            open={localStorage.getItem("SalesOrderId") ? AddProduct : getSalesId}
            product={prod[0]}
            name={prod[0].name}
            subDept={prod[0].subcategory.name}
            upc={prod[0].upc}
            code={prod[0].upcUnit}
            available={prod[0].available}
            packaging={prod[0].packaging}
            price={prod[0].sales_price}
            minimum={prod[0].minimum}
            image={imageIP + prod[0]?.image}
            prodId={prod[0].id}
            reRun={() => {
              let value = reRun;
              setReRun(!reRun);
              return value;
            }}
            onSale={currentSale}
            shopper={currentShopper}
            viewImage={handleSetCurrentImage}
          />
        );
        setProductCards(cards);
      });
  }

  function sortStockAscend() {
    let cards = [];

    data
      .sort((a, b) => (a.available > b.available ? 1 : -1))
      .map((item) => {
        cards.push(
          <ProductCard
            role={role}
            showButton={role == 7}
            open={localStorage.getItem("SalesOrderId") ? AddProduct : getSalesId}
            product={item}
            name={item.name}
            subDept={item.subcategory.name}
            upc={item.upc}
            code={item.upcUnit}
            available={item.available}
            packaging={item.packaging}
            price={item.sales_price}
            minimum={item.minimum}
            image={imageIP + item.image}
            prodId={item.id}
            reRun={() => {
              let value = reRun;
              setReRun(!reRun);
              return value;
            }}
            onSale={currentSale}
            shopper={currentShopper}
            viewImage={handleSetCurrentImage}
          />
        );
        setProductCards(cards);
      });
  }

  function sortStockDescend() {
    let cards = [];

    data
      .sort((a, b) => (a.available < b.available ? 1 : -1))
      .map((item) => {
        cards.push(
          <ProductCard
            role={role}
            showButton={role == 7}
            open={localStorage.getItem("SalesOrderId") ? AddProduct : getSalesId}
            product={item}
            name={item.name}
            subDept={item.subcategory.name}
            upc={item.upc}
            code={item.upcUnit}
            available={item.available}
            packaging={item.packaging}
            price={item.sales_price}
            minimum={item.minimum}
            image={imageIP + item.image}
            prodId={item.id}
            reRun={() => {
              let value = reRun;
              setReRun(!reRun);
              return value;
            }}
            onSale={currentSale}
            shopper={currentShopper}
            viewImage={handleSetCurrentImage}
          />
        );
        setProductCards(cards);
      });
  }

  async function CambiarEstatus() {
    await axios
      .post(
        IP + "/confirmed/sales",
        {
          sales_order_id: localStorage.getItem("SalesOrderId"),
          transport: transport,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        toggleSnackbar2();
        setTimeout(() => {
          // window.location.reload(false);
          localStorage.setItem("viewOrderId", localStorage.getItem("SalesOrderId"));
          localStorage.removeItem("SalesOrderId")
          navigate("/view-order");
        }, 3000);
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        if (error.response.data.message == 300) {
          setShowError(true)
          setErrorProd(error.response.data.data)
        }
        console.log(error);
      });
  }

  async function CheckPendingSale() {
    await axios
      .get(IP + "/checkCurrentSaleOrder", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        if (response.data.data.sales_order_id) {
          localStorage.setItem("SalesOrderId", response.data.data.sales_order_id);
          getProductData();
        } else {
          localStorage.removeItem("SalesOrderId");
          setCartItems(true)
          getProductData();
        }
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function checkStoreZone() {
    await axios
    .get(IP + "/checkCurrentZone", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        Accept: "application/json",
      },
    })
    .then((response) => {
      let zone = response.data.data.zone
      let day = response.data.data?.delivery_day
      let days = ""
      if (day == "Monday")
        setZoneDeliveryDay(language == 1 ? "Lunes" : "Monday")
      if (day == "Tuesday")
        setZoneDeliveryDay(language == 1 ? "Martes" : "Tuesday")
      if (day == "Wednesday")
        setZoneDeliveryDay(language == 1 ? "Miércoles" : "Wednesday")
      if (day == "Thursday")
        setZoneDeliveryDay(language == 1 ? "Jueves" : "Thursday")
      if (day == "Friday")
        setZoneDeliveryDay(language == 1 ? "Viernes" : "Friday")
      if (day == "Saturday")
        setZoneDeliveryDay(language == 1 ? "Sábado" : "Saturday")
      if (day == "Sunday")
        setZoneDeliveryDay(language == 1 ? "Domingo" : "Sunday")

      if (zone.monday == 1)
        days += (language == 1 ? "LUN" : "MON")
      if (zone.tuesday == 1)
        days += (days.length > 0 ? ", " : "") + (language == 1 ? "MAR" : "TUE")
      if (zone.wednesday == 1)
        days += (days.length > 0 ? ", " : "") + (language == 1 ? "MIER" : "WED")
      if (zone.thursday == 1)
        days += (days.length > 0 ? ", " : "") + (language == 1 ? "JUEV" : "THUR")
      if (zone.friday == 1)
        days += (days.length > 0 ? ", " : "") + (language == 1 ? "VIER" : "FRI")
      if (zone.saturday == 1)
        days += (days.length > 0 ? ", " : "") + (language == 1 ? "SAB" : "SAT")
      if (zone.sunday == 1)
        days += (days.length > 0 ? ", " : "") + (language == 1 ? "DOM" : "SUN")
      setZoneOrderDays(days)

      if (!response.data.data?.order_day) {
        setShowZoneError(true)
        setTimeout(()=> {
          setShowZoneError(false)
        }, 5000)
      }
    })
    .catch((error) => {
      if (!error.response.data.authenticated) {
        navigate("/signIn");
      }
      console.log(error);
    });
  }

  async function getCarouselData() {
    await axios
      .get(IP + "/carousel/ecommerce", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(async (response) => {
        await axios
        .get(IP + "/carousels/products", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        })
        .then(function (response2) {
          let prods = response2.data.data
          generateCarousel(
            response.data.data, 
            prods
          )
        })
        .catch(function (error) {
          console.log(error)
        })
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  function generateCarousel(carousels, products) {
    let slides = []
    for (let i = 0; i < carousels.length; i++) {
      let carousel = carousels[i]
      let prods = products.filter(item=>{
        return item.carousel_id == carousel.carousel_id
      })[0]
      console.log(prods.products)

      slides.push(
        <Carousel.Item interval={carousel.interval}>
          <img src={imageIP + carousel.image} width="100%" height="100%" />
          {prods.products.length > 0 ? <Grid container>
            <Grid item lg={2.4 * ((5 - prods.products.length)/2)}></Grid>
            {prods.products.filter(prod=>{
              return prod.active == "Active"
            }).map(prod=>{
              return (
                <CarouselCard
                  role={role}
                  showButton={role == 7}
                  open={AddProduct}
                  name={prod.name}
                  subDept={prod.subcategory.name}
                  upc={prod.upc}
                  code={prod.upcUnit}
                  available={prod.available}
                  packaging={prod.packaging}
                  price={prod.sales_price}
                  minimum={prod.minimum}
                  image={imageIP + prod.image}
                  prodId={prod.id}
                  reRun={() => {
                    let value = reRun;
                    setReRun(!reRun);
                    return value;
                  }}
                  onSale={currentSale}
                  shopper={currentShopper}
                  viewImage={handleSetCurrentImage}
                />
              )
            })}
            <Grid item lg={2.4 * ((5 - prods.products.length)/2)}></Grid> 
        </Grid> : null}
        </Carousel.Item>
      ) 
      controlSlides.push(            
        <Carousel.Item interval={carousel.interval}>
          <img src=""/>
        </Carousel.Item>
      )
    }
    setCarouselSlides(slides)
  }

  const generatePagination = () => {
    console.log("running");

    let pages = [];

    let final;

    for (let id = 0; id < pageData?.length; id++) {
      if ((id + 1) % 100 == 0) {
        console.log(id);
        console.log((id + 1) / 100);
        pages.push(
          <MDPagination
            onClick={() => {
              setPage((id + 1) / 100 - 1), generatePagination2((id + 1) / 100 - 1);
            }}
            item
            active={page == (id + 1) / 100 - 1}
          >
            {(id + 1) / 100}
          </MDPagination>
        );

        final = (id + 1) / 100;
        setFinalPage((id + 1) / 100);
      }
    }
    console.log("final: " + final);

    if (pageData?.length > final * 100) {
      pages.push(
        <MDPagination
          onClick={() => {
            setPage(final), generatePagination2(final);
          }}
          item
          active={page == final}
        >
          {final + 1}
        </MDPagination>
      );
    }
    setPageOptions(pages);
  };

  const generatePagination2 = (currentPage) => {
    console.log("running");

    let pages = [];

    let final;

    for (let id = 0; id < pageData?.length; id++) {
      if ((id + 1) % 100 == 0) {
        console.log(id);
        console.log((id + 1) / 100);
        pages.push(
          <MDPagination
            onClick={() => {
              console.log("HEERE")
              setPage((id + 1) / 100 - 1), generatePagination2((id + 1) / 100 - 1);
            }}
            item
            active={currentPage == (id + 1) / 100 - 1}
          >
            {(id + 1) / 100}
          </MDPagination>
        );

        final = (id + 1) / 100;
        setFinalPage((id + 1) / 100);
      }
    }
    console.log("final: " + final);

    if (pageData.length > final * 100) {
      pages.push(
        <MDPagination
          onClick={() => {
            setPage(final), generatePagination2(final);
          }}
          item
          active={currentPage == final}
        >
          {final + 1}
        </MDPagination>
      );
    }
    setPageOptions(pages);
  };

  useEffect(() => {
    getUserDetails()
  }, []);

  useEffect(() => {
    if (role != 0) { 
      getCarouselData()
      CheckPendingSale();
      GetSubcategories();
      sortOptions();
      checkStoreZone()
      getOnSale();
      getShopper();
      GetCategories();
    }
  }, [role]);

  useEffect(() => {
    generatePagination();
  }, [pageData]);

  useEffect(() => {
    if (added) {
      if (search.length > 0)
        searchProducts(search)
      setAdded(false)
    }
  }, [added])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [page])

  useEffect(()=>{
    handleResize()
  })

  // Hook
  function usePrevious(value) {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref = useRef();
    // Store current value in ref
    useEffect(() => {
      ref.current = value;
    }, [value]); // Only re-run if value changes
    // Return previous value (happens before update in useEffect above)
    return ref.current;
  }

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  return (
    <DashboardLayout>
      {/* <ImgsViewer
        imgs={[{ src: viewableImage }]}
        isOpen={imgsViewer}
        onClose={closeImgsViewer}
        backdropCloseable
      /> */}

      <ThemeProvider theme={darkTheme}>
        <Dialog open={imgsViewer}>
          <DialogContent>
            <MDBox component="img" height="50%" width="50%" src={imageIP + viewableImage} alt="" ml={17} />
            <TextField
              margin="dense"
              label="Sub. Dept."
              type="text"
              fullWidth
              value={clickedProduct?.subcategory?.name}
              variant="standard"
            />
            <TextField
              margin="dense"
              label="UPC"
              type="text"
              fullWidth
              value={clickedProduct?.upc}
              variant="standard"
            />
            <TextField
              margin="dense"
              label={language == 1 ? "UPC Unidad" : "UPC Unit"}
              type="text"
              fullWidth
              value={clickedProduct?.upcUnit}
              variant="standard"
            />
            <TextField
              margin="dense"
              label={language == 1 ? "Disponible" : "Available"}
              type="text"
              fullWidth
              value={clickedProduct?.available}
              variant="standard"
            />
            <TextField
              margin="dense"
              label={language == 1 ? "Empaque" : "Package"}
              type="text"
              fullWidth
              value={clickedProduct?.packaging}
              variant="standard"
            />
            <TextField
              margin="dense"
              label={language == 1 ? "Precio" : "Price"}
              type="text"
              fullWidth
              value={"$" + clickedProduct?.sales_price}
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setImgsViewer(false);
                setClickedProduct({});
              }}
            >
              {language == 1 ? "Esconder" : "Hide"}
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
      <MDSnackbar
        color="info"
        icon="notifications"
        title={language == 1 ? "EMOS Notificación" : "EMOS Notification"}
        content={language == 1 ? "Producto añadido al carrito!" : "Product added to cart!"}
        dateTime={language==1 ? "Ahora" : "Now"}
        open={showM}
        close={toggleSnackbar}
      />
      <MDSnackbar
        color="error"
        icon="notifications"
        title={language == 1 ? "EMOS Notificación" : "EMOS Notification"}
        content={error}
        dateTime={language==1 ? "Ahora" : "Now"}
        open={showM4}
        close={() => {
          setShowM4(!showM4);
        }}
      />
      <MDSnackbar
        color="error"
        icon="notifications"
        title={language == 1 ? "EMOS Notificación" : "EMOS Notification"}
        content={language==1 
          ? "Estas fuera de los días de entrega de tu zona; la orden se entregará el próximo " + (zoneDeliveryDay).toLowerCase() 
          : "You are outside your zone's shipping days; the order will be delivered next " + (zoneDeliveryDay).toLowerCase()
        }
        dateTime={language==1 ? "Ahora" : "Now"}
        open={showZoneError}
        close={() => {
          setShowZoneError(false);
        }}
      />
      <MDSnackbar
        color="info"
        icon="notifications"
        title={language == 1 ? "EMOS Notificación" : "EMOS Notification"}
        content={
          language == 1
            ? "La orden fue confirmada exitosamente!"
            : "The order was confirmed successfully!"
        }
        dateTime={language==1 ? "Ahora" : "Now"}
        open={showM2}
        close={toggleSnackbar2}
      />
      <MDSnackbar
        color="info"
        icon="notifications"
        title={language == 1 ? "EMOS Notificación" : "EMOS Notification"}
        content={language == 1 ? "El Carrito fue actualizado." : "The Cart has been updated."}
        dateTime={language==1 ? "Ahora" : "Now"}
        open={showM3}
        close={toggleSnackbar3}
      />
      <MDSnackbar
        color="error"
        icon="notifications"
        title={language == 1 ? "EMOS Notificación" : "EMOS Notification"}
        content={language == 1 ?  errorProd + " ya no esta disponible" : errorProd + " is no longer available"}
        dateTime={language==1 ? "Ahora" : "Now"}
        open={showError}
        close={()=>setShowError(!showError)}
      />
      <AlertDialog2
        Show={changeStatus}
        setShowConfirm={setChangeStatus}
        Message={
          language == 1
            ? "¿Estás seguro que quieres que se confirme esta orden?"
            : "Are you sure you want to confirm this order?"
        }
        selected={transport}
        setSelected={setTransport}
        OkayFunction={CambiarEstatus}
        fee={deliveryFeeAmount}
        total={total}
      />
      <AlertDialog
        Show={showDelete}
        setShowConfirm={setShowDelete}
        Message={
          language == 1
            ? "¿Estás seguro que quieres que se borre este producto de la orden?"
            : "Are you sure you want remove this product from the order?"
        }
        OkayFunction={DeleteOrderDetailProduct}
      />
      <Dialog open={viewTotals} onBackdropClick={()=>setViewTotals(false)}>
        <DialogContent>
          <MDTypography variant="h6" style={{ marginBottom: "1%" }}>
            <strong>
              {" "}
              {language == 1 ? "Tienda Seleccionada:" : "Selected Store:"} {storeLabel}{" "}
            </strong>
          </MDTypography>
          <MDTypography variant="h6">
            {language == 1 ? "Total de Bultos:" : "Qty Total:"} {qtyTotal}
          </MDTypography>
          <MDTypography variant="h6">Sub-total: ${parseFloat(subtotal).toFixed(2)}</MDTypography>
          <MDTypography variant="h6">
            {language == 1 ? "Total de Impuestos" : "Tax Total"}: $
            {parseFloat(taxTotal).toFixed(2)}
          </MDTypography>
          <MDTypography variant="h6">
            Total: ${parseFloat(total).toFixed(2)}
          </MDTypography>
          <MDTypography variant="h6">
            {language==1 ? "Balance Previo" : "Previous Balance"}: ${parseFloat(previousBalance).toFixed(2)}
          </MDTypography>
          <MDTypography variant="h6">
            {language==1 ? "Balance Nuevo" : "New Balance"}: ${parseFloat(total + previousBalance).toFixed(2)}
          </MDTypography>
        </DialogContent>
      </Dialog>
      <ThemeProvider theme={darkTheme}>
        <Dialog open={open}>
          <DialogTitle>{language == 1 ? "Producto" : "Product"}:</DialogTitle>
          <DialogTitle>{cartItemToUpOrDel?.name}</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              value={quantity}
              label={language == 1 ? "Cantidad" : "Order Qty"}
              type="number"
              onChange={(e) => {
                if ((e.target.value >= 0 || e.target.value == "") && e.target.value <= cartItemToUpOrDel.available) {
                  setQuantity(e.target.value);
                }
              }}
              fullWidth
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{language == 1 ? "Cancelar" : "Cancel"}</Button>
            <Button
              onClick={() => {
                localStorage.getItem("SalesOrderId")
                  ? AddProduct({
                      product: {
                        product_id: cartItemToUpOrDel?.id,
                        quantity: quantity,
                      },
                    })
                  : getSalesId({
                      date: new Date().toISOString(),
                      subtotal: 0.0,
                      tax: 0.0,
                      store_id: 1,
                      status_id: 1,
                      active: 1,
                      product: {
                        product_id: cartItemToUpOrDel?.id,
                        quantity: quantity,
                      },
                    });
                setOpen(false);
                setQuantity();
              }}
            >
              {language == 1 ? "Añadir" : "Add"}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={open4}>
          <DialogTitle>{language == 1 ? "Producto" : "Product"}:</DialogTitle>
          <DialogTitle>{cartItemToUpOrDel?.product?.name}</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              value={quantity}
              label={language == 1 ? "Cantidad" : "Product Qty"}
              type="number"
              onChange={(e) => {
                if (
                  e.target.value >= 0 &&
                  e.target.value <= cartItemToUpOrDel?.product?.available
                ) {
                  setQuantity(e.target.value);
                }
              }}
              fullWidth
              variant="standard"
            />
            <TextField
              autoFocus
              margin="dense"
              id="name"
              value={special ? discount : cartItemToUpOrDel?.product?.sales_price}
              label={language == 1 ? "Precio" : "Price"}
              type="text"
              fullWidth
              variant="standard"
            />
            <MDTypography variant="h6">
              Total: $
              {special
                ? parseFloat(quantity * discount).toFixed(2)
                : parseFloat(quantity * cartItemToUpOrDel?.product?.sales_price).toFixed(2)}
            </MDTypography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpen4(false);
              }}
            >
              {language == 1 ? "Cancelar" : "Cancel"}
            </Button>
            <Button
              onClick={() => {
                updateCart();
                setOpen4(false);
              }}
            >
              {language == 1 ? "Actualizar" : "Update"}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={open2}>
          <DialogTitle>{language == 1 ? "Organizar por:" : "Sort by:"}</DialogTitle>
          <DialogContent>
            <Autocomplete
              sx={{ mb: 1 }}
              disablePortal
              fullWidth
              value={sortBy}
              onChange={(event, newValue) => {
                setSortBy(newValue);
              }}
              options={options}
              renderInput={(params) => <TextField {...params} label="" variant="standard" />}
            />
            {sortBy ? (
              <FormControl>
                <RadioGroup
                  name="Sort Options"
                  onChange={(e) => {
                    if (e.target.value == "ascending") {
                      setSortSelect(e.target.value);
                    } else if (e.target.value == "descending") {
                      setSortSelect(e.target.value);
                    }
                  }}
                >
                  <FormControlLabel
                    value="descending"
                    control={<Radio />}
                    label={language == 1 ? "Mayor a menor" : "Highest to lowest"}
                  />
                  <FormControlLabel
                    value="ascending"
                    control={<Radio />}
                    label={language == 1 ? "Menor a mayor" : "Lowest to highest"}
                  />
                </RadioGroup>
              </FormControl>
            ) : null}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose2}>{language == 1 ? "Cancelar" : "Cancel"}</Button>
            <Button
              onClick={() => {
                if (sortSelect == "ascending" && sortBy.id == 1) {
                  sortPriceAscend();
                } else if (sortSelect == "descending" && sortBy.id == 1) {
                  sortPriceDescend();
                } else if (sortSelect == "ascending" && sortBy.id == 2) {
                  sortStockAscend();
                } else if (sortSelect == "descending" && sortBy.id == 2) {
                  sortStockDescend();
                }
              }}
            >
              {language == 1 ? "Organizar" : "Sort"}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={open3}>
          <DialogTitle>{language == 1 ? "Filtros" : "Filters"}</DialogTitle>
          <DialogContent>
            <MDBox p={1}>
              <FormControl>
                <RadioGroup
                  name="Filter Options"
                  onChange={(e) => {
                    if (e.target.value == "lowstock") {
                      setCheck(false);
                      setFilterSelect(e.target.value);
                    } else if (e.target.value == "subdept") {
                      setCheck(true);
                      setFilterSelect(e.target.value);
                    } else if (e.target.value == "discount") {
                      setCheck(false);
                      setFilterSelect(e.target.value);
                    } else if (e.target.value == "shopper") {
                      setCheck(false);
                      setFilterSelect(e.target.value);
                    } else {
                      setCheck(false);
                    }
                  }}
                >
                  {/* <FormControlLabel
                    value="subdept"
                    control={<Radio />}
                    label={language == 1 ? "Sub-Departamento" : "Sub-Department"}
                  />
                  {check ? (
                    <Autocomplete
                      disablePortal
                      value={selectedSubcategory}
                      onChange={(event, newValue) => {
                        console.log(newValue);
                        setSelectedSubcategory(newValue);
                      }}
                      options={subcategories}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={language == 1 ? "Sub-Departamento" : "Sub-Department"}
                          variant="standard"
                        />
                      )}
                    />
                  ) : null}
                  {success ? null : (
                    <MDTypography color="error" variant="button">
                      {language == 1 ? "Seleccione un sub-departamento" : "Select a sub-department"}
                    </MDTypography>
                  )} */}
                  <FormControlLabel
                    value="discount"
                    control={<Radio />}
                    label={language == 1 ? "Productos en especial" : "Products on sale"}
                  />
                  <FormControlLabel
                    value="shopper"
                    control={<Radio />}
                    label={language == 1 ? "Productos en el shopper" : "Shopper Products"}
                  />
                  <FormControlLabel
                    value="lowstock"
                    control={<Radio />}
                    label={language == 1 ? "Poca cantidad" : "Low stock"}
                  />
                </RadioGroup>
              </FormControl>
            </MDBox>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose3}>{language == 1 ? "Cancelar" : "Cancel"}</Button>
            <Button 
              onClick={()=>{
                setFilterSelect()
                filterSelect = null
                getProductData() 
                setSelectedCategory()
              }}
            >
              {language == 1 ? "Remover filtro" : "Remove Filter"}
            </Button>
            <Button
              onClick={() => {
                if (filterSelect == "lowstock") {
                  filterLowStock(selectedCategory?.id);
                } else if (filterSelect == "subdept") {
                  filterSubDept(selectedCategory?.id);
                } else if (filterSelect == "discount") {
                  filterOnSale(selectedCategory?.id);
                } else if (filterSelect == "shopper") {
                  filterShopper(selectedCategory?.id);
                }
                setOpen3(false)
              }}
            >
              {language == 1 ? "Aplicar filtro" : "Apply Filter"}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={open5}>
          <DialogTitle>{cartItemToUpOrDel?.product?.name}</DialogTitle>
          <DialogContent>
            <MDBox
              component="img"
              height="50%"
              width="50%"
              src={imageIP + cartItemToUpOrDel?.product?.image}
              alt=""
              ml={17}
            />
            <TextField
              margin="dense"
              label="Sub. Dept."
              type="text"
              fullWidth
              value={cartItemToUpOrDel?.product?.subcategory?.name}
              variant="standard"
            />
            <TextField
              margin="dense"
              label="UPC"
              type="text"
              fullWidth
              value={cartItemToUpOrDel?.product?.upc}
              variant="standard"
            />
            <TextField
              margin="dense"
              label={language == 1 ? "UPC Unidad" : "UPC Unit"}
              type="text"
              fullWidth
              value={cartItemToUpOrDel?.product?.upcUnit}
              variant="standard"
            />
            <TextField
              margin="dense"
              label={language == 1 ? "Disponible" : "Available"}
              type="text"
              fullWidth
              value={cartItemToUpOrDel?.product?.available}
              variant="standard"
            />
            <TextField
              margin="dense"
              label={language == 1 ? "Empaque" : "Package"}
              type="text"
              fullWidth
              value={cartItemToUpOrDel?.product?.packaging}
              variant="standard"
            />
            <TextField
              margin="dense"
              label={language == 1 ? "Precio" : "Price"}
              type="text"
              fullWidth
              value={"$" + cartItemToUpOrDel?.product?.sales_price}
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen5(!open5)}>
              {language == 1 ? "Cancelar" : "Cancel"}
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
      <DashboardNavbar />
      {!isMobile ? <Card sx={{ mb: 2, backgroundColor: "#3a3a31" }}>
        <Grid container spacing={3}>
          {market
            ? categories.length > 0 &&
              categories.map((item) => {
                return (
                  <Grid item xs={12} md={3} lg={categories.length >  5 ? 2 : (12 / categories.length)} overflow={'hidden'}>
                    <Tooltip title={item.name}>
                      <MDButton
                        sx={{ mb: 1, mt: 1, ml: 5 }}
                        onClick={() => {
                          if (item.id != selectedCategory?.id) {
                            setSelectedCategory(item)
                            getProductsWithCategories(item.id);
                          } else {
                            setSelectedCategory()
                            selectedCategory.id = null
                            getProductData()
                          }
                        }}
                        className="btncom"
                        variant="text"
                        color={selectedCategory?.id == item.id ? "success" : ""}
                      >
                        {item.name}
                      </MDButton>
                    </Tooltip>
                  </Grid>
                );
              })
            : null}
        </Grid>
      </Card> 
      :
      <Autocomplete
        disablePortal
        value={selectedCategory}
        onChange={(event, newValue) => {
          getProductsWithCategories(newValue.id);
          setSelectedCategory(newValue);
        }}
        options={categories.map(item=>{
          return {label: item.name, id: item.id}
        })}
        renderInput={(params) => (
          <TextField
            {...params}
            label={language == 1 ? "Departamentos" : "Departments"}
            variant="standard"
          />
        )}
      />}
      {market ? (
        <>
          {controlSlides.length > 0 && !isMobile ? <MDBox mb={-2}>
            <Carousel 
              activeIndex={currentSlide} 
              onSelect={(selectedIndex)=>{setCurrentSlide(selectedIndex)}} 
              interval={pause} 
              pause='hover' 
              variant="dark" 
              controls={false}
            >
              {controlSlides}
            </Carousel>
          </MDBox> : null}
          {controlSlides.length > 0 && !isMobile && <Card sx={{ mb: 2, overflow: "hidden" }}>
            <Grid container spacing={0}>
              <Grid item xs={12} lg={12}>
                <Carousel 
                  activeIndex={currentSlide} 
                  onMouseEnter={()=>setPause(null)} 
                  onMouseLeave={()=>setPause(5)} 
                  indicators={false} 
                  controls={false} 
                  pause='hover' 
                  touch={true} 
                  variant="dark" 
                  keyboard={true}
                >
                  {carouselSlides}
                </Carousel>
              </Grid>
            </Grid>
          </Card>}
          <Grid container spacing={10}>
            <Grid item lg={"auto"}>
              {currentShopper.length > 0 && <MDTypography variant="h5">
                {"Shopper (" + (language == 1 ? "Pedido: " : "Order: ") + shopperDates?.start + ' - ' + shopperDates?.end + ", " + (language == 1 ? "Vigencia: " : "Display: ") + shopperDates?.startDisplay + ' - ' + shopperDates?.endDisplay + ")"}
              </MDTypography>}
            </Grid>
            {zoneOrderDays?.length > 0 && <Grid item lg={'auto'}>
              <MDTypography variant="h5">
                {(language == 1 ? "Días de Pedido" : "Order days: ") + zoneOrderDays}
              </MDTypography>
            </Grid>}
            {zoneDeliveryDay?.length > 0 && <Grid item lg={'auto'}>
              <MDTypography variant="h5">
                {(language == 1 ? "Día de Entrega" : "Delivery day: ") + zoneDeliveryDay}
              </MDTypography>
            </Grid>}
          </Grid>
          <MDBox mt={1} display="flex" justifyContent="space-between" alignItems="flex-start">
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={2.5}>
                {!show ? (
                  <>
                    <MDBox display="flex" justifyContent="space-between" alignItems="flex-start">
                      <MDBox mt={1} display="flex" justifyContent="left" alignItems="flex-start">
                        <MDButton
                          variant="outlined"
                          color="dark"
                          size="small"
                          onClick={() => setOpen2(true)}
                        >
                          <Icon>sort</Icon>
                          &nbsp;{language == 1 ? "Organizar" : "Sort"}
                        </MDButton>
                        <MDButton
                          sx={{ ml: 0.5 }}
                          variant="outlined"
                          color="dark"
                          size="small"
                          onClick={() => setOpen3(true)}
                        >
                          {language == 1 ? "Filtrar" : "Filter"}&nbsp;
                          <Icon>keyboard_arrow_down</Icon>
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </>
                ) : (
                  <MDBox
                    mt={1}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="flex-start"
                  >
                    <MDButton
                      sx={{ ml: 0.5 }}
                      variant="outlined"
                      color="dark"
                      size="small"
                      onClick={() => setOpen3(true)}
                    >
                      {language == 1 ? "Filtrar" : "Filter"}&nbsp;
                      <Icon>keyboard_arrow_down</Icon>
                    </MDButton>
                  </MDBox>
                )}
              </Grid>
              <Grid item xs={12} lg={6.5}>
                <MDBox display="flex" justifyContent="right" alignItems="flex-start">
                  <MDInput
                    label={language == 1 ? "Buscar..." : "Search..."}
                    variant="outlined"
                    type="search"
                    value={search}
                    fullWidth
                    onChange={(e) => {
                      searchProducts(e.target.value), setSearch(e.target.value);
                    }}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} lg={3}>
                <MDBox display="flex" justifyContent="right" alignItems="flex-start">
                  <MDButton
                    color="dark"
                    size="medium"
                    onClick={() => setMarket(true)}
                    variant={market ? "gradient" : "outlined"}
                  >
                    <Icon>shopping_bag</Icon>
                    &nbsp;{language == 1 ? "Mercado" : "Market"}
                  </MDButton>
                  <MDButton
                    sx={{ ml: 1 }}
                    color="dark"
                    size="medium"
                    onClick={() => setMarket(false)}
                    variant={market ? "outlined" : "gradient"}
                  >
                    <Icon>shopping_cart</Icon>
                    &nbsp;{language == 1 ? "Carrito" : "Cart"}
                  </MDButton>
                  <MDButton
                    sx={{ mt: -0.5 }}
                    title="Cards"
                    color="dark"
                    size="large"
                    variant="text"
                    onClick={() => setShow(false)}
                    iconOnly
                  >
                    <Icon>view_compact</Icon>
                  </MDButton>
                  {/* <Divider orientation="vertical" flexItem/> */}
                  <MDButton
                    sx={{ mt: -0.5, ml: -2.5 }}
                    title="List"
                    color="dark"
                    size="large"
                    variant="text"
                    onClick={() => setShow(true)}
                    iconOnly
                  >
                    <Icon>menu</Icon>
                  </MDButton>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        </>
      ) : (
        <MDBox display="flex" justifyContent="right" alignItems="flex-start">
          <MDButton
            color="dark"
            size="medium"
            onClick={() => setMarket(true)}
            variant={market ? "gradient" : "outlined"}
          >
            <Icon>shopping_bag</Icon>
            &nbsp;{language == 1 ? "Mercado" : "Market"}
          </MDButton>
          <MDButton
            sx={{ ml: 1 }}
            color="dark"
            size="medium"
            onClick={() => setMarket(false)}
            variant={market ? "outlined" : "gradient"}
          >
            <Icon>shopping_cart</Icon>
            &nbsp;{language == 1 ? "Carrito" : "Cart"}
          </MDButton>
        </MDBox>
      )}
      <MDBox mt={2} display="flex" justifyContent="center" alignItems="center">
        <ClipLoader
          loading={loading}
          color="#3e82ea"
          size={50}
        />
      </MDBox>

      <MDBox my={2}>
        {market ? (
          show ? (
            <Card>
              <MDBox mt={1} />
              <DataTable table={products} entriesPerPage={true} />
            </Card>
          ) : productCards?.length > 100 ? (
            <>
              <Grid container spacing={2}>
                <Grid item xs={6} md={6} lg={12}>
                  <MDBox mr={1} display="flex" justifyContent="left" alignItems="flex-start">
                    <MDPagination size="medium">
                      <MDPagination
                        onClick={() => {
                          if (page > 0) {
                            setPage(page - 1), generatePagination2(page - 1);
                          }
                        }}
                        item
                      >
                        <Icon>keyboard_arrow_left</Icon>
                      </MDPagination>
                      {isMobile 
                        ? pageOptions.slice(page > 0 ? (page > 1 ? page - 2 : page - 1) : page, pageOptions.length - 1 > page + 4 ? pageOptions.length -1 : page + 4)
                        : pageOptions}
                      <MDPagination
                        onClick={() => {
                          if (page < finalPage) {
                            setPage(page + 1), generatePagination2(page + 1);
                          }
                        }}
                        item
                      >
                        <Icon>keyboard_arrow_right</Icon>
                      </MDPagination>
                    </MDPagination>
                  </MDBox>
                </Grid>
                {productCards?.slice(page * 100, page * 100 + 100)}
                <Grid item xs={6} md={6} lg={12}>
                  <MDBox mr={1} display="flex" justifyContent="left" alignItems="flex-start">
                    <MDPagination size="medium">
                      <MDPagination
                        onClick={() => {
                          if (page > 0) {
                            setPage(page - 1);
                            generatePagination2(page - 1)
                          } 
                        }}
                        item
                      >
                        <Icon>keyboard_arrow_left</Icon>
                      </MDPagination>
                      {isMobile 
                        ? pageOptions.slice(page > 0 ? (page > 1 ? page - 2 : page - 1) : page, pageOptions.length - 1 > page + 4 ? pageOptions.length -1 : page + 4)
                        : pageOptions}
                      <MDPagination
                        onClick={() => {
                          if (page < finalPage) {
                            setPage(page + 1);
                            generatePagination2(page + 1)
                          } 
                        }}
                        item
                      >
                        <Icon>keyboard_arrow_right</Icon>
                      </MDPagination>
                    </MDPagination>
                  </MDBox>
                </Grid>
              </Grid>
            </>
          ) : 
          (
            <Grid container spacing={2}>
              {productCards}
            </Grid>
          )
        ) : (
          <Card>
            <MDBox p={2}>

              <Grid>
                {isMobile ? <>
                  <MDButton
                    color="light"
                    size="medium"
                    variant="gradient"
                    onClick={()=>setViewTotals(true)}
                  >
                    {language==1 ? "Ver Totales" : "View Totals"}
                  </MDButton>
                  <MDButton
                    color="info"
                    size="medium"
                    onClick={() => {
                      setChangeStatus(true);
                    }}
                    hidden={cartItems}
                    variant="gradient"
                  >
                    <Icon>checkmark</Icon>
                    &nbsp;{language == 1 ? "Confirmar Orden" : "Confirm Order"}
                  </MDButton>
                </>
                :
                <DataTable
                  table={{
                    columns: [
                      {Header: language == 1 ? "Tienda:" : "Store:", accessor: "store"},
                      {Header: language == 1 ? "Total de Bultos:" : "Qty Total:", accessor: "qty"},
                      {Header: "Sub-Total", accessor: "sub"},
                      {Header: language == 1 ? "Total de Impuestos:" : "Tax Total:", accessor: "tax"},
                      {Header: "Total", accessor: "total"},
                      {Header: language==1 ? "Balance Previo" : "Previous Balance", accessor: "previous"},
                      {Header: language==1 ? "Balance Nuevo" : "New Balance", accessor: "new"},
                      {Header: "", accessor: "action"}
                    ],
                    rows: [
                      {
                        store: storeLabel,
                        qty: qtyTotal, 
                        sub: '$' + parseFloat(subtotal).toFixed(2), 
                        tax: '$' + parseFloat(taxTotal).toFixed(2), 
                        total: '$' + parseFloat(total).toFixed(2),
                        previous: '$' + parseFloat(previousBalance).toFixed(2),
                        new: '$' + parseFloat(parseFloat(previousBalance) + parseFloat(total)).toFixed(2),
                        action: 
                        <MDButton
                          color="info"
                          style={{ float: "right" }}
                          size="medium"
                          onClick={() => {
                            setChangeStatus(true)
                          }}
                          hidden={cartItems}
                          variant="gradient"
                        >
                          <Icon>checkmark</Icon>
                          &nbsp;{language == 1 ? "Confirmar Orden" : "Confirm Order"}
                        </MDButton>
                      }
                    ]
                  }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                />}
              </Grid>
            </MDBox>
            <DataTable2
              table={selectedProds}
              index={index}
              setIndex={setIndex}
              pageEntries={10}
              entriesPerPage={true}
              canSearch
            />{" "}
          </Card>
        )}
      </MDBox>
    </DashboardLayout>
  );
}

export default Ecommerce;
