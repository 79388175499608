import { StyleSheet, Text, View } from '@react-pdf/renderer'
import React from 'react'
import PropTypes from 'prop-types'

const styles = StyleSheet.create({
  table: {
    width: '100%',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid #EEE',
    paddingTop: 8,
    paddingBottom: 5,
  },
  header: {
    borderTop: 'none',
  },
  bold: {
    fontWeight: 'bold',
  },
  // So Declarative and unDRY 👌
  row1: {
    fontSize: 10,
    width: '13%',
  },
  row2: {
    fontSize: 10,
    width: '20%',
  },
  row3: {
    fontSize: 10,
    width: '15%',
  },
  row4: {
    fontSize: 10,
    width: '20%',
  },
  row5: {
    width: '27%',
  },
})

const PdfTable = ({ data, maximumDays }) => {
  const [language, setLanguage] = React.useState(localStorage.getItem("Language"));
  return (
    <View style={styles.table}>
      <View style={[styles.row, styles.bold, styles.header]}>
        <Text style={styles.row1}>{language == 1 ? "UPC Unidad" : "UPC Unit"}</Text>
        <Text style={styles.row2}>UPC</Text>
        <Text style={styles.row2}>{language == 1 ? "Producto" : "Product"}</Text>
        <Text style={styles.row1}>{language == 1 ? "Cantidad de Envío" : "Shipping Quantity"}</Text>
        <Text style={styles.row1}>{language == 1 ? "Precio" : "Price"}</Text>
        <Text style={styles.row3}>{language == 1 ? "% de Impuesto" : "Tax %"}</Text>
        <Text style={styles.row1}>Total</Text>
      </View>
      {data.map((row, i) => (
        <View key={i} style={styles.row} wrap={false}>
          <Text style={styles.row1}>{row.upcunit}</Text>
          <Text style={styles.row2}>{row.upc}</Text>
          <Text style={styles.row2}>{row.name}</Text>
          <Text style={styles.row1}>{row.qty}</Text>
          <Text style={styles.row1}>{row.price}</Text>
          <Text style={styles.row3}>{row.tax}</Text>
          <Text style={styles.row1}>{row.amount}</Text>
        </View>
      ))}
    </View>
  )
}

PdfTable.propTypes = {
  data: PropTypes.array.isRequired,
  maximumDays: PropTypes.number.isRequired,
}

export default PdfTable