/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import AlertDialog from "app/components/confirmBox";
import DataTable2 from "app/components/shopperTable";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import IP from "app/IP";
import { Tooltip } from "@mui/material";

const axios = require("axios");

function ViewDelivery() {
  const [menu, setMenu] = useState(null);
  const [deliveries, setDeliveries] = useState({ columns: [], rows: [] });
  const [index, setIndex] = useState(0);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [active, setActive] = useState(false)
  const [language, setLanguage] = useState(localStorage.getItem("Language"));

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);
  const navigate = useNavigate();
  const toggleSnackbar = () => setShow(!show);
  const toggleSnackbar2 = () => setShow2(!show2);

  async function changeStatus(id) {
    await axios
      .post(
        IP + "/deliveries/setActive/" + id,
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        toggleSnackbar();
        getDeliveryData();
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        if (language == 1 ? error.response.data.data == "No se puede activar el envío, esta atado a una tienda" : error.response.data.data == "Cannot activate delivery, it is tied to a store") {
          toggleSnackbar2()
        }
        console.log(error);
      });
  }

  async function getDeliveryData() {
    await axios
      .get(IP + "/deliveries", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        let columns = [
          { Header: language == 1 ? "Nombre" : "Name", accessor: "name" },
          { Header: language == 1 ? "Cantidad" : "Amount", accessor: "amount" },
          { Header: language == 1 ? "Activo" : "Active", accessor: "active" },
          { Header: language == 1 ? "Acción" : "Action", accessor: "action" },
        ];

        let rows = response.data.data.map((item) => {
          //console.log(item);
          return {
            name: item.name,
            amount: item.amount,
            active: item.active == "Active" ? 
              language==1 ? "Activo" : "Active" :
              language==1 ? "Inactivo" : "Inactive",
            action: (
              <Grid>
              {item.active == "Inactive" ? (
                <Tooltip title={language==1 ? "Activar" : "Activate"}>
                  <MDButton
                    variant="gradient"
                    color="success"
                    onClick={() => {
                      changeStatus(item.id);
                      setActive(false)
                    }}
                    iconOnly
                  >
                    <Icon>checkmark</Icon>
                  </MDButton>
                </Tooltip>
                ) : (
                <Tooltip title={language==1 ? "Desactivar" : "Deactivate"}>
                  <MDButton
                    variant="gradient"
                    color="warning"
                    onClick={() => {
                      changeStatus(item.id);
                      setActive(true)
                    }}
                    iconOnly
                  >
                    <Icon>do_not_disturb</Icon>
                  </MDButton>
                </Tooltip>
              )}{"  "}
                <Tooltip title={language==1 ? "Editar" : "Edit"}>
                <MDButton
                  variant="gradient"
                  color="info"
                  onClick={() => {
                    localStorage.setItem("deliveryId", item.id);
                    navigate("/edit-delivery");
                  }}
                  iconOnly
                >
                  <Icon>edit</Icon>
                </MDButton>
                </Tooltip>{" "}
              </Grid>
            )
          };
        });

        let tableData = { columns: columns, rows: rows };
        //console.log(tableData)
        setDeliveries(tableData);
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  function nav() {
    navigate("/create-delivery");
  }

  useEffect(() => {
    getDeliveryData();
  }, []);

  return (
    <DashboardLayout>
      <MDSnackbar
        color="info"
        icon="notifications"
        title=""
        content={language == 1 
          ? `${active ? 'Desactivado' : 'Activado'} Exitosamente!` 
          : `${active ? 'Deactivated' : 'Activated'} Succesfully!`
        }
        dateTime={language==1 ? "Ahora" : "Now"}
        open={show}
        close={toggleSnackbar}
      />
      <MDSnackbar
        color="error"
        icon="notifications"
        title=""
        content={language == 1 
          ? `No se pudo ${active ? 'desactivar' : 'activar'}, esta atado a una tienda` 
          : `Could not be ${active ? 'deactivated' : 'activated'}, it is tied to a store`
        }
        dateTime={language==1 ? "Ahora" : "Now"}
        open={show2}
        close={toggleSnackbar2}
      />
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          <MDButton variant="gradient" color="info" onClick={nav}>
            {language == 1 ? "Crear" : "Create"}
          </MDButton>
        </MDBox>
        <Card>
          <MDBox p={3}>
            <DataTable
              table={deliveries}
              entriesPerPage={true}
            />
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default ViewDelivery;
