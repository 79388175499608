/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import BookingCard from "examples/Cards/BookingCard";
import CategoriesList from "examples/Lists/CategoriesList";

// Anaytics dashboard components
import SalesByCountry from "layouts/dashboards/analytics/components/SalesByCountry";

// Data
import reportsBarChartData from "layouts/dashboards/analytics/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboards/analytics/data/reportsLineChartData";

// Images
import booking1 from "assets/images/products/product-1-min.jpg";
import booking2 from "assets/images/products/product-2-min.jpg";
import booking3 from "assets/images/products/product-3-min.jpg";

import progressLineChartData from "layouts/pages/widgets/data/progressLineChartData";
import DefaultItem from "examples/Items/DefaultItem";
import ProgressLineChart from "examples/Charts/LineCharts/ProgressLineChart";
import categoriesListData from "layouts/pages/widgets/data/categoriesListData";
import IP from "app/IP";

const axios = require("axios");

function Analytics() {
  const { sales, tasks } = reportsLineChartData;
  const [dashinfo, setDashInfo] = useState();
  const [language, setLanguage] = useState(localStorage.getItem("Language"));
  const [chartData, setChartData] = useState({
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  });
  const [chartCount, setChartCount] = useState(0);

  const navigate = useNavigate();

  async function GetDashBoard() {
    await axios
      .get(IP + "/dashboard", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          //Accept: "application/json",
        },
      })
      .then((response) => {
        console.log(response.data.data);
        setDashInfo(response.data.data);
        setChartData({
          labels: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
          data: response.data?.data?.counters?.sales_made_current_year ?? [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          ],
        });
        let countData = 0;
        if (response.data?.data?.counters?.sales_made_current_year) {
          response.data?.data?.counters?.sales_made_current_year.forEach((item) => {
            countData += item;
          });
          setChartCount(countData);
        }

        setChartCount;
      })
      .catch((error) => {
        // if (!error.response.data.authenticated) {
        //   navigate("/signIn");
        // }
        console.log(error);
      });
  }

  const MostPurchased = [
    {
      color: "dark",
      icon: "launch",
      name: "CARMELA SALCHICHAS POLLO 48/5",
      description: (
        <>
          {language == 1 ? "250 disponible," : "250 in stock,"}{" "}
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {language == 1 ? "346+ vendidos" : "346+ sold"}
          </MDTypography>
        </>
      ),
      route: "/",
    },
    {
      color: "dark",
      icon: "book_online",
      name: "RAGU PLAIN 12/14",
      description: (
        <>
          {language == 1 ? "123 cerrados," : "123 closed,"}{" "}
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {language == 1 ? "15 abiertos" : "15 open"}
          </MDTypography>
        </>
      ),
      route: "/",
    },
    {
      color: "dark",
      icon: "priority_high",
      name: "Utreee HAB PINTAS 24/15.5",
      description: (
        <>
          1 {language == 1 ? "esta activo" : "is active"},{" "}
          <MDTypography variant="caption" color="text" fontWeight="medium">
            40 {language == 1 ? "cerrados" : "closed"}
          </MDTypography>
        </>
      ),
      route: "/",
    },
    {
      color: "dark",
      icon: "insert_emoticon",
      name: "FABULOSO B/SODA-CITRUS&FR12/28",
      description: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          + 430
        </MDTypography>
      ),
      route: "/",
    },
  ];

  const StoreListData = [
    {
      color: "dark",
      icon: "launch",
      name: "Utreee IDEAL",
      description: (
        <>
          250 in stock,{" "}
          <MDTypography variant="caption" color="text" fontWeight="medium">
            346+ sold
          </MDTypography>
        </>
      ),
      route: "/",
    },
    {
      color: "dark",
      icon: "book_online",
      name: "Utreee AGUADILLA",
      description: (
        <>
          123 closed,{" "}
          <MDTypography variant="caption" color="text" fontWeight="medium">
            15 open
          </MDTypography>
        </>
      ),
      route: "/",
    },
    {
      color: "dark",
      icon: "priority_high",
      name: "Utreee TRUJILLO ALTO-CONQUISTADOR",
      description: (
        <>
          1 is active,{" "}
          <MDTypography variant="caption" color="text" fontWeight="medium">
            40 closed
          </MDTypography>
        </>
      ),
      route: "/",
    },
    {
      color: "dark",
      icon: "insert_emoticon",
      name: "BUSINESS DEVELOPMENT",
      description: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          + 430
        </MDTypography>
      ),
      route: "/",
    },
  ];

  // Action buttons for the BookingCard
  const actionButtons = (
    <>
      <Tooltip title="Refresh" placement="bottom">
        <MDTypography
          variant="body1"
          color="primary"
          lineHeight={1}
          sx={{ cursor: "pointer", mx: 3 }}
        >
          <Icon color="inherit">refresh</Icon>
        </MDTypography>
      </Tooltip>
      <Tooltip title="Edit" placement="bottom">
        <MDTypography variant="body1" color="info" lineHeight={1} sx={{ cursor: "pointer", mx: 3 }}>
          <Icon color="inherit">edit</Icon>
        </MDTypography>
      </Tooltip>
    </>
  );

  useEffect(() => {
    GetDashBoard();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox mt={1.5}>
          <Grid container spacing={3}>
            <Grid item xs={6} sm={6} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="dark"
                  icon="weekend"
                  title={
                    language == 1 ? "Productos Bajo el Mínimo" : "Products Under Minimum Stock"
                  }
                  count={dashinfo?.counters?.minimum_stock ?? 0}
                  route="/minimum-stock-report"
                  percentage={{
                    color: "success",
                    amount: "+55%",
                    label: "than lask week",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="leaderboard"
                  title={language == 1 ? "Ventas Pendientes" : "Sales Order Pending"}
                  count={dashinfo?.counters?.sales_pending ?? 0}
                  percentage={{
                    color: "success",
                    amount: "+3%",
                    label: "than last month",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="store"
                  title={
                    language == 1
                      ? "Ventas Recibidas este mes"
                      : "Sales Order Received Current Month"
                  }
                  count={dashinfo?.counters?.sales_current_month ?? 0}
                  percentage={{
                    color: "success",
                    amount: "+1%",
                    label: "than yesterday",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="primary"
                  icon="person_add"
                  title={language == 1 ? "Ventas Hechas este mes" : "Sales Made Current Month"}
                  count={"$" + (dashinfo?.counters?.sales_made_current_month ?? 0)}
                  percentage={{
                    color: "success",
                    amount: "",
                    label: "Just updated",
                  }}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mb={3} mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <Card sx={{ height: "100%" }}>
                <MDBox pt={2} px={2} lineHeight={1}>
                  <MDTypography variant="h6" fontWeight="medium">
                    {language == 1 ? "Receivings Aproximados" : "Upcoming Receivings"}
                  </MDTypography>
                  <MDTypography variant="button" color="text" fontWeight="regular"></MDTypography>
                </MDBox>
                <MDBox p={2}>
                  {dashinfo?.lists?.upcoming_receivings?.map((item) => {
                    return (
                      <MDBox mt={1}>
                        <DefaultItem
                          color="dark"
                          icon="task"
                          title={item.vendor.name}
                          description={item.eda}
                        />
                      </MDBox>
                    );
                  })}
                </MDBox>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} lg={8}>
              <MDBox mb={3}>
                <Card sx={{ height: "100%" }}>
                  <MDBox pt={2} px={2} lineHeight={1}>
                    <MDTypography variant="h6" fontWeight="medium">
                      {language == 1 ? "Mas Comprado" : "Most Purchased"}
                    </MDTypography>
                    <MDTypography variant="button" color="text" fontWeight="regular"></MDTypography>
                  </MDBox>
                  <MDBox p={2}>
                    {dashinfo?.lists?.most_purchased?.map((item) => {
                      return (
                        <MDBox mt={1}>
                          <DefaultItem
                            color="dark"
                            icon="task"
                            title={item.product.name}
                            description={(language == 1 ? "Comprado: " : "Purchased: ") + item.count}
                          />
                        </MDBox>
                      );
                    })}
                  </MDBox>
                </Card>
              </MDBox>
              {/* </Grid>
          <Grid item xs={12} md={6} lg={4}> */}
              <MDBox mb={3}>
                <Card sx={{ height: "100%" }}>
                  <MDBox pt={2} px={2} lineHeight={1}>
                    <MDTypography variant="h6" fontWeight="medium">
                      {language == 1 ? "Tiendas Que Más Compran" : "Most Purchasing Stores"}
                    </MDTypography>
                    <MDTypography variant="button" color="text" fontWeight="regular"></MDTypography>
                  </MDBox>
                  <MDBox p={2}>
                    {dashinfo?.lists?.most_purchasing_clients?.map((item) => {
                      return (
                        <MDBox mt={1}>
                          <DefaultItem
                            color="dark"
                            icon="task"
                            title={item?.sales_order?.store?.name ?? ""}
                            description={
                              (language == 1 ? "Total Comprado: " : "Purchased total: ") +
                              item.count
                            }
                          />
                        </MDBox>
                      );
                    })}
                  </MDBox>
                </Card>
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={6} md={12} lg={4}>
              <Card sx={{ height: "100%" }}>
                <MDBox pt={2} px={2} lineHeight={1}>
                  <MDTypography variant="h6" fontWeight="medium">
                    {language == 1 ? "Poca Cantidad" : "Low Stock"}
                  </MDTypography>
                  <MDTypography variant="button" color="text" fontWeight="regular"></MDTypography>
                </MDBox>
                <MDBox p={2}>
                  {dashinfo?.lists?.low_stock?.map((item) => {
                    return (
                      <MDBox mt={1}>
                        <DefaultItem
                          color="dark"
                          icon="task"
                          title={item.name}
                          description={
                            (language == 1 ? "En Almacen: " : "On Stock: ") + item.on_stock
                          }
                        />
                      </MDBox>
                    );
                  })}
                </MDBox>
              </Card>
            </Grid>
            <Grid item xs={12} md={12} lg={8}>
              <ProgressLineChart
                icon="date_range"
                title={language == 1 ? "Ventas Mensuales" : "Monthly Sales"}
                count={chartCount}
                height="20rem"
                chart={chartData}
              />
            </Grid>
          </Grid>
          <Card sx={{ height: "100%", mt: 2 }}>
                <MDBox pt={2} px={2} lineHeight={1}>
                  <MDTypography variant="h6" fontWeight="medium">
                    {language == 1 ? "Tiendas pasadas de su límite de crédito" : "Stores passed their credit limit"}
                  </MDTypography>
                  <MDTypography variant="button" color="text" fontWeight="regular"></MDTypography>
                </MDBox>
                <MDBox p={2}>
                  {dashinfo?.lists?.passed_credit_limit?.sort((a, b) => (a.diff < b.diff ? 1 : -1)).slice(0,5).map((item) => {
                    return (
                      <MDBox mt={1}>
                        <DefaultItem
                          color="dark"
                          icon="task"
                          title={item.name}
                          description={
                            (language == 1 ? "Diferencia: " : "Difference: ") + '$' + parseFloat(item.diff).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }
                        />
                      </MDBox>
                    );
                  })}
                </MDBox>
              </Card>
          {/* </MDBox>
        <Grid container spacing={3}> */}
        </MDBox>
        {/* <Grid container>
          <SalesByCountry />
        </Grid> */}
        {/* <MDBox mt={6}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="info"
                  title="website views"
                  description="Last Campaign Performance"
                  date="campaign sent 2 days ago"
                  chart={reportsBarChartData}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="success"
                  title="monthly sales"
                  description={
                    <>
                      (<strong>+15%</strong>) increase in today sales.
                    </>
                  }
                  date="updated 4 min ago"
                  chart={sales}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="dark"
                  title="completed tasks"
                  description="Last Campaign Performance"
                  date="just updated"
                  chart={tasks}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox> */}
        <MDBox mt={2}>
          <Grid container spacing={3}>
            {/* <Grid item xs={12} md={6} lg={4}>
              <MDBox mt={3}>
                <BookingCard
                  image={booking1}
                  title="Cozy 5 Stars Apartment"
                  description='The place is close to Barceloneta Beach and bus stop just 2 min by walk and near to "Naviglio" where you can enjoy the main night life in Barcelona.'
                  price="$899/night"
                  location="Barcelona, Spain"
                  action={actionButtons}
                />
              </MDBox>
            </Grid> */}
            {/* <Grid item xs={12} md={6} lg={4}>
              <MDBox mt={3}>
                <BookingCard
                  image={booking2}
                  title="Office Studio"
                  description='The place is close to Metro Station and bus stop just 2 min by walk and near to "Naviglio" where you can enjoy the night life in London, UK.'
                  price="$1.119/night"
                  location="London, UK"
                  action={actionButtons}
                />
              </MDBox>
            </Grid> */}
            {/* <Grid item xs={12} md={6} lg={4}>
              <MDBox mt={3}>
                <BookingCard
                  image={booking3}
                  title="Beautiful Castle"
                  description='The place is close to Metro Station and bus stop just 2 min by walk and near to "Naviglio" where you can enjoy the main night life in Milan.'
                  price="$459/night"
                  location="Milan, Italy"
                  action={actionButtons}
                />
              </MDBox>
            </Grid> */}
          </Grid>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default Analytics;
