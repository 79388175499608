/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import MDButton from "components/MDButton";

import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";

// Overview page components
import Header from "layouts/pages/profile/components/Header";
import PlatformSettings from "layouts/pages/profile/profile-overview/components/PlatformSettings";

// Data
import profilesListData from "layouts/pages/profile/profile-overview/data/profilesListData";

// Images
import homeDecor1 from "assets/images/home-decor-1.jpg";
import homeDecor2 from "assets/images/home-decor-2.jpg";
import homeDecor3 from "assets/images/home-decor-3.jpg";
import homeDecor4 from "assets/images/home-decor-4.jpeg";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";

import DataTable from "examples/Tables/DataTable";

// Data
import dataTableData from "layouts/applications/data-tables/data/dataTableData";
// NewUser page components
import FormField from "layouts/pages/users/new-user/components/FormField";
// Material Dashboard 2 React Components
import MDInput from "components/MDInput";
import selectData from "layouts/pages/account/settings/components/BasicInfo/data/selectData";
import Autocomplete from "@mui/material/Autocomplete";

import MDSnackbar from "components/MDSnackbar";
import { useNavigate, Navigate } from "react-router-dom";

import IP from "../IP";

const axios = require("axios");

function UserProfile() {
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [name, setName] = useState("");
  const [username, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confPassword, setConfPassword] = useState("");
  const [show, setShow] = useState(false);
  const toggleSnackbar = () => setShow(!show);
  const [errors, setErrors] = useState([]);
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState({ label: "", id: "" });
  const [variables, setVariables] = useState({ name: "" });
  const [warehouses, setWarehoues] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState({ label: "", id: "" });
  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState([]);
  const [language, setLanguage] = useState(localStorage.getItem("Language"));

  const navigate = useNavigate();

  async function getUserDetails() {
    await axios
      .get(IP + "/user/profile", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        console.log(response.data.data);

        let user = response.data.data;

        console.log("user");
        setUserName(user.username);
        setName(user.name);
        setEmail(user.email);
        setSelectedRole({ id: user.role.id, label: user.role.name });
        setRoles([{ id: user.role.id, label: user.role.name }]);
        setSelectedStore(user.stores.map(item=>{
          return { id: item.id, label: item.name }
        }));
        //setStores([{ id: user.store.id, label: user.store.name }]);
        setSelectedWarehouse({ id: user.warehouse.id, label: user.warehouse.name });
        setWarehoues([{ id: user.warehouse.id, label: user.warehouse.name }]);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function GetRoles() {
    await axios
      .get(IP + "/roles", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        console.log(response.data.data);
        setRoles(
          response.data.data.map((item) => {
            return { label: item.name, id: item.id };
          })
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function GetWareHouses() {
    await axios
      .get(IP + "/warehouses", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        console.log(response.data.data);
        setWarehoues(
          response.data.data.map((item) => {
            return { label: item.name, id: item.id };
          })
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function GetStores() {
    await axios
      .get(IP + "/stores", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        console.log(response.data.data);
        setStores(
          response.data.data.map((item) => {
            return { label: item.name, id: item.id };
          })
        );
      })
      .catch(function (error) {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
      });
  }

  async function EditUserFunc() {
    let req = {
      name: name,
      username: username,
      email: email
    };

    await axios
      .post(IP + "/user/profile", req, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(function (response) {
        toggleSnackbar();
        setErrors([])
        setTimeout(() => {
          setShow(false);
          // navigate("/emos/viewUsers");
        }, 7000);
      })
      .catch(function (error) {
        console.log(error);
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        setErrors(error.response.data.data);
      });
  }

  function TestValidation(message) {
    if (message)
      return message.map((item) => {
        return (
          <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
            <label>{item}</label>
          </MDTypography>
        );
      });
    else return null;
  }

  function ShowError(param) {
    if (param.id) return TestValidation(errors[param.id]);
    else return null;
  }

  useEffect(() => {
    getUserDetails();
  }, []);

  return (
    <>
      <MDSnackbar
        color="info"
        icon="notifications"
        title={language == 1 ? "EMOS Notificación" : "EMOS Notification"}
        content={language == 1 ? "Usuario Editado Exitosamente!" : "User Edited Successfully!"}
        dateTime={language==1 ? "Ahora" : "Now"}
        open={show}
        close={toggleSnackbar}
      />
      <Grid container sx={{mt: 3}} justifyContent="center" alignItems="center">
        <Grid item xs={12} lg={28}>
          <Card sx={{ height: "100%" }}>
            <MDBox p={3}>
              <MDBox>
                  <MDTypography variant="h4">
                    {language == 1 ? "Perfil de Usuario" : "User Profile"}
                  </MDTypography>
                <MDBox>
                  <MDBox lineHeight={0}>
                    <MDTypography variant="h5"></MDTypography>
                    <MDTypography variant="button" color="text">
                      {language == 1
                        ? "Favor de entrar información de usuario."
                        : "Please enter User information."}
                    </MDTypography>
                  </MDBox>
                  <MDBox id="box1" mt={1.625}>
                    <Grid id="grid1" container spacing={3}>
                      <Grid id="grid1-1" item xs={12} sm={6}>
                        <MDInput
                          variant="standard"
                          label={language == 1 ? "Nombre de Usuario" : "User Name"}
                          fullWidth
                          value={username}
                          onChange={(e) => {
                            //  var x = {...variables};

                            setUserName(e.target.value);
                          }}
                        />
                        <ShowError id={"username"} />
                      </Grid>
                      <Grid id="grid1-1" item xs={12} sm={6}>
                        <MDInput
                          variant="standard"
                          label={language == 1 ? "Nombre Completo" : "Full Name"}
                          fullWidth
                          value={name}
                          onChange={(e) => {
                            setName(e.target.value);
                          }}
                        />
                        <ShowError id={"name"} />
                      </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={12}>
                        <MDInput
                          variant="standard"
                          label="Email"
                          fullWidth
                          value={email}
                          onChange={(e) => {
                            if (e.target.value != " ") 
                              setEmail(e.target.value);
                          }}
                        />
                        <ShowError id={"email"} />
                      </Grid>
                      {/* <Grid item xs={12} sm={6}>
                        <MDInput
                          variant="standard"
                          label={language == 1 ? "Rol" : "Role"}
                          fullWidth
                          value={selectedRole?.label}
                        />
                        <ShowError id={"role_id"} />
                      </Grid> */}
                    </Grid>
                    {(selectedRole?.id == 7 || selectedRole?.label == "Store") && (
                      <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <MDTypography style={{marginTop: 4}} variant='h5'>{language == 1 ? "Tiendas" : "Stores"}</MDTypography>
                            {selectedStore.map((item, key)=>{
                              return <MDTypography variant='button' sx={{fontWeight: 'bold'}}>{item.label + (selectedStore.length == ( key + 1) ? '' : ', ')}</MDTypography>
                            })}
                            {/* <Autocomplete
                              disablePortal
                              value={selectedStore}
                              multiple
                              // onChange={(event, newValue) => {
                              //   console.log(newValue);
                              //   setSelectedStore(newValue);
                              //   setSelectedWarehouse({ label: "", id: "" });
                              // }}
                              options={stores}
                              renderInput={(params) => (
                                <MDInput
                                  {...params}
                                  label={language == 1 ? "Tiendas" : "Stores"}
                                  variant="standard"
                                />
                              )}
                            />
                            <ShowError id={"store_id"} /> */}
                          </Grid>
                      </Grid>
                    )}
                  </MDBox>
                </MDBox>
                <MDBox mt={2} width="100%" display="flex" justifyContent="right">
                  <MDButton
                    type="button"
                    variant="gradient"
                    className="float-sm-right"
                    color="info"
                    onClick={EditUserFunc}
                  >
                    {language == 1 ? "Guardar" : "Save"}
                  </MDButton>
                </MDBox>
              </MDBox>
            </MDBox>
          </Card>
        </Grid>
      </Grid>
      </>
  );
}

export default UserProfile;
