/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import AlertDialog from "app/components/confirmBox";
import ProductCell from "layouts/ecommerce/products/product-page/components/ProductCell";
import { ExportToExcel } from "app/components/exportToExcel";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import jsPDF from "jspdf";

import IP from "app/IP";
import imageIP from "app/imageIP";

const axios = require("axios");

function ReportNewProducts() {
  const current = new Date();
  let day = current.getDate();
  let month = current.getMonth() + 1;
  const currentDate = `${current.getFullYear()}-${month < 10 ? `0${month}` : `${month}`}-${
    day < 10 ? `0${day}` : `${day}`
  }`;

  const [menu, setMenu] = useState(null);
  const [report, setReport] = useState({ columns: [], rows: [] });
  const [data, setData] = useState();
  const [date, setDate] = useState(true);
  const [startDate, setStartDate] = useState(currentDate);
  const [endDate, setEndDate] = useState(currentDate);
  const [options, setOptions] = useState("");
  const [vendors, setVendors] = useState([])
  const [selectedVendor, setSelectedVendor] = useState("")
  const [open, setOpen] = useState(false);
  const [allData, setAllData] = useState([])
  const [responseData, setResponseData] = useState([]);
  const [download, setDownload] = useState(true)
  const [language, setLanguage] = useState(localStorage.getItem("Language"));
  const [sortBy, setSortBy] = useState(
    language == 1 ? { label: "Cantidad Por Enviar", id: 1 } : { label: "Quantity Out", id: 1 }
  );
  const [sortSelect, setSortSelect] = useState();

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);
  const navigate = useNavigate();

  const createPDF = () => {
    setDownload(false)
    const pdf = new jsPDF("portrait", "px", "a0"); //px
    const data = document.querySelector("#pdf");
    pdf.html(data).then(() => {
      pdf.save("inventory.pdf");
      setDownload(true)
    });
  };

  const handleClose = () => {
    setOpen(false);
    setSortSelect("");
  };
  
  async function getInventoryData() {
    await axios
      .get(IP + "/reports/new_products", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        setAllData(response.data.data)
        setResponseData(response.data.data);

        let columns = [
          { Header: language == 1 ? "Imagen" : "Image", accessor: "image" },
          { Header: language == 1 ? "Nombre de Producto" : "Product Name", accessor: "name" },
          { Header: language == 1 ? "Empaque" : "Package", accessor: "packaging" },
          { Header: language == 1 ? "Created" : "Creado", accessor: "created" },
        //   { Header: language == 1 ? "Vendedor" : "Vendor", accessor: "vendor" },
        //   { Header: language == 1 ? "En Inventario" : "In Stock", accessor: "stock" },
        //   { Header: language == 1 ? "Por Enviar" : "Quantity Out", accessor: "sold" },
        //   { Header: language == 1 ? "Por Recibir" : "Quantity In", accessor: "bought" },
        ];

        let rows = response.data.data
        .map((item) => {
          const created = new Date(item.created_at);
          let day = created.getDate();
          let month = created.getMonth() + 1;
          const createdDate = `${created.getFullYear()}-${month < 10 ? `0${month}` : `${month}`}-${
            day < 10 ? `0${day}` : `${day}`
          }`;
          return {
            image: <ProductCell name="" image={imageIP + item.image} />,
            name: item.name,
            packaging: item.packaging,
            created: createdDate
          };
        });

        let tableData = { columns: columns, rows: rows };
        //console.log(tableData)
        setReport(tableData);
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function sendDates() {
    await axios
      .get(
        IP + "/reports/inventory",
        {
          from: startDate,
          to: endDate,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        getInventoryData();
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  function sortSoldDescend() {
    let columns = [
      { Header: language == 1 ? "Imagen" : "Image", accessor: "image", width: "10%" },
      { Header: "UPC", accessor: "upc" },
      { Header: language == 1 ? "Nombre de Producto" : "Product Name", accessor: "name" },
      { Header: language == 1 ? "Vendedor" : "Vendor", accessor: "vendor" },
      { Header: language == 1 ? "En Inventario" : "In Stock", accessor: "stock" },
      { Header: language == 1 ? "Por Enviar" : "Quantity Out", accessor: "sold" },
      { Header: language == 1 ? "Por Recibir" : "Quantity In", accessor: "bought" },
    ];

    let rows = responseData
      .sort((a, b) => (a.out_qty < b.out_qty ? 1 : -1))
      .map((item) => {
        return {
          image: <ProductCell name="" image={imageIP + item.image} />,
          upc: item.upc,
          vendor: item.vendor.name,
          sold: item.out_qty,
          bought: item.in_qty,
          name: item.name,
          stock: item.stock,
        };
      });

    let tableData = { columns: columns, rows: rows };

    setReport(tableData);
    handleClose();
  }

  function sortSoldAscend() {
    let columns = [
      { Header: language == 1 ? "Imagen" : "Image", accessor: "image", width: "10%" },
      { Header: "UPC", accessor: "upc" },
      { Header: language == 1 ? "Nombre de Producto" : "Product Name", accessor: "name" },
      { Header: language == 1 ? "Vendedor" : "Vendor", accessor: "vendor" },
      { Header: language == 1 ? "En Inventario" : "In Stock", accessor: "stock" },
      { Header: language == 1 ? "Por Enviar" : "Quantity Out", accessor: "sold" },
      { Header: language == 1 ? "Por Recibir" : "Quantity In", accessor: "bought" },
    ];

    let rows = responseData
      .sort((a, b) => (a.out_qty > b.out_qty ? 1 : -1))
      .map((item) => {
        return {
          image: <ProductCell name="" image={imageIP + item.image} />,
          upc: item.upc,
          sold: item.out_qty,
          vendor: item.vendor.name,
          bought: item.in_qty,
          name: item.name,
          stock: item.stock,
        };
      });

    let tableData = { columns: columns, rows: rows };

    setReport(tableData);
    handleClose();
  }

  function sortBoughtAscend() {
    let columns = [
      { Header: language == 1 ? "Imagen" : "Image", accessor: "image", width: "10%" },
      { Header: "UPC", accessor: "upc" },
      { Header: language == 1 ? "Nombre de Producto" : "Product Name", accessor: "name" },
      { Header: language == 1 ? "Vendedor" : "Vendor", accessor: "vendor" },
      { Header: language == 1 ? "En Inventario" : "In Stock", accessor: "stock" },
      { Header: language == 1 ? "Por Enviar" : "Quantity Out", accessor: "sold" },
      { Header: language == 1 ? "Por Recibir" : "Quantity In", accessor: "bought" },
    ];

    let rows = responseData
      .sort((a, b) => (a.in_qty > b.in_qty ? 1 : -1))
      .map((item) => {
        return {
          image: <ProductCell name="" image={imageIP + item.image} />,
          upc: item.upc,
          sold: item.out_qty,
          vendor: item.vendor.name,
          bought: item.in_qty,
          name: item.name,
          stock: item.stock,
        };
      });

    let tableData = { columns: columns, rows: rows };

    setReport(tableData);
    handleClose();
  }

  function sortBoughtDescend() {
    let columns = [
      { Header: language == 1 ? "Imagen" : "Image", accessor: "image", width: "10%" },
      { Header: "UPC", accessor: "upc" },
      { Header: language == 1 ? "Nombre de Producto" : "Product Name", accessor: "name" },
      { Header: language == 1 ? "Vendedor" : "Vendor", accessor: "vendor" },
      { Header: language == 1 ? "En Inventario" : "In Stock", accessor: "stock" },
      { Header: language == 1 ? "Por Enviar" : "Quantity Out", accessor: "sold" },
      { Header: language == 1 ? "Por Recibir" : "Quantity In", accessor: "bought" },
    ];

    let rows = responseData
      .sort((a, b) => (a.in_qty < b.in_qty ? 1 : -1))
      .map((item) => {
        return {
          image: <ProductCell name="" image={imageIP + item.image} />,
          upc: item.upc,
          sold: item.out_qty,
          vendor: item.vendor.name,
          bought: item.in_qty,
          name: item.name,
          stock: item.stock,
        };
      });

    let excelRows = responseData.map((item) => {
      if (language == 1) {
        return {
          UPC: item.upc,
          Vendido: item.out_qty,
          Comprado: item.in_qty,
        };
      } else {
        return {
          UPC: item.upc,
          Sold: item.out_qty,
          Bought: item.in_qty,
        };
      }
    });

    setData(excelRows);

    let tableData = { columns: columns, rows: rows };

    setReport(tableData);
    handleClose();
  }

  function filterVendor(vendor) {
    if (vendor) {
      setResponseData(allData.filter((item) => {
        return item.vendor.id == vendor.id
      }))
    }
    if (!vendor) {
      setResponseData(allData)
    }
    let columns = [
      { Header: language == 1 ? "Imagen" : "Image", accessor: "image", width: "10%" },
      { Header: "UPC", accessor: "upc" },
      { Header: language == 1 ? "Nombre de Producto" : "Product Name", accessor: "name" },
      { Header: language == 1 ? "Vendedor" : "Vendor", accessor: "vendor" },
      { Header: language == 1 ? "En Inventario" : "In Stock", accessor: "stock" },
      { Header: language == 1 ? "Por Enviar" : "Quantity Out", accessor: "sold" },
      { Header: language == 1 ? "Por Recibir" : "Quantity In", accessor: "bought" },
    ];

    if (vendor) {
      let rows = allData.filter((item) => {
        return item.vendor.id == vendor.id
      }).map(item=>{
        return {
          image: <ProductCell name="" image={imageIP + item.image} />,
          upc: item.upc,
          sold: item.out_qty,
          vendor: item.vendor.name,
          bought: item.in_qty,
          name: item.name,
          stock: item.stock,
        };
      });

      let excelRows = allData.filter((item) => {
        return item.vendor.id == vendor.id
      }).map(item=>{
        if (language == 1) {
          return {
            UPC: item.upc,
            "Nombre de Producto": item.name,
            "En Almacen": item.stock,
            Vendido: item.out_qty,
            Comprado: item.in_qty,
          };
        } else {
          return {
            UPC: item.upc,
            "Product Name": item.name,
            "In Stock": item.stock,
            Sold: item.out_qty,
            Bought: item.in_qty,
          };
        }
      });

      setData(excelRows);

      let tableData = { columns: columns, rows: rows };

      setReport(tableData);
    }

    if (!vendor) {
      let rows = allData.map((item) => {
        return {
          image: <ProductCell name="" image={imageIP + item.image} />,
          upc: item.upc,
          sold: item.out_qty,
          vendor: item.vendor.name,
          bought: item.in_qty,
          name: item.name,
          stock: item.stock,
        };
      });

      let excelRows = allData.map((item) => {
        if (language == 1) {
          return {
            UPC: item.upc,
            "Nombre de Producto": item.name,
            "En Almacen": item.stock,
            Vendido: item.out_qty,
            Comprado: item.in_qty,
          };
        } else {
          return {
            UPC: item.upc,
            "Product Name": item.name,
            "In Stock": item.stock,
            Sold: item.out_qty,
            Bought: item.in_qty,
          };
        }
      });

      setData(excelRows);

      let tableData = { columns: columns, rows: rows };
      //console.log(tableData)
      setReport(tableData);
    }
  }

  useEffect(() => {
    getInventoryData();
  }, []);

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ThemeProvider theme={darkTheme}>
        <Dialog open={open}>
          <DialogTitle>{language == 1 ? "Organizar por:" : "Sort by:"}</DialogTitle>
          <DialogContent>
            <Autocomplete
              sx={{ mb: 1 }}
              disablePortal
              fullWidth
              value={sortBy}
              onChange={(event, newValue) => {
                setSortBy(newValue);
              }}
              options={options}
              renderInput={(params) => <TextField {...params} label="" variant="standard" />}
            />
            {sortBy ? (
              <FormControl>
                <RadioGroup
                  name="Sort Options"
                  onChange={(e) => {
                    if (e.target.value == "ascending") {
                      setSortSelect(e.target.value);
                    } else if (e.target.value == "descending") {
                      setSortSelect(e.target.value);
                    }
                  }}
                >
                  <FormControlLabel
                    value="descending"
                    control={<Radio />}
                    label={language == 1 ? "Mayor a menor" : "Highest to lowest"}
                  />
                  <FormControlLabel
                    value="ascending"
                    control={<Radio />}
                    label={language == 1 ? "Menor a mayor" : "Lowest to highest"}
                  />
                </RadioGroup>
              </FormControl>
            ) : null}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{language == 1 ? "Cancelar" : "Cancel"}</Button>
            <Button
              onClick={() => {
                if (sortSelect == "ascending" && sortBy.id == 1) {
                  sortSoldAscend();
                } else if (sortSelect == "descending" && sortBy.id == 1) {
                  sortSoldDescend();
                } else if (sortSelect == "ascending" && sortBy.id == 2) {
                  sortBoughtAscend();
                } else if (sortSelect == "descending" && sortBy.id == 2) {
                  sortBoughtDescend();
                }
              }}
            >
              {language == 1 ? "Organizar" : "Sort"}
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
      <MDBox my={3}>
        {!date ? (
          <MDBox py={3} mb={20} height="65vh">
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              sx={{ height: "100%", mt: 8 }}
            >
              <Grid item xs={12} lg={8}>
                <Card sx={{ height: "100%" }}>
                  <MDBox p={3}>
                    <MDBox>
                      <MDBox>
                        <MDBox lineHeight={0}>
                          <MDTypography variant="h5">
                            {language == 1 ? "Inventario" : "Inventory"}
                          </MDTypography>
                          <MDTypography variant="button" color="text">
                            {language == 1 ? "Favor entrar fechas" : "Please enter dates"}
                          </MDTypography>
                        </MDBox>
                        <MDBox id="box1" mt={2.5}>
                          <Grid id="grid1" container spacing={3}>
                            <Grid id="grid1-1" item xs={12} lg={6}>
                              <MDInput
                                value={startDate}
                                fullWidth
                                type="date"
                                onChange={(e) => setStartDate(e.target.value)}
                                variant="standard"
                                label={language == 1 ? "Desde" : "Since"}
                              />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                              <MDInput
                                value={endDate}
                                fullWidth
                                type="date"
                                onChange={(e) => setEndDate(e.target.value)}
                                variant="standard"
                                label={language == 1 ? "Hasta" : "Hasta"}
                              />
                            </Grid>
                          </Grid>
                        </MDBox>
                      </MDBox>
                      <MDBox mt={2} width="100%" display="flex" justifyContent="right">
                        <MDButton variant="gradient" color="info" onClick={() => sendDates()}>
                          {language == 1 ? "Generar Reporte" : "Generate Report"}
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        ) : (
          <>
            <MDBox my={3}>
              <Card>
                {/* <Autocomplete
                  sx={{ width: "15%", mt: 2, ml: 2 }}
                  disablePortal
                  value={selectedVendor}
                  onChange={(event, newValue) => {
                    setSelectedVendor(newValue);
                    filterVendor(newValue)
                  }}
                  options={vendors}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={language == 1 ? "Vendedor" : "Vendor"}
                      variant="standard"
                    />
                  )}
                /> */}
                <DataTable table={report} entriesPerPage={true} canSearch />
              </Card>
            </MDBox>
          </>
        )}
      </MDBox>
    </DashboardLayout>
  );
}

export default ReportNewProducts;
