import { useEffect, useState, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// NewUser page components
import UserInfo from "layouts/pages/users/new-user/components/UserInfo";
import Address from "layouts/pages/users/new-user/components/Address";
import Socials from "layouts/pages/users/new-user/components/Socials";
import Profile from "layouts/pages/users/new-user/components/Profile";
import AlertDialog from "app/components/confirmBox";
import DataTable2 from "app/components/shopperTable";

// NewUser layout schemas for form and form feilds
import validations from "layouts/pages/users/new-user/schemas/validations";
import form from "layouts/pages/users/new-user/schemas/form";
import initialValues from "layouts/pages/users/new-user/schemas/initialValues";
import MDInput from "components/MDInput";

import IP from "app/IP";
import { item } from "examples/Sidenav/styles/sidenavItem";

const axios = require("axios");

function AddShopper() {

  const current = new Date();
  let day = current.getDate()
  let month = current.getMonth() + 1;
  const currentDate = `${current.getFullYear()}-${
  month < 10 ? `0${month}` : `${month}`
  }-${day < 10 ? `0${day}` : `${day}`}`;

  const [startDate, setStartDate] = useState(currentDate);
  const [endDate, setEndDate] = useState(currentDate);
  const [displayStartDate, setDisplayStartDate] = useState(currentDate);
  const [displayEndDate, setDisplayEndDate] = useState(currentDate);
  const [data, setData] = useState()
  const [productIds, setProductIds] = useState([])
  const [shopperIds, setShopperIds] = useState([])
  const [expressIds, setExpressIds] = useState([])
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false)
  const [show3, setShow3] = useState(false)
  const [disableButton, setDisableButton] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false)
  const [first, setFirst] = useState(true)
  const [error, setError] = useState()
  const [errors, setErrors] = useState([]);
  const [shopperId, setShopperId] = useState()
  const [addId, setAddId] = useState({id: null, shopper: false, express: false})
  const [prodId, setProdId] = useState()
  const [shopper, setShopper] = useState([])
  const [products, setProducts] = useState({ columns: [], rows: [] })
  const [selectedTable, setSelectedTable] = useState({ columns: [], rows: [] })
  const [selectedProd, setSelectedProd] = useState([])
  const [index, setIndex] = useState(0)
  const [index2, setIndex2] = useState(0)
  const [language, setLanguage] = useState(localStorage.getItem("Language"));
  const toggleSnackbar = () => setShow(!show);

  const navigate = useNavigate();

  async function getProducts() {
    await axios.get(IP + '/products', { headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      Accept: "application/json",
    },
  }).then(response=> {
    setShow2(true)
    setData(response.data.data)
    let productColumns = [
      // { Header: language==1 ? "UPC Unidad" : "UPC Unit", accessor: "upcunit", width: '8%' },
      { Header: "UPC", accessor: "upc", width: '5%' },
      { Header: language==1 ? "Código" : "Code", accessor: "code", width: '8%' },
      { Header: language==1 ? "Empaque" : "Packaging", accessor: "packaging", width: '8%' },
      { Header: language==1 ? "Nombre" : "Name", accessor: "name", width: '25%' },
      { Header: language == 1 ? "Precio Regular" : "Regular Price", accessor: "price" },
      { Header: language == 1 ? "Precio Especial" : "Special Price", accessor: "special" },
      { Header: "Regular", accessor: "action", width: '10%' },
      { Header: "Express", accessor: "express", width: "10%" },
      // { Header: language==1 ? "Precio" : "Price", accessor: "price" },
    ];

    let activeProds = response.data.data.filter((item)=>{
        return item.active == "Active"
    })

     let productRows = activeProds.map((item) => {
      console.log(productIds.some(item2=>item.id==item2))
      return {
        code: item.code,
        upcunit: item.upcUnit,
        name: item.name,
        packaging: item.packaging,
        price: item.sales_price,
        special: item.sales_price == item.current_price ? '' : item.current_price,
        upc: item.upc,
        action: !productIds ? (<MDButton variant='gradient' color='info' onClick={()=>{setFirst(false), setAddId({id: item.id, shopper: true, express: false})}}>
          {language==1 ? "Añadir" : "Add"}
        </MDButton>) : (shopperIds.includes(item.id) ? (<MDButton 
          color='error' 
          variant='gradient'
          onClick={()=>{setProdId({id: item.id, shopper: false, express: true}), setShowConfirm(true)}}
        >
          {language==1 ? "Remover" : "Remove"}
        </MDButton>) 
        : (<MDButton variant='gradient' color='info' onClick={()=>{setFirst(false), setAddId({id: item.id, shopper: true, express: false})}}>
          {language==1 ? "Añadir" : "Add"}
        </MDButton>)),
        express: !productIds ? (<MDButton variant='gradient' color='info' onClick={()=>{setFirst(false),setAddId({id: item.id, shopper: false, express: true})}}>
          {language==1 ? "Añadir" : "Add"}
        </MDButton>) : (expressIds.includes(item.id) ? (<MDButton 
          color='error' 
          variant='gradient'
          onClick={()=>{setProdId({id: item.id, shopper: false, express: true}), setShowConfirm(true)}}
        >
          {language==1 ? "Remover" : "Remove"}
        </MDButton>) 
        : (<MDButton variant='gradient' color='info' onClick={()=>{setFirst(false), setAddId({id: item.id, shopper: false, express: true})}}>
          {language==1 ? "Añadir" : "Add"}
        </MDButton>))
      };
    });
  
    let tableData = { columns: productColumns, rows: productRows };
     //console.log(tableData)
    setProducts(tableData)

    let columns = [
      // { Header: language == 1 ? "UPC Unidad" : "UPC Unit", accessor: "upcunit", width: "8%" },
      { Header: "UPC", accessor: "upc", width: "5%" },
      { Header: language==1 ? "Código" : "Code", accessor: "code", width: '8%' },
      { Header: language==1 ? "Empaque" : "Packaging", accessor: "packaging", width: '8%' },
      { Header: language == 1 ? "Nombre" : "Name", accessor: "name", width: "25%" },
      { Header: language == 1 ? "Precio Regular" : "Regular Price", accessor: "price" },
      { Header: language == 1 ? "Precio Especial" : "Special Price", accessor: "special" },
      { Header: "Regular", accessor: "action", width: "10%" },
      { Header: "Express", accessor: "express", width: "10%" },
    ];

    let rows = selectedProd.map((item) => {
      return {
        code: item.code,
        packaging: item.packaging,
        upcunit: item.upcUnit,
        name: item.name,
        price: item.sales_price,
        special: item.sales_price == item.current_price ? '' : item.current_price,
        upc: item.upc,
        action: shopperIds.includes(item.id) ?
        <MDButton 
          color='error' 
          variant='gradient'
          onClick={()=>{setProdId({id: item.id, shopper: true, express: false}), setShowConfirm(true)}}
        >
          {language==1 ? "Remover" : "Remove"}
        </MDButton>
        :
        <MDButton variant='gradient' color='info' onClick={()=>{setFirst(false), setAddId({id: item.id, shopper: true, express: false})}}>
          {language==1 ? "Añadir" : "Add"}
        </MDButton>,
        express: expressIds.includes(item.id) ?
        <MDButton 
          color='error' 
          variant='gradient'
          onClick={()=>{setProdId({id: item.id, shopper: false, express: true}), setShowConfirm(true)}}
        >
          {language==1 ? "Remover" : "Remove"}
        </MDButton>
        :
        <MDButton variant='gradient' color='info' onClick={()=>{setFirst(false), setAddId({id: item.id, shopper: false, express: true})}}>
          {language==1 ? "Añadir" : "Add"}
        </MDButton>
      };
    });

    let tableData2 = { columns: columns, rows: rows }

    setSelectedTable(tableData2)
  }).catch((error)=>{
    if (!error.response.data.authenticated) {
      navigate("/signIn");
    }
    console.log(error)})
  }

  async function getProducts2(list, selectedList, shopperList, expressList) {
    await axios.get(IP + '/products', { headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      Accept: "application/json",
    },
  }).then(response=> {
    let productColumns = [
      // { Header: language==1 ? "UPC Unidad" : "UPC Unit", accessor: "upcunit", width: '8%' },
      { Header: "UPC", accessor: "upc", width: '5%' },
      { Header: language==1 ? "Código" : "Code", accessor: "code", width: '8%' },
      { Header: language==1 ? "Empaque" : "Packaging", accessor: "packaging", width: '8%' },
      { Header: language==1 ? "Nombre" : "Name", accessor: "name", width: '25%' },
      { Header: language == 1 ? "Precio Regular" : "Regular Price", accessor: "price" },
      { Header: language == 1 ? "Precio Especial" : "Special Price", accessor: "special" },
      { Header: "Regular", accessor: "action", width: '10%' },
      { Header: "Express", accessor: "express", width: "10%" },
      // { Header: language==1 ? "Precio" : "Price", accessor: "price" },
    ];

    let activeProds = response.data.data.filter((item)=>{
        return item.active == "Active"
    })

     let productRows = activeProds.map((item) => {
      return {
        code: item.code,
        packaging: item.packaging,
        upcunit: item.upcUnit,
        name: item.name,
        price: item.sales_price,
        special: item.sales_price == item.current_price ? '' : item.current_price,
        upc: item.upc,
        action: !list ? (<MDButton variant='gradient' color='info' onClick={()=>{setFirst(false), setAddId({id: item.id, shopper: true, express: false})}}>
          {language==1 ? "Añadir" : "Add"}
        </MDButton>) : (shopperList.includes(item.id) ? (<MDButton 
          color='error' 
          variant='gradient'
          onClick={()=>{setProdId({id: item.id, shopper: true, express: false}), setShowConfirm(true)}}
        >
          {language==1 ? "Remover" : "Remove"}
        </MDButton>) 
        : (<MDButton variant='gradient' color='info' onClick={()=>{setFirst(false), setAddId({id: item.id, shopper: true, express: false})}}>
          {language==1 ? "Añadir" : "Add"}
        </MDButton>)),
        express: !list ? (<MDButton variant='gradient' color='info' onClick={()=>{setFirst(false),setAddId({id: item.id, shopper: false, express: true})}}>
          {language==1 ? "Añadir" : "Add"}
        </MDButton>) : (expressList.includes(item.id) ? (<MDButton 
          color='error' 
          variant='gradient'
          onClick={()=>{setProdId({id: item.id, shopper: false, express: true}), setShowConfirm(true)}}
        >
          {language==1 ? "Remover" : "Remove"}
        </MDButton>) 
        : (<MDButton variant='gradient' color='info' onClick={()=>{setFirst(false), setAddId({id: item.id, shopper: false, express: true})}}>
          {language==1 ? "Añadir" : "Add"}
        </MDButton>))
      };
    });
  
    let tableData = { columns: productColumns, rows: productRows };
     //console.log(tableData)
    setProducts(tableData)

    let columns = [
      // { Header: language == 1 ? "UPC Unidad" : "UPC Unit", accessor: "upcunit", width: "8%" },
      { Header: "UPC", accessor: "upc", width: "5%" },
      { Header: language==1 ? "Código" : "Code", accessor: "code", width: '8%' },
      { Header: language==1 ? "Empaque" : "Packaging", accessor: "packaging", width: '8%' },
      { Header: language == 1 ? "Nombre" : "Name", accessor: "name", width: "25%" },
      { Header: language == 1 ? "Precio Regular" : "Regular Price", accessor: "price" },
      { Header: language == 1 ? "Precio Especial" : "Special Price", accessor: "special" },
      { Header: "Regular", accessor: "action", width: "10%" },
      { Header: "Express", accessor: "express", width: "10%" },
    ];

    let rows = selectedList.map((item) => {
      return {
        code: item.code,
        packaging: item.packaging,
        upcunit: item.upcUnit,
        name: item.name,
        upc: item.upc,
        price: item.sales_price,
        special: item.sales_price == item.current_price ? '' : item.current_price,
        action: shopperList.includes(item.id) ?
        <MDButton 
          color='error' 
          variant='gradient'
          onClick={()=>{setProdId({id: item.id, shopper: true, express: false}), setShowConfirm(true)}}
        >
          {language==1 ? "Remover" : "Remove"}
        </MDButton> 
        :
        <MDButton variant='gradient' color='info' onClick={()=>{setFirst(false), setAddId({id: item.id, shopper: true, express: false})}}>
          {language==1 ? "Añadir" : "Add"}
        </MDButton>,
        express: expressList.includes(item.id) ?
        <MDButton 
          color='error' 
          variant='gradient'
          onClick={()=>{setProdId({id: item.id, shopper: false, express: true}), setShowConfirm(true)}}
        >
          {language==1 ? "Remover" : "Remove"}
        </MDButton>
        : 
        <MDButton variant='gradient' color='info' onClick={()=>{setFirst(false), setAddId({id: item.id, shopper: false, express: true})}}>
          {language==1 ? "Añadir" : "Add"}
        </MDButton>
      };
    });

    let tableData2 = { columns: columns, rows: rows }

    setSelectedTable(tableData2)
  }).catch((error)=>{
    if (!error.response.data.authenticated) {
      navigate("/signIn");
    }
    console.log(error)})
  }

  async function sendShopper() {
    setDisableButton(true);
    if (productIds.length == 0) { 
      await axios
        .post(
          IP + "/shopper",
          {
            startDate: startDate,
            endDate: endDate,
            displayStartDate: displayStartDate,
            displayEndDate: displayEndDate,
            active: 1
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          console.log(response.data.data.id);
          setShow3(true)
          setShopperId(response.data.data.id);
          getProducts();
          console.log(response);
          toggleSnackbar();
          setError()
        })
        .catch((error) => {
          if (error.response.data.data){
            setErrors(error.response.data.data);
            setError()
          }
          if (!error.response.data.authenticated) {
            navigate("/signIn");
          }
          setError(error.response.data);
          console.log(error);
        });
    } else {
      addDiscount()
      await axios
        .put(
          IP + "/shopper/" + shopperId,
          {
            startDate: startDate,
            endDate: endDate,
            active: 1
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          console.log(response);
           toggleSnackbar();
        })
        .catch((error) => {
          setErrors(error.response.data.data);
          if (!error.response.data.authenticated) {
            navigate("/signIn");
          }
          console.log(error);
        });

      await axios
        .post(
          IP + "/shopper/" + shopperId + "/shopper_details",
          {
            products: shopper,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          navigate('/shopper')
        })
        .catch((error) => {
          setErrors(error.response.data.data);
          if (!error.response.data.authenticated) {
            navigate("/signIn");
          }
          console.log(error);
        });
    }

    setTimeout(() => {
      setDisableButton(false);
    }, 3000);
  }

  function changeTable() {
    if (show2) {
      setShow2(false)
    }
    else{
      setShow2(true)
    }
  }

  function addDiscount() {
    productIds.forEach(item=>{
      shopper.push({
        product_id: item,
        shopper_id: shopperId,
        shopper: shopperIds.includes(item),
        express: expressIds.includes(item)
      }
      )
    })
    console.log(shopper)
  }

  function addProduct({id, shopper, express}) {
    if (!first) {
      let productInfo = data.filter(prod=>{
        return prod.id == id
      })[0]
      if (shopper && !shopperIds.includes(id))
        shopperIds.push(id)
      if (express && !expressIds.includes(id))
        expressIds.push(id)
      if (!productIds.includes(id))
        productIds.push(id)
      if (!selectedProd.includes(productInfo))
        selectedProd.push(productInfo)
      console.log(productIds)
      getProducts2(productIds, selectedProd, shopperIds, expressIds)
    }
  }
 
  function removeDiscount({id, shopper, express}) {
    // console.log(productIds)
    let filteredShopper = shopperIds
    let filteredExpress = expressIds
    let filteredArray = productIds
    let filteredSelected = selectedProd
    if (shopper)
      filteredShopper = shopperIds.filter(item=>{
        return item != id
      })
    if (express)
      filteredExpress = expressIds.filter(item=>{
        return item != id
      })
    if (!filteredExpress.includes(id) && !filteredShopper.includes(id)) {
      filteredArray = productIds.filter(item=>{
        return item != id
      })
      filteredSelected = selectedProd.filter(item=>{
        return item.id !== id
      })
    }
    // console.log('result')
    getProducts2(filteredArray, filteredSelected, filteredShopper, filteredExpress)
    setProductIds(filteredArray)
    setSelectedProd(filteredSelected)
    setShopperIds(filteredShopper)
    setExpressIds(filteredExpress)
  }

  function TestValidation(message) {
    if (message)
      return message.map((item) => {
        return (
          <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
            <label>{item}</label>
          </MDTypography>
        );
      });
    else return null;
  }

  function ShowError(param) {
    // console.log(errors["name"]);
    if (param.id) return TestValidation(errors[param.id]);
    else return null;
  }

  useEffect(()=>{
    addProduct(addId)
  }, [addId])

  useEffect(()=>{
    console.log('test')
    setFirst(true)
  }, [])

  return (
    <DashboardLayout>
      <MDSnackbar
        color="info"
        icon="notifications"
        title=""
        content={language == 1 ? "El shopper fue creado exitosamente!" : "Shopper Created Succesfully!"}
        dateTime={language==1 ? "Ahora" : "Now"}
        open={show}
        close={toggleSnackbar}
      />
      <AlertDialog
        Show={showConfirm}
        setShowConfirm={setShowConfirm}
        Message={language==1 
        ? "¿Estás seguro de que quieres remover este producto?" 
        : "Are you sure you want to remove this product?"
      }
        OkayFunction={()=>removeDiscount(prodId)}
      />
      <DashboardNavbar />
      <MDBox py={3} mb={20}>
        <Grid container sx={{ height: "100%"}}>
          <Grid item xs={12} lg={12}>
            <Card sx={{ height: "100%" }}>
              <MDBox p={3}>
                <MDBox lineHeight={0}>
                  <MDTypography variant="h4">
                    {language == 1 ? "Crear Shopper" : "Create Shopper"}
                  </MDTypography>
                  <MDTypography variant="button" color="text">
                    {language == 1
                      ? "Favor de entrar la información del shopper."
                      : "Please enter shopper information."
                    }
                  </MDTypography>
                </MDBox>
                <MDBox mt={1.625}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={3} lg={3}>
                      <MDInput
                        type="date"
                        label={language == 1 ? "Desde" : "Since"}
                        variant="standard"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        fullWidth
                      />
                      {error?.message == "dates" && <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                        <label>{error.data}</label>
                      </MDTypography>}
                      <ShowError id={'startDate'} />
                    </Grid>
                    <Grid item xs={12} md={3} lg={3}>
                      <MDInput
                        type="date"
                        label={language == 1 ? "Hasta" : "Until"}
                        variant="standard"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        fullWidth
                      />
                      <ShowError id={'endDate'} />
                    </Grid>
                  <Grid item xs={12} md={3} lg={3}>
                      <MDInput
                        type="date"
                        label={language == 1 ? "Desde (Vigencia)" : "Since (Display)"}
                        variant="standard"
                        value={displayStartDate}
                        onChange={(e) => setDisplayStartDate(e.target.value)}
                        fullWidth
                      />
                      {error?.message == "display" && <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                        <label>{error.data}</label>
                      </MDTypography>}
                      <ShowError id={'displayStartDate'} />
                    </Grid>
                    <Grid item xs={12} md={3} lg={3}>
                      <MDInput
                        type="date"
                        label={language == 1 ? "Hasta (Vigencia)" : "Until (Display)"}
                        variant="standard"
                        value={displayEndDate}
                        onChange={(e) => setDisplayEndDate(e.target.value)}
                        fullWidth
                      />
                      <ShowError id={'displayEndDate'} />
                    </Grid>
                  </Grid>
                  {show3 ? show2 ? 
                  <>
                  {productIds.length!==0 ?
                  <MDBox mt={2} mb={-8} width="100%" display="flex" justifyContent="space-between">
                    <MDButton variant="gradient" color='info' onClick={changeTable}>
                      {language==1 ? "Productos Seleccionados" : "Selected Products"}
                    </MDButton>
                  </MDBox> : null
                  }
                  <DataTable2 table={products} index={index} setIndex={setIndex} pageEntries={10} entriesPerPage={false} canSearch />
                  </> 
                  : 
                  <>
                  <MDBox mt={2} mb={-8} width="100%" display="flex" justifyContent="space-between">
                    <MDButton variant="gradient" color='info' onClick={changeTable}>
                      {language==1 ? "Seleccionar Productos" : "Select Products"}
                    </MDButton>
                  </MDBox>
                  <DataTable2 table={selectedTable} index={index2} setIndex={setIndex2} pageEntries={10} entriesPerPage={false} canSearch />
                  </> : null
                  }
                    <Grid item xs={12} lg={12}>
                    <MDBox mt={2} width="100%" display="flex" justifyContent="space-between">
                      <MDButton
                        variant="gradient"
                        color="light"
                        onClick={()=>navigate('/shopper')}
                      >
                        {language == 1 ? "Cancelar" : "Cancel"}
                      </MDButton>
                      <MDButton
                        variant="gradient"
                        color="info"
                        onClick={sendShopper}
                        disabled={disableButton}
                      >
                        {language == 1 ? "Guardar" : "Save"}
                      </MDButton>
                    </MDBox>
                  </Grid>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default AddShopper;
