/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import MDButton from "components/MDButton";

import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";

// Overview page components
import Header from "layouts/pages/profile/components/Header";
import PlatformSettings from "layouts/pages/profile/profile-overview/components/PlatformSettings";

// Data
import profilesListData from "layouts/pages/profile/profile-overview/data/profilesListData";

// Images
import homeDecor1 from "assets/images/home-decor-1.jpg";
import homeDecor2 from "assets/images/home-decor-2.jpg";
import homeDecor3 from "assets/images/home-decor-3.jpg";
import homeDecor4 from "assets/images/home-decor-4.jpeg";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import { useNavigate, Navigate } from "react-router-dom";

import DataTable from "examples/Tables/DataTable";

// Data
import dataTableData from "layouts/applications/data-tables/data/dataTableData";

import AlertDialog from "app/components/confirmBox";
import MDSnackbar from "components/MDSnackbar";
import DataTable2 from "app/components/shopperTable";

import IP from "../IP";

const axios = require("axios");

function ViewUsers() {
  const [data, setData] = useState({ columns: [], rows: [] });
  const [index, setIndex] = useState(0);
  const [redirect, setRedirect] = useState(false);
  const [active, setActive] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [userToDelName, setUserToDelName] = useState(false);
  const [show, setShow] = useState(false);
  const toggleSnackbar = () => setShow(!show);
  const [language, setLanguage] = useState(localStorage.getItem("Language"));

  const navigate = useNavigate();

  async function changeStatus(id) {
    await axios
      .post(
        IP + "/user/setActive/" + id,
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        toggleSnackbar();
        GetUsers();
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function GetUsers() {
    //console.log("aqui");
    //setRedirect(true);
    await axios
      .get(IP + "/users", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          XDEBUG_SESSION_START: "PHPSTORM",
        },
      })
      .then(function (response) {
        console.log(response.data.data);

        let storeColums = [
          { Header: language == 1 ? "Nombre de Usuario" : "User Name", accessor: "username" },
          { Header: language == 1 ? "Nombre Completo" : "Full Name", accessor: "name" },
          { Header: "Email", accessor: "email" },
          { Header: language == 1 ? "Rol" : "Role", accessor: "role" },
          { Header: language == 1 ? "Activo" : "Active", accessor: "active" },
          { Header: language == 1 ? "Acción" : "Action", accessor: "action", width: "25%" },
        ];
        let storeRows = response.data.data.map((item) => {
          //  console.log(item);
          return {
            username: item.username,
            name: item.name,
            email: item.email,
            role: item.role.name,
            active: item.active == "Active" ? 
              language==1 ? "Activo" : "Active" :
              language==1 ? "Inactivo" : "Inactive",
            action: (
              <Grid>
                <Tooltip title={language == 1 ? "Editar" : "Edit"}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={() => {
                      EditUser(item.id);
                    }}
                    iconOnly
                  >
                    <Icon>edit</Icon>
                  </MDButton>
                </Tooltip>{" "}
                {item.active == "Inactive" ? (
                  <Tooltip title={language == 1 ? "Activar" : "Activate"}>
                    <MDButton
                      variant="gradient"
                      color="success"
                      onClick={() => {
                        changeStatus(item.id);
                        setActive(false);
                      }}
                      iconOnly
                    >
                      <Icon>checkmark</Icon>
                    </MDButton>
                  </Tooltip>
                ) : (
                  <Tooltip title={language == 1 ? "Desactivar" : "Deactivate"}>
                    <MDButton
                      variant="gradient"
                      color="warning"
                      onClick={() => {
                        changeStatus(item.id);
                        setActive(true);
                      }}
                      iconOnly
                    >
                      <Icon>do_not_disturb</Icon>
                    </MDButton>
                  </Tooltip>
                )}
                {"  "}
                <Tooltip title={language == 1 ? "Borrar" : "Delete"}>
                  <MDButton
                    variant="gradient"
                    color="error"
                    onClick={() => {
                      console.log("this");
                      setShowConfirm(true);
                      setUserToDelName(item.name);
                      localStorage.setItem("ustodel", item.id);
                    }}
                    iconOnly
                  >
                    <Icon>delete</Icon>
                  </MDButton>
                </Tooltip>
              </Grid>
            ),
          };
        });

        var tableData = { columns: storeColums, rows: storeRows };

        //console.log(tableData);

        setData(tableData);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function EditUser(userid) {
    localStorage.setItem("UsId", userid);
    setRedirect(true);
    //console.log("hello");
  }

  async function DeleteUser() {
    await axios
      .delete(IP + "/users/" + localStorage.getItem("ustodel"), {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        localStorage.removeItem("ustodel");
        GetUsers();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    GetUsers();
  }, []);

  return (
    <DashboardLayout>
      {redirect && <Navigate push={true} to="/editUser" />}
      <AlertDialog
        Show={showConfirm}
        setShowConfirm={setShowConfirm}
        Message={
          language == 1
            ? "¿Estás seguro que quieres borrar el usuario ''" + userToDelName + "''? "
            : "Are you sure you want to delete the User ''" + userToDelName + "'' "
        }
        OkayFunction={DeleteUser}
      />
      <MDSnackbar
        color="info"
        icon="notifications"
        title=""
        content={
          language == 1
            ? `Usuario ${active ? "Desactivado" : "Activado"} Exitosamente!`
            : `User ${active ? "Deactivated" : "Activated"} Succesfully!`
        }
        dateTime={language==1 ? "Ahora" : "Now"}
        open={show}
        close={toggleSnackbar}
      />
      <DashboardNavbar />
      <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
        <MDTypography variant="h5" fontWeight="medium">
          <MDButton
            variant="gradient"
            color="info"
            onClick={() => {
              navigate("/emos/createUsers");
            }}
          >
            {language == 1 ? "Crear Usuario" : "Create User"}
          </MDButton>
        </MDTypography>
      </MDBox>
      <Card>
        <DataTable2
          table={data}
          index={index}
          setIndex={setIndex}
          pageEntries={10}
          entriesPerPage={true}
          canSearch
        />
      </Card>
    </DashboardLayout>
  );
}

export default ViewUsers;
