/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import AlertDialog from "app/components/confirmBox";
import ProductCell from "layouts/ecommerce/products/product-page/components/ProductCell";
import { ExportToExcel } from "app/components/exportToExcel";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import IP from "app/IP";
import { item } from "examples/Sidenav/styles/sidenavItem";
import jsPDF from "jspdf";


const axios = require("axios");

function ReportExpirationList() {
  const current = new Date();
  let day = current.getDate();
  let month = current.getMonth() + 1;
  const currentDate = `${current.getFullYear()}-${month < 10 ? `0${month}` : `${month}`}-${
    day < 10 ? `0${day}` : `${day}`
  }`;

  const [menu, setMenu] = useState(null);
  const [open, setOpen] = useState(false)
  const [open2, setOpen2] = useState(false)
  const [sortSelect, setSortSelect] = useState()
  const [report, setReport] = useState({ columns: [], rows: [] });
  const [data, setData] = useState();
  const [errors, setErrors] = useState([])
  const [vendors, setVendors] = useState([])
  const [selectedVendor, setSelectedVendor] = useState()
  const [dateFrom, setDateFrom] = useState(currentDate);
  const [dateTo, setDateTo] = useState(currentDate);
  const [allData, setAllData] = useState([])
  const [responseData, setResponseData] = useState([]);
  const [download, setDownload] = useState(true)
  const [language, setLanguage] = useState(localStorage.getItem("Language"));

  const handleClose = () => {
    setOpen(false);
  };

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);
  const navigate = useNavigate();

  const createPDF = () => {
    setDownload(false)
    const pdf = new jsPDF("portrait", "px", "a0"); //px
    const data = document.querySelector("#pdf");
    pdf.html(data).then(() => {
      pdf.save("expirations.pdf");
      setDownload(true)
    });
  };

  async function GetVendors() {
    // console.log("aqui");
    //setRedirect(true);
    await axios
      .get(IP + "/vendors", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(function (response) {
        //console.log(response.data.data);
        let vendor = [];

        response.data.data.map((item) => {
          if (item.active == "Active") {
            vendor.push({ label: item.name, id: item.id });
          }

          setVendors(vendor);
        });
      })
      .catch(function (error) {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function getExpirationData() {
    await axios
      .get(IP + "/showExpireProducts?startDate=" + dateFrom + "&endDate=" + dateTo, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        setResponseData(response.data.data);
        console.log(response.data.data);
        let columns = [
          { Header: language == 1 ? "Vendedor" : "Vendor", accessor: 'vendor' },
          { Header: language == 1 ? "Nombre" : "Name", accessor: "name" },
          { Header: language == 1 ? "Empaque" : "Packaging", accessor: "packaging" },
          { Header: "UPC", accessor: 'upc' },
          { Header: language == 1 ? "Fecha Expiración" : "Expiration Date", accessor: "date" },
          { Header: language == 1 ? "Cantidad" : "Quantity", accessor: "quantity" },
          { Header: language == 1 ? "Aceptado Por" : "Accepted By", accessor: "user" },
        ];

        let rows = response.data.data.map((item) => {
          return {
            vendor: item.vendor.name,
            quantity: item.inventory_available,
            packaging: item.product.packaging,
            upc: item.product.upc,
            name: item.product.name,
            user: item.user.name,
            date: item.date,
          };
        });

        let excelRows = response.data.data.map((item) => {
          if (language == 1) {
            return {
              Vendor: item.vendor.name,
              Nombre: item.product.name,
              Empaque: item.product.packaging,
              UPC: item.product.upc,
              Fecha: item.date,
              Cantidad: item.inventory_available,
              "Aceptado Por": item.user.name
            };
          } else {
            return {
              Vendor: item.vendor.name,
              Name: item.product.name,
              Packaging: item.product.packaging,
              UPC: item.product.upc,
              Date: item.date,
              Quantity: item.inventory_available,
              "Accepted By": item.user.name
            };
          }
        });

        setData(excelRows);

        let tableData = { columns: columns, rows: rows };
        //console.log(tableData)
        setReport(tableData);
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
      setOpen2(false)
  }

  function sortQuantityAscend() {
    let columns = [
      { Header: language == 1 ? "Vendedor" : "Vendor", accessor: 'vendor' },
      { Header: language == 1 ? "Nombre" : "Name", accessor: "name" },
      { Header: language == 1 ? "Empaque" : "Packaging", accessor: "packaging" },
      { Header: "UPC", accessor: 'upc' },
      { Header: language == 1 ? "Fecha Expiración" : "Expiration Date", accessor: "date" },
      { Header: language == 1 ? "Cantidad" : "Quantity", accessor: "quantity" },
      { Header: language == 1 ? "Aceptado Por" : "Accepted By", accessor: "user" },
    ];

    let rows = responseData
      .sort((a, b) => (a.inventory_available > b.inventory_available ? 1 : -1))
      .map((item) => {
        return {
          vendor: item.vendor.name,
          quantity: item.inventory_available,
          packaging: item.product.packaging,
          upc: item.product.upc,
          name: item.product.name,
          user: item.user.name,
          date: item.date,
        };
      });

    let excelRows = responseData
      .sort((a, b) => (a.inventory_available > b.inventory_available ? 1 : -1))
      .map((item) => {
        if (language == 1) {
          return {
            Vendor: item.vendor.name,
            Nombre: item.product.name,
            Empaque: item.product.packaging,
            UPC: item.product.upc,
            Fecha: item.date,
            Cantidad: item.inventory_available,
            "Aceptado Por": item.user.name
          };
        } else {
          return {
            Vendor: item.vendor.name,
            Name: item.product.name,
            Packaging: item.product.packaging,
            UPC: item.product.upc,
            Date: item.date,
            Quantity: item.inventory_available,
            "Accepted By": item.user.name
          };
        }
      });

    setData(excelRows);

    let tableData = { columns: columns, rows: rows };
    //console.log(tableData)
    setReport(tableData);
  }

  function sortQuantityDescend() {
    let columns = [
      { Header: language == 1 ? "Vendedor" : "Vendor", accessor: 'vendor' },
      { Header: language == 1 ? "Nombre" : "Name", accessor: "name" },
      { Header: language == 1 ? "Empaque" : "Packaging", accessor: "packaging" },
      { Header: "UPC", accessor: 'upc' },
      { Header: language == 1 ? "Fecha Expiración" : "Expiration Date", accessor: "date" },
      { Header: language == 1 ? "Cantidad" : "Quantity", accessor: "quantity" },
      { Header: language == 1 ? "Aceptado Por" : "Accepted By", accessor: "user" },
    ];

    let rows = responseData
      .sort((a, b) => (a.inventory_available < b.inventory_available ? 1 : -1))
      .map((item) => {
        return {
          vendor: item.vendor.name,
          quantity: item.inventory_available,
          packaging: item.product.packaging,
          upc: item.product.upc,
          name: item.product.name,
          user: item.user.name,
          date: item.date,
        };
      });

    let excelRows = responseData
      .sort((a, b) => (a.inventory_available < b.inventory_available ? 1 : -1))
      .map((item) => {
        if (language == 1) {
          return {
            Vendor: item.vendor.name,
            Nombre: item.product.name,
            Empaque: item.product.packaging,
            UPC: item.product.upc,
            Fecha: item.date,
            Cantidad: item.inventory_available,
            "Aceptado Por": item.user.name
          };
        } else {
          return {
            Vendor: item.vendor.name,
            Name: item.product.name,
            Packaging: item.product.packaging,
            UPC: item.product.upc,
            Date: item.date,
            Quantity: item.inventory_available,
            "Accepted By": item.user.name
          };
        }
      });

    setData(excelRows);

    let tableData = { columns: columns, rows: rows };
    //console.log(tableData)
    setReport(tableData);
  }

  function filterVendor(vendor) {
    if (vendor?.id) {
      let columns = [
        { Header: language == 1 ? "Vendedor" : "Vendor", accessor: 'vendor' },
        { Header: language == 1 ? "Nombre" : "Name", accessor: "name" },
        { Header: language == 1 ? "Empaque" : "Packaging", accessor: "packaging" },
        { Header: "UPC", accessor: 'upc' },
        { Header: language == 1 ? "Fecha Expiración" : "Expiration Date", accessor: "date" },
        { Header: language == 1 ? "Cantidad" : "Quantity", accessor: "quantity" },
        { Header: language == 1 ? "Aceptado Por" : "Accepted By", accessor: "user" },
      ];

      let rows = responseData
      .filter(item=>{return item.vendor.id == vendor?.id})
      .map((item) => {
        return {
          vendor: item.vendor.name,
          quantity: item.inventory_available,
          packaging: item.product.packaging,
          upc: item.product.upc,
          name: item.product.name,
          user: item.user.name,
          date: item.date,
        };
      });

      let excelRows = responseData
      .filter(item=>{return item.vendor.id == vendor?.id})
      .map((item) => {
        if (language == 1) {
          return {
            Vendor: item.vendor.name,
            Nombre: item.product.name,
            Empaque: item.product.packaging,
            UPC: item.product.upc,
            Fecha: item.date,
            Cantidad: item.inventory_available,
            "Aceptado Por": item.user.name
          };
        } else {
          return {
            Vendor: item.vendor.name,
            Name: item.product.name,
            Packaging: item.product.packaging,
            UPC: item.product.upc,
            Date: item.date,
            Quantity: item.inventory_available,
            "Accepted By": item.user.name
          };
        }
      });

      setData(excelRows);

      let tableData = { columns: columns, rows: rows };

      setReport(tableData);
    } else {
      let columns = [
        { Header: language == 1 ? "Vendedor" : "Vendor", accessor: 'vendor' },
        { Header: language == 1 ? "Nombre" : "Name", accessor: "name" },
        { Header: language == 1 ? "Empaque" : "Packaging", accessor: "packaging" },
        { Header: "UPC", accessor: 'upc' },
        { Header: language == 1 ? "Fecha Expiración" : "Expiration Date", accessor: "date" },
        { Header: language == 1 ? "Cantidad" : "Quantity", accessor: "quantity" },
        { Header: language == 1 ? "Aceptado Por" : "Accepted By", accessor: "user" },
      ];

      let rows = responseData
      .map((item) => {
        return {
          vendor: item.vendor.name,
          quantity: item.inventory_available,
          packaging: item.product.packaging,
          user: item.user.name,
          upc: item.product.upc,
          name: item.product.name,
          date: item.date,
        };
      });

      let excelRows = responseData
      .map((item) => {
        if (language == 1) {
          return {
            Vendor: item.vendor.name,
            Nombre: item.product.name,
            Empaque: item.product.packaging,
            UPC: item.product.upc,
            Fecha: item.date,
            Cantidad: item.inventory_available,
            "Aceptado Por": item.user.name
          };
        } else {
          return {
            Vendor: item.vendor.name,
            Name: item.product.name,
            Packaging: item.product.packaging,
            UPC: item.product.upc,
            Date: item.date,
            Quantity: item.inventory_available,
            "Accepted By": item.user.name
          };
        }
      });

      setData(excelRows);

      let tableData = { columns: columns, rows: rows };

      setReport(tableData);
    }
  }

  function TestValidation(message) {
    if (message)
      return message.map((item) => {
        return (
          <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
            <label>{item}</label>
          </MDTypography>
        );
      });
    else return null;
  }

  function ShowError(param) {
    // console.log(errors["name"]);
    if (param.id) return TestValidation(errors[param.id]);
    else return null;
  }

  useEffect(() => {
    //getExpirationData();
    GetVendors()
  }, []);

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ThemeProvider theme={darkTheme}>
        <Dialog open={open}>
          <DialogTitle>{language == 1 ? "Organizar por:" : "Sort by:"}</DialogTitle>
          <DialogContent>
            <MDTypography variant="body1">{language == 1 ? "Cantidad" : "Quantity"}</MDTypography>
            <FormControl>
              <RadioGroup
                name="Sort Options"
                onChange={(e) => {
                  if (e.target.value == "ascending") {
                    setSortSelect(e.target.value);
                  } else if (e.target.value == "descending") {
                    setSortSelect(e.target.value);
                  }
                }}
              >
                <FormControlLabel
                  value="descending"
                  control={<Radio />}
                  label={language == 1 ? "Mayor a menor" : "Highest to lowest"}
                />
                <FormControlLabel
                  value="ascending"
                  control={<Radio />}
                  label={language == 1 ? "Menor a mayor" : "Lowest to highest"}
                />
              </RadioGroup>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{language == 1 ? "Cancelar" : "Cancel"}</Button>
            <Button
              onClick={() => {
                if (sortSelect == "ascending") {
                  sortQuantityAscend();
                } else if (sortSelect == "descending") {
                  sortQuantityDescend();
                }
              }}
            >
              {language == 1 ? "Organizar" : "Sort"}
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
      <MDBox my={3}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={4}>
          <MDBox display="flex">
            <MDBox ml={1}>
              <ExportToExcel apiData={data} fileName="Expirations" />
            </MDBox>
            <MDBox ml={1}>
            <MDButton variant="outlined" color="dark" onClick={() => setOpen(true)}>
              {language == 1 ? "Organizar" : "Sort"}&nbsp;
              <Icon>keyboard_arrow_down</Icon>
            </MDButton>
            </MDBox>
            <MDBox ml={1}>
              <MDButton variant="outlined" color="dark" onClick={createPDF}>
                {language == 1 ? "Descargar PDF" : "Download PDF"}
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
        <Card>
          <MDBox p={3}>
            <MDTypography variant='h4' sx={{mb: 1}}>{language == 1 ? "Busqueda" : "Search"}</MDTypography>
            <Grid container spacing={1}>
              <Grid id="grid1-1" item xs={12} sm={2}>
                <MDInput
                  variant="standard"
                  type="date"
                  label={language == 1 ? "Desde" : "From Date"}
                  fullWidth
                  value={dateFrom}
                  onChange={(e) => {
                    setDateFrom(e.target.value);
                  }}
                />
                <ShowError id={"startDate"} />
              </Grid>
              <Grid id="grid1-1" item xs={12} sm={2}>
                <MDInput
                  variant="standard"
                  type="date"
                  label={language == 1 ? "Hasta" : "To Date"}
                  fullWidth
                  value={dateTo}
                  onChange={(e) => {
                    setDateTo(e.target.value);
                  }}
                />
                <ShowError id={"endDate"} />
              </Grid>
              <Grid id="grid1-1" item xs={12} sm={2}>
                <MDButton
                  type="button"
                  variant="gradient"
                  className="float-sm-right"
                  color="info"
                  onClick={getExpirationData}
                >
                  {language == 1 ? "Busqueda" : "Search"}
                </MDButton>
              </Grid>
            </Grid>
            {responseData.length > 0 ? <>
              <Autocomplete
                sx={{ width: "15%", mt: 2, ml: 2 }}
                disablePortal
                value={selectedVendor}
                onChange={(event, newValue) => {
                  setSelectedVendor(newValue);
                  filterVendor(newValue)
                }}
                options={vendors}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={language == 1 ? "Vendedor" : "Vendor"}
                    variant="standard"
                  />
                )}
              />
            </> : null}
            <DataTable table={report} entriesPerPage={true} canSearch />
          </MDBox>
        </Card>
        <div hidden={download}>
        <MDBox mt={50}>
        <div id="pdf">
          <h3>Sales by Store Report</h3>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>{language==1 ? "Vendedor" : "Vendor"}</th>
                <th>{language==1 ? "Nombre" : "Name"}</th>
                <th>{language==1 ? "Empaque" : "Packaging"}</th>
                <th>UPC</th>
                <th>{language==1 ? "Fecha" : "Date"}</th>
                <th>{language==1 ? "Cantidad" : "Quantity"}</th>
                <th>{language==1 ? "Aceptado Por" : "Accepted By"}</th>
              </tr>
            </thead>
            <tbody>
               {responseData.map(item=>{
                  return <tr>
                    <td>{item.vendor.name}</td>
                    <td>{item.product.name}</td>
                    <td>{item.product.packaging}</td>
                    <td>{item.product.upc}</td>
                    <td>{item.date}</td>
                    <td>{item.quantity_received}</td>
                    <td>{item.user.name}</td>
                  </tr>
               })} 
            </tbody>
          </table>
        </div>
        </MDBox>
        </div>
      </MDBox>
    </DashboardLayout>
  );
}

export default ReportExpirationList;
