/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import AlertDialog from "app/components/confirmBox";
import DataTable2 from "app/components/shopperTable";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import IP from "app/IP";
import { Checkbox, Tooltip } from "@mui/material";
import ProductCell from "layouts/ecommerce/products/product-page/components/ProductCell";
import imageIP from "app/imageIP";

const axios = require("axios");

function ImageCopy() {
  const [products, setProducts] = useState([])
  const [productData, setProductData] = useState({ columns: [], rows: [] });
  const [selectedProductNoImage, setSelectedProductNoImage] = useState(0) //contains the id of the selected product without an image
  const [selectedProductImage, setSelectedProductImage] = useState(0) //contains the id of the selected product with an image
  const [selectedImage, setSelectedImage] = useState("") //contains the image path of the selected product with image
  const [index, setIndex] = useState(0);
  const [show, setShow] = useState(false);
  const [language, setLanguage] = useState(localStorage.getItem("Language"));

  const navigate = useNavigate();
  const toggleSnackbar = () => setShow(!show);

  async function copyImage() {
    await axios
      .post(IP + "/copyImage", {noImageProductId: selectedProductNoImage, imageProductId: selectedProductImage}, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      }).then(response=>{
        console.log(response)
        toggleSnackbar()
        setProducts([])
        setSelectedImage("")
        setSelectedProductImage(0)
        setSelectedProductNoImage(0)
      }).catch((error) => {
        if (!error?.response?.data?.authenticated) {
          //navigate("/signIn");
        }
        console.log(error);
      });
  }
  async function getProductData() {
    console.log("DATA")
    await axios
      .get(IP + "/products?all_products=true", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        setProducts(response.data.data)
        let productColumns = [
        //   { Header: language == 1 ? "Código" : "Code", accessor: "code", width: "15%" },
        //   { Header: language == 1 ? "UPC Unidad" : "UPC Unit", accessor: "upcunit", width: "15%" },
        //   { Header: "UPC", accessor: "upc", width: "15%" },
          { Header: language == 1 ? "Acción" : "Action", accessor: "action", width: "20%"},
          { Header: language == 1 ? "Nombre" : "Name", accessor: "name" },
          { Header: language == 1 ? "Empaque" : "Package", accessor: "package"},
        //   { Header: language == 1 ? "Disponible" : "Available", accessor: "available" },
        //   { Header: language == 1 ? "Costo" : "Cost", accessor: "cost" },
        //   { Header: language == 1 ? "Precio de Venta" : "Sales Price", accessor: "price" },
        ];

        let productRows = response.data.data.map((item) => {
          //console.log(item);
          return {
            image: item.image,
            // code: item.code,
            // upcunit: item.upcUnit,
            // upc: item.upc,
            action: <Checkbox
              checked={item.image == null ? selectedProductNoImage == item.id : selectedProductImage == item.id}
              onChange={(event) => {
                console.log(event.target.checked)
                // if (selectedProductNoImage != item.id && selectedProductNoImage != 0) {
                //     event.preventDefault()
                // } else {
                  //console.log(event.target.checked)
                if (item.image == null)
                  if (event.target.checked)
                    setSelectedProductNoImage(item.id)
                  else 
                    setSelectedProductNoImage(0)
                else 
                  if (event.target.checked) {
                    setSelectedProductImage(item.id)
                    setSelectedImage(item.image)
                  }
                  else {
                    setSelectedProductImage(0)
                    setSelectedImage("")
                  }
                // }
              }}
            />,
            name: item.name,
            package: item.packaging
            // available: item.available,
            // cost: item.cost,
            // price: item.sales_price,
          };
        });

        let tableData = { columns: productColumns, rows: productRows };
        //console.log(tableData)
        setProductData(tableData);
      })
      .catch((error) => {
        if (!error?.response?.data?.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  function resetTable() {
    console.log("RESET")
    let productColumns = [
      //   { Header: language == 1 ? "Código" : "Code", accessor: "code", width: "15%" },
      //   { Header: language == 1 ? "UPC Unidad" : "UPC Unit", accessor: "upcunit", width: "15%" },
      //   { Header: "UPC", accessor: "upc", width: "15%" },
        { Header: language == 1 ? "Acción" : "Action", accessor: "action", width: "20%"},
        { Header: language == 1 ? "Nombre" : "Name", accessor: "name" },
        { Header: language == 1 ? "Empaque" : "Package", accessor: "package"},
      //   { Header: language == 1 ? "Disponible" : "Available", accessor: "available" },
      //   { Header: language == 1 ? "Costo" : "Cost", accessor: "cost" },
      //   { Header: language == 1 ? "Precio de Venta" : "Sales Price", accessor: "price" },
      ];

      let productRows = products.map((item) => {
        //console.log(item);
        return {
          image: item.image,
          // code: item.code,
          // upcunit: item.upcUnit,
          // upc: item.upc,
          action: <Checkbox
            checked={item.image == null ? selectedProductNoImage == item.id : selectedProductImage == item.id}
            onChange={(event) => {
              console.log(event.target.checked)
              // if (selectedProductNoImage != item.id && selectedProductNoImage != 0) {
              //     event.preventDefault()
              // } else {
                //console.log(event.target.checked)
              if (item.image == null)
                if (event.target.checked)
                  setSelectedProductNoImage(item.id)
                else 
                  setSelectedProductNoImage(0)
              else 
                if (event.target.checked) {
                  setSelectedProductImage(item.id)
                  setSelectedImage(item.image)
                }
                else {
                  setSelectedProductImage(0)
                  setSelectedImage("")
                }
              // }
            }}
          />,
          name: item.name,
          package: item.packaging
          // available: item.available,
          // cost: item.cost,
          // price: item.sales_price,
        };
      });

      let tableData = { columns: productColumns, rows: productRows };
      //console.log(tableData)
      setProductData(tableData);
  }

  function nav() {
    navigate("/create-product");
  }

  // useEffect(() => {
  //   getProductData();
  // }, []);

  useEffect(() => {
    console.log(products)
    if (products.length > 0)
      resetTable()
    else
      getProductData()
  }, [selectedProductNoImage])

  useEffect(() => {
    console.log("effect")
    if (products.length > 0)
      resetTable()
  }, [selectedProductImage])

  return (
    <DashboardLayout>
      <MDSnackbar
        color="info"
        icon="notifications"
        title=""
        content={
          language == 1
            ? "Imagen Copiada Exitosamente!"
            : "Image Copied Succesfully!"
        }
        dateTime={language==1 ? "Ahora" : "Now"}
        open={show}
        close={toggleSnackbar}
      />
      <DashboardNavbar />
      <MDBox my={3}>
        <Grid container spacing={3}>
          <Grid item lg={6}>
            <Card sx={{p: 1}}>
              {selectedProductImage != 0 && <MDBox display="flex" justifyContent="center" alignItems="flex-center">
                <MDBox
                  component="img"
                  src={imageIP + selectedImage}
                  alt=""
                  borderRadius="lg"
                  shadow="sm"
                  onClick={() => console.log(imageIP + selectedImage)}
                  sx={{ width: "35%", height: "235px", mt: 2 }}
                  hidden={!(selectedImage.length > 0)}
                />
                {selectedProductNoImage != 0 && 
                  <MDButton variant="text" style={{marginLeft: "15%"}} size="large" color="info" onClick={copyImage}>
                    {language == 1 ? "Copiar" : "Copy"}&nbsp;
                    <Icon>forward</Icon>
                  </MDButton>
                }
              </MDBox>}
              <MDTypography variant="h5" sx={{ml: 2, mt: 2}}>{language == 1 ? "Productos con Imagen" : "Products with Image"}</MDTypography>
              <DataTable2
                table={{...productData, rows: productData.rows.filter(item=>{
                  return item.image != null
                })}}
                index={index}
                setIndex={setIndex}
                pageEntries={5}
                entriesPerPage={false}
                canSearch={true}
              />
            </Card>
          </Grid>
          <Grid item lg={6}>
            <Card sx={{p: 1}}>
            <MDTypography variant="h5" sx={{ml: 2, mt: 2}}>{language == 1 ? "Productos sin Imagen" : "Products without Image"}</MDTypography>
              <DataTable2
                table={{...productData, rows: productData.rows.filter(item=>{
                  return item.image == null
                })}}
                index={index}
                setIndex={setIndex}
                pageEntries={10}
                entriesPerPage={false}
                canSearch={true}
              />
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default ImageCopy;
