import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// NewUser page components
import UserInfo from "layouts/pages/users/new-user/components/UserInfo";
import Address from "layouts/pages/users/new-user/components/Address";
import Socials from "layouts/pages/users/new-user/components/Socials";
import Profile from "layouts/pages/users/new-user/components/Profile";

// NewUser layout schemas for form and form feilds
import validations from "layouts/pages/users/new-user/schemas/validations";
import form from "layouts/pages/users/new-user/schemas/form";
import initialValues from "layouts/pages/users/new-user/schemas/initialValues";
import MDInput from "components/MDInput";

import IP from "app/IP";

const axios = require("axios");

function EditTax() {
  const [active, setActive] = useState(0);
  const [check, setCheck] = useState(false);
  const [show, setShow] = useState(false);
  const [percent, setPercent] = useState("");
  const [errors, setErrors] = useState([]);
  const [language, setLanguage] = useState(localStorage.getItem("Language"));
  const [disableButton, setDisableButton] = useState(false);
  const toggleSnackbar = () => setShow(!show);

  const handleChange = () => {
    setCheck(!check);
  };

  function activate() {
    check ? setActive(1) : setActive(0);
  }

  const navigate = useNavigate();

  function nav() {
    localStorage.removeItem("taxId");
    navigate("/taxes");
  }

  async function getTaxData() {
    await axios
      .get(IP + "/taxes/" + localStorage.getItem("taxId"), {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        let taxes = response.data.data;
        if (taxes.active == "Active") {
          setCheck(true);
        }
        setPercent(parseFloat(taxes.percent).toFixed(2));
      });
  }

  async function sendTax() {
    setDisableButton(true);
    await axios
      .put(
        IP + "/taxes/" + localStorage.getItem("taxId"),
        { percent: percent, active: active },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        setTimeout(() => {
          navigate("/taxes");
        }, 3000);
        console.log(response);
        toggleSnackbar();
        localStorage.removeItem("taxId");
      })
      .catch((error) => {
        setErrors(error.response.data.data);
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
    setTimeout(() => {
      setDisableButton(false);
    }, 3000);
  }

  function TestValidation(message) {
    if (message)
      return message.map((item) => {
        return (
          <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
            <label>{item}</label>
          </MDTypography>
        );
      });
    else return null;
  }

  function ShowError(param) {
    // console.log(errors["name"]);
    if (param.id) return TestValidation(errors[param.id]);
    else return null;
  }

  useEffect(() => {
    getTaxData();
  }, []);

  useEffect(() => {
    activate();
  });

  return (
    <DashboardLayout>
      <MDSnackbar
        color="info"
        icon="notifications"
        title={language == 1 ? "EMOS Notificación" : "EMOS Notification"}
        content={
          language == 1 ? "El Impuesto fue actualizado exitosamente!" : "Tax updated succesfully!"
        }
        dateTime={language==1 ? "Ahora" : "Now"}
        open={show}
        close={toggleSnackbar}
      />
      <DashboardNavbar />
      <MDBox py={3} mb={20} height="65vh">
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 8 }}>
          <Grid item xs={12} lg={8}>
            <Card sx={{ height: "100%" }}>
              <MDBox p={3}>
                <MDBox lineHeight={0}>
                  <MDTypography variant="h4">
                    {language == 1 ? "Editar Impuesto" : "Edit tax"}
                  </MDTypography>
                  <MDTypography variant="button" color="text">
                    {language == 1
                      ? "Favor de entrar información de impuesto."
                      : "Please enter tax information."}
                  </MDTypography>
                </MDBox>
                <MDBox mt={1.625}>
                  <Grid item xs={12} sm={"auto"}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={12}>
                        <MDInput
                          type="number"
                          label={language == 1 ? "Porcentaje" : "Percent"}
                          variant="standard"
                          value={percent}
                          onBlur={() => {
                            setPercent(parseFloat(percent).toFixed(2));
                            console.log("here");
                          }}
                          onChange={(e) => {
                            if (e.target.value >= 0 && e.target.value <= 100)
                              setPercent(String(e.target.value));
                          }}
                          fullWidth
                        />
                        <ShowError id={"percent"} />
                      </Grid>
                    </Grid>
                    <MDBox mt={2} width="100%" display="flex" justifyContent="space-between">
                      <MDButton variant="gradient" color="light" onClick={nav}>
                        {language == 1 ? "Cancelar" : "Cancel"}
                      </MDButton>
                      <MDButton
                        variant="gradient"
                        disabled={disableButton}
                        color="info"
                        onClick={sendTax}
                      >
                        {language == 1 ? "Guardar" : "Save"}
                      </MDButton>
                    </MDBox>
                  </Grid>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default EditTax;
