import { useEffect, useState } from "react";
import FadeIn from "react-fade-in";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Icon } from "@mui/material";
import Grid from "@mui/material/Grid";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import IP from "app/IP";
import MDTypography from "components/MDTypography";
import AlertDialog from "app/components/confirmBox";

const axios = require("axios");

export default function PurchaseOrderModal({
  open,
  setOpen,
  Product,
  setVendorProducts,
  setPurchaseOProducts,
  isEdit,
  setIsQtyEdit,
  setProd,
  setAdded,
  deleteProd,
  setProdToDel,
  currentTotal,
  setCurrentTotal
}) {
  const [productQty, setProductQty] = useState(isEdit ? Product.quantity : 0);
  const [confirm, setConfirm] = useState(false);
  const [prodDetailID, setProdDetailID] = useState(null);
  const [shows, setShows] = useState(false);
  const [total, setTotal] = useState(0.00)
  const [language, setLanguage] = useState(localStorage.getItem("Language"));
  const toggleSnackbar2 = () => setShows(!shows);
  const handleClose = () => {
    setOpen(false);
    console.log(Product);
    console.log(isEdit);
  };

  if ((total == 0.00 || total != currentTotal) && currentTotal) {
    setTotal(currentTotal)
  }

  async function sendProduct() {
    setAdded(true);
    let reqBody = {
      cost: Product.cost,
      quantity: productQty,
      product_id: Product.id,
      purchase_order_id: localStorage.getItem("purchaseOrderId"),
      quantity_received: 0,
    };
    await axios
      .post(
        IP + "/purchase_orders/" + localStorage.getItem("purchaseOrderId") + "/po_details",
        reqBody,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        console.log(response);

        let vendorProductColumns = [
          { Header: language == 1 ? "Nombre" : "Name", accessor: "name" },
          { Header: "UPC", accessor: "upc" },
          { Header: language == 1 ? "UPC Unidad" : "UPC Unit", accessor: "upcunit" },
          { Header: language == 1 ? "Cant. Max." : "Qty. Max", accessor: "maximum" },
          { Header: language == 1 ? "Cant. Min." : "Qty. Min", accessor: "minimum" },
          { Header: language == 1 ? "Disponible" : "Available", accessor: "available" },
          { Header: "Diff.", accessor: "diff" },
          { Header: language == 1 ? "Cant." : "Qty", accessor: "qty" },
          { Header: language == 1 ? "Precio" : "Price", accessor: "price" },
          { Header: language==1 ? "Impuesto" : "Tax", accessor: "tax" },
          { Header: language == 1 ? "Acción" : "Action", accessor: "action" },
        ];

        let vendorProductRows = response.data.data.all.map((item) => {
          // console.log(item);
          return {
            name: item.name,
            upc: item.upc,
            upcunit: item.upcUnit,
            maximum: item.maximum,
            minimum: item.minimum,
            available: item.available,
            diff: item.diff,
            price: item.cost,
            tax: parseFloat(item.cost * (item.tax.percent/100)).toFixed(2),
            qty: 0,
            action: (
              <MDButton
                color="info"
                onClick={() => {
                  setOpen(true);
                  setProd(item);
                  setIsQtyEdit(false);
                }}
                type="button"
                variant="gradient"
              >
                Add
              </MDButton>
            ),
          };
        });

        let tableData = { columns: vendorProductColumns, rows: vendorProductRows };
        setVendorProducts(tableData);

        let purchaseOColumns = [
          { Header: language == 1 ? "Nombre" : "Name", accessor: "name" },
          { Header: "UPC", accessor: "upc" },
          { Header: language == 1 ? "UPC Unidad" : "UPC Unit", accessor: "upcunit" },
          { Header: language == 1 ? "Precio" : "Price", accessor: "price" },
          { Header: language == 1 ? "Cantidad" : "Quantity", accessor: "qty" },
          { Header: "Sub-Total", accessor: "subtotal" },
          { Header: language == 1 ? "Impuesto" : "Tax", accessor: "tax" },
          { Header: "Total", accessor: "total", width: "15%" },
          { Header: "Action", accessor: "action" },
        ];

        let purchaseORows = response.data.data.selected.map((item) => {
          return {
            name: item.product.name,
            qty: item.quantity,
            upc: item.product.upc,
            tax: parseFloat(item.tax).toFixed(2),
            subtotal: parseFloat(item.quantity * item.cost).toFixed(2),
            upcunit: item.product.upcUnit,
            price: item.cost,
            total: parseFloat(item.total).toFixed(2),
            action: (
              <>
                <MDButton
                  color="info"
                  onClick={() => {
                    setOpen(true);
                    setProd(item);
                    setIsQtyEdit(true);
                  }}
                  type="button"
                  variant="gradient"
                  iconOnly
                >
                  <Icon>edit</Icon>
                </MDButton>
                <MDButton
                  color="error"
                  onClick={() => {
                    setProdToDel(item);
                    setProdDetailID(item);
                    setConfirm(true);
                  }}
                  type="button"
                  variant="gradient"
                  iconOnly
                >
                  <Icon>delete</Icon>
                </MDButton>
              </>
            ),
          };
        });

        let sub = response.data.data.selected.map(item=>{
          return parseFloat(item.quantity * item.cost)
        })

        let subtotal = sub.reduce((prev, current) => prev + current, 0)

        let taxTotal = response.data.data.selected.map(item=>{
          return parseFloat(item.tax)
        }).reduce((prev, current) => prev + current, 0)

        let prices = response.data.data.selected.map((item) => {
          return parseFloat(item.total);
        });

        let totalPrice = prices.reduce((prev, current) => prev + current, 0);
        setTotal(totalPrice)
        setCurrentTotal(totalPrice)

        let totalQty = response.data.data.selected.reduce(
          (prev, current) => prev + current.quantity,
          0
        );

        purchaseORows.push({
          name: "",
          price: "",
          qty: language == 1 ? "Total de bulto: " + totalQty : "Total Quantity: " + totalQty,
          subtotal: "Sub-Total: $" + parseFloat(subtotal).toFixed(2),
          tax: language == 1 ? "Total de Impuestos: $" + parseFloat(taxTotal).toFixed(2) : "Tax Total: $" + parseFloat(taxTotal).toFixed(2),
          total:
            language == 1
              ? "Precio Total: $" + parseFloat(totalPrice).toFixed(2)
              : "Total Price: $" + parseFloat(totalPrice).toFixed(2),
          action: "",
        });

        let tableDataPO = { columns: purchaseOColumns, rows: purchaseORows };
        setPurchaseOProducts(tableDataPO);

        setProductQty(0);
        setOpen(false);
        setShows(true);
        setTimeout(() => {
          setShows(false);
        }, 3000);
      });
  }

  async function sendProductEdit() {
    let reqBody = {
      cost: Product.cost,
      quantity: productQty,
      product_id: Product.product.id,
      purchase_order_id: localStorage.getItem("purchaseOrderId"),
      quantity_received: 0,
    };
    await axios
      .put(IP + "/po_details/" + Product.id, reqBody, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log(response.data);

        let vendorProductColumns = [
          { Header: language == 1 ? "Nombre" : "Name", accessor: "name" },
          { Header: "UPC", accessor: "upc" },
          { Header: language == 1 ? "UPC Unidad" : "UPC Unit", accessor: "upcunit" },
          { Header: language == 1 ? "Cant. Max." : "Qty. Max", accessor: "maximum" },
          { Header: language == 1 ? "Cant. Min." : "Qty. Min", accessor: "minimum" },
          { Header: language == 1 ? "Disponible" : "Available", accessor: "available" },
          { Header: "Diff.", accessor: "diff" },
          { Header: language == 1 ? "Cant." : "Qty", accessor: "qty" },
          { Header: language == 1 ? "Precio" : "Price", accessor: "price" },
          { Header: language==1 ? "Impuesto" : "Tax", accessor: "tax" },
          { Header: language == 1 ? "Acción" : "Action", accessor: "action" },
        ];

        let vendorProductRows = response.data.data.all.map((item) => {
          console.log(item);
          return {
            name: item.name,
            upc: item.upc,
            upcunit: item.upcUnit,
            maximum: item.maximum,
            minimum: item.minimum,
            tax: parseFloat(item.cost * (item.tax.percent/100)).toFixed(2),
            available: item.available,
            diff: item.diff,
            qty: 0,
            action: (
              <MDButton
                color="info"
                onClick={() => {
                  setOpen(true);
                  setProd(item);
                  setIsQtyEdit(false);
                }}
                type="button"
                variant="gradient"
              >
                Add
              </MDButton>
            ),
          };
        });

        let tableData = { columns: vendorProductColumns, rows: vendorProductRows };
        setVendorProducts(tableData);

        let purchaseOColumns = [
          { Header: language == 1 ? "Nombre" : "Name", accessor: "name" },
          { Header: "UPC", accessor: "upc" },
          { Header: language == 1 ? "UPC Unidad" : "UPC Unit", accessor: "upcunit" },
          { Header: language == 1 ? "Precio" : "Price", accessor: "price" },
          { Header: language == 1 ? "Cantidad" : "Quantity", accessor: "qty" },
          { Header: "Sub-Total", accessor: "subtotal" },
          { Header: language == 1 ? "Impuesto" : "Tax", accessor: "tax" },
          { Header: "Total", accessor: "total", width: "15%" },
          { Header: "Action", accessor: "action" },
        ];

        let purchaseORows = response.data.data.selected.map((item) => {
          return {
            name: item.product.name,
            qty: item.quantity,
            upc: item.product.upc,
            upcunit: item.product.upcUnit,
            price: item.cost,
            subtotal: parseFloat(item.quantity * item.cost).toFixed(2),
            tax: parseFloat(item.tax).toFixed(2),
            total: parseFloat(item.total).toFixed(2),
            action: (
              <>
                <MDButton
                  color="info"
                  onClick={() => {
                    setOpen(true);
                    setProd(item);
                    setIsQtyEdit(true);
                  }}
                  type="button"
                  variant="gradient"
                  iconOnly
                >
                  <Icon>edit</Icon>
                </MDButton>
                <MDButton
                  color="error"
                  onClick={() => {
                    setProdToDel(item);
                    setProdDetailID(item);
                    setConfirm(true);
                  }}
                  type="button"
                  variant="gradient"
                  iconOnly
                >
                  <Icon>delete</Icon>
                </MDButton>
              </>
            ),
          };
        });

        let sub = response.data.data.selected.map(item=>{
          return parseFloat(item.quantity * item.cost)
        })

        let subtotal = sub.reduce((prev, current) => prev + current, 0)

        let taxTotal = response.data.data.selected.map(item=>{
          return parseFloat(item.tax)
        }).reduce((prev, current) => prev + current, 0)

        let prices = response.data.data.selected.map((item) => {
          return parseFloat(item.total);
        });

        let totalPrice = prices.reduce((prev, current) => prev + current, 0);
        setTotal(totalPrice)

        let totalQty = response.data.data.selected.reduce(
          (prev, current) => prev + current.quantity,
          0
        );

        purchaseORows.push({
          name: "",
          price: "",
          qty: language == 1 ? "Total de bulto: " + totalQty : "Total Quantity: " + totalQty,
          subtotal: "Sub-Total: $" + parseFloat(subtotal).toFixed(2),
          tax: language == 1 ? "Total de Impuestos: $" + parseFloat(taxTotal).toFixed(2) : "Tax Total: $" + parseFloat(taxTotal).toFixed(2),
          total:
            language == 1
              ? "Precio Total: $" + parseFloat(totalPrice).toFixed(2)
              : "Total Price: $" + parseFloat(totalPrice).toFixed(2),
          action: "",
        });

        let tableDataPO = { columns: purchaseOColumns, rows: purchaseORows };
        setPurchaseOProducts(tableDataPO);

        setProductQty(0);
        setOpen(false);
      });
  }

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  return (
    <>
      <MDSnackbar
        color="info"
        icon="notifications"
        title="Material Dashboard"
        content="Product Added!"
        dateTime={language==1 ? "Ahora" : "Now"}
        open={shows}
        close={toggleSnackbar2}
      />
      <AlertDialog
        Show={confirm}
        setShowConfirm={setConfirm}
        Message={
          "Are you sure you want to delete the product '" + prodDetailID?.product?.name + "' ?"
        }
        OkayFunction={deleteProd}
      />
      <ThemeProvider theme={darkTheme}>
        <Dialog open={open}>
          <DialogTitle>
            <Grid>
              {language == 1 ? "Producto: " : "Product: "}
              {isEdit ? Product.product.name : Product.name}
            </Grid>
            <Grid>
              {language == 1 ? "UPC Unidad: " : "UPC Unit: "}
              {isEdit ? Product.product.upcUnit : Product.upcUnit}
            </Grid>
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="price"
              value={"$" + Product.cost}
              label={language == 1 ? "Precio" : "Price"}
              fullWidth
              variant="standard"
            />
            <TextField
              autoFocus
              margin="dense"
              id="name"
              value={productQty}
              label={language == 1 ? "Cantidad" : "Order Qty"}
              type="number"
              onChange={(e) => {
                if (e.target.value >= 0 || e.target.value == "") {
                  setProductQty(e.target.value);
                }
              }}
              fullWidth
              variant="standard"
            />
            <MDTypography sx={{ mt: 1.5 }} variant="h6">
              Total: <FadeIn>{"$" + parseFloat(
                total + ((isEdit ? productQty - Product.quantity : productQty) * (isEdit ? Product.product.cost : Product.cost)) 
                + ((isEdit ? productQty - Product.quantity : productQty) * (isEdit ? Product.product.cost : Product.cost) 
                * ((isEdit ? Product?.product?.tax?.percent : Product?.tax?.percent)/100))).toFixed(2)}</FadeIn>
            </MDTypography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{language == 1 ? "Cancelar" : "Cancel"}</Button>
            <Button
              disabled={productQty == 0}
              onClick={() => {
                if (isEdit) {
                  sendProductEdit();
                } else {
                  sendProduct();
                }
              }}
            >
              {language == 1 ? "Guardar" : "Save"}
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </>
  );
}
