/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import MDButton from "components/MDButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import { Input, InputAdornment, InputLabel, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";

import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";

// Overview page components
import Header from "layouts/pages/profile/components/Header";
import PlatformSettings from "layouts/pages/profile/profile-overview/components/PlatformSettings";

// Data
import profilesListData from "layouts/pages/profile/profile-overview/data/profilesListData";

// Images
import homeDecor1 from "assets/images/home-decor-1.jpg";
import homeDecor2 from "assets/images/home-decor-2.jpg";
import homeDecor3 from "assets/images/home-decor-3.jpg";
import homeDecor4 from "assets/images/home-decor-4.jpeg";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";

import DataTable from "examples/Tables/DataTable";

// Data
import dataTableData from "layouts/applications/data-tables/data/dataTableData";
// NewUser page components
import FormField from "layouts/pages/users/new-user/components/FormField";
// Material Dashboard 2 React Components
import MDInput from "components/MDInput";
import selectData from "layouts/pages/account/settings/components/BasicInfo/data/selectData";
import Autocomplete from "@mui/material/Autocomplete";

import MDSnackbar from "components/MDSnackbar";
import { useNavigate, Navigate } from "react-router-dom";

import IP from "../IP";

const axios = require("axios");

function EditUser() {
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [name, setName] = useState("");
  const [username, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState(null);
  const [confPassword, setConfPassword] = useState(null);
  const [show, setShow] = useState(false);
  const toggleSnackbar = () => setShow(!show);
  const [errors, setErrors] = useState([]);
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState({ label: "", id: "" });
  const [variables, setVariables] = useState({ name: "" });
  const [warehouses, setWarehoues] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState({ label: "", id: "" });
  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState([]);
  const [showPassword, setShowPassword] = useState(false)
  const [showConfPassword, setShowConfPassword] = useState(false)
  const [language, setLanguage] = useState(localStorage.getItem("Language"));

  const navigate = useNavigate();

  async function getUserDetails() {
    await axios
      .get(IP + "/users/" + localStorage.getItem("UsId"), {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(function (response) {
        console.log(response.data.data);

        let user = response.data.data;

        console.log("user");
        setUserName(user.username);
        setName(user.name);
        setEmail(user.email);
        setSelectedRole({ id: user.role.id, label: user.role.name });
        setSelectedWarehouse({ id: user.warehouse.id, label: user.warehouse.name });
        if (user.stores != "No stores assigned") {
          setSelectedStore(
            user.stores.map((store) => {
              return { label: store.name, value: store.id, id: store.id };
            })
          );
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function changeStatus() {
    await axios
      .post(
        IP + "/user/setActive/" + localStorage.getItem("UsId"),
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        toggleSnackbar();
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function GetRoles() {
    await axios
      .get(IP + "/roles", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(function (response) {
        console.log(response.data.data);
        setRoles(
          response.data.data.map((item) => {
            return { label: item.name, id: item.id };
          })
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function GetWareHouses() {
    await axios
      .get(IP + "/warehouses", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(function (response) {
        console.log(response.data.data);
        setWarehoues(
          response.data.data.map((item) => {
            return { label: item.name, id: item.id };
          })
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function GetStores() {
    await axios
      .get(IP + "/stores/getActive", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(function (response) {
        console.log(response.data.data);
        setStores(
          response.data.data.map((item) => {
            return { label: item.name, value: item.id, id: item.id };
          })
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function EditUserFunc() {
    let req = {
      name: name,
      password: password == confPassword ? password : null,
      email: email,
      role_id: selectedRole?.id,
      warehouse_id: 1,
      // store_id: [],
      username: username,
      status: 1,
      stores: [],
    };

    if (selectedRole?.id == 7) {
      let storeIds = [];
      selectedStore.map((item) => {
        if (!storeIds.some((item2) => item2.store_id == item.id)) {
          storeIds.push({ store_id: item.id });
        }
      });
      console.log(storeIds);
      let emptyStores = [{store_id: 0}];

      if (storeIds.length > 0) {
        req = {
          name: name,
          password: password == confPassword ? password : null,
          username: username,
          email: email,
          role_id: selectedRole?.id,
          //  warehouse_id: 1,
          stores: storeIds,
          status: 1,
        };
      } else {
        req = {
          name: name,
          password: password == confPassword ? password : null,
          username: username,
          // stores: emptyStores,
          email: email,
          role_id: selectedRole?.id,
          //  warehouse_id: 1,
          status: 1,
        };
      }
    }

    await axios
      .put(IP + "/users/" + localStorage.getItem("UsId"), req, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(function (response) {
        toggleSnackbar();
        setTimeout(() => {
          setShow(false);
          navigate("/emos/viewUsers");
        }, 3000);
      })
      .catch(function (error) {
        console.log(error);
        setErrors(error.response.data.data);
      });
  }

  function TestValidation(message) {
    console.log(message);
    if (message)
      return message.map((item) => {
        return (
          <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
            <label>{item}</label>
          </MDTypography>
        );
      });
    else return null;
  }

  function ShowError(param) {
    if (param.id) return TestValidation(errors[param.id]);
    else return null;
  }

  useEffect(() => {
    getUserDetails();
    GetRoles();
    // GetWareHouses();
    GetStores();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDSnackbar
        color="info"
        icon="notifications"
        title=""
        content={language == 1 ? "Usuario Editado Exitosamente" : "User Edited Successfully!"}
        dateTime={language==1 ? "Ahora" : "Now"}
        open={show}
        close={toggleSnackbar}
      />
      <MDBox py={3} mb={20} height="65vh">
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 8 }}>
          <Grid item xs={12} lg={8}>
            <Card sx={{ height: "100%" }}>
              <MDBox p={3}>
                <MDBox>
                  <MDTypography variant="h4" fontWeight="medium">
                    {language == 1 ? "Editar Usuario" : "Edit User"}
                  </MDTypography>
                  <MDBox>
                    <MDBox lineHeight={0}>
                      <MDTypography variant="h5"></MDTypography>
                      <MDTypography variant="button" color="text">
                        {language == 1
                          ? "Por favor entre la información del usuario"
                          : "Please enter User information."}
                      </MDTypography>
                    </MDBox>
                    <MDBox id="box1" mt={1.625}>
                      <Grid id="grid1" container spacing={3}>
                        <Grid id="grid1-1" item xs={12} sm={6}>
                          <MDInput
                            variant="standard"
                            label={language == 1 ? "Nombre de Usuario" : "User Name"}
                            fullWidth
                            value={username}
                            onChange={(e) => {
                              //  var x = {...variables};

                              setUserName(e.target.value);
                            }}
                          />
                          <ShowError id={"username"} />
                        </Grid>
                        <Grid id="grid1-1" item xs={12} sm={6}>
                          <MDInput
                            variant="standard"
                            label={language == 1 ? "Nombre Completo" : "Full Name"}
                            fullWidth
                            value={name}
                            onChange={(e) => {
                              setName(e.target.value);
                            }}
                          />
                          <ShowError id={"name"} />
                        </Grid>
                      </Grid>

                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                          <MDInput
                            variant="standard"
                            type="email"
                            label="Email"
                            fullWidth
                            value={email}
                            onChange={(e) => {
                              setEmail(e.target.value);
                            }}
                          />
                          <ShowError id={"email"} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Autocomplete
                            disablePortal
                            value={selectedRole}
                            onChange={(event, newValue) => {
                              console.log("Roles");

                              setSelectedRole(newValue);
                            }}
                            options={roles}
                            renderInput={(params) => (
                              <MDInput {...params} label="Roles" variant="standard" />
                            )}
                          />
                          <ShowError id={"role_id"} />
                        </Grid>
                      </Grid>
                      {
                        selectedRole?.id == 7 || selectedRole?.label == "Store" ? (
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <Autocomplete
                                multiple
                                disablePortal
                                value={selectedStore}
                                onChange={(event, newValue) => {
                                  console.log(newValue);
                                  console.log(newValue);

                                  var cleanArray = newValue.filter(
                                    (arr, index, self) =>
                                      index === self.findIndex((t) => t.value === arr.value)
                                  );
                                  console.log(cleanArray);
                                  setSelectedStore(cleanArray);
                                  setSelectedWarehouse({ label: "", id: "" });
                                }}
                                options={stores}
                                renderInput={(params) => (
                                  <MDInput
                                    {...params}
                                    label={language == 1 ? "Tiendas" : "Stores"}
                                    variant="standard"
                                  />
                                )}
                              />
                              <ShowError id={"stores"} />

                              <ShowError id={"stores.0.store_id"} />
                            </Grid>
                          </Grid>
                        ) : null
                        // <Grid item xs={12}>
                        //   <Autocomplete
                        //     disablePortal
                        //     value={selectedWarehouse}
                        //     onChange={(event, newValue) => {
                        //       console.log(newValue);
                        //       setSelectedWarehouse(newValue);
                        //       setSelectedStore({ label: "", id: "" });
                        //     }}
                        //     options={warehouses}
                        //     renderInput={(params) => (
                        //       <MDInput {...params} label={language==1 ? "Almacenes" : "Warehouses"} variant="standard" />
                        //     )}
                        //   />
                        //   <ShowError id={"store_id"} />
                        // </Grid>
                      }
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                          <FormControl sx={{width: '100%'}} variant='standard'>
                          <InputLabel htmlFor="password">{language == 1 ? "Contraseña" : "Password"}</InputLabel>
                          <Input
                            id="password"
                            variant="standard"
                            label={language == 1 ? "Contraseña" : "Password"}
                            type={showPassword ? "text" : "password"}
                            fullWidth
                            value={password}
                            onChange={(e) => {
                              setPassword(e.target.value);
                            }}
                            endAdornment={<InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => {
                                setShowPassword(!showPassword);
                                console.log('here')
                              }}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>}
                          />
                          </FormControl>
                          <ShowError id={"password"} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                        <FormControl sx={{width: '100%'}} variant='standard'>
                          <InputLabel htmlFor="confpassword">{language == 1 ? "Confirmar Contraseña" : "Confirm Password"}</InputLabel>
                          <Input
                            id="confpassword"
                            variant="standard"
                            label={language == 1 ? "Contraseña" : "Password"}
                            type={showConfPassword ? "text" : "password"}
                            fullWidth
                            value={confPassword}
                            onChange={(e) => {
                              setConfPassword(e.target.value);
                            }}
                            endAdornment={<InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => {
                                  setShowConfPassword(!showConfPassword);
                                }}
                                edge="end"
                              >
                                {showConfPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>}
                          />
                          </FormControl>
                          <ShowError id={"password_confirmation"} />
                          {password != null && password != confPassword ? (
                            <MDTypography
                              variant="caption"
                              display="block"
                              gutterBottom
                              color="error"
                            >
                              {language == 1
                                ? "La confirmación y la contraseña deben ser iguales"
                                : "The password confirmation has to be the same as the new password."}
                            </MDTypography>
                          ) : null}
                        </Grid>
                      </Grid>
                    </MDBox>
                  </MDBox>
                  {/* <Grid container spacing={3}>
                    <Grid item xs={12} lg ={4}>
                    <MDButton
                    sx={{mt: 2}}
                    variant="gradient"
                    color='success'
                    onClick={()=>{
                      changeStatus()
                    }}
                  >
                    {language==1 ? "Activar" : "Activate"}
                  </MDButton>
                    </Grid>
                    <Grid item xs={12} lg={8}> */}
                  <MDBox mt={2} wdith="100%" display="flex" justifyContent="right">
                    <MDButton
                      type="button"
                      variant="gradient"
                      className="float-sm-right"
                      color="light"
                      onClick={() => {
                        navigate("/emos/viewUsers");
                      }}
                    >
                      {language == 1 ? "Cancelar" : "Cancel"}
                    </MDButton>{" "}
                    <MDButton
                      type="button"
                      variant="gradient"
                      className="float-sm-right"
                      color="info"
                      onClick={EditUserFunc}
                    >
                      {language == 1 ? "Guardar" : "Save"}
                    </MDButton>
                  </MDBox>
                  {/* </Grid>
                  </Grid> */}
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default EditUser;
