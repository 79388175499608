/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { jsPDF } from "jspdf";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Tooltip } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
//import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
//import ReactPDF from "@react-pdf/renderer";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import AlertDialog from "app/components/confirmBox";
import ProductCell from "layouts/ecommerce/products/product-page/components/ProductCell";
import MDInput from "components/MDInput";
import { ExportToExcel } from "app/components/exportToExcel";
import "bootstrap/dist/css/bootstrap.min.css";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import Pdf from "react-to-pdf";
import IP from "app/IP";

const axios = require("axios");

function ReportBestSeller() {
  const current = new Date();
  let month = current.getMonth() + 1;
  let day = current.getDate();
  const currentDate = `${current.getFullYear()}-${month < 10 ? `0${month}` : `${month}`}-${
    day < 10 ? `0${day}` : `${day}`
  }`;

  const [menu, setMenu] = useState(null);
  const [report, setReport] = useState({ columns: [], rows: [] });
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([])
  const [responseData, setResponseData] = useState([]);
  const [download, setDownload] = useState(true);
  const [vendors, setVendors] = useState([])
  const [selectedVendor, setSelectedVendor] = useState('')
  const [open, setOpen] = useState(false)
  const [sortSelect, setSortSelect] = useState('')
  const [errors, setErrors] = useState([]);
  const [dateFrom, setDateFrom] = useState(currentDate);
  const [dateTo, setDateTo] = useState(currentDate);
  const [language, setLanguage] = useState(localStorage.getItem("Language"));

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);
  const navigate = useNavigate();

  const ref = useRef();

  // const createPDF = () => {
  //   setDownload(false)
  //   const pdf = new jsPDF("portrait", "px", "a0");
  //   const data = document.querySelector("#pdf");
  //   pdf.html(data).then(() => {
  //     pdf.save("best-sellers");
  //     setDownload(true)
  //   });
  // };

  const options = {
    orientation: "portrait",
    unit: "in",
    format: [20, 10],
  };

  async function GetVendors() {
    // console.log("aqui");
    //setRedirect(true);
    await axios
      .get(IP + "/vendors", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(function (response) {
        //console.log(response.data.data);
        let vendor = [];

        response.data.data.map((item) => {
          if (item.active == "Active") {
            vendor.push({ label: item.name, id: item.id });
          }

          setVendors(vendor);
        });
      })
      .catch(function (error) {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function getBestSellersData() {
    await axios
      .get(
        IP + "/reports/best_seller?startDate=" + dateFrom + "&endDate=" + dateTo,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        GetVendors()
        setAllData(response.data.data)
        setResponseData(response.data.data);
        console.log(response.data.data);
        let columns = [
          { Header: language == 1 ? "Vendedor" : "Vendor", accessor: 'vendor', width: '15%' },
          { Header: "UPC", accessor: "upc", width: '15%' },
          { Header: language == 1 ? "Producto" : "Product", accessor: "product", width: '15%' },
          { Header: language == 1 ? "Cantidad" : "Quantity", accessor: "qty", width: '10%' },
          // { Header: language == 1 ? "Acción" : "Action", accessor: "action", width: '10%' },
        ];

        let rows = response.data.data.map((item) => {
          return {
            vendor: item.product.vendor.name,
            product: item.product.name,
            qty: item.count,
            upc: item.product.upc,
            action: <Tooltip title={language == 1 ? "Ver Ordenes" : "View Orders"}>
            <MDButton
              variant="gradient"
              color="info"
              onClick={() => {
                navigate('/best-sellers-orders')
              }}
              iconOnly
            >
              <Icon>visibility</Icon>
            </MDButton>
          </Tooltip>
          };
        });

        let excelRows = response.data.data.map((item) => {
          if (language == 1) {
            return {
              UPC: item.product.upc,
              Nombre_De_Producto: item.product.name,
              Cantidad: item.count,
            };
          } else {
            return {
              UPC: item.product.upc,
              Product_Name: item.product.name,
              Quantity: item.count,
            };
          }
        });
        setErrors([]);

        setData(excelRows);

        let tableData = { columns: columns, rows: rows };
        //console.log(tableData)
        setReport(tableData);
      })
      .catch((error) => {
        // setErrors(error.response.data.data);
        // if (!error.response.data.authenticated) {
        //   navigate("/signIn");
        // }
        console.log(error);
      });
  }

  function filterVendor(vendor) {
    if (vendor) {
      setResponseData(allData.filter((item) => {
        return item.product.vendor.id == vendor.id
      }))
    }
    if (!vendor) {
      setResponseData(allData)
    }

    let columns = [
      { Header: language == 1 ? "Vendedor" : "Vendor", accessor: 'vendor', width: '15%' },
      { Header: "UPC", accessor: "upc", width: '15%' },
      { Header: language == 1 ? "Producto" : "Product", accessor: "product", width: '15%' },
      { Header: language == 1 ? "Cantidad" : "Quantity", accessor: "qty", width: '10%' },
      // { Header: language == 1 ? "Acción" : "Action", accessor: "action", width: '10%' },
    ];

    if (vendor) {
      let rows = allData.filter((item) => {
        return item.product.vendor.id == vendor.id
      }).map((item) => {
        return {
          vendor: item.product.vendor.name,
          product: item.product.name,
          qty: item.count,
          upc: item.product.upc,
          action: <Tooltip title={language == 1 ? "Ver Ordenes" : "View Orders"}>
            <MDButton
              variant="gradient"
              color="info"
              onClick={() => {
                navigate('/best-sellers-orders')
              }}
              iconOnly
            >
              <Icon>visibility</Icon>
            </MDButton>
          </Tooltip>
        };
      });

      let excelRows = allData.filter((item) => {
        return item.product.vendor.id == vendor.id
      }).map((item) => {
        if (language == 1) {
          return {
            UPC: item.product.upc,
            Nombre_De_Producto: item.product.name,
            Cantidad: item.count,
          };
        } else {
          return {
            UPC: item.product.upc,
            Product_Name: item.product.name,
            Quantity: item.count,
          };
        }
      });
      setErrors([]);

      setData(excelRows);
  
      let tableData = { columns: columns, rows: rows };
      //console.log(tableData)
      setReport(tableData);
    }

    if (!vendor) {
      let rows = allData.map((item) => {
        return {
          vendor: item.product.vendor.name,
          product: item.product.name,
          qty: item.count,
          upc: item.product.upc,
          action: <Tooltip title={language == 1 ? "Ver Ordenes" : "View Orders"}>
            <MDButton
              variant="gradient"
              color="info"
              onClick={() => {
                navigate('/best-sellers-orders')
              }}
              iconOnly
            >
              <Icon>visibility</Icon>
            </MDButton>
          </Tooltip>
        };
      });

      let excelRows = allData.map((item) => {
        if (language == 1) {
          return {
            UPC: item.product.upc,
            Nombre_De_Producto: item.product.name,
            Cantidad: item.count,
          };
        } else {
          return {
            UPC: item.product.upc,
            Product_Name: item.product.name,
            Quantity: item.count,
          };
        }
      });
      setErrors([]);

      setData(excelRows);
  
      let tableData = { columns: columns, rows: rows };
      //console.log(tableData)
      setReport(tableData);
    }
  }

  function sortQuantityAscend() {
    let columns = [
      { Header: language == 1 ? "Vendedor" : "Vendor", accessor: 'vendor', width: '15%' },
      { Header: "UPC", accessor: "upc", width: '15%' },
      { Header: language == 1 ? "Producto" : "Product", accessor: "product", width: '15%' },
      { Header: language == 1 ? "Cantidad" : "Quantity", accessor: "qty", width: '10%' },
      // { Header: language == 1 ? "Acción" : "Action", accessor: "action", width: '10%' },
    ];

    let rows = responseData
      .sort((a, b)=>(parseInt(a.count) > parseInt(b.count) ? 1 : -1))
      .map(item=>{
        return {
          vendor: item.product.vendor.name,
          product: item.product.name,
          qty: item.count,
          upc: item.product.upc,
          action: <Tooltip title={language == 1 ? "Ver Ordenes" : "View Orders"}>
            <MDButton
              variant="gradient"
              color="info"
              onClick={() => {
                navigate('/best-sellers-orders')
              }}
              iconOnly
            >
              <Icon>visibility</Icon>
            </MDButton>
          </Tooltip>
        };
      })

    let tableData = { columns: columns, rows: rows };
    //console.log(tableData)
    setReport(tableData);
  }

  function sortQuantityDescend() {
    let columns = [
      { Header: language == 1 ? "Vendedor" : "Vendor", accessor: 'vendor', width: '15%' },
      { Header: "UPC", accessor: "upc", width: '15%' },
      { Header: language == 1 ? "Producto" : "Product", accessor: "product", width: '15%' },
      { Header: language == 1 ? "Cantidad" : "Quantity", accessor: "qty", width: '10%' },
      // { Header: language == 1 ? "Acción" : "Action", accessor: "action", width: '10%' },
    ];

    let rows = responseData
      .sort((a, b)=>(parseInt(a.count) < parseInt(b.count) ? 1 : -1))
      .map(item=>{
        return {
          vendor: item.product.vendor.name,
          product: item.product.name,
          qty: item.count,
          upc: item.product.upc,
          action: <Tooltip title={language == 1 ? "Ver Ordenes" : "View Orders"}>
            <MDButton
              variant="gradient"
              color="info"
              onClick={() => {
                navigate('/best-sellers-orders')
              }}
              iconOnly
            >
              <Icon>visibility</Icon>
            </MDButton>
          </Tooltip>
        };
      })

    let tableData = { columns: columns, rows: rows };
    //console.log(tableData)
    setReport(tableData);
  }

  function TestValidation(message) {
    if (message)
      return message.map((item) => {
        return (
          <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
            <label>{item}</label>
          </MDTypography>
        );
      });
    else return null;
  }

  function ShowError(param) {
    // console.log(errors["name"]);
    if (param.id) return TestValidation(errors[param.id]);
    else return null;
  }

  // const TableDocument = () => {
  //   <Document>
  //     <Page>
  //       <View>
  //         <h3>Best Sellers Report</h3>
  //         <table className="table table-striped">
  //           <thead>
  //             <tr>
  //               <th>UPC</th>
  //               <th>Product</th>
  //               <th>Quantity</th>
  //             </tr>
  //           </thead>
  //           <tbody>
  //             {responseData.map((item) => {
  //               return (
  //                 <tr>
  //                   <td>{item.product.upc}</td>
  //                   <td>{item.product.name}</td>
  //                   <td>{item.count}</td>
  //                 </tr>
  //               );
  //             })}
  //           </tbody>
  //         </table>
  //       </View>
  //     </Page>
  //   </Document>;
  // };

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ThemeProvider theme={darkTheme}>
        <Dialog open={open}>
          <DialogTitle>{language == 1 ? "Organizar por cantidad:" : "Sort by quantity:"}</DialogTitle>
          <DialogContent>
            <FormControl>
              <RadioGroup
                name="Sort Options"
                onChange={(e) => {
                  setSortSelect(e.target.value)
                }}
              >
                <FormControlLabel
                  value="descending"
                  control={<Radio />}
                  label={language == 1 ? "Mayor a menor" : "Highest to lowest"}
                />
                <FormControlLabel
                  value="ascending"
                  control={<Radio />}
                  label={language == 1 ? "Menor a mayor" : "Lowest to highest"}
                />
              </RadioGroup>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={()=>{setOpen(!open)}}>{language == 1 ? "Cancelar" : "Cancel"}</Button>
            <Button
              onClick={() => {
                if (sortSelect == "ascending") {
                  sortQuantityAscend()
                }
                if (sortSelect == "descending") {
                  sortQuantityDescend()
                }
              }}
            >
              {language == 1 ? "Organizar" : "Sort"}
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
      <MDBox my={3}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          <MDBox display="flex">
            {data.length > 0 ? (
              <>
                <MDButton variant="outlined" color="dark" onClick={() => setOpen(true)}>
                  {language == 1 ? "Organizar" : "Sort"}&nbsp;
                  <Icon>keyboard_arrow_down</Icon>
                </MDButton>
                <MDBox ml={1}>
                  <ExportToExcel apiData={data} fileName="BestSellers" />
                </MDBox>
                <MDBox ml={1}>
                  <Pdf targetRef={ref} filename="code-example.pdf" options={options}>
                    {({ toPdf }) => (
                      <MDButton variant="outlined" color="dark" onClick={toPdf}>
                        {language==1 ? "Descargar PDF" : "Download PDF"}
                      </MDButton>
                    )}
                  </Pdf>
                </MDBox>
              </>
            ) : null}
          </MDBox>
        </MDBox>
        <Card>
          <MDBox p={3}>
            <MDTypography variant="h4" sx={{ mb: 1 }}>
              {language == 1 ? "Busqueda" : "Search"}
            </MDTypography>
            <Grid container spacing={1} sx={{mb: 4}}>
              <Grid id="grid1-1" item xs={12} sm={2}>
                <MDInput
                  variant="standard"
                  type="date"
                  label={language == 1 ? "Desde" : "From Date"}
                  fullWidth
                  value={dateFrom}
                  onChange={(e) => {
                    setDateFrom(e.target.value);
                  }}
                />
                <ShowError id={"startDate"} />
              </Grid>
              <Grid id="grid1-1" item xs={12} sm={2}>
                <MDInput
                  variant="standard"
                  type="date"
                  label={language == 1 ? "Hasta" : "To Date"}
                  fullWidth
                  value={dateTo}
                  onChange={(e) => {
                    setDateTo(e.target.value);
                  }}
                />
                <ShowError id={"endDate"} />
              </Grid>
              <Grid id="grid1-1" item xs={12} sm={2}>
                <MDButton
                  type="button"
                  variant="gradient"
                  className="float-sm-right"
                  color="info"
                  onClick={getBestSellersData}
                >
                  {language == 1 ? "Busqueda" : "Search"}
                </MDButton>
              </Grid>
            </Grid>
            {vendors.length > 0 ?
            <>
              <Autocomplete
                disablePortal
                value={selectedVendor}
                onChange={(event, newValue) => {
                  console.log(newValue)
                  setSelectedVendor(newValue);
                  filterVendor(newValue)
                }}
                options={vendors}
                style={{marginTop: 20, width: '15%'}}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={language == 1 ? "Vendedor" : "Vendor"}
                    variant="standard"
                  />
                )}
              />
            </> : null}
            <div ref={ref}>
              <DataTable table={report} entriesPerPage={true} canSearch />
            </div>
          </MDBox>
        </Card>
        {/* <div hidden={true}>
        <MDBox mt={50}>
        <div id="pdf">
          <h3>Best Sellers Report</h3>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>UPC</th>
                <th>Product</th>
                <th>Quantity</th>
              </tr>
            </thead>
            <tbody>
               {responseData.map(item=>{
                  return <tr>
                    <td>{item.product.upc}</td>
                    <td>{item.product.name}</td>
                    <td>{item.count}</td>
                  </tr>
               })} 
            </tbody>
          </table>
        </div>
        </MDBox>
        </div> */}
      </MDBox>
    </DashboardLayout>
  );
}

export default ReportBestSeller;
