/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ImgsViewer from "react-images-viewer";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Carousel from "react-bootstrap/Carousel";
import Autocomplete from "@mui/material/Autocomplete";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import AlertDialog from "app/components/confirmBox";
import ProductCell from "layouts/ecommerce/products/product-page/components/ProductCell";
import ProductCard from "app/components/productCard";
import CarouselCard from "../components/carouselCard";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import DataTable2 from "app/components/shopperTable";
import MDInput from "components/MDInput";
import MDSnackbar from "components/MDSnackbar";

import IP from "app/IP";
import imageIP from "app/imageIP";

const axios = require("axios");

function ManageCarousel() {
  const current = new Date();
  let day = current.getDate();
  let month = current.getMonth() + 1;
  const currentDate = `${current.getFullYear()}-${month < 10 ? `0${month}` : `${month}`}-${
    day < 10 ? `0${day}` : `${day}`
  }`;
  const { state } = useLocation();

  const [data, setData] = useState([]);
  const [slides, setSlides] = useState([])
  const [carouselData, setCarouselData] = useState([])
  const [duration, setDuration] = useState("");
  const [products, setProducts] = useState({ columns: [], rows: [] });
  const [prodIds, setProdIds] = useState([]);
  const [addId, setAddId] = useState();
  const [removeId, setRemoveId] = useState();
  const [first, setFirst] = useState(true);
  const [startDate, setStartDate] = useState(currentDate);
  const [endDate, setEndDate] = useState(currentDate);
  const [vendors, setVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState({label: "", id: 0});
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [showProducts, setShowProducts] = useState(true)
  const [open, setOpen] = useState(false);
  const [id, setId] = useState()
  const [select, setSelect] = useState(state?.select ? state?.select : 0);
  const [files, setFiles] = useState([]);
  const [images, setImages] = useState([])
  const [image, setImage] = useState()
  const [errors, setErrors] = useState([])
  const [name, setName] = useState("");
  const [interval, setInterval] = useState(5);
  const [position, setPosition] = useState("");
  const [index, setIndex] = useState();
  const [language, setLanguage] = useState(localStorage.getItem("Language"));
  const [positionArray, setPositionArray] = useState(["", "", "", "", "", "", "", "", "", ""]);
  const [carouselArray, setCarouselArray] = useState([])
  const [check, setCheck] = useState(false)
  //   {
  //     products: [],
  //     interval: "",
  //     position: "",
  //     duration: "",
  //     vendor: "",
  //     name: "",
  //   },
  // ]);

  const fileRef = useRef();

  const handleChange = (e) => {
    const [fileVar] = e.target.files;
    let img = new Image()
    img.src = URL.createObjectURL(fileVar)
    img.onload = () => {
      console.log(img.width + " " + img.height);
      if (img.width == 1920 && img.height == 400) {
        files[select] = fileVar
        images[select] = URL.createObjectURL(fileVar)
        slides[select] = <Carousel.Item>
        <img width={'100%'} height={'100%'} src={images[select]} />
      </Carousel.Item>
        setImages(images)
        setShow(false)
      } else {
        setShow(true)
        if (images[select]) {
          setTimeout(()=>{
            setShow(false)
          }, 8000)
        }
      }
    }
  };

  const toggleSnackbar = () => setOpen(!open);

  const handleSelect = (selectedIndex, e) => {
    console.log('previous ' + select)
    console.log('current ' + selectedIndex)
    carouselArray[select] = {
      products: prodIds,
      interval: interval,
      position: position,
      duration:
        duration == "recurring" || duration == ""
          ? duration
          : {
              duration: duration,
              from: startDate,
              until: endDate,
            },
      vendor: selectedVendor,
      name: name,
    };
    setImage(images[selectedIndex])
    setProdIds(carouselArray[selectedIndex].products);
    setInterval(carouselArray[selectedIndex].interval);
    setName(carouselArray[selectedIndex].name);
    console.log(carouselArray[selectedIndex])
    if (
      carouselArray[selectedIndex].duration == "recurring" ||
      carouselArray[selectedIndex].duration == ""
    ) {
      setDuration(carouselArray[selectedIndex].duration);
      setStartDate(currentDate);
      setEndDate(currentDate);
    } else {
      setDuration(carouselArray[selectedIndex].duration.duration);
      setStartDate(carouselArray[selectedIndex].duration.from);
      setEndDate(carouselArray[selectedIndex].duration.until);
    }
    setPosition(carouselArray[selectedIndex].position);
    setSelectedVendor(carouselArray[selectedIndex].vendor);
    generateTable(carouselArray[selectedIndex].products, carouselArray[selectedIndex].vendor);
    setSelect(selectedIndex);
    setAddId();
    setRemoveId();
  };

  const navigate = useNavigate();

  // function getWindowDimensions() {
  //   const { innerWidth: width, innerHeight: height } = window;
  //   return {
  //     width,
  //     height,
  //   };
  // }

  // function useWindowDimensions() {
  //   const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  //   useEffect(() => {
  //     function handleResize() {
  //       setWindowDimensions(getWindowDimensions());
  //     }

  //     window.addEventListener("resize", handleResize);
  //     return () => window.removeEventListener("resize", handleResize);
  //   }, []);

  //   return windowDimensions;
  // }

  // const { height, width } = useWindowDimensions();

  async function getVendors(prods) {
    // console.log("aqui");
    //setRedirect(true);
    await axios
      .get(IP + "/vendors", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(function (response) {
        let ids = prods.map((prod) => {
          return prod.vendor.id;
        });

        let vendor = [];

        response.data.data.map((item) => {
          if (ids.includes(item.id) && item.active == "Active") {
            vendor.push({ label: item.name, id: item.id });
          }
        });

        setVendors(vendor);
        if (state?.single) {
          getSingleCarousel(vendor)
        } else {
          getCarousel(vendor)
        }
      })
      .catch(function (error) {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        //  console.log(error);
      });
  }

  async function getCarouselProducts(vendor) {
    await axios
    .get(IP + "/carousels/products", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        Accept: "application/json",
      },
    })
    .then(function (response) {
      let length = response.data.data.length
      let prods = response.data.data

      for (let i = 0; i < length; i++) {
        carouselArray.map(carousel=>{
          if (carousel.id == prods[i]?.carousel_id && prods[i].products.length > 0) {
            prods[i]?.products?.filter(item=>{
              return item.active == "Active"
            }).map(item=>{
              carousel.products.push(item.id)
            })
          }
        })
      }

      if (state?.single) {
        let current = response.data.data.filter(item=>{
          return item.carousel_id == state?.id
        })[0]
        let ids = []

        if (current.products.length > 0) {
          current.products.map(item=>{
            if (item.active == "Active") {
              ids.push(item.id)
            }
          })
        }

        setProdIds(ids)
        generateTable(ids, vendor)
      } else {
        let current = response.data.data.filter(item=>{
          return carouselArray[select].id == item.carousel_id 
        })[0]
        let ids = []

        if (current.products.length > 0) {
          current.products.filter(item=>{
            return item.active == "Active"
          }).map(item=>{
            ids.push(item.id)
          })
        }

        setProdIds(ids)
        generateTable(ids, vendor)
      }
    })
    .catch(function (error) {
      console.log(error)
    })
  }

  async function getCarousel(Vendors) {
    //setRedirect(true);
    await axios
      .get(IP + "/carousel/ecommerce", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(function (response) {
        setCarouselData(response.data.data)

        let imageArray = []
        response.data.data.map(item=>{
          carouselArray.push({
            products: [],
            id: item.carousel_id,
            duration: item.is_recurrent
            ? "recurring"
            : {
                duration: "date",
                from: item?.from,
                until: item?.to,
              },
            interval: item.interval/1000,
            name: item.name,
            position: item.position,
            vendor: 
              {
                label: Vendors.filter(vendor => {return vendor.id == item.vendor_id})[0].label,
                id: Vendors.filter(vendor => {return vendor.id == item.vendor_id})[0].id
              },
          })

          imageArray.push(item.image)
        })
        console.log(imageArray)
        setImages(imageArray)
        console.log(carouselArray)

        let current = response.data.data[select]
        console.log(select)

        getCarouselProducts({
          label: Vendors.filter(vendor => {return vendor.id == current.vendor_id})[0].label,
          id: Vendors.filter(vendor => {return vendor.id == current.vendor_id})[0].id
        })

        setDuration(current.is_recurrent ? "recurring" : "date")
        if (!current.is_recurrent) {
          setStartDate(current.from)
          setEndDate(current.to)
        }
        setInterval(current.interval/1000)
        setName(current.name)
        setPosition(current.position)
        setSelectedVendor({
          label: Vendors.filter(vendor => {return vendor.id == current.vendor_id})[0].label,
          id: Vendors.filter(vendor => {return vendor.id == current.vendor_id})[0].id
        })
      })
      .catch(function (error) {
        // if (!error.response.data.authenticated) {
        //   navigate("/signIn");
        // }
         console.log(error);
      });
  }

  async function getSingleCarousel(Vendors) {
    //setRedirect(true);
    await axios
      .get(IP + "/carousels/" + state?.id, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(function (response) {
        setCarouselData([response.data.data])
        let carousel = response.data.data

        let imageArray = [imageIP + carousel.image]
        
        setImages(imageArray)

        getCarouselProducts({
          label: Vendors.filter(vendor => {return vendor.id == carousel.vendor_id})[0].label,
          id: Vendors.filter(vendor => {return vendor.id == carousel.vendor_id})[0].id
        })

        setDuration(carousel.is_recurrent ? "recurring" : "date")
        if (!carousel.is_recurrent) {
          setStartDate(carousel.from)
          setEndDate(carousel.to)
        }
        setInterval(carousel.interval/1000)
        setName(carousel.name)
        setPosition(carousel.position)
        setSelectedVendor({
          label: Vendors.filter(vendor => {return vendor.id == carousel.vendor_id})[0].label,
          id: Vendors.filter(vendor => {return vendor.id == carousel.vendor_id})[0].id
        })
      })
      .catch(function (error) {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
         console.log(error);
      });
  }

  // function imageCheck(images) {
  //   for (let i = 0; i < images.length; i++) {
  //     for (let j = images.length - 1; j <= 0; j = j -1) {
  //       if (images[i].image == images[j].image && !check) {
  //         setCheck(false)
  //       } else {
  //         setCheck(true)
  //       }
  //     }
  //   }
  //   if (check) {
  //     console.log("images are not the same")
  //   } else {
  //     console.log("images are the same")
  //   }
  // }

  async function getProductData() {
    await axios
      .get(IP + "/products", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        response.data.data.map((item) => {
          data.push(item);
        });
        getVendors(response.data.data);
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        //   console.log(error);
      });
  }

  function generateTable(ids, vendor) {
    setProdIds(ids)
    if (vendor) {
      let columns = [
        { Header: "UPC", accessor: "upc", width: '10%' },
        { Header: language == 1 ? "UPC Unidad" : "UPC Unit", accessor: "upcunit", width: '10%' },
        { Header: language == 1 ? "Vendedor" : "Vendor", accessor: "vendor", width: '15%' },
        { Header: language == 1 ? "Nombre" : "Name", accessor: "name" },
        { Header: language == 1 ? "Acción" : "Action", accessor: "action" },
      ];

      let rows = data
        .filter((item) => {
          return item.vendor.id == vendor.id;
        })
        .map((item) => {
          return {
            upc: item.upc,
            upcunit: item.upcUnit,
            vendor: item.vendor.name,
            name: item.name,
            action: ids?.includes(item.id) ? (
              <MDButton
                color="error"
                variant="gradient"
                sx={{ float: "right" }}
                onClick={() => {
                  setRemoveId(item.id);
                  setAddId();
                }}
              >
                {language == 1 ? "Remover" : "Remove"}
              </MDButton>
            ) : (
              <MDButton
                color="info"
                variant="gradient"
                sx={{ float: "right" }}
                onClick={() => {
                  if (ids.length <= 4) {
                    setAddId(item.id);
                    setRemoveId();
                  } else {
                    setOpen(true);
                    setRemoveId();
                  }
                }}
              >
                {language == 1 ? "Añadir" : "Add"}
              </MDButton>
            ),
          };
        });

      let tableData = { columns: columns, rows: rows };

      setProducts(tableData);
    }
  }

  function addProduct(id) {
    if (!first) {
      if (id) {
        console.log(prodIds)
        prodIds.push(id);
        generateTable(prodIds, selectedVendor);
      }
    } else {
      setFirst(false);
      getProductData();
    }
  }

  function removeProduct(id) {
    if (!first) {
      if (id) {
        console.log(prodIds)
        let filteredIds = prodIds.filter((item2) => {
          return id != item2;
        });
        setProdIds(filteredIds);
        generateTable(filteredIds, selectedVendor);
      }
    } else setFirst(false);
  }

  async function saveCarousel() {
    const formData = new FormData();
    formData.append("name", name)
    if (files[select]) {
      formData.append("image", files[select])
    }
    if (duration == "recurring") {
      formData.append("is_recurrent", 1)
    } else {
      formData.append("is_recurrent", 0)
      formData.append("from", startDate)
      formData.append("to", endDate)
    }
    formData.append("interval", interval * 1000)
    formData.append("position", position)
    formData.append("vendor_id", selectedVendor?.id)
    formData.append('_method', 'PUT')

    await axios
      .post(IP + "/carousels/" + carouselData[select].carousel_id, formData, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "multipart/form-data",
        },
      })
      .then(async function(response) {
        let details = {
          carousel_id: response.data.data.carousel_id,
          products: prodIds
        }
        await axios
          .post(IP + "/carousels/products", details, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
              Accept: "application/json",
            },
          })
          .then(response=>{
            setShow2(true)
            setTimeout(()=>{
              navigate('/carousel')
            }, 4000)
          })
          .catch(function (error) {
            setErrors(error?.response?.data?.data)
            console.log(error);
            // setErrors(error.response.data.data);
          })
      })
      .catch(function (error) {
        setErrors(error?.response?.data?.data)
        console.log(error);
        // setErrors(error.response.data.data);
      })
  }

  function removeImage() {
    console.log(images)
    images[select] = null
    files[select] = null
    let newImages = []
    let newFiles = []

    images.map(item=>{
      if (item != null) {
        newImages.push(item)
      }
    })

    files.map(item=>{
      if (item != null) {
        newFiles.push(item)
      }
    })

    console.log(newImages)
    setImages(newImages)
    setFiles(newFiles)

    let filteredArray = carouselArray.filter((item) => {
      return item != carouselArray[select];
    });

    generateTable(filteredArray[select].products, filteredArray[select].vendor);
    setProdIds(filteredArray[select].products);
    setInterval(filteredArray[select].interval);
    if (filteredArray[select] == "recurring" || filteredArray[select] == "") {
      setDuration(filteredArray[select].duration);
      setStartDate(currentDate);
      setEndDate(currentDate);
    } else {
      setDuration(filteredArray[select].duration.duration);
      setStartDate(filteredArray[select].duration.from);
      setEndDate(filteredArray[select].duration.until);
    }
    setPosition(filteredArray[select].position);
    setSelectedVendor(filteredArray[select].vendor);
    setName(filteredArray[select].name);

    setCarouselArray(filteredArray);
  }

  function generateCarousel(carousel) {
    if (!first) {
      let slide = []
      for (let i = 0; i < carousel.length; i++) {
        slide.push(      
          <Carousel.Item>
            <img width={'100%'} height={'100%'} src={images[i]} />
          </Carousel.Item>
        )
      }
      setSlides(slide)
    }
  }

  function TestValidation(message) {
    if (message) {
      setTimeout(()=>{
        setErrors([])
      }, 8000)
      return message.map((item) => {
        return (
          <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
            <label>{item}</label>
          </MDTypography>
        );
      });
    }
    else return null;
  }

  function ShowError(param) {
    // console.log(errors["name"]);
    if (param.id) return TestValidation(errors[param.id]);
    else return null;
  }

  useEffect(()=>{
    generateCarousel(carouselData)
  }, [images])

  useEffect(() => {
    addProduct(addId);
  }, [addId]);

  useEffect(() => {
    removeProduct(removeId);
  }, [removeId]);

  return (
    <DashboardLayout>
      <MDSnackbar
        color="error"
        icon="notifications"
        title={language == 1 ? "EMOS Notificación" : "EMOS Notification"}
        content={
          language == 1
            ? "Cada imagen puede tener un máximo de 5 productos"
            : "Each image may only have up to 5 products."
        }
        dateTime={language==1 ? "Ahora" : "Now"}
        open={open}
        close={toggleSnackbar}
      />
      <MDSnackbar
        color="info"
        icon="notifications"
        title={language == 1 ? "EMOS Notificación" : "EMOS Notification"}
        content={
          language == 1
            ? "Guardado Exitosamente!"
            : "Saved Succesfully!"
        }
        dateTime={language==1 ? "Ahora" : "Now"}
        open={show2}
        close={()=>{setShow2(!show2)}}
      />
      <DashboardNavbar />
      <Card>
        <MDBox p={2.5}>
          {/* <MDTypography variant='h4' sx={{mb: 1}}>{language==1 ? "Carrusel" : "Carousel"}</MDTypography> */}
          <input
            ref={fileRef}
            onChange={(e) => {
              handleChange(e);
            }}
            multiple={false}
            type="file"
            hidden
          />
          <Carousel
            variant="dark"
            interval={null}
            activeIndex={select}
            controls={false}
            indicators={false}
            onSelect={handleSelect}
            keyboard={true}
          >
            {slides}
          </Carousel>
          <Grid container spacing={1} sx={{ mt: 1.5 }}>
            <Grid item lg={12} md={4}>
              <MDButton
                variant="gradient"
                color="info"
                size="medium"
                sx={{ mt: 1.5 }}
                onClick={() => fileRef.current.click()}
              >
                {language == 1 ? "Adjuntar" : "Attach"}
              </MDButton>
              {"  "}
              <MDButton
                variant="gradient"
                color="light"
                size="medium"
                sx={{ mt: 1.5 }}
                hidden={!images[select]}
                onClick={removeImage}
              >
                {language == 1 ? "remover" : "remove"}
              </MDButton>
              {show ?
                <MDTypography variant='body2' fontWeight='regular' color='error'>
                  {language == 1
                    ? "La imagen debe tener un largo de 1920 y una altura de 400"
                    : "The image must have a width of 1920 and a height of 400"}
                </MDTypography> 
              : null}
            </Grid>
          </Grid>
          <MDTypography sx={{ mt: 1 }} variant="body1">
            {language == 1 ? "Duración" : "Duration"}
          </MDTypography>
          <Grid container spacing={0}>
            <Grid item lg={2.5} md={6}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={duration}
                  onChange={(e) => {
                    setDuration(e.target.value);
                  }}
                >
                  <FormControlLabel
                    value="recurring"
                    control={<Radio />}
                    label={language == 1 ? "Recurrente" : "Recurring"}
                  />
                  <FormControlLabel
                    value="date"
                    control={<Radio />}
                    label={language == 1 ? "Rango de Fechas" : "Date Range"}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item lg={9}>
              <Grid container spacing={1}>
                {duration == "date" ? (
                  <>
                    <Grid item lg={2}>
                      <MDInput
                        type="date"
                        label={language == 1 ? "Desde" : "From"}
                        variant="standard"
                        value={startDate}
                        onChange={(e) => {
                          setStartDate(e.target.value);
                        }}
                        fullWidth
                      />
                      <ShowError id={'from'} />
                    </Grid>
                    <Grid item lg={2}>
                      <MDInput
                        type="date"
                        label={language == 1 ? "Hasta" : "To"}
                        variant="standard"
                        value={endDate}
                        onChange={(e) => {
                          setEndDate(e.target.value);
                        }}
                        fullWidth
                      />
                      <ShowError id={'to'} />
                    </Grid>
                  </>
                ) : null}
                <Grid item lg={1.5} md={5}>
                  <MDInput
                    type="number"
                    label={language == 1 ? "Intervalo (Segundos)" : "Interval (Seconds)"}
                    variant="standard"
                    value={interval}
                    onChange={(e) => {
                      if ((e.target.value >= 5 && e.target.value <= 10) || e.target.value == "") {
                        setInterval(e.target.value);
                      }
                    }}
                  />
                  <ShowError id={'interval'} />
                </Grid>
                <Grid item lg={1}>
                  <MDInput
                    type="number"
                    label={language == 1 ? "Posición" : "Position"}
                    variant="standard"
                    value={position}
                    onChange={(e) => {
                      if (
                        (e.target.value >= 1 &&
                          e.target.value <= 10 &&
                          !positionArray.includes(e.target.value)) ||
                        e.target.value == ""
                      ) {
                        setPosition(e.target.value);
                        positionArray[select] = e.target.value;
                      }
                    }}
                  />
                  <ShowError id={'position'} />
                </Grid>
                <Grid item lg={2}>
                  <MDInput
                    type="text"
                    label={language == 1 ? "Nombre" : "Name"}
                    variant="standard"
                    value={name}
                    fullWidth
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                  <ShowError id={"name"} />
                </Grid>
                <Grid item lg={2}>
                  {/* <Autocomplete
                    // sx={{ width: "25%", ml: 1, mt: 1 }}
                    disablePortal
                    value={selectedVendor}
                    onChange={(event, newValue) => {
                      generateTable(prodIds, newValue);
                      setSelectedVendor(newValue);
                      setProdIds([])
                    }}
                    options={vendors}
                    renderInput={(params) => (
                      <TextField {...params} label="Vendors" variant="standard" />
                    )}
                  /> */}
                  <MDInput
                    type="text"
                    label={language == 1 ? "Vendedor" : "Vendor"}
                    variant="standard"
                    value={selectedVendor.label}
                    fullWidth
                  />
                  <ShowError id={'vendor_id'} /> 
                </Grid>
                <Grid item lg={1.5}>
                  <MDButton
                    variant='gradient'
                    color='info'
                    size="medium"
                    onClick={saveCarousel}
                  >
                    {language == 1 ? "Guardar" : "Save"}
                  </MDButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {((images[0] && select == 0) ||
          (images[1] && select == 1) ||
          (images[2] && select == 2) ||
          (images[3] && select == 3) ||
          (images[4] && select == 4) ||
          (images[5] && select == 5) ||
          (images[6] && select == 6) ||
          (images[7] && select == 7) ||
          (images[8] && select == 8) ||
          (images[9] && select == 9)) && showProducts ? (
            <>
              <MDTypography variant="h4" sx={{ mt: 1 }}>
                {language == 1
                  ? "Selecciona hasta 5 productos"
                  : "Select up to 5 products"}
              </MDTypography>
            </>
          ) : null}
          {selectedVendor && showProducts ? (
            <MDBox mt={0}>
              <DataTable2
                table={products}
                index={index}
                setIndex={setIndex}
                pageEntries={10}
                entriesPerPage={true}
                canSearch
              />
            </MDBox>
          ) : null}
        </MDBox>
      </Card>
    </DashboardLayout>
  );
}

export default ManageCarousel;
