/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import AlertDialog from "app/components/confirmBox";
import DataTable2 from "app/components/shopperTable";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import IP from "app/IP";
import { Tooltip } from "@mui/material";

const axios = require("axios");

function CarouselView() {
  const current = new Date();
  let day = current.getDate();
  let month = current.getMonth() + 1;
  const currentDate = `${current.getFullYear()}-${month < 10 ? `0${month}` : `${month}`}-${
    day < 10 ? `0${day}` : `${day}`
  }`;

  const [menu, setMenu] = useState(null);
  const [carousel, setCarousel] = useState({ columns: [], rows: [] });
  const [show, setShow] = useState(false);
  const [data, setData] = useState([])
  const [active, setActive] = useState(false);
  const [id, setId] = useState()
  const [showConfirm, setShowConfirm] = useState(false);
  const [language, setLanguage] = useState(localStorage.getItem("Language"));

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);
  const navigate = useNavigate();
  const toggleSnackbar = () => setShow(!show);

  async function changeStatus(id) {
    await axios
      .post(
        IP + "/products/setActive/" + id,
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        toggleSnackbar();
        getProductData();
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        //    console.log(error);
      });
  }

  async function DeleteCarousel() {
    await axios
      .delete(IP + "/carousels/" + id, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(function (response) {
        getCarouselData()
      })
      .catch(function (error) {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  // async function getCurrent() {
  //   await axios
  //     .get(IP + "/carousel/ecommerce", {
  //       headers: {
  //         Authorization: "Bearer " + localStorage.getItem("token"),
  //         Accept: "application/json",
  //       },
  //     })
  //     .then(response => {
  //       getCarouselData()
  //     })
  //     .catch((error) => {
  //       if (!error.response.data.authenticated) {
  //         navigate("/signIn");
  //       }
  //       console.log(error);
  //     });
  // }

  async function getCarouselData(ecommerce) {
    await axios
      .get(IP + "/carousels", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        setData(response.data.data)
        let columns = [
          { Header: language == 1 ? "Nombre" : "Name", accessor: "name" },
          { Header: language == 1 ? "Duración" : "Duration", accessor: "duration" },
          { Header: language == 1 ? "Posición" : "Position", accessor: "position" },
          { Header: language == 1 ? "Acción" : "Action", accessor: "action" },
        ];

        let rows = []
        for (let i = 0; i < response.data.data.length; i++) {
          let item = response.data.data[i]

          // let index;
          // if (new Date(item?.from?.replace(/-/g, '\/')) > new Date(currentDate.replace(/-/g, '\/')) || item.is_recurrent == 1) {
          //   for (let i = 0; i < ecommerce.length; i++) {
          //     if (item.carousel_id == ecommerce[i].carousel_id) {
          //       index = i
          //       console.log(index)
          //     }
          //   }
          // }

          rows.push(
            {
              name: item.name,
              duration: item.is_recurrent ? "Recurring" : item.from + ' to ' + item.to,
              position: item.position,
              action: <>
              <Tooltip title="Edit">
                <MDButton variant='gradient' color='info' iconOnly onClick={()=>{
                  navigate('/edit-carousel', {state: {single: true, id: item.carousel_id}}) 
                }}>
                  <Icon>edit</Icon>
                </MDButton>
              </Tooltip>{"  "}
              <Tooltip title="Delete">
                <MDButton variant='gradient' color='error' onClick={()=>{setShowConfirm(true), setId(item.carousel_id)}} iconOnly>
                  <Icon>delete</Icon>
                </MDButton>
              </Tooltip>
              </>
            }
          )
        }

        // let rows = [
        //   {
        //     name: "Promo #1",
        //     duration: "Recurrente",
        //     position: "1",
        //   },
        //   {
        //     name: "Promo #2",
        //     duration: "11-08-2022 to 11-12-2022",
        //     position: "2",
        //   },
        // ];

        let tableData = { columns: columns, rows: rows };
        //console.log(tableData)
        setCarousel(tableData);
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  useEffect(() => {
    //getCurrent();
    getCarouselData()
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <AlertDialog
        Show={showConfirm}
        setShowConfirm={setShowConfirm}
        Message={
          language == 1
            ? "¿Estás seguro que quieres borrar este carrusel?"
            : "Are you sure you want to delete this carousel?"
        }
        OkayFunction={DeleteCarousel}
      />
      <MDBox my={3}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          <Grid item lg={4}>
          {/* <MDButton variant="gradient" color="info" onClick={()=>navigate('/edit-carousel')}>
            {language == 1 ? "Editar" : "Edit"}
          </MDButton>{"   "} */}
          {data.length < 10 ? <MDButton variant="gradient" color="info" onClick={()=>navigate('/create-carousel')}>
            {language == 1 ? "Crear" : "Create"}
          </MDButton> : null}
          </Grid>
        </MDBox>
        <Card>
          <MDBox p={3}>
            <DataTable table={carousel} entriesPerPage={true} />
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default CarouselView;
