/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import AlertDialog from "app/components/confirmBox";
import ProductCell from "layouts/ecommerce/products/product-page/components/ProductCell";
import { ExportToExcel } from "app/components/exportToExcel";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import jsPDF from "jspdf";

import IP from "app/IP";

const axios = require("axios")

function ReportPurchases() {

  const current = new Date();
  let day = current.getDate()
  let month = current.getMonth() + 1;
  const currentDate = `${current.getFullYear()}-${
  month < 10 ? `0${month}` : `${month}`
  }-${day < 10 ? `0${day}` : `${day}`}`;
  
  const [menu, setMenu] = useState(null);
  const [open, setOpen] = useState(false);
  const [sortSelect, setSortSelect] = useState()
  const [reportData, setReportData] = useState([])
  const [report, setReport] = useState({ columns: [], rows: [] })
  const [errors, setErrors] = useState([]);
  const [vendors, setVendors] = useState([])
  const [selectedVendor, setSelectedVendor] = useState()
  const [dateFrom, setDateFrom] = useState(currentDate);
  const [dateTo, setDateTo] = useState(currentDate);
  const [data, setData] = useState([])
  const [download, setDownload] = useState(true)
  const [language, setLanguage] = useState(localStorage.getItem("Language"));

  const handleClose = () => {
    setOpen(false);
  };

  const navigate = useNavigate()

  const createPDF = () => {
    setDownload(false)
    const pdf = new jsPDF("portrait", "px", "a0"); //px
    const data = document.querySelector("#pdf");
    pdf.html(data).then(() => {
      pdf.save("purchases.pdf");
      setDownload(true)
    });
  };

  async function GetVendors() {
    await axios
      .get(IP + "/vendors", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        console.log(response.data.data);
        let vendor = [];

        response.data.data.map((item) => {
          if (item.active == "Active") {
            vendor.push({ label: item.name, id: item.id });
          }
        });
        vendor.unshift({ label: language==1 ? "Todas" : "All", id: 0 })

        setVendors(vendor);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function getSaleData() {
    await axios.get(IP + '/reports/purchases?startDate=' + dateFrom + '&endDate=' + dateTo + '&vendor_id=' + selectedVendor?.id, 
    { headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      Accept: "application/json",
    },
  }).then(response=> {
    function sortTotal(total) {
      let newTotal = ''
      for (let i = 0; i < total.length; i++) {
        if (total[i] != ',')
          newTotal += total[i]
      }
      return parseFloat(newTotal)
    }

    setReportData(response.data.data.map(item=>{
      return {...item, sortTotal: sortTotal(item.total_amount)}
    }))

    let columns = [
      { Header: language==1 ? "Número de Orden" : "Order Number", accessor: "count" },
      { Header: language==1 ? "Fecha" : "Date", accessor: "date" },
      { Header: language==1 ? "Vendedor" : "Vendor", accessor: "vendor" },
      { Header: "Total", accessor: "amount" },
    ];
  
    let rows = response.data.data.map((item) => {
      return {
        count: item.po_number,
        date: item.date,
        vendor: item.vendor.name,
        amount: '$' + item.total_amount,
      }
    });

     let excelRows = response.data.data.map((item) => {
      if (language==1) {
        return {
          "Número de Orden": item.po_number,
          "Fecha": item.date,
          "Vendedor": item.vendor.name,
          "Total": item.total_amount,
        }
      }
      else {
        return {
          "Order Number": item.po_number,
          "Date": item.date,
          "Vendor": item.vendor.name,
          "Amount": item.total_amount,
        }
      }
    });

    setData(excelRows)
  
    let tableData = { columns: columns, rows: rows };
     //console.log(tableData)
    setReport(tableData)
  })
    .catch((error)=>{
      setErrors(error.response.data.data);
      if (!error.response.data.authenticated) {
        navigate("/signIn");
      }
      console.log(error)
    })
  }

  function sortAscending() {
    let columns = [
      { Header: language==1 ? "Número de Orden" : "Order Number", accessor: "count" },
      { Header: language==1 ? "Fecha" : "Date", accessor: "date" },
      { Header: language==1 ? "Vendedor" : "Vendor", accessor: "vendor" },
      { Header: "Total", accessor: "amount" },
    ];
  
    let rows = reportData
      .sort((a, b) => (a.sortTotal > b.sortTotal ? 1 : -1))
      .map((item) => {
          return {
          count: item.po_number,
          date: item.date,
          vendor: item.vendor.name,
          amount: '$' + item.total_amount,
        }
      }
    );

    let excelRows = reportData.map((item) => {
      if (language==1) {
        return {
          "Número de Orden": item.po_number,
          "Fecha": item.date,
          "Vendedor": item.vendor.name,
          "Total": item.total_amount,
        }
      }
      else {
        return {
          "Order Number": item.po_number,
          "Date": item.date,
          "Vendor": item.vendor.name,
          "Amount": item.total_amount,
        }
      }
    });

    setData(excelRows)
  
    let tableData = { columns: columns, rows: rows };
    setReport(tableData)
  }

  function sortDescending() {
    let columns = [
      { Header: language==1 ? "Número de Orden" : "Order Number", accessor: "count" },
      { Header: language==1 ? "Fecha" : "Date", accessor: "date" },
      { Header: language==1 ? "Vendedor" : "Vendor", accessor: "vendor" },
      { Header: "Total", accessor: "amount" },
    ];
  
    let rows = reportData
      .sort((a, b) => (a.sortTotal < b.sortTotal ? 1 : -1))
      .map((item) => {
          return {
          count: item.po_number,
          date: item.date,
          vendor: item.vendor.name,
          amount: '$' + item.total_amount,
        }
    });

    let excelRows = reportData.map((item) => {
      if (language==1) {
        return {
          "Número de Orden": item.po_number,
          "Fecha": item.date,
          "Vendedor": item.vendor.name,
          "Total": item.total_amount,
        }
      }
      else {
        return {
          "Order Number": item.po_number,
          "Date": item.date,
          "Vendor": item.vendor.name,
          "Amount": item.total_amount,
        }
      }
    });

    setData(excelRows)
  
    let tableData = { columns: columns, rows: rows };
    setReport(tableData)
  }

  function TestValidation(message) {
    if (message)
      return message.map((item) => {
        return (
          <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
            <label>{item}</label>
          </MDTypography>
        );
      });
    else return null;
  }

  function ShowError(param) {
    // console.log(errors["name"]);
    if (param.id) return TestValidation(errors[param.id]);
    else return null;
  }

  useEffect(()=>{
    GetVendors()
  }, [])

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ThemeProvider theme={darkTheme}>
        <Dialog open={open}>
          <DialogTitle>{language == 1 ? "Organizar por:" : "Sort by:"}</DialogTitle>
          <DialogContent>
            <MDTypography variant="body1">Total</MDTypography>
            <FormControl>
              <RadioGroup
                name="Sort Options"
                onChange={(e) => {
                  if (e.target.value == "ascending") {
                    setSortSelect(e.target.value);
                  } else if (e.target.value == "descending") {
                    setSortSelect(e.target.value);
                  }
                }}
              >
                <FormControlLabel
                  value="descending"
                  control={<Radio />}
                  label={language == 1 ? "Mayor a menor" : "Highest to lowest"}
                />
                <FormControlLabel
                  value="ascending"
                  control={<Radio />}
                  label={language == 1 ? "Menor a mayor" : "Lowest to highest"}
                />
              </RadioGroup>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{language == 1 ? "Cancelar" : "Cancel"}</Button>
            <Button
              onClick={() => {
                if (sortSelect == "ascending") {
                  sortAscending()
                }
                if (sortSelect == "descending") {
                  sortDescending()
                }
              }}
            >
              {language == 1 ? "Organizar" : "Sort"}
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
      <MDBox my={3}>
      { data.length > 0 ?
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          <MDBox display="flex">
            <MDButton variant="outlined" color="dark" onClick={() => setOpen(true)}>
              {language == 1 ? "Organizar" : "Sort"}&nbsp;
              <Icon>sort</Icon>
            </MDButton>
            <MDBox ml={1}>
            <ExportToExcel apiData={data} fileName='Purchases' />
            </MDBox>
            <MDBox ml={1}>
              <MDButton variant="outlined" color="dark" onClick={createPDF}>
                {language == 1 ? "Descargar PDF" : "Download PDF"}
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox> : null }
        <Card>
        <MDBox p={3}>
        <MDTypography variant="h4" sx={{ mb: 1 }}>
              {language == 1 ? "Busqueda" : "Search"}
            </MDTypography>
            <Grid container spacing={1}>
              <Grid id="grid1-1" item xs={12} sm={2}>
                <MDInput
                  variant="standard"
                  type="date"
                  label={language == 1 ? "Desde" : "From Date"}
                  fullWidth
                  value={dateFrom}
                  onChange={(e) => {
                    setDateFrom(e.target.value);
                  }}
                />
                <ShowError id={"startDate"} />
              </Grid>
              <Grid id="grid1-1" item xs={12} sm={2}>
                <MDInput
                  variant="standard"
                  type="date"
                  label={language == 1 ? "Hasta" : "To Date"}
                  fullWidth
                  value={dateTo}
                  onChange={(e) => {
                    setDateTo(e.target.value);
                  }}
                />
                <ShowError id={"endDate"} />
              </Grid>
              <Grid item lg={2}>
                <Autocomplete
                  disablePortal
                  value={selectedVendor}
                  onChange={(event, newValue) => {
                    setSelectedVendor(newValue);
                  }}
                  options={vendors}
                  renderInput={(params) => (
                    <MDInput
                      {...params}
                      label={language == 1 ? "Vendedores" : "Vendors"}
                      variant="standard"
                    />
                  )}
                />
              </Grid>
              <Grid id="grid1-1" item xs={12} sm={2}>
                <MDButton
                  type="button"
                  variant="gradient"
                  className="float-sm-right"
                  color="info"
                  onClick={getSaleData}
                >
                  {language == 1 ? "Busqueda" : "Search"}
                </MDButton>
              </Grid>
            </Grid>
          <DataTable table={report} entriesPerPage={true} canSearch />
        </MDBox>
        </Card>
        <div hidden={download}>
        <MDBox mt={50}>
        <div id="pdf">
          <h3>{language == 1 ? "Reporte de Compras" : "Purchases Report"}</h3>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>{language==1 ? "Número de Orden" : "Order Number"}</th>
                <th>{language==1 ? "Fecha" : "Date"}</th>
                <th>{language==1 ? "Vendedor" : "Vendor"}</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
               {reportData.map(item=>{
                  return <tr>
                    <td>{item.po_number}</td>
                    <td>{item.date}</td>
                    <td>{item.vendor.name}</td>
                    <td>${item.total_amount}</td>
                  </tr>
               })} 
            </tbody>
          </table>
        </div>
        </MDBox>
        </div>
      </MDBox>
    </DashboardLayout>
  );
}

export default ReportPurchases;
