import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MDButton from "components/MDButton";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { Grid } from "@mui/material";
import MDTypography from "components/MDTypography";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

export default function AlertDialog2({
  Show,
  setShowConfirm,
  Message,
  OkayFunction,
  selected,
  setSelected,
  fee,
  total
}) {
  const [open, setOpen] = React.useState(false);
  const [method, setMethod] = React.useState(null)
  const [language, setLanguage] = React.useState(localStorage.getItem("Language"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  //console.log(selected)

  // console.log(Show);

  return (
    <div>
      {/*  <Button variant="outlined" onClick={handleClickOpen}>
        Open alert dialog
      </Button> */}
      <Dialog
        open={Show}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        // PaperProps={{ sx: { height: "60%" } }}
      >
        <DialogTitle>
          {" "}
          <p style={{ color: "black" }}>{language == 1 ? "Favor Leer." : "Please Read."}</p>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Grid>
              {/* <h5 style={{ color: "gray" }}>
                {language == 1 ? "Método de Transportación:" : "Transportation Method:"}{" "}
              </h5> */}
              <Autocomplete
                disablePortal
                sx={{ width: {xs: 250, lg: 500} }}
                value={method}
                onChange={(event, newValue) => {
                  setMethod(newValue)
                  if (newValue?.id == 1)
                    setSelected('C')
                  else if (newValue?.id == 2)
                    setSelected('D')
                }}
                options={[
                  {id: 1, label: language==1 ? "Recogido" : "Pickup"},
                  {id: 2, label: language==1 ? "Entrega" : "Delivery"}
                ]}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={language == 1 ? "Método de Transportación:" : "Transportation Method:"}
                    variant="standard"
                  />
                )}
              />
              {/* <FormControl>
                <h5 style={{ color: "gray" }}>
                  {language == 1 ? "Método de Transportación:" : "Transportation Method:"}{" "}
                </h5>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={selected}
                  onChange={(e) => {
                    setSelected(e.target.value);
                  }}
                >
                  <FormControlLabel
                    value={"D"}
                    style={{ borderRadius: "100px" }}
                    control={<Radio />}
                    label={language==1 ? "Entrega" : "Delivery"}
                  />
                  <FormControlLabel value={"C"} control={<Radio />} label={language==1 ? "Recogido" : "Carry Out"} />
                </RadioGroup>
              </FormControl> */}
            </Grid>
            <Grid>
              <p style={{ color: "black" }}>{Message}</p>
              {selected != 'C' && selected != null ? (
                <MDTypography variant="subtitle2">
                  {language == 1 ? "Cargo por entrega: " : "Delivery fee: "}
                  {parseFloat(fee).toFixed(2)}
                </MDTypography>
              ) : null}
              <MDTypography variant="h6">
                Total: $
                {parseFloat(parseFloat(total ?? 0.0) + parseFloat(selected == 'D' ? fee : 0.0)).toFixed(2)}
              </MDTypography>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton
            variant="gradient"
            color="light"
            onClick={() => {
              setShowConfirm(false);
            }}
          >
            {language == 1 ? "Cancelar" : "Cancel"}
          </MDButton>
          <MDButton
            variant="gradient"
            color="info"
            disabled={method == null}
            onClick={() => {
              OkayFunction();
              setShowConfirm(false);
            }}
            autoFocus
          >
            {language == 1 ? "Sí" : "Yes"}
          </MDButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
