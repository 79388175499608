/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import AlertDialog from "app/components/confirmBox";
import DataTable2 from "app/components/shopperTable";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import IP from "app/IP";
import { Tooltip } from "@mui/material";

const axios = require("axios");

function ViewZones() {
  const [zoneData, setZoneData] = useState({ columns: [], rows: [] });
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false)
  const [id, setId] = useState()
  const [active, setActive] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [language, setLanguage] = useState(localStorage.getItem("Language"));

  const navigate = useNavigate();
  const toggleSnackbar = () => setShow(!show);
  const toggleSnackbar2 = () => setShow2(!show2);

//   async function changeStatus(id) {
//     await axios
//       .post(
//         IP + "/products/setActive/" + id,
//         {},
//         {
//           headers: {
//             Authorization: "Bearer " + localStorage.getItem("token"),
//             Accept: "application/json",
//           },
//         }
//       )
//       .then((response) => {
//         toggleSnackbar();
//         getProductData();
//       })
//       .catch((error) => {
//         if (!error.response.data.authenticated) {
//           navigate("/signIn");
//         }
//         if (error?.response?.data?.data) {
//           setShow3(true)
//         }
//         console.log(error);
//       });
//   }

  async function DeleteZone() {
    await axios
      .delete(IP + "/zones/" + id, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(function (response) {
        localStorage.removeItem("productDeleteId");
        getZoneData();
      })
      .catch(function (error) {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        setShow(true)
        console.log(error);
      });
  }

  async function getZoneData() {
    await axios
      .get(IP + "/zones", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        let columns = [
          { Header: language == 1 ? "Nombre" : "Name", accessor: "name" },
          { Header: language == 1 ? "Día de Entrega" : "Delivery Day", accessor: "delivery" },
          { Header: language == 1 ? "Lunes" : "Monday", accessor: "monday" },
          { Header: language == 1 ? "Martes" : "Tuesday", accessor: "tuesday" },
          { Header: language == 1 ? "Miércoles" : "Wednesday", accessor: "wednesday" },
          { Header: language == 1 ? "Jueves" : "Thursday", accessor: "thursday" },
          { Header: language == 1 ? "Viernes" : "Friday", accessor: "friday" },
          { Header: language == 1 ? "Sábado" : "Saturday", accessor: "saturday" },
          { Header: language == 1 ? "Domingo" : "Sunday", accessor: "sunday" },
          { Header: language==1 ? "Acción" : "Action", accessor: "action" }
        ];

        let rows = response.data.data.map((item) => {
          //console.log(item);
          return {
            name: item.name,
            delivery: item.delivery_day,
            monday: <Checkbox checked={item.monday} />,
            tuesday: <Checkbox checked={item.tuesday} />,
            wednesday: <Checkbox checked={item.wednesday} />,
            thursday: <Checkbox checked={item.thursday} />,
            friday: <Checkbox checked={item.friday} />,
            saturday: <Checkbox checked={item.saturday} />,
            sunday: <Checkbox checked={item.sunday} />,
            action: (
              <Grid>
                <Tooltip title={language == 1 ? "Editar" : "Edit"}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={() => {
                      navigate("/edit-zone", {state: {id: item.zone_id}});
                    }}
                    iconOnly
                  >
                    <Icon>edit</Icon>
                  </MDButton>
                </Tooltip>{" "}
                {/* {item.active == "Inactive" ? (
                  <Tooltip title={language == 1 ? "Activar" : "Activate"}>
                    <MDButton
                      variant="gradient"
                      color="success"
                      onClick={() => {
                        changeStatus(item.zone_id);
                        setActive(false);
                      }}
                      iconOnly
                    >
                      <Icon>checkmark</Icon>
                    </MDButton>
                  </Tooltip>
                ) : (
                  <Tooltip title={language == 1 ? "Desactivar" : "Deactivate"}>
                    <MDButton
                      variant="gradient"
                      color="warning"
                      onClick={() => {
                        changeStatus(item.zone_id);
                        setActive(true);
                      }}
                      iconOnly
                    >
                      <Icon>do_not_disturb</Icon>
                    </MDButton>
                  </Tooltip>
                )} */}
                {" "}
                <Tooltip title={language == 1 ? "Borrar" : "Delete"}>
                  <MDButton
                    variant="gradient"
                    color="error"
                    onClick={() => {
                      setId(item.zone_id)
                      setShowConfirm(true);
                    }}
                    iconOnly
                  >
                    <Icon>delete</Icon>
                  </MDButton>
                </Tooltip>
              </Grid>
            ),
          };
        });

        let tableData = { columns: columns, rows: rows };
        //console.log(tableData)
        setZoneData(tableData);
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  useEffect(() => {
    getZoneData();
  }, []);

  return (
    <DashboardLayout>
      <AlertDialog
        Show={showConfirm}
        setShowConfirm={setShowConfirm}
        Message={
          language == 1
            ? "¿Estás seguro que quieres borrar esta zona?"
            : "Are you sure you want to delete this zone?"
        }
        OkayFunction={DeleteZone}
      />
      {/* <MDSnackbar
        color="info"
        icon="notifications"
        title=""
        content={
          language == 1
            ? `Producto ${active ? "Desactivado" : "Activado"} Exitosamente!`
            : `Product ${active ? "Deactivated" : "Activated"} Succesfully!`
        }
        dateTime={language==1 ? "Ahora" : "Now"}
        open={show}
        close={toggleSnackbar}
      /> */}
      <MDSnackbar
        color="error"
        icon="notifications"
        title=""
        content={
          language == 1
            ? "Esta zona actualmente esta asignada a una tienda; no se pudo borrar."
            : "This zone is currently assigned to a store; could not be deleted."
        }
        dateTime={language==1 ? "Ahora" : "Now"}
        open={show}
        close={toggleSnackbar}
      />
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          <MDButton variant="gradient" color="info" onClick={()=>navigate('/create-zone')}>
            {language == 1 ? "Crear zona" : "create zone"}
          </MDButton>
        </MDBox>
        <Card>
          <DataTable
            table={zoneData}
            entriesPerPage={true}
            canSearch
          />
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default ViewZones;
