/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import { Button, Grid } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import AlertDialog from "app/components/confirmBox";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import dataTableData from "layouts/ecommerce/orders/order-list/data/dataTableData";

import IP from "app/IP";
import DataTable2 from "app/components/shopperTable";

const axios = require("axios");

function Cities() {
  const [menu, setMenu] = useState(null);
  const [cityData, setCityData] = useState({ columns: [], rows: [] });
  const [showConfirm, setShowConfirm] = useState(false);
  const [index, setIndex] = useState(0);
  const [language, setLanguage] = useState(localStorage.getItem("Language"));
  const [show, setShow] = useState(false);
  const toggleSnackbar = () => setShow(!show);

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);
  const navigate = useNavigate();

  async function DeleteCity() {
    await axios
      .delete(IP + "/cities/" + localStorage.getItem("cityDeleteId"), {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(function (response) {
        localStorage.removeItem("cityDeleteId");
        getCityData();
      })
      .catch(function (error) {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function changeStatus(id) {
    await axios
      .post(
        IP + "/cities/setActive/" + id,
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        toggleSnackbar();
        getCityData();
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function getCityData() {
    await axios
      .get(IP + "/cities", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        let cityColumns = [
          { Header: language == 1 ? "Nombre" : "Name", accessor: "name" },
          { Header: language == 1 ? "Activo" : "Active", accessor: "active" },
          { Header: language == 1 ? "Acción" : "Action", accessor: "action", width: "25%" },
        ];

        let cityRows = response.data.data.map((item) => {
          //console.log(item);
          return {
            name: item.name,
            active: item.active,
            action: (
              <Grid>
                {item.active == "Inactive" ? (
                  <MDButton
                    variant="gradient"
                    color="success"
                    onClick={() => {
                      changeStatus(item.id);
                    }}
                  >
                    {language == 1 ? "Activar" : "Activate"}
                  </MDButton>
                ) : (
                  <MDButton
                    variant="gradient"
                    color="warning"
                    onClick={() => {
                      changeStatus(item.id);
                    }}
                    iconOnly
                  >
                    <Icon>do_not_disturb</Icon>
                  </MDButton>
                )}{" "}
                <MDButton
                  variant="gradient"
                  color="info"
                  onClick={() => {
                    localStorage.setItem("cityId", item.id);
                    localStorage.setItem("cityName", item.name);
                    navigate("/edit-city");
                  }}
                  iconOnly
                >
                  <Icon>edit</Icon>
                </MDButton>
                <MDButton
                  variant="gradient"
                  color="error"
                  onClick={() => {
                    localStorage.setItem("cityDeleteId", item.id);
                    setShowConfirm(true);
                  }}
                  iconOnly
                >
                  <Icon>delete</Icon>
                </MDButton>
              </Grid>
            ),
          };
        });

        let tableData = { columns: cityColumns, rows: cityRows };
        //console.log(tableData)
        setCityData(tableData);
      })
      .catch((error) => {
        console.log(error);
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
      });
  }

  useEffect(() => {
    getCityData();
  }, []);

  return (
    <DashboardLayout>
      <AlertDialog
        Show={showConfirm}
        setShowConfirm={setShowConfirm}
        Message={
          language == 1
            ? "¿Estás seguro de que quieres borrar esta ciudad?"
            : "Are you sure you want to delete this city?"
        }
        OkayFunction={DeleteCity}
      />
      <MDSnackbar
        color="info"
        icon="notifications"
        title=""
        content={language == 1 ? "La ciudad ha sido activada!" : "City Activated Succesfully!"}
        dateTime={language==1 ? "Ahora" : "Now"}
        open={show}
        close={toggleSnackbar}
      />
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          <MDButton variant="gradient" color="info" onClick={() => navigate("/create-city")}>
            {language == 1 ? "Crear Ciudad" : "create city"}
          </MDButton>
        </MDBox>
        <Card>
          <DataTable2
            table={cityData}
            index={index}
            setIndex={setIndex}
            pageEntries={10}
            entriesPerPage={false}
            canSearch
          />
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default Cities;
