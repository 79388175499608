import { useEffect, useState, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import AlertDialog from "app/components/confirmBox";
import DataTable2 from "app/components/shopperTable";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// NewUser page components
import UserInfo from "layouts/pages/users/new-user/components/UserInfo";
import Address from "layouts/pages/users/new-user/components/Address";
import Socials from "layouts/pages/users/new-user/components/Socials";
import Profile from "layouts/pages/users/new-user/components/Profile";

// NewUser layout schemas for form and form feilds
import validations from "layouts/pages/users/new-user/schemas/validations";
import form from "layouts/pages/users/new-user/schemas/form";
import initialValues from "layouts/pages/users/new-user/schemas/initialValues";
import MDInput from "components/MDInput";

import IP from "app/IP";
import { item } from "examples/Sidenav/styles/sidenavItem";

const axios = require("axios");

function AddOnSale() {
  const current = new Date();
  let day = current.getDate();
  let month = current.getMonth() + 1;
  const currentDate = `${current.getFullYear()}-${month < 10 ? `0${month}` : `${month}`}-${
    day < 10 ? `0${day}` : `${day}`
  }`;

  const [startDate, setStartDate] = useState(currentDate);
  const [endDate, setEndDate] = useState(currentDate);
  const [data, setData] = useState()
  const [discountPrice, setDiscountPrice] = useState("");
  const [price, setPrice] = useState("");
  const [error, setError] = useState()
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false)
  const [name, setName] = useState("");
  const [id, setId] = useState();
  const [prod, setProd] = useState();
  const [productIds, setProductIds] = useState([]);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false)
  const [show3, setShow3] = useState(false)
  const [show4, setShow4] = useState(false)
  const [selected, setSelected] = useState(false)
  const [disableButton, setDisableButton] = useState(false);
  const [errors, setErrors] = useState([]);
  const [index, setIndex] = useState(0)
  const [index2, setIndex2] = useState(0)
  const [products, setProducts] = useState({ columns: [], rows: [] });
  const [selectedTable, setSelectedTable] = useState({ columns: [], rows: [] })
  const [selectedProd, setSelectedProd] = useState([])
  const [language, setLanguage] = useState(localStorage.getItem("Language"));
  const [onSaleId, setOnSaleId] = useState();
  const toggleSnackbar = () => setShow(!show);

  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
    setEdit(false);
    setDiscountPrice("");
  };

  async function getProducts() {
    await axios
      .get(IP + "/products", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        setData(response.data.data)
        setShow2(true)
        let productColumns = [
          { Header: language == 1 ? "UPC Unidad" : "UPC Unit", accessor: "upcunit", width: "8%" },
          { Header: "UPC", accessor: "upc", width: "5%" },
          { Header: language == 1 ? "Código" : "Code", accessor: "code", width: "5%" },
          { Header: language == 1 ? "Nombre" : "Name", accessor: "name", width: "25%" },
          { Header: language == 1 ? "Precio Regular" : "Regular Price", accessor: "price" },
          { Header: language == 1 ? "Precio Especial" : "On Sale Price", accessor: "saleprice", width: '25%' },
          { Header: language == 1 ? "Acción" : "Action", accessor: "action", width: "10%" },
        ];

        let activeProds = response.data.data.filter((item)=>{
            return item.active == "Active"
        })

        let productRows = activeProds.map((item) => {
          return {
            upcunit: item.upcUnit,
            name: item.name,
            price: "$" + item.sales_price,
            upc: item.upc,
            code: item.code,
            saleprice: productIds.some((item2) => item.id == item2.product_id) ? (
              <Grid container spacing={2}>
                <Grid mt={1.2} item xs={12} lg={8}>
                  <MDTypography variant="inherit" color="inherit">
                    ${
                      productIds.filter((item2) => {
                        return item.id == item2.product_id;
                      })[0].specialPrice
                    }
                  </MDTypography>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <MDButton
                    color="info"
                    iconOnly
                    onClick={() => {
                      setOpen(true);
                      setId(item.id);
                      setName(item.name);
                      setPrice(item.sales_price);
                      setDiscountPrice(
                        productIds
                          .filter((item2) => {
                            item2.product_id == item.id, console.log(item2);
                            return item2.price;
                          })
                          .map((item2) => {
                            if (item.id == item2.product_id) {
                              return item2.price;
                            }
                          })
                      );
                      setProd(item);
                      setEdit(true);
                    }}
                  >
                    <Icon>edit</Icon>
                  </MDButton>
                </Grid>
              </Grid>
            ) : (
              0
            ),
            action: !productIds ? (
              <MDButton
                variant="gradient"
                color="info"
                onClick={() => {
                  setOpen(true);
                  setId(item.id);
                  setName(item.name);
                  setPrice(item.sales_price);
                }}
              >
                {language == 1 ? "Añadir" : "Add"}
              </MDButton>
            ) : productIds.some((item2) => item.id == item2.product_id) ? (
              <MDButton
                color="error"
                variant="gradient"
                onClick={() => {
                  setId(item.id)
                  setShowConfirm(true)
                }}
              >
                {language == 1 ? "Remover" : "Remove"}
              </MDButton>
            ) : (
              <MDButton
                variant="gradient"
                color="info"
                onClick={() => {
                  setOpen(true);
                  setId(item.id);
                  setName(item.name);
                  setPrice(item.sales_price);
                }}
              >
                {language == 1 ? "Añadir" : "Add"}
              </MDButton>
            ),
          };
        });

        let tableData = { columns: productColumns, rows: productRows };
        //console.log(tableData)
        setProducts(tableData);

        let columns = [
          { Header: language == 1 ? "UPC Unidad" : "UPC Unit", accessor: "upcunit", width: "8%" },
          { Header: "UPC", accessor: "upc", width: "5%" },
          { Header: language == 1 ? "Código" : "Code", accessor: "code", width: "5%" },
          { Header: language == 1 ? "Nombre" : "Name", accessor: "name", width: "25%" },
          { Header: language == 1 ? "Precio Regular" : "Regular Price", accessor: "price" },
          { Header: language == 1 ? "Precio Especial" : "On Sale Price", accessor: "saleprice" },
          { Header: language == 1 ? "Acción" : "Action", accessor: "action", width: "10%" },
        ];
  
        let rows = selectedProd.map((item) => {
          return {
            upcunit: item.upcUnit,
            name: item.name,
            price: "$" + item.sales_price,
            upc: item.upc,
            saleprice: "$" + item.specialPrice,
            code: item.code,
            action: 
              <MDButton
                color="error"
                variant="gradient"
                onClick={() => {
                  setId(item.id)
                  setShowConfirm(true);
                }}
              >
                {language == 1 ? "Remover" : "Remove"}
              </MDButton>,
          };
        });
  
        let tableData2 = { columns: columns, rows: rows }
  
        setSelectedTable(tableData2)
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function getProducts2(list, selectedList) {
    await axios
      .get(IP + "/products", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        let productColumns = [
          { Header: language == 1 ? "UPC Unidad" : "UPC Unit", accessor: "upcunit", width: "8%" },
          { Header: "UPC", accessor: "upc", width: "5%" },
          { Header: language == 1 ? "Código" : "Code", accessor: "code", width: "5%" },
          { Header: language == 1 ? "Nombre" : "Name", accessor: "name", width: "25%" },
          { Header: language == 1 ? "Precio Regular" : "Regular Price", accessor: "price" },
          { Header: language == 1 ? "Precio Especial" : "On Sale Price", accessor: "saleprice" },
          { Header: language == 1 ? "Acción" : "Action", accessor: "action", width: "10%" },
        ];

        let activeProds = response.data.data.filter((item)=>{
            return item.active == "Active"
        })

        let productRows = activeProds.map((item) => {
          return {
            upcunit: item.upcUnit,
            name: item.name,
            code: item.code,
            price: "$" + item.sales_price,
            upc: item.upc,
            saleprice: list.some((item2) => item.id == item2.product_id) ? (
              <Grid container spacing={2}>
                <Grid mt={1.2} item xs={12} lg={8}>
                  <MDTypography variant="inherit" color="inherit">
                    ${
                      list.filter((item2) => {
                        return item.id == item2.product_id;
                      })[0].specialPrice
                    }
                  </MDTypography>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <MDButton
                    color="info"
                    iconOnly
                    onClick={() => {
                      setOpen(true);
                      setId(item.id);
                      setName(item.name);
                      setPrice(item.sales_price);
                      setDiscountPrice(
                        list
                          .filter((item2) => {
                            item2.id == item.id, console.log(item2);
                            return item2.price;
                          })
                          .map((item2) => {
                            if (item.id == item2.product_id) {
                              return item2.price;
                            }
                          })
                      );
                      setProd(item);
                      setEdit(true);
                    }}
                  >
                    <Icon>edit</Icon>
                  </MDButton>
                </Grid>
              </Grid>
            ) : (
              0
            ),
            action: !productIds ? (
              <MDButton
                variant="gradient"
                color="info"
                onClick={() => {
                  setOpen(true);
                  setId(item.id);
                  setName(item.name);
                  setPrice(item.sales_price);
                }}
              >
                {language == 1 ? "Añadir" : "Add"}
              </MDButton>
            ) : list.some((item2) => item.id == item2.product_id) ? (
              <MDButton
                color="error"
                variant="gradient"
                onClick={() => {
                  setId(item.id)
                  setShowConfirm(true);
                }}
              >
                {language == 1 ? "Remover" : "Remove"}
              </MDButton>
            ) : (
              <MDButton
                variant="gradient"
                color="info"
                onClick={() => {
                  setOpen(true);
                  setId(item.id);
                  setName(item.name);
                  setPrice(item.sales_price);
                }}
              >
                {language == 1 ? "Añadir" : "Add"}
              </MDButton>
            ),
          };
        });

        let tableData = { columns: productColumns, rows: productRows };
        //console.log(tableData)
        setProducts(tableData);

        let columns = [
          { Header: language == 1 ? "UPC Unidad" : "UPC Unit", accessor: "upcunit", width: "8%" },
          { Header: "UPC", accessor: "upc", width: "5%" },
          { Header: language == 1 ? "Código" : "Code", accessor: "code", width: "5%" },
          { Header: language == 1 ? "Nombre" : "Name", accessor: "name", width: "25%" },
          { Header: language == 1 ? "Precio Regular" : "Regular Price", accessor: "price" },
          { Header: language == 1 ? "Precio Especial" : "On Sale Price", accessor: "saleprice" },
          { Header: language == 1 ? "Acción" : "Action", accessor: "action", width: "10%" },
        ];
  
        let rows = selectedList.map((item) => {
          return {
            upcunit: item.upcUnit,
            name: item.name,
            code: item.code,
            price: "$" + item.sales_price,
            upc: item.upc,
            saleprice: <Grid container spacing={2}>
            <Grid mt={1.2} item xs={12} lg={8}>
              <MDTypography variant="inherit" color="inherit">
                ${item.specialPrice}
              </MDTypography>
            </Grid>
            <Grid item xs={12} lg={4}>
              <MDButton
                color="info"
                iconOnly
                onClick={() => {
                  setOpen(true);
                  setId(item.id);
                  setName(item.name);
                  setPrice(item.sales_price);
                  setDiscountPrice(item.specialPrice);
                  setProd(item);
                  setEdit(true);
                }}
              >
                <Icon>edit</Icon>
              </MDButton>
            </Grid>
          </Grid>,
            action: 
              <MDButton
                color="error"
                variant="gradient"
                onClick={() => {
                  setId(item.id)
                  setShowConfirm(true);
                }}
              >
                {language == 1 ? "Remover" : "Remove"}
              </MDButton>,
          };
        });
  
        let tableData2 = { columns: columns, rows: rows }
  
        setSelectedTable(tableData2)
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function sendSale() {
    setDisableButton(true);
    if (productIds.length == 0 && !onSaleId) { 
      await axios
        .post(
          IP + "/on_sale",
          {
            startDate: startDate,
            endDate: endDate,
            active: 1
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          console.log(response.data.data);
          setOnSaleId(response.data.data.id);
          getProducts();
          console.log(response);
          toggleSnackbar();
          setShow3(true)
          setError()
        })
        .catch((error) => {
          if (error.response.data.data){
            setErrors(error.response.data.data);
            setError()
          }
          if (!error.response.data.authenticated) {
            navigate("/signIn");
          }
            setError(error.response.data.message);
        });
    } else {
      await axios
        .put(
          IP + "/on_sale/" + onSaleId,
          {
            startDate: startDate,
            endDate: endDate,
            active: 1,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          console.log(response);
          setShow4(true)
          setTimeout(()=>{
            navigate('/on-sale')
          }, 4000)
          //  toggleSnackbar();
        })
        .catch((error) => {
          setErrors(error.response.data.data);
          if (!error.response.data.authenticated) {
            navigate("/signIn");
          }
          console.log(error);
        });

      await axios
        .post(
          IP + "/on_sale/" + onSaleId + "/os_details",
          {
            products: productIds,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
              Accept: "application/json",
            },
          }
        )
        .then((response) => {})
        .catch((error) => {
          setErrors(error.response.data.data);
          if (!error.response.data.authenticated) {
            navigate("/signIn");
          }
          console.log(error);
        });
    }

    setTimeout(() => {
      setDisableButton(false);
    }, 3000);
  }

  function addDiscount() {
    productIds.push({
      product_id: id,
      specialPrice: parseFloat(discountPrice).toFixed(2),
      on_sale_id: onSaleId,
    });
    console.log(productIds);
    data.map(item=>{
      if (item.id == id){
        selectedProd.push({...item, specialPrice: parseFloat(discountPrice).toFixed(2)})
      }
    })
    getProducts2(productIds, selectedProd);
    handleClose();
  }

  function removeDiscount(item) {
    console.log(item);
    console.log(productIds);
    let filteredArray = productIds.filter((item2) => {
      return item2.product_id !== item;
    });
    let selectedFiltered = selectedProd.filter(prod=>{
      return prod.id !== item
    })
    console.log("result");
    console.log(filteredArray);
    setSelectedProd(selectedFiltered)
    setProductIds(filteredArray);
    console.log(selectedFiltered)
    getProducts2(filteredArray, selectedFiltered);
  }

  function changeTable() {
    if (show2) {
      setShow2(false)
    }
    else{
      setShow2(true)
    }
  }

  function editDiscount(item) {
    console.log(item);
    console.log(productIds);
    let filteredArray = productIds.filter((item2) => {
      return item2.product_id !== item.id;
    });
    filteredArray.push({
      product_id: id,
      specialPrice: parseFloat(discountPrice).toFixed(2),
      on_sale_id: onSaleId,
    });
    let filteredSelected = selectedProd.filter(item2=>{
      return item2.id !== item.id
    })
    filteredSelected.push({...item, specialPrice: parseFloat(discountPrice).toFixed(2)})
    setProductIds(filteredArray);
    setSelectedProd(filteredSelected)
    getProducts2(filteredArray, filteredSelected);
  }

  function TestValidation(message) {
    if (message)
      return message.map((item) => {
        return (
          <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
            <label>{item}</label>
          </MDTypography>
        );
      });
    else return null;
  }

  function ShowError(param) {
    // console.log(errors["name"]);
    if (param.id) return TestValidation(errors[param.id]);
    else return null;
  }

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  return (
    <DashboardLayout>
      <MDSnackbar
        color="info"
        icon="notifications"
        title=""
        content={
          language == 1 ? "El especial fue creado exitosamente!" : "Sale Created Succesfully!"
        }
        dateTime={language==1 ? "Ahora" : "Now"}
        open={show}
        close={toggleSnackbar}
      />
      <MDSnackbar
        color="info"
        icon="notifications"
        title=""
        content={
          language == 1 ? "Los productos se guardaron exitosamente!" : "Products Saved Succesfully!"
        }
        dateTime={language==1 ? "Ahora" : "Now"}
        open={show4}
        close={()=>{setShow4(!show4)}}
      />
       <AlertDialog
        Show={showConfirm}
        setShowConfirm={setShowConfirm}
        Message={language==1 
          ? "¿Estás seguro de que quieres remover este producto del especial?" 
          : "Are you sure you want to remove this product from the sale?"
        }
        OkayFunction={()=>removeDiscount(id)}
      />
      <ThemeProvider theme={darkTheme}>
        <Dialog open={open}>
          <DialogTitle>{name}</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label={language == 1 ? "Precio Especial" : "Discounted Price"}
              type="number"
              variant="standard"
              value={discountPrice}
              onChange={(e) => {
                if (
                  e.target.value < parseFloat(price) &&
                  (e.target.value > 0 || e.target.value == "")
                ) {
                  setDiscountPrice(e.target.value);
                }
              }}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{language == 1 ? "Cancelar" : "Cancel"}</Button>
            {edit ? (
              <Button
                onClick={() => {
                  editDiscount(prod), handleClose();
                }}
                disabled={discountPrice == ""}
              >
                {language == 1 ? "Editar" : "Edit"}
              </Button>
            ) : (
              <Button onClick={addDiscount} disabled={discountPrice == ""}>
                {language == 1 ? "Añadir" : "Add"}
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </ThemeProvider>
      <DashboardNavbar />
      <MDBox py={3} mb={20}>
        <Grid container sx={{ height: "100%" }}>
          <Grid item xs={12} lg={12}>
            <Card sx={{ height: "100%" }}>
              <MDBox p={3}>
                <MDBox lineHeight={0}>
                  <MDTypography variant="h4">
                    {language == 1 ? "Crear Especial" : "Create Sale"}
                  </MDTypography>
                  <MDTypography variant="button" color="text">
                    {language == 1
                      ? "Favor de entrar la información del especial."
                      : "Please enter sale information."}
                  </MDTypography>
                </MDBox>
                <MDBox mt={1.625}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6} lg={6}>
                      <MDInput
                        type="date"
                        label={language == 1 ? "Desde" : "Since"}
                        variant="standard"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        fullWidth
                      />
                      <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                        <label>{error}</label>
                      </MDTypography>
                      <ShowError id={'startDate'} />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <MDInput
                        type="date"
                        label={language == 1 ? "Hasta" : "Until"}
                        variant="standard"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        fullWidth
                      />
                      <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                        <label>{error}</label>
                      </MDTypography>
                      <ShowError id={'endDate'} />
                    </Grid>
                  </Grid>
                  {show3 ? show2 ? 
                  <>
                  {productIds.length!==0 ?
                  <MDBox mt={2} mb={-8} width="100%" display="flex" justifyContent="space-between">
                    <MDButton variant="gradient" color='info' onClick={changeTable}>
                      {language==1 ? "Productos Seleccionados" : "Selected Products"}
                    </MDButton>
                  </MDBox> : null
                  }
                  <DataTable2 table={products} index={index} setIndex={setIndex} pageEntries={10} entriesPerPage={false} canSearch />
                  </> 
                  : 
                  <>
                  <MDBox mt={2} mb={-8} width="100%" display="flex" justifyContent="space-between">
                    <MDButton variant="gradient" color='info' onClick={changeTable}>
                      {language==1 ? "Seleccionar Productos" : "Select Products"}
                    </MDButton>
                  </MDBox>
                  <DataTable2 table={selectedTable} index={index2} setIndex={setIndex2} pageEntries={10} entriesPerPage={false} canSearch />
                  </> : null
                  }
                  <Grid item xs={12} lg={12}>
                    <MDBox mt={2} width="100%" display="flex" justifyContent="space-between">
                      <MDButton
                        variant="gradient"
                        color="light"
                        onClick={() => navigate("/on-sale")}
                      >
                        {language == 1 ? "Cancelar" : "Cancel"}
                      </MDButton>
                      <MDButton
                        variant="gradient"
                        color="info"
                        onClick={sendSale}
                        disabled={disableButton}
                      >
                        {language == 1 ? "Guardar" : "Save"}
                      </MDButton>
                    </MDBox>
                  </Grid>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default AddOnSale;
