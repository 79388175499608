/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import AlertDialog from "app/components/confirmBox";
import ProductCell from "layouts/ecommerce/products/product-page/components/ProductCell";
import { ExportToExcel } from "app/components/exportToExcel";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import jsPDF from "jspdf";

import IP from "app/IP";
import { item } from "examples/Sidenav/styles/sidenavItem";

const axios = require("axios")

function ReportTaxes() {

  const current = new Date();
  let day = current.getDate()
  let month = current.getMonth() + 1;
  const currentDate = `${current.getFullYear()}-${
  month < 10 ? `0${month}` : `${month}`
  }-${day < 10 ? `0${day}` : `${day}`}`;
  
  const [menu, setMenu] = useState(null);
  const [open, setOpen] = useState(false);
  const [sortSelect, setSortSelect] = useState()
  const [reportData, setReportData] = useState([])
  const [stores, setStores] = useState([])
  const [report, setReport] = useState({ columns: [], rows: [] })
  const [errors, setErrors] = useState([]);
  const [selectedStore, setSelectedStore] = useState()
  const [dateFrom, setDateFrom] = useState(currentDate);
  const [dateTo, setDateTo] = useState(currentDate);
  const [data, setData] = useState([])
  const [download, setDownload] = useState(true)
  const [language, setLanguage] = useState(localStorage.getItem("Language"));

  const handleClose = () => {
    setOpen(false);
  };

  const navigate = useNavigate()

  const createPDF = () => {
    setDownload(false)
    const pdf = new jsPDF("portrait", "px", "a0"); //px
    const data = document.querySelector("#pdf");
    pdf.html(data).then(() => {
      pdf.save("taxes.pdf");
      setDownload(true)
    });
  };

  async function GetStores() {
    await axios
      .get(IP + "/stores", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        console.log(response.data.data);
        setStores(response.data.data.filter(item => item.active == "Active").map(item=>{
          return {label: item.name, id: item.id}
        }));
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function getReportData() {
    await axios.get(IP + '/reports/taxes?startDate=' + dateFrom + '&endDate=' + dateTo + (selectedStore?.id ? `&store_id=${selectedStore?.id}` : ""), 
    { headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      Accept: "application/json",
    },
  }).then(response=> {
    console.log(response.data.data)
    setReportData([{"subtotal": response.data.data.subtotal, "10%": response.data.data["10.5"], "1%": response.data.data["1"], total: response.data.data.total}])

    let columns = [
      { Header: "Subtotal", accessor: "subtotal" },
      { Header: "10.5%", accessor: '10' },
      { Header: "1%", accessor: "1" },
      { Header: "Total", accessor: "total" },
    ];
  
    // let rows = response.data.data.map((item) => {
    //   return {
    //     subtotal: item.subtotal,
    //     "10.5": item["10.5"],
    //     "1": item["1"],
    //     total: item.total,
    //   }
    // });
  
    let tableData = { columns: columns, rows: [{...response.data.data, "10": response.data.data["10.5"]}] };
    // console.log(tableData)
    setReport(tableData)
  })
    .catch((error)=>{
      // setErrors(error.response.data.data);
      if (!error?.response?.data?.authenticated) {
        navigate("/signIn");
      }
      console.log(error)
    })
  }

  function TestValidation(message) {
    if (message)
      return message.map((item) => {
        return (
          <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
            <label>{item}</label>
          </MDTypography>
        );
      });
    else return null;
  }

  function ShowError(param) {
    // console.log(errors["name"]);
    if (param.id) return TestValidation(errors[param.id]);
    else return null;
  }

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  useEffect(() => {
    GetStores()
  }, [])

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ThemeProvider theme={darkTheme}>
        <Dialog open={open}>
          <DialogTitle>{language == 1 ? "Organizar por:" : "Sort by:"}</DialogTitle>
          <DialogContent>
            <MDTypography variant="body1">Total</MDTypography>
            <FormControl>
              <RadioGroup
                name="Sort Options"
                onChange={(e) => {
                  if (e.target.value == "ascending") {
                    setSortSelect(e.target.value);
                  } else if (e.target.value == "descending") {
                    setSortSelect(e.target.value);
                  }
                }}
              >
                <FormControlLabel
                  value="descending"
                  control={<Radio />}
                  label={language == 1 ? "Mayor a menor" : "Highest to lowest"}
                />
                <FormControlLabel
                  value="ascending"
                  control={<Radio />}
                  label={language == 1 ? "Menor a mayor" : "Lowest to highest"}
                />
              </RadioGroup>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{language == 1 ? "Cancelar" : "Cancel"}</Button>
            <Button
              onClick={() => {
                if (sortSelect == "ascending") {
                  sortAscending()
                }
                if (sortSelect == "descending") {
                  sortDescending()
                }
              }}
            >
              {language == 1 ? "Organizar" : "Sort"}
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
      <MDBox my={3}>
      { report.rows.length > 0 &&
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          <MDBox display="flex">
            {/* <MDButton variant="outlined" color="dark" onClick={() => setOpen(true)}>
              {language == 1 ? "Organizar" : "Sort"}&nbsp;
              <Icon>sort</Icon>
            </MDButton> */}
            <MDBox ml={1}>
            <ExportToExcel apiData={reportData} fileName='Taxes' />
            </MDBox>
            <MDBox ml={1}>
              <MDButton variant="outlined" color="dark" onClick={createPDF}>
                {language == 1 ? "Descargar PDF" : "Download PDF"}
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>}
        <Card>
        <MDBox p={3}>
        <MDTypography variant="h4" sx={{ mb: 1 }}>
              {language == 1 ? "Busqueda" : "Search"}
            </MDTypography>
            <Grid container spacing={1}>
              <Grid id="grid1-1" item xs={12} sm={2}>
                <MDInput
                  variant="standard"
                  type="date"
                  label={language == 1 ? "Desde" : "From Date"}
                  fullWidth
                  value={dateFrom}
                  onChange={(e) => {
                    setDateFrom(e.target.value);
                  }}
                />
                <ShowError id={"startDate"} />
              </Grid>
              <Grid id="grid1-1" item xs={12} sm={2}>
                <MDInput
                  variant="standard"
                  type="date"
                  label={language == 1 ? "Hasta" : "To Date"}
                  fullWidth
                  value={dateTo}
                  onChange={(e) => {
                    setDateTo(e.target.value);
                  }}
                />
                <ShowError id={"endDate"} />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Autocomplete
                  disablePortal
                  value={selectedStore}
                  onChange={(event, newValue) => {
                    setSelectedStore(newValue);
                  }}
                  options={stores}
                  renderInput={(params) => (
                    <MDInput
                      {...params}
                      label={language == 1 ? "Tiendas" : "Stores"}
                      variant="standard"
                    />
                  )}
                />
              </Grid>
              <Grid id="grid1-1" item xs={12} sm={2}>
                <MDButton
                  type="button"
                  variant="gradient"
                  className="float-sm-right"
                  color="info"
                  onClick={getReportData}
                >
                  {language == 1 ? "Busqueda" : "Search"}
                </MDButton>
              </Grid>
            </Grid>
          <DataTable table={report} entriesPerPage={true} canSearch />
          <div hidden={download}>
        <MDBox mt={150}>
        <div id="pdf">
          <h3>{language == 1 ? "Reporte de Impuestos" : "Taxes Report"}</h3>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Subtotal</th>
                <th>10.5</th>
                <th>1%</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
               {reportData.map(item=>{
                  return <tr>
                    <td>{item.subtotal}</td>
                    <td>{item["10.5"]}</td>
                    <td>{item["1"]}</td>
                    <td>{item.total}</td>
                  </tr>
               })} 
            </tbody>
          </table>
        </div>
        </MDBox>
        </div>
        </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default ReportTaxes;
